import { ReactNode } from "react";
import { Color } from "../../referentials";
import { BaseCardContainer, BaseCardContainerInterface } from "../BaseCardContainer";
import { CardCommon } from "../Card/CardCommon";

export interface RowCardInterface extends Omit<BaseCardContainerInterface, "border"> {
    icon?: ReactNode;
    color?: Color;
    footer?: ReactNode;
    title?: CardCommon.WithTitle;
}

export function RowCard({ icon, children, footer, color, title, ...props }: RowCardInterface): JSX.Element {
    return (
        <BaseCardContainer
            children={
                <div className="flex w-full flex-col md:flex-row gap-5 p-5">
                    {icon && <div className="flex shrink-0 items-center justify-center rounded-lg">{icon}</div>}
                    <div className="flex w-full flex-grow flex-col self-center">
                        {title && <CardCommon.Title {...title} />}
                        <div className="flex flex-1 flex-col gap-5">
                            <div className="flex items-center gap-5 font-light">{children}</div>
                            {footer && <footer>{footer}</footer>}
                        </div>
                    </div>
                </div>
            }
            {...(color && { border: { color, position: "left" } })}
            {...props}
        />
    );
}

import { IconSvgProps } from "../../../../../common";

export default function ScopeIPOutlinedSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.58423 19.2071H14.4156V21.0968C14.4156 21.5937 14.0092 22 13.5124 22H10.4876C9.99068 22 9.58429 21.5937 9.58429 21.0968V19.2071H9.58423Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.68115 17.4847H15.3191V19.2902C15.3191 19.7871 14.9127 20.1935 14.4159 20.1935H9.58439C9.08761 20.1935 8.68115 19.7871 8.68115 19.2902V17.4847Z"
            />
            <path
                d="M12.0002 5.87532C14.7939 5.87532 17.0587 8.14012 17.0587 10.9338C17.0587 12.2317 16.6912 12.8604 16.2165 13.6724C16.1804 13.7341 16.1438 13.7968 16.1066 13.8609C15.5893 14.7536 15.0403 15.8273 14.8549 17.8869H9.14544C8.95999 15.8273 8.41098 14.7537 7.89373 13.861C7.85657 13.7968 7.81992 13.7341 7.78389 13.6725C7.30915 12.8604 6.94165 12.2318 6.94165 10.9338C6.94165 8.14012 9.20645 5.87532 12.0002 5.87532Z"
                stroke={color ?? "black"}
                fill="none"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8716 18.2718V14.1505H10.3363C9.95799 14.1505 9.61435 13.9954 9.36548 13.7455C9.11662 13.4956 8.96216 13.1506 8.96216 12.7707C8.96216 12.3908 9.11662 12.0457 9.36548 11.7958C9.61435 11.546 9.95799 11.3909 10.3363 11.3909C10.7146 11.3909 11.0583 11.546 11.3071 11.7958C11.556 12.0457 11.7105 12.3908 11.7105 12.7707V13.3082H12.2905V12.7707C12.2905 12.3908 12.445 12.0457 12.6938 11.7958C12.9427 11.546 13.2863 11.3909 13.6647 11.3909C14.043 11.3909 14.3866 11.546 14.6355 11.7958C14.8844 12.0457 15.0388 12.3908 15.0388 12.7707C15.0388 13.1506 14.8844 13.4956 14.6355 13.7455C14.3866 13.9954 14.043 14.1505 13.6647 14.1505H13.1294V18.2718H12.2905V14.1505H11.7105V18.2718H10.8716ZM10.8716 13.3081V12.7706C10.8716 12.6233 10.8112 12.489 10.714 12.3914C10.6168 12.2938 10.4831 12.2332 10.3363 12.2332C10.1896 12.2332 10.0559 12.2938 9.95866 12.3914C9.86145 12.489 9.8011 12.6233 9.8011 12.7706C9.8011 12.918 9.86145 13.0523 9.95866 13.1499C10.0559 13.2475 10.1896 13.3081 10.3363 13.3081L10.8716 13.3081ZM13.1294 13.3081H13.6647C13.8114 13.3081 13.9452 13.2475 14.0424 13.1499C14.1396 13.0523 14.1999 12.918 14.1999 12.7707C14.1999 12.6233 14.1396 12.489 14.0424 12.3914C13.9452 12.2938 13.8114 12.2332 13.6647 12.2332C13.5179 12.2332 13.3842 12.2938 13.287 12.3914C13.1898 12.489 13.1294 12.6234 13.1294 12.7707V13.3081Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4347 3.69062C12.4347 3.93074 12.2401 4.12543 12.0001 4.12543C11.7601 4.12543 11.5655 3.93074 11.5655 3.69062V2.43482C11.5655 2.19469 11.7601 2 12.0001 2C12.2401 2 12.4347 2.19469 12.4347 2.43482V3.69062ZM19.1774 8.0872C18.9702 8.20633 18.7057 8.13481 18.5866 7.92751C18.4675 7.72021 18.539 7.45557 18.7462 7.33643L19.8333 6.70851C20.0405 6.58937 20.305 6.66084 20.4241 6.86819C20.5432 7.07549 20.4718 7.34013 20.2645 7.45927L19.1774 8.0872ZM16.3946 4.98381C16.2755 5.19111 16.011 5.26258 15.8038 5.1435C15.5966 5.02436 15.5252 4.75972 15.6442 4.55242L16.2718 3.46484C16.3909 3.25754 16.6554 3.18607 16.8627 3.30515C17.0699 3.42429 17.1413 3.68893 17.0223 3.89623L16.3946 4.98381ZM5.25403 7.33643C5.46123 7.45557 5.53267 7.72021 5.41364 7.92751C5.29456 8.13481 5.03004 8.20628 4.82284 8.0872L3.73575 7.45927C3.52855 7.34013 3.45711 7.07549 3.57614 6.86819C3.69522 6.66089 3.95974 6.58942 4.16694 6.70851L5.25403 7.33643ZM8.356 4.55068C8.47601 4.75798 8.40533 5.02338 8.19813 5.14344C7.99092 5.26351 7.72564 5.19279 7.60563 4.9855L6.97799 3.89791C6.85798 3.69062 6.92866 3.42522 7.13587 3.30515C7.34307 3.18509 7.60835 3.2558 7.72836 3.4631L8.356 4.55068Z"
            />
        </svg>
    );
}

import { IconSvgProps } from "../Icons";

export default function MailSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} color={color} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.75 20.25H5.25C3.6 20.25 2.25 18.9 2.25 17.25V6.75C2.25 5.1 3.6 3.75 5.25 3.75H18.75C20.4 3.75 21.75 5.1 21.75 6.75V17.25C21.75 18.9 20.4 20.25 18.75 20.25Z"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path d="M2.25 7.5L12 13.5L21.75 7.5" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
}

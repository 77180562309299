/* eslint-disable max-len */
import { merge } from "lodash";
import { frCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const fr: BaseLang = merge(frCommon, {
    apps: {
        account: {
            name: "Compte",
            description: "Gérez vos informations personnelles et vos préférences."
        },
        deposit: {
            name: "Dépôt logiciel",
            description: "Déposez vos actifs numériques en quelques clics sur nos serveurs sécurisés."
        },
        escrow: {
            name: "Escrow agreement",
            description: "Créez le contrat d'entiercement logiciel adapté à vos besoins."
        },
        "full-audit": {
            name: "Due Diligence technologique",
            description: "Effectuez une due diligence logicielle pour limiter vos risques de propriété intellectuelle, de cybersécurité et de maintenabilité."
        },
        timestamping: {
            name: "Timestamping",
            description: "Garantissez la date exacte et l'intégrité des données de vos documents importants."
        }
    },
    register: {
        slogan: "La solution en ligne pour protéger et auditer vos actifs numériques"
    }
});

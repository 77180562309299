/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { initFirebase, useAuthEmulator, useFirestoreEmulator, useStorageEmulator } from "@vaultinum/vaultinum-sdk";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";

initFirebase(process.env.REACT_APP_FIREBASE || "");

if (process.env.REACT_APP_FIREBASE_EMULATOR_HOST) {
    // eslint-disable-next-line no-console
    console.log("Running local environment");
    useAuthEmulator(`http://${process.env.REACT_APP_FIREBASE_EMULATOR_HOST}:${process.env.REACT_APP_AUTH_PORT}`);
    useFirestoreEmulator(`${process.env.REACT_APP_FIREBASE_EMULATOR_HOST}`, Number(process.env.REACT_APP_FIRESTORE_PORT));
    useStorageEmulator(`${process.env.REACT_APP_FIREBASE_EMULATOR_HOST}`, Number(process.env.REACT_APP_STORAGE_PORT));
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);

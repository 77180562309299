import { AccountRights, AccountRole } from "@vaultinum/vaultinum-api";
import { Input, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { AccountLang } from "../lang/AccountLang";
import { formatAccountRole, formatAccountRoleDescription } from "../services";
import { getAccountRoleRights, getAccountUserRole } from "../services/accountService";

export default function AccountUserRightsSelector({
    isLoggedUserAdmin,
    rights,
    onRightsChange,
    isDisabled
}: {
    isLoggedUserAdmin: boolean;
    rights: AccountRights[];
    onRightsChange: (rights: AccountRights[]) => void;
    isDisabled?: boolean;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    const accountUserRole = getAccountUserRole(rights);
    const [selectedRole, setSelectedRole] = useState<AccountRole | undefined>(accountUserRole);

    const rightsOptions = [...(isLoggedUserAdmin ? [AccountRole.ADMIN] : []), AccountRole.REPRESENTATIVE, AccountRole.CONTRIBUTOR, AccountRole.READ];

    function onValueChange(role: AccountRole) {
        setSelectedRole(role);
        onRightsChange(getAccountRoleRights(role));
    }

    return (
        <div className="space-y-2">
            {rightsOptions.map(role => (
                <div key={role} className="flex gap-5 p-2">
                    <Input.Radio
                        id={role}
                        name="roles"
                        value={role}
                        checked={role === selectedRole}
                        {...(role !== selectedRole && { onClick: () => onValueChange(role) })}
                        disabled={isDisabled}
                        label={
                            <>
                                <div className="font-bold">{formatAccountRole(lang, role)}</div>
                                <div>{formatAccountRoleDescription(lang, role)}</div>
                            </>
                        }
                    />
                </div>
            ))}
        </div>
    );
}

import { uniq } from "lodash";
import { Chart } from "react-google-charts";
import { COLOR_SCHEME } from "../../../referentials";

export type SankeyChartParams = {
    dataset: {
        // from & to are the keys and could have been cleaned from special characters
        from: string;
        to: string;
        weight: number;
        color?: string;
        // Original values to display
        raw?: {
            from: string;
            to: string;
        };
    }[];
    nodeColors?: Record<string, string>;
    height?: string | number;
    width?: string | number;
    hasBgColor?: boolean;
};

export function SankeyChart({ dataset, width, height, nodeColors, hasBgColor = true }: SankeyChartParams): JSX.Element {
    const nodes = uniq(dataset.flatMap(data => [data.from, data.to]));
    const colors = nodes.map(node => nodeColors?.[node] ?? COLOR_SCHEME.blue.primary);

    return (
        <Chart
            chartType="Sankey"
            width={width ?? "100%"}
            height={height ?? "100%"}
            options={{
                sankey: {
                    node: {
                        colors,
                        label: {
                            fontSize: 18
                        }
                    }
                },
                ...(!hasBgColor && {
                    backgroundColor: "transparent"
                })
            }}
            data={[
                ["from", "to", "weight", { type: "string", role: "style" }],
                ...dataset.map(data => [data.raw?.from ?? data.from, data.raw?.to ?? data.to, data.weight, `color: ${data.color ?? COLOR_SCHEME.grey.light}`])
            ]}
        />
    );
}

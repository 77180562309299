import { IconSvgProps } from "../Icons";

function ReportSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 0H2V21C2 21.7956 2.31607 22.5587 2.87868 23.1213C3.44129 23.6839 4.20435 24 5 24H20V20H22V0ZM18 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21C4 20.7348 4.10536 20.4804 4.29289 20.2929C4.48043 20.1054 4.73478 20 5 20H18V22ZM20 18H5C4.65886 18.0027 4.32067 18.0636 4 18.18V2H20V18Z"
                fill={color}
            />
            <path d="M17 5H7V7H17V5Z" fill={color} />
            <path d="M17 9H7V11H17V9Z" fill={color} />
        </svg>
    );
}

export default ReportSvg;

import { LanguageCode } from "@vaultinum/vaultinum-api";
import { getLang, languages, useLang } from "../../lang";

function useIndustryList(langCode?: LanguageCode): { value: string; label: string }[] {
    const lang = langCode ? getLang(langCode, languages) : useLang();

    const industries: { value: string; label: string }[] = [
        { value: "businessConsultancyManagement", label: lang.industryList.businessConsultancyManagement },
        { value: "accountancyBankingFinance", label: lang.industryList.accountancyBankingFinance },
        { value: "energyUtilities", label: lang.industryList.energyUtilities },
        { value: "engineeringManufacturing", label: lang.industryList.engineeringManufacturing },
        { value: "environmentAgriculture", label: lang.industryList.environmentAgriculture },
        { value: "softwarePublisher", label: lang.industryList.softwarePublisher },
        { value: "iTServices", label: lang.industryList.iTServices },
        { value: "law", label: lang.industryList.law },
        { value: "lawEnforcementSecurity", label: lang.industryList.lawEnforcementSecurity },
        { value: "leisureHospitalityTourism", label: lang.industryList.leisureHospitalityTourism },
        { value: "mediaDigitalPublicRelations", label: lang.industryList.mediaDigitalPublicRelations },
        { value: "propertyConstruction", label: lang.industryList.propertyConstruction },
        { value: "publicServicesAdministration", label: lang.industryList.publicServicesAdministration },
        { value: "scientificResearch", label: lang.industryList.scientificResearch },
        { value: "pharmaceuticalsHealthcare", label: lang.industryList.pharmaceuticalsHealthcare },
        { value: "education", label: lang.industryList.education },
        { value: "transportLogistics", label: lang.industryList.transportLogistics }
    ].sort((a, b) => a.label.localeCompare(b.label));

    return [...industries, { value: "other", label: lang.industryList.other }];
}

export default useIndustryList;

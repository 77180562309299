import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, CardWithFeedback, Controller, Form, Input, UseFormReturn, message } from "../../../design-system";
import { getNormalisedBrowserLang, useLang } from "../../../lang";
import { useRequiredString } from "../../hooks";
import { ABSOLUTE_SHOWCASE_URL, yup } from "../../services";
import { confirmValidation, passwordValidation, useForm } from "../../tools";
import AuthenticationLogo from "../AuthenticationLogo";
import PasswordFields from "./PasswordFields";

export interface RegisterFormInterface {
    doRegister: ({ email, password }: { email: string; password: string }) => Promise<void>;
    loginUrl: string;
    companyName?: string;
    initialEmailValue?: string;
    logoSubtitle?: ReactNode;
}

interface RegisterInputs {
    email: string;
    password: string;
    confirm: string;
    "accept-terms-conditions": boolean;
}

export default function RegisterForm({ doRegister, loginUrl, companyName, initialEmailValue, logoSubtitle }: RegisterFormInterface): JSX.Element {
    const [working, setWorking] = useState(false);
    const lang = useLang();
    const langCode = getNormalisedBrowserLang();

    const schema = yup.object<RegisterInputs>({
        email: useRequiredString().matches(EMAIL_REGEX, lang.shared.invalidEmail),
        password: passwordValidation(lang),
        confirm: confirmValidation(lang),
        "accept-terms-conditions": yup
            .boolean()
            .oneOf([true], lang.register.acceptTermsConditionsAndPrivacyPolicyErrorMessage)
            .required(lang.register.acceptTermsConditionsAndPrivacyPolicyErrorMessage)
    });

    const form = useForm<RegisterInputs>({
        schema,
        mode: "onChange"
    });

    const onFinish = async ({ email, password }: RegisterInputs) => {
        setWorking(true);
        try {
            await doRegister({ email, password });
        } catch (error) {
            void message.error(lang.register.failMessage);
        } finally {
            setWorking(false);
        }
    };

    const { password, confirm } = form.watch();

    useEffect(() => {
        // Needed to trigger confirm password match from first password field
        if (confirm) {
            void form.trigger("confirm");
        }
    }, [password, confirm]);

    return (
        <CardWithFeedback
            extra={<AuthenticationLogo logoSubtitle={logoSubtitle} />}
            title={<h1 className="text-lg">{companyName ? lang.register.join(companyName) : lang.register.createAccount}</h1>}
        >
            <Form data-id="registration-form" onSubmit={form.handleSubmit(onFinish)}>
                <p>{lang.register.registerWelcome}</p>
                <Controller
                    name="email"
                    control={form.control}
                    data-id="email-control"
                    defaultValue={initialEmailValue}
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            data-id="email-input"
                            label={lang.shared.email}
                            autoFocus={!initialEmailValue}
                            disabled={working}
                            required
                            errorMessage={form.formState.errors.email?.message}
                        />
                    )}
                />
                <PasswordFields
                    form={form as unknown as UseFormReturn<Pick<RegisterInputs, "password" | "confirm">>}
                    initialEmailValue={initialEmailValue}
                    password={password}
                    working={working}
                    lang={lang}
                />
                <Controller
                    name="accept-terms-conditions"
                    control={form.control}
                    data-id="conditions-policy-control"
                    render={({ field: { onChange } }) => (
                        <Input.Checkbox
                            label={
                                <>
                                    {lang.register.iAcceptThe}
                                    <a
                                        href={ABSOLUTE_SHOWCASE_URL.langCode(langCode).legalNotice.platformUserAgreement}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link pl-1"
                                    >
                                        {lang.register.termsConditions}
                                    </a>
                                    <span> & </span>
                                    <a
                                        href={ABSOLUTE_SHOWCASE_URL.langCode(langCode).legalNotice.privacyPolicy}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        {lang.register.privacyPolicy}
                                    </a>
                                </>
                            }
                            data-id="accept-conditions-policy"
                            id="accept-conditions-policy"
                            disabled={working}
                            onChange={onChange}
                            required
                            errorMessage={form.formState.errors["accept-terms-conditions"]?.message}
                        />
                    )}
                />
                <div
                    className={classNames("flex flex-wrap pt-6", {
                        "justify-between": !companyName,
                        "justify-end": companyName
                    })}
                >
                    {!companyName && (
                        <div className="space-x-1">
                            <span>{lang.register.alreadyRegistered}</span>
                            <Link to={loginUrl} className="link" children={lang.register.login} />
                        </div>
                    )}
                    <Button
                        data-id="register-button"
                        htmlType="submit"
                        isLoading={working}
                        children={lang.register.register}
                        isDisabled={!form.formState.isValid}
                    />
                </div>
            </Form>
        </CardWithFeedback>
    );
}

import { IconSvgProps } from "../../../../../common";

export default function ScopeEsgOutlinedSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4815 11.8912L18.4829 11.8892C19.3135 10.7227 19.7803 9.02679 19.9689 7.31482C20.1567 5.61056 20.0522 4.04995 19.8646 3.24348C19.8269 3.08697 19.6855 2.9875 19.5306 3.00136C18.7053 3.0974 17.2014 3.51734 15.6589 4.25587C14.1056 4.99951 12.6572 5.99828 11.8255 7.16587C11.5908 7.49649 11.3932 7.81559 11.2286 8.12283C10.9715 7.908 10.7025 7.70268 10.4258 7.50734C10.5941 7.20642 10.7884 6.89928 11.0106 6.58635C12.9574 3.85227 17.503 2.22706 19.4244 2.00698C20.0847 1.93926 20.6857 2.37096 20.838 3.01427C21.2782 4.90188 21.2443 9.73519 19.2975 12.4693C17.5786 14.8955 15.6361 15.6221 13.9599 15.4549C14.0679 15.1463 14.1459 14.8178 14.1878 14.4702C14.4565 14.4874 14.7319 14.475 15.0108 14.4286C16.0525 14.255 17.2845 13.5808 18.4815 11.8912ZM13.2117 10.4655C13.3596 10.7258 13.4895 10.9812 13.6026 11.2314C14.7226 9.41786 15.9969 7.68294 17.4185 6.09541C17.6471 5.84993 17.2916 5.51135 17.0461 5.73143C16.4366 6.35781 15.861 7.02652 15.3024 7.69522C14.5707 8.58847 13.8687 9.51137 13.2117 10.4655Z"
            />
            <path
                d="M12.5181 10.2819L12.5184 10.2824C13.6821 12.1168 13.8862 13.6496 13.6178 14.8371C13.3479 16.0318 12.5842 16.9473 11.6838 17.5143C10.7813 18.0827 9.62555 18.3834 8.42966 18.1199C7.24139 17.8582 5.94323 17.0237 4.77968 15.1894L4.77961 15.1893C3.94812 13.8793 3.56151 12.0646 3.45996 10.3094C3.35862 8.55752 3.54546 6.94577 3.79624 6.08328C3.91211 5.68916 4.30013 5.44353 4.70278 5.50582C5.58493 5.64718 7.12133 6.16479 8.663 7.00335C10.2079 7.84369 11.6866 8.96794 12.5181 10.2819Z"
                stroke={color ?? "black"}
                fill="none"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.0647 8.75899C6.98452 8.66919 6.88264 8.62287 6.77698 8.62099C6.67481 8.61917 6.58133 8.65896 6.51118 8.71839C6.37156 8.83666 6.29621 9.06562 6.45174 9.26423C7.1326 10.1469 7.78947 11.1404 8.38037 12.0943C10.0086 14.7602 11.3804 17.6149 12.1625 20.6422L12.1627 20.643L12.3647 21.4593C12.4429 21.8395 12.826 22.0839 13.2042 21.9732C13.5599 21.8727 13.7577 21.5057 13.6587 21.1539L13.4128 20.2973L13.4126 20.2965C12.8247 18.3286 11.9735 16.4054 10.9875 14.6033L10.9873 14.6031C9.85751 12.5473 8.55713 10.5753 7.06888 8.76387L7.06684 8.76139L7.0647 8.75899Z"
            />
        </svg>
    );
}

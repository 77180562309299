import { values } from "lodash";
import { ChevronLeftIcon, ChevronRightIcon } from "../..";
import { endYear, startYear, years } from "../../../helpers";
import { CommonLang, useLang } from "../../../lang";

export function DatePickerHeader({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    range
}: {
    changeYear: (year: number) => void;
    changeMonth: (month: number) => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    date?: Date;
    range?: [number, number];
}) {
    const lang = useLang<CommonLang>();
    const months = values(lang.shared.months);
    return (
        <div className="flex items-center justify-center gap-2">
            <ChevronLeftIcon
                onClick={e => {
                    e.stopPropagation();
                    if (!prevMonthButtonDisabled) {
                        decreaseMonth();
                    }
                }}
            />
            <select
                className="hover:border-grey focus:shadow-outline-slate cursor-pointer rounded-md border border-grey-light bg-white p-2 text-base outline-none transition duration-300 focus:border-slate-primary"
                children={years(range || [startYear, endYear]).map(year => {
                    return (
                        <option key={year} value={year.toString()}>
                            {year}
                        </option>
                    );
                })}
                value={date instanceof Date ? date.getFullYear().toString() : ""}
                onChange={e => changeYear(parseInt(e.target.value))}
            />
            <select
                className="hover:border-grey focus:shadow-outline-slate cursor-pointer rounded-md border border-grey-light bg-white p-2 text-base outline-none transition duration-300 focus:border-slate-primary"
                children={months.map(month => {
                    return (
                        <option key={month} value={month.toString()}>
                            {month}
                        </option>
                    );
                })}
                value={months[date instanceof Date ? date.getMonth() : 0]}
                onChange={e => changeMonth(months.indexOf(e.target.value))}
            />
            <ChevronRightIcon
                onClick={e => {
                    e.stopPropagation();
                    if (!nextMonthButtonDisabled) {
                        increaseMonth();
                    }
                }}
            />
        </div>
    );
}

import classNames from "classnames";
import { ReactNode } from "react";

export function Badge({
    children,
    title,
    size,
    type = "primary",
    onClick,
    dot,
    count
}: {
    children: string | number | ReactNode;
    size: "small" | "middle";
    title?: string;
    type?: "primary" | "transparent" | "success" | "warning" | "danger";
    onClick?: () => void;
    dot?: boolean;
    count?: JSX.Element;
}): JSX.Element {
    return (
        <div
            onClick={onClick}
            title={title}
            className={classNames(`relative bg-${type} flex items-center justify-center rounded-3xl`, {
                "h-7 w-7": size === "small",
                "h-9 w-9 text-lg": size === "middle",
                "cursor-pointer": !!onClick,
                "cursor-default": !onClick,
                "text-white": type !== "transparent"
            })}
        >
            {children}
            {count && <div className="absolute left-2/3">{count}</div>}
            {dot && <div className="absolute top-0 left-2/3 h-2 w-2 rounded-full bg-danger" />}
        </div>
    );
}

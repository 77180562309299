import { ColCard, Skeleton, Skeletons } from "../../../../design-system";

function Rows({ count }: { count: number }): JSX.Element {
    return (
        <>
            {Array.from({ length: count }, (_v, i) => (
                <Skeletons.Row key={i} row={["w-6 h-6 rounded-full", "h-4 flex-1"]} />
            ))}
        </>
    );
}

export default function PricingColCardSkeleton({
    featureCount,
    hasIcon = false,
    hasButton = false
}: {
    featureCount: number;
    hasIcon?: boolean;
    hasButton?: boolean;
}): JSX.Element {
    return (
        <div className="h-max w-full sm:max-w-sm">
            <ColCard
                header={
                    <div className="flex flex-col items-center gap-8">
                        <Skeleton className="m-auto h-8 w-2/3" />
                        {hasIcon && <Skeleton className="m-auto h-24 w-24" />}
                        <Skeleton className="m-auto h-8 w-2/3" />
                        {hasButton && <Skeleton className="m-auto h-10 w-2/5 rounded-md" />}
                    </div>
                }
                children={
                    <div className="hidden flex-col gap-4 px-4 sm:flex">
                        <Rows count={featureCount} />
                    </div>
                }
            />
        </div>
    );
}

import { ElementType } from "react";
import { CommonLang, useLang } from "../../../lang";

export function NoResult({ logo: Logo }: { logo: ElementType }): JSX.Element {
    const lang = useLang<CommonLang>();
    return (
        <div className="m-12 flex flex-col items-center space-y-6">
            <Logo />
            <div className="text-center text-2xl">{lang.shared.noResult}</div>
            <div>{lang.shared.changeFilter}</div>
        </div>
    );
}

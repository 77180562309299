import { DEBUG_LANG, LANG_DE, LANG_EN, LANG_ES, LANG_FR, LANG_IT, LanguageCode, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { CommonLang } from "./CommonLang";
import { deCommon, enCommon, esCommon, frCommon, itCommon } from "./locales";

export const DEFAULT_LANG = LANG_EN;

export const SUPPORTED_LANGUAGES: {
    code: LanguageCode;
    label: string;
    enLabel: string;
}[] = [
    { code: LANG_EN, label: enCommon.getLang, enLabel: enCommon.getLang },
    { code: LANG_FR, label: frCommon.getLang, enLabel: enCommon.langs.french },
    { code: LANG_DE, label: deCommon.getLang, enLabel: enCommon.langs.german },
    { code: LANG_ES, label: esCommon.getLang, enLabel: enCommon.langs.spanish },
    { code: LANG_IT, label: itCommon.getLang, enLabel: enCommon.langs.italian },
    ...((process.env.REACT_APP_PLATFORM || process.env.REACT_APP_PLATFORM) === "development"
        ? [
              {
                  code: DEBUG_LANG as LanguageCode,
                  label: enCommon.langs.debug,
                  enLabel: enCommon.langs.debug
              }
          ]
        : [])
];

export const languages = new Map<SupportedLanguageCode, CommonLang>([
    [LANG_EN, enCommon],
    [LANG_FR, frCommon],
    [LANG_DE, deCommon],
    [LANG_ES, esCommon],
    [LANG_IT, itCommon]
]);

function getSupportedLang(langCode: LanguageCode = DEFAULT_LANG) {
    return SUPPORTED_LANGUAGES.find(language => language.code === langCode);
}

export function getLangLabel(langCode?: LanguageCode): string {
    return getSupportedLang(langCode)?.label || enCommon.getLang;
}

export function getLangEnLabel(langCode?: LanguageCode): string {
    return getSupportedLang(langCode)?.enLabel || enCommon.getLang;
}

export function objectMap<T extends object>(obj: T, fct: (entry: ReturnType<typeof Object.entries>[number]) => typeof entry): typeof obj {
    return Object.fromEntries(Object.entries(obj).map(fct)) as typeof obj;
}

export function isNotNull<T>(value: T | null): value is T {
    return value !== null;
}

export function isObject(obj: unknown): obj is Record<string, unknown> {
    return typeof obj === "object" && obj !== null && !Array.isArray(obj) && !(obj instanceof Date);
}

export function isArrayOfObject(arr: unknown): arr is object[] {
    return Array.isArray(arr) && arr.every(item => isObject(item));
}

export function isArrayOfString(arr: unknown): arr is string[] {
    return Array.isArray(arr) && arr.every(item => typeof item === "string");
}

import {
    Button,
    ContentLoader,
    List,
    ModalHelper,
    openNotificationWithIcon,
    PaymentMethodAddModal,
    PaymentMethodDetails,
    StripePaymentService,
    useAuthContext,
    useLang,
    useModal
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import Stripe from "stripe";
import { AccountLang } from "../lang/AccountLang";
import ViewWrapper from "./ViewWrapper";

export default function PaymentMethodList(): JSX.Element {
    const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>();
    const { isOpen, doOpen, doClose } = useModal();
    const { selectedAccount: account } = useAuthContext();
    const lang = useLang<AccountLang>();
    const paymentService = new StripePaymentService();

    const listPaymentMethods = async function () {
        try {
            if (account) {
                setPaymentMethods(await paymentService.listPaymentMethods(account.id));
            }
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: lang.accountSettings.billingView.paymentMethods.listPaymentMethodFailed });
        }
    };

    useEffect(() => {
        void listPaymentMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onDeletePaymentMethod(paymentMethod: Stripe.PaymentMethod): void {
        ModalHelper.Confirm({
            title: lang.accountSettings.billingView.paymentMethods.deletePaymentMethod,
            children: lang.accountSettings.billingView.paymentMethods.deletePaymentMethodConfirmation,
            okText: lang.shared.delete,
            onConfirm: async function () {
                try {
                    if (account) {
                        await paymentService.detachPaymentMethod(account.id, paymentMethod.id);
                        await listPaymentMethods();
                    }
                } catch (e) {
                    openNotificationWithIcon({ type: "error", description: lang.accountSettings.billingView.paymentMethods.deletePaymentMethodFailed });
                }
            },
            lang
        });
    }

    if (!account) {
        return <ContentLoader />;
    }

    return (
        <ViewWrapper title={lang.accountSettings.billingView.paymentMethods.title}>
            <div className="ml-auto">
                <Button onClick={doOpen} isLoading={false}>
                    {lang.payment.method.addPaymentMethod}
                </Button>
            </div>
            <PaymentMethodAddModal accountId={account.id} visible={isOpen} doClose={doClose} onSuccessCallback={listPaymentMethods} />
            <List
                list={paymentMethods}
                render={paymentMethod => (
                    <PaymentMethodDetails.Action
                        paymentMethod={paymentMethod}
                        actions={[
                            {
                                label: lang.accountSettings.billingView.paymentMethods.deletePaymentMethod,
                                onClick: () => onDeletePaymentMethod(paymentMethod)
                            }
                        ]}
                    />
                )}
                emptyText={lang.shared.noResult}
            />
        </ViewWrapper>
    );
}

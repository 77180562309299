import { App } from "@vaultinum/vaultinum-api";
import { PriceInfo, doGet } from "@vaultinum/vaultinum-sdk";
import Stripe from "stripe";

export function getInvoices(accountId: string): Promise<Stripe.Invoice[]> {
    return doGet(`account/${accountId}/invoices`, {}, process.env.REACT_APP_API_HOST);
}

export function getProductName(plan: App.PricingPlans | undefined, productList: PriceInfo[]) {
    let productName = "N/A";

    if (plan) {
        productName = productList.find(element => element.typeKey === plan)?.title || productName;
    }

    return productName;
}

import {
    Account,
    ACCOUNT_COLLECTION,
    ACCOUNT_GIT_CONNECTION_COLLECTION,
    ACCOUNT_GIT_CONNECTION_REPOSITORY_COLLECTION,
    ACCOUNT_GIT_OPERATION_COLLECTION
} from "@vaultinum/vaultinum-api";
import { collection, DocumentSnapshot, getDocs, getFirestore, onSnapshot, orderBy, query, where } from "../../../common";

const docToGitConnection = (snapshot: DocumentSnapshot): Account.GitConnection => ({
    ...(snapshot.data() as Account.GitConnection),
    id: snapshot.id,
    installationDate: snapshot.data()?.installationDate?.toDate(),
    lastUpdateDate: snapshot.data()?.lastUpdateDate?.toDate(),
    lastConnectionDate: snapshot.data()?.lastConnectionDate?.toDate()
});

const docToGitRepository = (snapshot: DocumentSnapshot): Account.GitRepository => ({
    ...(snapshot.data() as Account.GitRepository),
    id: snapshot.id,
    additionDate: snapshot.data()?.additionDate?.toDate(),
    creationDate: snapshot.data()?.creationDate?.toDate()
});

const docToGitOperation = (snapshot: DocumentSnapshot): Account.GitOperation => ({
    ...(snapshot.data() as Account.GitOperation),
    id: snapshot.id,
    creationDate: snapshot.data()?.creationDate?.toDate(),
    lastUpdateDate: snapshot.data()?.lastUpdateDate?.toDate(),
    nextUpdateDate: snapshot.data()?.nextUpdateDate?.toDate()
});

export function getGitConnections(accountId: string, onUpdate: (gitConnections: Account.GitConnection[]) => void): () => void {
    return onSnapshot(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_GIT_CONNECTION_COLLECTION), querySnapshot => {
        onUpdate(querySnapshot.docs.map(docToGitConnection));
    });
}

export function getGitOperations(accountId: string, onUpdate: (gitOperations: Account.GitOperation[]) => void): () => void {
    return onSnapshot(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_GIT_OPERATION_COLLECTION), querySnapshot => {
        onUpdate(querySnapshot.docs.map(docToGitOperation));
    });
}

export async function getGitRepositories(accountId: string, gitConnectionId: string, onlyActive?: boolean): Promise<Account.GitRepository[]> {
    const gitRepositoriesCollection = collection(
        getFirestore(),
        ACCOUNT_COLLECTION,
        accountId,
        ACCOUNT_GIT_CONNECTION_COLLECTION,
        gitConnectionId,
        ACCOUNT_GIT_CONNECTION_REPOSITORY_COLLECTION
    );
    const repositoriesDocs = await getDocs(onlyActive ? query(gitRepositoriesCollection, where("isActive", "==", true)) : gitRepositoriesCollection);

    return repositoriesDocs.docs.map(docToGitRepository);
}

export function getGitOperation(accountId: string, targetId: string): Promise<Account.GitOperation | null>;
export function getGitOperation(accountId: string, targetId: string, onUpdate: (gitOperation: Account.GitOperation | null) => void): () => void;
export function getGitOperation(
    accountId: string,
    targetId: string,
    onUpdate?: (surveyLangs: Account.GitOperation | null) => void
): Promise<Account.GitOperation | null> | (() => void) {
    const queryOperation = query(
        collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_GIT_OPERATION_COLLECTION),
        where("usedBy.targetId", "==", targetId)
    );
    if (onUpdate) {
        return onSnapshot(queryOperation, querySnapshot => {
            if (querySnapshot.docs.length === 1) {
                return onUpdate(docToGitOperation(querySnapshot.docs[0]));
            }
            return () => {};
        });
    }
    return getDocs(queryOperation).then(response => (response.docs.length === 1 ? docToGitOperation(response.docs[0]) : null));
}

/*
 * Note: #getLatestGitOperation is use to deal with the fact we currently support only one gitOperationId per depositStore.
 * Improvement will be to have a list of gitOperationId once all legals ins and outs are known.
 */
export function getLatestGitOperation(accountId: string, targetId: string, onUpdate: (surveyLangs: Account.GitOperation | null) => void): () => void {
    return onSnapshot(
        query(
            collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_GIT_OPERATION_COLLECTION),
            where("usedBy.targetId", "==", targetId),
            where("isArchived", "==", false),
            orderBy("creationDate", "desc")
        ),
        querySnapshot => {
            if (querySnapshot.docs.length >= 1) {
                return onUpdate(docToGitOperation(querySnapshot.docs[0]));
            }
            return onUpdate(null);
        }
    );
}

import { Direction, DIRECTION_TO_ROTATION_CLASS } from "../../../referentials";
import { IconSvgProps } from "../Icons";

export default function ChevronUpDoubleSvg({ color, size, direction = "up" }: IconSvgProps & { direction?: Direction }): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} className={DIRECTION_TO_ROTATION_CLASS[direction]} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6293 12.7392C18.248 13.0866 17.665 13.0869 17.2833 12.74L12.2191 8.13693L7.15499 12.74C6.77327 13.0869 6.19029 13.0866 5.80895 12.7392L5.53068 12.4858C5.09524 12.0891 5.09524 11.4039 5.53068 11.0072L11.5457 5.52795C11.9274 5.1803 12.5109 5.1803 12.8926 5.52795L18.9076 11.0072C19.343 11.4039 19.343 12.0891 18.9076 12.4858L18.6293 12.7392Z" />
            <path d="M18.6293 18.7392C18.248 19.0866 17.665 19.0869 17.2833 18.74L12.2191 14.1369L7.15499 18.74C6.77327 19.0869 6.19029 19.0866 5.80895 18.7392L5.53068 18.4858C5.09524 18.0891 5.09524 17.4039 5.53068 17.0072L11.5457 11.528C11.9274 11.1803 12.5109 11.1803 12.8926 11.5279L18.9076 17.0072C19.343 17.4039 19.343 18.0891 18.9076 18.4858L18.6293 18.7392Z" />
        </svg>
    );
}

import { DEBUG_LANG, LANG_DE, LANG_EN, LANG_ES, LANG_FR, LANG_IT, LanguageCode } from "@vaultinum/vaultinum-api";
import worldCountries from "world-countries";
import { DEFAULT_LANG } from "../../lang";

function getIso6393Code(langCode: LanguageCode): string {
    switch (langCode) {
        case LANG_FR:
            return "fra";
        case LANG_DE:
            return "deu";
        case LANG_ES:
            return "spa";
        case LANG_IT:
            return "ita";
        case LANG_EN:
        default:
            return "eng";
    }
}

export function getCountriesData(langCode: LanguageCode): {
    cca2: string;
    name: string;
    callingCode: string;
}[] {
    const isDefaultLang = langCode === DEFAULT_LANG || langCode === DEBUG_LANG;
    return worldCountries
        .filter(country => country.independent)
        .map(({ cca2, translations, name, idd }) => ({
            cca2,
            name: isDefaultLang ? name.common : translations[getIso6393Code(langCode)].common,
            callingCode: idd.root + idd.suffixes[0]
        }));
}

import { LANG_DE, LANG_EN, LANG_FR, LANG_IT, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import queryString from "query-string";
import { objectMap } from "../helpers/object.helper";

export type Routes = {
    readonly index: `/${string}/*` | "/*";
    readonly all: "/*";
    readonly [key: string]: Routes | `/${string}`;
};

export const SUPPORT_EMAIL = "support@vaultinum.com";

// HELPERS

export function addQueryParamsToUrl<T>(url: string, queryParams: { [key: string]: T }): string {
    const queryParamsValue = queryString.stringify(queryParams, { arrayFormat: "bracket", skipNull: true, skipEmptyString: true });
    const separator = url.includes("?") ? "&" : "?";
    return queryParamsValue ? url.concat(separator).concat(queryParamsValue) : url;
}

export function prependIndex<T extends Routes>(routes: T, previousIndex?: string): typeof routes {
    const currentIndex = routes.index.replace("/*", "");
    const index = previousIndex ? `${previousIndex}${currentIndex}` : currentIndex;
    return {
        ...objectMap(routes, ([key, value]) => (typeof value === "string" ? [key, `${index}${value}`] : [key, prependIndex(value, index)])),
        index
    };
}

export function replaceId<T extends Routes>(routes: T, id: string): typeof routes {
    return objectMap(routes, ([key, value]) => (typeof value === "string" ? [key, value.replace(":id", id)] : [key, replaceId(value, id)]));
}

// PATHS & URLS

const SHARED_PATH = {
    index: "/*",
    app: {
        index: "/app/*",
        login: "/login",
        account: {
            index: "/account/*",
            new: "/new",
            all: "/*"
        },
        settings: {
            index: "/settings/*",
            all: "/*"
        },
        // apps
        deposit: "/deposit",
        escrow: "/escrow",
        kys: {
            index: "/kys/*",
            fullAudit: "/full-audit",
            all: "/*"
        },
        timestamping: "/timestamping",
        sealing: "/sealing",
        archiving: "/archiving",
        all: "/*"
    },
    contact: "/contact",
    all: "/*"
} as const;

export enum SettingsKey {
    PROFILE = "profile",
    ORGANISATION = "organisation",
    USERS = "users",
    GIT = "git"
}

export const SHARED_URL = prependIndex(SHARED_PATH);

function getPlatformUserAgreementSectionId(langCode: SupportedLanguageCode): string {
    switch (langCode) {
        case LANG_FR:
            return "accord-dutilisation-de-la-plate-forme";
        case LANG_DE:
            return "nutzungsvereinbarung-fur-die-plattform";
        case LANG_IT:
            return "condizioni-duso";
        case LANG_EN:
        default:
            return "services-and-platform-user-agreement";
    }
}

function getPrivacyPolicySectionId(langCode: SupportedLanguageCode): string {
    switch (langCode) {
        case LANG_FR:
            return "politique-de-confidentialite";
        case LANG_DE:
            return "datenschutzpolitik";
        case LANG_IT:
            return "informativa-privacy";
        case LANG_EN:
        default:
            return "privacy-policy";
    }
}

export const ABSOLUTE_SHOWCASE_URL = {
    langCode: (langCode: SupportedLanguageCode) => {
        const baseUrl = `${process.env.REACT_APP_VAULTINUM_HOME}/${langCode}`;
        const legalNoticeBaseUrl = `${baseUrl}/legal-notice`;
        return {
            legalNotice: {
                platformUserAgreement: `${legalNoticeBaseUrl}#${getPlatformUserAgreementSectionId(langCode)}`,
                privacyPolicy: `${legalNoticeBaseUrl}#${getPrivacyPolicySectionId(langCode)}`
            },
            kysSurveys: {
                id: (id: string): string => `${baseUrl}/kys/surveys/${id}`
            }
        };
    }
} as const;

import {
    COMPANY_SIZES,
    Controller,
    getLangCode,
    Input,
    NationalitySelect,
    Select,
    useAuthContext,
    useForm,
    useIndustryList,
    useLang,
    useRequiredString,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useEffect } from "react";
import { AccountLang } from "../../../lang/AccountLang";
import { CreateAs, OrganisationInformationsFields } from "../../../services";
import { OnboardingContext } from "./onboardingMachine";
import { Step } from "./Step";

export default function OrganisationInformationsStep({
    context,
    onChange,
    doBack,
    canDoNext,
    doNext
}: {
    context: OnboardingContext;
    onChange: (update: OrganisationInformationsFields) => void;
    doBack: () => void;
    canDoNext: boolean;
    doNext: () => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    const { userProfile } = useAuthContext();
    const industryList = useIndustryList();
    const isRepresentative = context.createAsFields.createAs === CreateAs.REPRESENTATIVE;

    const schema = yup.object({
        companyName: useRequiredString(),
        companyIndustry: useRequiredString(),
        companySize: useRequiredString()
    } satisfies Partial<Record<keyof OrganisationInformationsFields, yup.AnySchema>>);

    const form = useForm<OrganisationInformationsFields>({
        schema,
        defaultValues: context.organisationInformationsFields,
        mode: "onChange"
    });

    useEffect(() => {
        const { unsubscribe } = form.watch(() => {
            onChange(form.getValues());
        });
        return () => unsubscribe();
    }, [form, onChange]);

    return (
        <Step
            title={lang.onboarding.account.accountInfo.title(isRepresentative)}
            doBack={doBack}
            nextButtonDisabled={!canDoNext}
            onSubmit={doNext}
            stepKey="organisation-profile-step"
            form={form}
        >
            <Controller
                data-id="company-name-control"
                name="companyName"
                control={form.control}
                render={({ field }) => (
                    <Input.Text
                        {...field}
                        label={lang.accountInformation.form.companyName}
                        data-id="company-name-input"
                        placeholder={lang.accountInformation.form.companyName}
                        required
                        errorMessage={form.formState.errors.companyName?.message}
                    />
                )}
            />
            <Controller
                data-id="company-industry-control"
                name="companyIndustry"
                control={form.control}
                render={({ field }) => (
                    <Select.Basic
                        {...field}
                        label={lang.accountInformation.form.companyIndustry}
                        data-id="company-industry-input"
                        placeholder={lang.accountInformation.form.companyIndustry}
                        options={industryList}
                        required
                        errorMessage={form.formState.errors.companyIndustry?.message}
                    />
                )}
            />
            <Controller
                data-id="company-size-control"
                name="companySize"
                control={form.control}
                render={({ field }) => (
                    <Select.Basic
                        {...field}
                        label={lang.accountInformation.form.companySize}
                        data-id="company-size-input"
                        placeholder={lang.accountInformation.form.companySize}
                        options={COMPANY_SIZES.map(size => ({ value: size, label: size }))}
                        required
                        errorMessage={form.formState.errors.companySize?.message}
                    />
                )}
            />
            <Controller
                data-id="company-nationality-control"
                name="companyNationality"
                control={form.control}
                render={({ field }) => (
                    <NationalitySelect
                        {...field}
                        label={lang.accountInformation.form.companyNationality}
                        disabled={false}
                        data-id="company-nationality-input"
                        langCode={getLangCode(userProfile)}
                    />
                )}
            />
            <Controller
                data-id="company-registration-number-control"
                name="companyRegistrationNumber"
                control={form.control}
                render={({ field }) => (
                    <Input.Text
                        {...field}
                        label={lang.accountInformation.form.companyRegistrationNumber}
                        data-id="company-registration-number-input"
                        placeholder={lang.accountInformation.form.companyRegistrationNumber}
                    />
                )}
            />
        </Step>
    );
}

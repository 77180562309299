import { IconSvgProps } from "../Icons";

function CreditCardSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.375 3.75H2.625C1.17759 3.75 0 4.92759 0 6.375V17.625C0 19.0724 1.17759 20.25 2.625 20.25H21.375C22.8224 20.25 24 19.0724 24 17.625V6.375C24 4.92759 22.8224 3.75 21.375 3.75ZM2.625 5.25H21.375C21.9953 5.25 22.5 5.7547 22.5 6.375V7.875H1.5V6.375C1.5 5.7547 2.0047 5.25 2.625 5.25ZM21.375 18.75H2.625C2.0047 18.75 1.5 18.2453 1.5 17.625V9.375H22.5V17.625C22.5 18.2453 21.9953 18.75 21.375 18.75Z" />
            <path d="M5.25 16.5H4.5C4.08581 16.5 3.75 16.1642 3.75 15.75V15C3.75 14.5858 4.08581 14.25 4.5 14.25H5.25C5.66419 14.25 6 14.5858 6 15V15.75C6 16.1642 5.66419 16.5 5.25 16.5Z" />
        </svg>
    );
}

export default CreditCardSvg;

import { useNavigate } from "react-router-dom";

/**
 * This component is a simple Anchor link that will use a standard "href" redirection if not routed
 * Else, it will use react-router history to push the provided "href" link.
 *
 * This alows to do a standard redirect when needed and perform a smooth page change when in the same application.
 */
export default function AdaptiveLink({
    href,
    routed,
    onClick,
    ...props
}: {
    href?: string;
    routed?: boolean;
    className?: string;
    onClick?: () => void;
    children: React.ReactNode;
}): JSX.Element {
    const navigate = useNavigate();
    return (
        <a
            href={href}
            onClick={e => {
                if (onClick) {
                    onClick();
                }
                if (routed && history) {
                    e.preventDefault();
                    if (href) {
                        navigate(href);
                    }
                }
            }}
            {...props}
        />
    );
}

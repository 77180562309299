import { COLOR_SCHEME } from "../../../referentials";
import { LogoSvgProps } from "../Logos";

function DepositOutlinedSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.2738 43.1372H3.37812C2.26406 43.1372 1.35664 42.1909 1.35664 41.0291V3.52294C1.34766 2.35175 2.25508 1.40543 3.37812 1.40543H31.8406C32.218 1.40543 32.5144 1.08686 32.5144 0.702713C32.5144 0.318563 32.209 0 31.8406 0H3.37812C1.51836 0 0 1.58345 0 3.52294V41.0291C0 42.9217 1.4375 44.4677 3.24336 44.5427V45.8919C3.24336 47.0537 4.15078 48 5.26484 48H8.65195C9.41562 48 10.1344 47.5315 10.4758 46.8194L11.5449 44.552H28.2738C28.6512 44.552 28.9477 44.2335 28.9477 43.8493C28.9477 43.4558 28.6422 43.1372 28.2738 43.1372ZM9.26289 46.2011C9.14609 46.4447 8.9125 46.5946 8.65195 46.5946H5.26484C4.89648 46.5946 4.59102 46.276 4.59102 45.8919V44.552H10.0355L9.26289 46.2011Z"
                fill="url(#paint0_linear_3806_16466)"
            />
            <path
                d="M42.6215 0H34.5355C34.1582 0 33.8617 0.318563 33.8617 0.702713C33.8617 1.08686 34.1672 1.40543 34.5355 1.40543H42.6215C43.7355 1.40543 44.643 2.35175 44.643 3.51357V41.0197C44.643 42.1815 43.7355 43.1279 42.6215 43.1279H30.9688C30.5914 43.1279 30.2949 43.4464 30.2949 43.8306C30.2949 44.2147 30.6004 44.5333 30.9688 44.5333H34.4547L35.5238 46.8007C35.8652 47.5222 36.575 47.9813 37.3477 47.9813H40.7348C41.8488 47.9813 42.7562 47.0349 42.7562 45.8731V44.5239C44.5531 44.449 45.9996 42.903 45.9996 41.0103V3.52294C45.9996 1.58345 44.4812 0 42.6215 0ZM40.7348 46.5946H37.3477C37.0961 46.5946 36.8535 46.4353 36.7367 46.2011L35.9641 44.552H41.4086V45.8919C41.4086 46.276 41.1031 46.5946 40.7348 46.5946Z"
                fill="url(#paint1_linear_3806_16466)"
            />
            <path
                d="M12.2719 22.8711H11.041C10.6637 22.8711 10.3672 23.1897 10.3672 23.5738C10.3672 23.958 10.6727 24.2765 11.041 24.2765H12.2719C12.6492 24.2765 12.9457 23.958 12.9457 23.5738C12.9457 23.1897 12.6492 22.8711 12.2719 22.8711Z"
                fill="url(#paint2_linear_3806_16466)"
            />
            <path
                d="M15.9301 22.8711H14.6992C14.3219 22.8711 14.0254 23.1897 14.0254 23.5738C14.0254 23.958 14.3309 24.2765 14.6992 24.2765H15.9301C16.3074 24.2765 16.6039 23.958 16.6039 23.5738C16.6039 23.1897 16.2984 22.8711 15.9301 22.8711Z"
                fill="url(#paint3_linear_3806_16466)"
            />
            <path
                d="M19.5766 22.8711H18.3457C17.9684 22.8711 17.6719 23.1897 17.6719 23.5738C17.6719 23.958 17.9773 24.2765 18.3457 24.2765H19.5766C19.9539 24.2765 20.2504 23.958 20.2504 23.5738C20.2504 23.1897 19.9539 22.8711 19.5766 22.8711Z"
                fill="url(#paint4_linear_3806_16466)"
            />
            <path
                d="M12.2719 25.7568H11.041C10.6637 25.7568 10.3672 26.0754 10.3672 26.4595C10.3672 26.8437 10.6727 27.1623 11.041 27.1623H12.2719C12.6492 27.1623 12.9457 26.8437 12.9457 26.4595C12.9457 26.0754 12.6492 25.7568 12.2719 25.7568Z"
                fill="url(#paint5_linear_3806_16466)"
            />
            <path
                d="M15.9301 25.7568H14.6992C14.3219 25.7568 14.0254 26.0754 14.0254 26.4595C14.0254 26.8437 14.3309 27.1623 14.6992 27.1623H15.9301C16.3074 27.1623 16.6039 26.8437 16.6039 26.4595C16.6039 26.0754 16.2984 25.7568 15.9301 25.7568Z"
                fill="url(#paint6_linear_3806_16466)"
            />
            <path
                d="M19.5766 25.7568H18.3457C17.9684 25.7568 17.6719 26.0754 17.6719 26.4595C17.6719 26.8437 17.9773 27.1623 18.3457 27.1623H19.5766C19.9539 27.1623 20.2504 26.8437 20.2504 26.4595C20.2504 26.0754 19.9539 25.7568 19.5766 25.7568Z"
                fill="url(#paint7_linear_3806_16466)"
            />
            <path
                d="M12.2719 28.6426H11.041C10.6637 28.6426 10.3672 28.9611 10.3672 29.3453C10.3672 29.7294 10.6727 30.048 11.041 30.048H12.2719C12.6492 30.048 12.9457 29.7294 12.9457 29.3453C12.9457 28.9518 12.6492 28.6426 12.2719 28.6426Z"
                fill="url(#paint8_linear_3806_16466)"
            />
            <path
                d="M14.6895 30.048H15.9203C16.2977 30.048 16.5941 29.7294 16.5941 29.3453C16.5941 28.9611 16.2887 28.6426 15.9203 28.6426H14.6895C14.3121 28.6426 14.0156 28.9611 14.0156 29.3453C14.0156 29.7294 14.3211 30.048 14.6895 30.048Z"
                fill="url(#paint9_linear_3806_16466)"
            />
            <path
                d="M19.5766 28.6426H18.3457C17.9684 28.6426 17.6719 28.9611 17.6719 29.3453C17.6719 29.7294 17.9773 30.048 18.3457 30.048H19.5766C19.9539 30.048 20.2504 29.7294 20.2504 29.3453C20.2504 28.9611 19.9539 28.6426 19.5766 28.6426Z"
                fill="url(#paint10_linear_3806_16466)"
            />
            <path
                d="M29.6207 17.2495C29.2434 17.2495 28.9469 17.5681 28.9469 17.9522V19.2077C27.3297 19.4982 26.0449 20.838 25.7664 22.5245H24.5625C24.1852 22.5245 23.8887 22.8431 23.8887 23.2273C23.8887 23.6114 24.1941 23.93 24.5625 23.93H25.7664C26.0449 25.6165 27.3297 26.9563 28.9469 27.2468V28.5023C28.9469 28.8958 29.2523 29.205 29.6207 29.205C29.9891 29.205 30.2945 28.8864 30.2945 28.5023V27.2468C31.9117 26.9563 33.1965 25.6165 33.475 23.93H34.6789C35.0563 23.93 35.3527 23.6114 35.3527 23.2273C35.3527 22.8431 35.0473 22.5245 34.6789 22.5245H33.475C33.1965 20.838 31.9117 19.4982 30.2945 19.2077V17.9522C30.2945 17.5681 29.998 17.2495 29.6207 17.2495ZM32.1902 23.2366C32.1902 24.717 31.0402 25.9163 29.6207 25.9163C28.2012 25.9163 27.0512 24.717 27.0512 23.2366C27.0512 21.7562 28.2012 20.5569 29.6207 20.5569C31.0402 20.5569 32.1902 21.7562 32.1902 23.2366Z"
                fill="url(#paint11_linear_3806_16466)"
            />
            <path
                d="M20.4664 19.4979V18.2143C20.4664 17.2586 19.7207 16.481 18.8043 16.481H11.8145C10.898 16.481 10.1523 17.2586 10.1523 18.2143V19.4979C10.1523 20.4536 10.898 21.2313 11.8145 21.2313H18.8043C19.7207 21.2313 20.4664 20.4536 20.4664 19.4979ZM11.5 19.4979V18.2143C11.5 18.0363 11.6438 17.8864 11.8145 17.8864H18.8043C18.975 17.8864 19.1188 18.0363 19.1188 18.2143V19.4979C19.1188 19.676 18.975 19.8259 18.8043 19.8259H11.8145C11.6438 19.8259 11.5 19.676 11.5 19.4979Z"
                fill="url(#paint12_linear_3806_16466)"
            />
            <path
                d="M39.1809 4.60059H6.42383C5.30977 4.60059 4.40234 5.54691 4.40234 6.70873V37.8623C4.40234 39.0242 5.30977 39.9705 6.42383 39.9705H13.9617C14.3391 39.9705 14.6355 39.6519 14.6355 39.2678C14.6355 38.8836 14.3301 38.5651 13.9617 38.5651H6.42383C6.05547 38.5651 5.75 38.2465 5.75 37.8623V6.70873C5.75 6.32458 6.05547 6.00601 6.42383 6.00601H39.1809C39.5492 6.00601 39.8547 6.32458 39.8547 6.70873V9.57579C39.0281 9.62264 38.3633 10.3347 38.3633 11.2155V14.2793C38.3633 15.16 39.0281 15.8721 39.8547 15.919V28.6709C39.0281 28.7177 38.3633 29.4298 38.3633 30.3105V33.3743C38.3633 34.2551 39.0281 34.9672 39.8547 35.014V37.8811C39.8547 38.2652 39.5492 38.5838 39.1809 38.5838H16.657C16.2797 38.5838 15.9832 38.9024 15.9832 39.2865C15.9832 39.6707 16.2887 39.9892 16.657 39.9892H39.1809C40.2949 39.9892 41.2023 39.0429 41.2023 37.8811V35.014C42.0289 34.9672 42.6937 34.2551 42.6937 33.3743V30.3105C42.6937 29.4298 42.0289 28.7177 41.2023 28.6709V15.9096C42.0289 15.8627 42.6937 15.1507 42.6937 14.2699V11.2061C42.6937 10.3254 42.0289 9.61327 41.2023 9.56643V6.70873C41.2113 5.54691 40.2949 4.60059 39.1809 4.60059ZM41.3461 30.3011V33.365C41.3461 33.4961 41.2473 33.5992 41.1215 33.5992H39.9355C39.8098 33.5992 39.7109 33.4961 39.7109 33.365V30.3011C39.7109 30.17 39.8098 30.0669 39.9355 30.0669H41.1215C41.2473 30.0669 41.3461 30.17 41.3461 30.3011ZM41.3461 11.2155V14.2793C41.3461 14.4105 41.2473 14.5135 41.1215 14.5135H39.9355C39.8098 14.5135 39.7109 14.4105 39.7109 14.2793V11.2155C39.7109 11.0843 39.8098 10.9812 39.9355 10.9812H41.1215C41.2473 10.9812 41.3461 11.0843 41.3461 11.2155Z"
                fill="url(#paint13_linear_3806_16466)"
            />
            <defs>
                <linearGradient id="paint0_linear_3806_16466" x1="-0.711254" y1="-1.5" x2="32.5884" y2="-1.45021" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint1_linear_3806_16466" x1="29.9514" y1="-1.49941" x2="46.0356" y2="-1.48771" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint2_linear_3806_16466" x1="10.3108" y1="22.8272" x2="12.9508" y2="22.8366" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint3_linear_3806_16466" x1="13.969" y1="22.8272" x2="16.609" y2="22.8366" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint4_linear_3806_16466" x1="17.6155" y1="22.8272" x2="20.258" y2="22.8413" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint5_linear_3806_16466" x1="10.3108" y1="25.7129" x2="12.9508" y2="25.7223" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint6_linear_3806_16466" x1="13.969" y1="25.7129" x2="16.609" y2="25.7223" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint7_linear_3806_16466" x1="17.6155" y1="25.7129" x2="20.258" y2="25.727" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint8_linear_3806_16466" x1="10.3108" y1="28.5987" x2="12.9508" y2="28.608" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint9_linear_3806_16466" x1="13.9592" y1="28.5987" x2="16.5993" y2="28.608" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint10_linear_3806_16466" x1="17.6155" y1="28.5987" x2="20.258" y2="28.6127" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint11_linear_3806_16466" x1="23.6379" y1="16.8759" x2="35.3784" y2="16.9004" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint12_linear_3806_16466" x1="9.92672" y1="16.3325" x2="20.4906" y2="16.3852" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint13_linear_3806_16466" x1="3.56472" y1="3.49469" x2="42.7806" y2="3.5883" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default DepositOutlinedSvg;

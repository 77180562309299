import { IconSvgProps } from "../Icons";

function OfficeSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.45188 6.0625C7.45188 5.71719 7.73275 5.4375 8.07952 5.4375H9.96243C10.3076 5.4375 10.5901 5.71719 10.5901 6.0625V7.9375C10.5901 8.28125 10.3076 8.5625 9.96243 8.5625H8.07952C7.73275 8.5625 7.45188 8.28125 7.45188 7.9375V6.0625ZM14.9835 5.4375C15.3287 5.4375 15.6112 5.71719 15.6112 6.0625V7.9375C15.6112 8.28125 15.3287 8.5625 14.9835 8.5625H13.1006C12.7554 8.5625 12.473 8.28125 12.473 7.9375V6.0625C12.473 5.71719 12.7554 5.4375 13.1006 5.4375H14.9835ZM7.45188 11.0625C7.45188 10.7187 7.73275 10.4375 8.07952 10.4375H9.96243C10.3076 10.4375 10.5901 10.7187 10.5901 11.0625V12.9375C10.5901 13.2813 10.3076 13.5625 9.96243 13.5625H8.07952C7.73275 13.5625 7.45188 13.2813 7.45188 12.9375V11.0625ZM14.9835 10.4375C15.3287 10.4375 15.6112 10.7187 15.6112 11.0625V12.9375C15.6112 13.2813 15.3287 13.5625 14.9835 13.5625H13.1006C12.7554 13.5625 12.473 13.2813 12.473 12.9375V11.0625C12.473 10.7187 12.7554 10.4375 13.1006 10.4375H14.9835ZM3.99988 4.5C3.99988 3.11914 5.12374 2 6.51043 2H16.5526C17.9373 2 19.0632 3.11914 19.0632 4.5V19.5C19.0632 20.8789 17.9373 22 16.5526 22H6.51043C5.12374 22 3.99988 20.8789 3.99988 19.5V4.5ZM5.88279 4.5V19.5C5.88279 19.8438 6.16366 20.125 6.51043 20.125H9.64861V17.625C9.64861 16.5898 10.492 15.75 11.5315 15.75C12.571 15.75 13.4144 16.5898 13.4144 17.625V20.125H16.5526C16.8978 20.125 17.1803 19.8438 17.1803 19.5V4.5C17.1803 4.15469 16.8978 3.875 16.5526 3.875H6.51043C6.16366 3.875 5.88279 4.15469 5.88279 4.5Z" />
        </svg>
    );
}

export default OfficeSvg;

import { useEffect } from "react";
import { getLangEnLabel } from "../../lang";
import trackingService from "../analytics/trackingService";
import { AuthProps } from "../contexts";

export default function useTracker({ userProfile, selectedAccount }: AuthProps): void {
    useEffect(() => {
        if (!userProfile) {
            return;
        }
        trackingService.setUserProperties({
            $first_name: userProfile?.firstName,
            $last_name: userProfile?.lastName
        });
    }, [userProfile?.firstName, userProfile?.lastName]);

    useEffect(() => {
        if (!userProfile) {
            return;
        }
        trackingService.setUserProperties({
            $email: userProfile?.email
        });
    }, [userProfile?.email]);

    useEffect(() => {
        if (!userProfile) {
            return;
        }
        trackingService.setUserProperties({
            "Preferred language": getLangEnLabel(userProfile?.settings?.preferredLang)
        });
    }, [userProfile?.settings?.preferredLang]);

    useEffect(() => {
        if (!selectedAccount) {
            return;
        }
        trackingService.attachEventProperties({
            "Selected account": selectedAccount?.id
        });
    }, [selectedAccount?.id]);
}

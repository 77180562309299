import { IconSvgProps } from "../../../../../common";

export default function ScopeGDPROutlinedSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.587 11.6204C13.414 11.6204 13.2739 11.4802 13.2739 11.3073V10.4747C13.2739 9.78447 12.7123 9.22292 12.0221 9.22292C11.3318 9.22292 10.7703 9.78447 10.7703 10.4747V11.3073C10.7703 11.4802 10.6301 11.6204 10.4571 11.6204C10.2842 11.6204 10.144 11.4802 10.144 11.3073V10.4747C10.144 9.43918 10.9865 8.59671 12.0221 8.59671C13.0576 8.59671 13.9001 9.43918 13.9001 10.4747V11.3073C13.9001 11.4802 13.7599 11.6204 13.587 11.6204Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2225 15.4033H13.7777C14.0389 15.4033 14.2507 15.1907 14.2507 14.9283V11.7613C14.2507 11.4991 14.0389 11.2864 13.7777 11.2864H10.2225C9.96127 11.2864 9.74951 11.499 9.74951 11.7613V14.9283C9.74951 15.1907 9.96123 15.4033 10.2225 15.4033ZM12.2804 13.4541L12.5961 14.1753C12.623 14.2369 12.5771 14.3054 12.5089 14.3055H11.4913C11.4231 14.3055 11.3771 14.2369 11.4041 14.1753L11.7197 13.4541C11.5407 13.3569 11.4194 13.1698 11.4194 12.9547C11.4194 12.6182 11.7159 12.3498 12.0662 12.3878C12.3322 12.4167 12.5477 12.6284 12.5771 12.8896C12.6044 13.1316 12.4773 13.3473 12.2804 13.4541Z"
            />
            <path d="M12 2.00002L12.6542 3.37865L14.103 3.61232L13.0515 4.68719L13.3085 6.20602L12 5.48165L10.6914 6.20602L10.9485 4.68719L9.89697 3.61232L11.3457 3.37865L12 2.00002Z" />
            <path d="M12 17.794L12.6542 19.1726L14.103 19.4063L13.0515 20.4812L13.3085 22L12 21.2756L10.6914 22L10.9485 20.4812L9.89697 19.4063L11.3457 19.1726L12 17.794Z" />
            <path d="M19.8969 9.897L20.5512 11.2756L22 11.5093L20.9485 12.5842L21.2055 14.103L19.8969 13.3786L18.5884 14.103L18.8454 12.5842L17.7939 11.5093L19.2427 11.2756L19.8969 9.897Z" />
            <path d="M4.103 9.897L4.75727 11.2756L6.20601 11.5093L5.1545 12.5842L5.41154 14.103L4.103 13.3786L2.79447 14.103L3.0515 12.5842L2 11.5093L3.44874 11.2756L4.103 9.897Z" />
            <path d="M6.17576 4.36911L6.83002 5.74775L8.27876 5.98141L7.22726 7.05628L7.48429 8.57512L6.17576 7.85075L4.86722 8.57512L5.12426 7.05628L4.07275 5.98141L5.52149 5.74775L6.17576 4.36911Z" />
            <path d="M17.8242 4.36911L18.4785 5.74775L19.9272 5.98141L18.8757 7.05628L19.1327 8.57512L17.8242 7.85075L16.5157 8.57512L16.7727 7.05628L15.7212 5.98141L17.1699 5.74775L17.8242 4.36911Z" />
            <path d="M6.17576 15.1288L6.83002 16.5074L8.27876 16.7411L7.22726 17.8159L7.48429 19.3348L6.17576 18.6104L4.86722 19.3348L5.12426 17.8159L4.07275 16.7411L5.52149 16.5074L6.17576 15.1288Z" />
            <path d="M17.8242 15.1288L18.4785 16.5074L19.9272 16.7411L18.8757 17.8159L19.1327 19.3348L17.8242 18.6104L16.5157 19.3348L16.7727 17.8159L15.7212 16.7411L17.1699 16.5074L17.8242 15.1288Z" />
        </svg>
    );
}

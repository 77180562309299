import { useBrandContext } from "../..";
import "./LogoSpinner.css";

export default function LogoSpinner({
    state = "spinning",
    size = "middle"
}: {
    state?: "open" | "spinning" | "close";
    size?: "small" | "middle" | "large" | "xlarge";
}): JSX.Element {
    const { LogoSpinnerSvg } = useBrandContext();
    return <LogoSpinnerSvg className={`logo-spinner ${size} state-${state}`} />;
}

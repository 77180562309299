import { Account, AccountInvitation, InvitationStatus } from "@vaultinum/vaultinum-api";
import {
    Alert,
    Button,
    Input,
    List,
    ModalHelper,
    getAccountsByIdsAndDomainId,
    openNotificationWithIcon,
    useAuthContext,
    useLang,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyAccountRow, ViewWrapper } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";
import { getPendingInvitationsByEmailAndDomainId } from "../../../services/accountInvitationService";
import { deleteAccount } from "../../../services/accountService";
import { URL } from "../../../services/routingService";
import LeaveAccountDialog from "./LeaveAccountDialog";

const CompanyAccounts = (): JSX.Element => {
    const { userProfile } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const [searchValue, setSearchValue] = useState("");
    const [leaveAccount, setLeaveAccount] = useState<Account | null>(null);
    const [accountInvitations, setAccountInvitations] = useState<AccountInvitation[]>([]);
    const [accountsFromInvitations, setAccountsFromInvitations] = useState<Account[]>([]);
    const lang = useLang<AccountLang>();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userProfile?.email) {
            return () => {};
        }
        return getPendingInvitationsByEmailAndDomainId(userProfile.email, whiteLabelDomain?.id, invitations => {
            setAccountInvitations(invitations.filter(invitation => invitation.status === InvitationStatus.PENDING));
        });
    }, [userProfile?.email, whiteLabelDomain?.id]);

    useEffect(() => {
        void (async function () {
            const accountsInBatches = await getAccountsByIdsAndDomainId(
                accountInvitations.map(invitation => invitation.accountId),
                whiteLabelDomain?.id
            );
            setAccountsFromInvitations(accountsInBatches);
        })();
    }, [accountInvitations, whiteLabelDomain?.id]);

    const filteredAccounts = useMemo(() => {
        const allAccounts = [...(userProfile?.accounts || []), ...accountsFromInvitations].sort((a, b) => a.companyName.localeCompare(b.companyName));
        return searchValue.trim() ? allAccounts.filter(account => account.companyName.toLowerCase().includes(searchValue.toLowerCase().trim())) : allAccounts;
    }, [userProfile?.accounts, accountsFromInvitations, searchValue]);

    const showDeleteConfirm = (account: Account) =>
        ModalHelper.Confirm({
            title: lang.profileSettings.companyAccounts.deleteAccount,
            children: `${lang.profileSettings.companyAccounts.deleteAccountConfirmation(account.companyName)} ${
                lang.profileSettings.companyAccounts.deleteAccountConfirmationDescription
            }`,
            okText: lang.profileSettings.companyAccounts.deleteAccount,
            onConfirm: async () => {
                try {
                    await deleteAccount(account);
                    openNotificationWithIcon({ type: "success", description: lang.shared.successMessage });
                } catch (err) {
                    openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
                }
            },
            lang
        });

    return (
        <ViewWrapper title={lang.profileSettings.companyAccounts.title}>
            <Alert.Info title={lang.profileSettings.companyAccounts.whatIs} message={lang.profileSettings.companyAccounts.definition} />
            <div className="flex justify-between">
                <Input.Search
                    value={searchValue}
                    className="w-1/3"
                    placeholder={lang.profileSettings.companyAccounts.search}
                    onChange={e => setSearchValue(e.target.value)}
                />
                <Button
                    data-id="new-account"
                    onClick={() => navigate(URL.account.new)}
                    isLoading={false}
                    children={lang.profileSettings.companyAccounts.newAccount}
                />
            </div>
            <List
                list={filteredAccounts}
                render={account => (
                    <CompanyAccountRow
                        account={account}
                        accountInvitation={accountInvitations.find(invitation => invitation.accountId === account.id)}
                        onLeaveClicked={() => setLeaveAccount(account)}
                        onDeleteAccountClicked={() => showDeleteConfirm(account)}
                    />
                )}
                emptyText={lang.shared.noResult}
                isVirtualized
            />
            {leaveAccount && <LeaveAccountDialog account={leaveAccount} onDeleteAccountClicked={showDeleteConfirm} onClose={() => setLeaveAccount(null)} />}
        </ViewWrapper>
    );
};
export default CompanyAccounts;

import { IconSvgProps } from "../../../../../../common";

export default function TrademarksSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2580_913)">
                <path
                    d="M17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9Z"
                    stroke="currentColor"
                    fill="none"
                    stroke-width="1.5"
                />
                <path d="M7.725 6.93748H6.15V12.2625H4.8375V6.93748H3.2625V5.84998H7.725V6.93748Z" fill={color} />
                <path
                    d="M10.1625 5.84998L11.3625 10.5L12.5625 5.84998H14.25V12.2625H12.9375V10.5375L13.05 7.87498L11.775 12.3H10.875L9.6375 7.87498L9.75 10.5375V12.2625H8.4375V5.84998H10.1625Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

import { Cart, PriceVariationType } from "@vaultinum/vaultinum-api";
import { useMemo, useState } from "react";
import { Button, Controller, CrossIcon, Form, Input, openNotificationWithIcon } from "../../../../design-system";
import { formatAsCurrency } from "../../../../helpers";
import { CommonLang, getLangCode, useLang } from "../../../../lang";
import { useAuthContext } from "../../../contexts";
import { useForm } from "../../../tools";
import "./PaymentInformations.css";
import PricingNames from "./PricingNames";
import TaxLine from "./TaxLine";

export default function PaymentInformations({
    cart,
    disabledPromoCode,
    applyPromoCode
}: {
    cart: Cart;
    disabledPromoCode?: boolean;
    applyPromoCode?: (promoCode: string | null) => Promise<void>;
}): JSX.Element {
    const lang = useLang<CommonLang>();
    const [isLoadingPromoCode, setIsLoadingPromoCode] = useState(false);
    const { userProfile } = useAuthContext();
    const langCode = getLangCode(userProfile);

    const { handleSubmit, control, setValue, watch } = useForm();

    async function applyCode() {
        const promoCode = watch("promoCode");
        if (applyPromoCode && promoCode) {
            try {
                await applyPromoCode(promoCode);
                setValue("promoCode", undefined);
            } catch {
                openNotificationWithIcon({ type: "error", message: lang.payment.payment.promoCodeError });
            } finally {
                setIsLoadingPromoCode(false);
            }
        }
    }

    const removePromoCode = async () => {
        await applyPromoCode?.(null);
    };

    const taxes = useMemo(() => {
        if (!cart.taxes?.value) {
            return 0;
        }
        if (cart.taxes?.type === PriceVariationType.Percent) {
            return Math.round(cart.amount * (cart.taxes.value / 100) * 100) / 100;
        }
        return cart.taxes.value;
    }, [cart]);

    const total = useMemo(() => {
        return cart.amount + taxes;
    }, [taxes, cart.amount]);

    const totalDiscount = useMemo(() => {
        if (!cart.discount?.value) {
            return 0;
        }
        if (cart.discount?.type === PriceVariationType.Percent) {
            return Math.round(total * (cart.discount.value / 100) * 100) / 100;
        }
        return cart.discount.value / 100;
    }, [total, cart]);

    return (
        <>
            <div className="border-t p-6 border-gray-200 space-y-2">
                <PricingNames products={cart.products} lang={lang} />
                <TaxLine percentage={cart.taxes?.value || 0} amount={taxes} langCode={langCode} />
            </div>
            <Form className="border-t border-gray-200 p-6" onSubmit={handleSubmit(applyCode)}>
                {!!applyPromoCode && (
                    <div className="text-left ">
                        <Controller
                            name="promoCode"
                            control={control}
                            render={({ field }) => (
                                <Input.Text
                                    {...field}
                                    label={lang.payment.payment.promoCode}
                                    placeholder={lang.payment.payment.promoCodePlaceholder}
                                    disabled={disabledPromoCode}
                                    rightChildren={<Button htmlType="submit" onClick={applyCode} isLoading={isLoadingPromoCode} children={lang.shared.apply} />}
                                />
                            )}
                        />
                    </div>
                )}
            </Form>
            <div className="border-t border-gray-200 p-6 space-y-2">
                {!!cart.discount?.value && (
                    <div className="flex text-sm">
                        <span className="font-light">{cart.discount?.code || lang.payment.payment.discount}</span>
                        <span className="ml-auto">
                            <span className="font-bold space-x-2">
                                <span>- {formatAsCurrency(totalDiscount, langCode)}</span>
                                <span className="cursor-pointer">
                                    <CrossIcon onClick={removePromoCode} />
                                </span>
                            </span>
                        </span>
                    </div>
                )}
                <div className="text-xl flex">
                    <span className="font-bold uppercase text-slate-light">{lang.payment.payment.total}</span>
                    <span className="flex ml-auto text-xl">{formatAsCurrency(total - totalDiscount, langCode)}</span>
                </div>
            </div>
        </>
    );
}

import { ElementType, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppsIcon, Dropdowns, LogoProps } from "../../../design-system";
import { useLang } from "../../../lang";

export type AppMenuItem = {
    name: string;
    href: string;
    logo: ElementType<LogoProps>;
    description?: string;
};

function renderLogo(Logo: ElementType<LogoProps>): JSX.Element {
    return <Logo size="xxs" />;
}

export default function AppsDropdownMenu({
    appsMenuItems,
    onItemClick
}: {
    appsMenuItems: AppMenuItem[];
    onItemClick?: (item: AppMenuItem) => void;
}): JSX.Element {
    const [selectedApp, setSelectedApp] = useState<AppMenuItem | undefined>();
    const lang = useLang();
    const location = useLocation();
    const isSettingsPage = location.pathname.startsWith("/app/settings");

    useEffect(() => {
        const app = appsMenuItems.find(item => location.pathname.startsWith(item.href));
        if (app) {
            setSelectedApp(app);
        }
    }, [appsMenuItems]);

    return (
        <div className="flex items-center gap-2">
            <Dropdowns.Menu
                align="start"
                icon={AppsIcon}
                actions={appsMenuItems.map(item => ({
                    label: item.name,
                    href: item.href,
                    icon: item.logo,
                    ...(onItemClick && { onClick: () => onItemClick(item) })
                }))}
                title={lang.apps.name}
            />
            {selectedApp?.logo && selectedApp?.href && (
                <Link to={selectedApp.href} className="flex rounded p-1.5 hover:bg-grey-extra-light md:hidden" children={renderLogo(selectedApp.logo)} />
            )}
            <div className="hidden cursor-default items-center gap-2 p-2 font-bold uppercase text-slate-primary md:flex">
                {selectedApp && (
                    <Link to={selectedApp.href} className="flex items-center gap-2 rounded p-1.5 text-slate-primary hover:bg-grey-extra-light">
                        {selectedApp.logo && <div>{renderLogo(selectedApp.logo)}</div>}
                        <span>{selectedApp.name}</span>
                    </Link>
                )}
                {isSettingsPage && <span>{lang.settingsMenu.settings}</span>}
            </div>
        </div>
    );
}

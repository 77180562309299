import { Card, Result } from "antd";
import { ResultStatusType } from "antd/lib/result";
import { ReactNode } from "react";

/**
 * Responsive card with a left and right panel used for small form pages (authentication pages for example).
 * Left panel (called "extra") should not contain important elements since it gets hidden on small screens.
 */
export default function CardWithFeedback({
    loading,
    extra,
    title,
    status,
    successTitle,
    successExtra,
    errorTitle,
    errorExtra,
    children
}: {
    loading?: boolean;
    extra: ReactNode;
    title?: ReactNode;
    status?: "success" | "error";
    successTitle?: ReactNode;
    successExtra?: ReactNode;
    errorTitle?: ReactNode;
    errorExtra?: ReactNode;
    children?: ReactNode;
}): JSX.Element {
    const getContent = (resultStatus?: ResultStatusType): ReactNode => {
        switch (resultStatus) {
            case "success":
                return <Result status={resultStatus} title={successTitle} extra={successExtra} />;
            case "error":
                return <Result status={resultStatus} title={errorTitle} extra={errorExtra} />;
            default:
                return children;
        }
    };

    return (
        <div className="bg-white shadow-md min-w-1/2">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="hidden items-center justify-center p-4 md:flex border-r">{extra}</div>
                <Card loading={loading} bordered={false} title={!loading && !status && title} className="rounded-none">
                    {getContent(status)}
                </Card>
            </div>
        </div>
    );
}

import { IconSvgProps } from "../../../../../common";

/* eslint max-len: 0 */
export default function ScopeSoftwareSvg({ size, color }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.5904C0 10.1141 10.114 0 22.5904 0H277.41C289.886 0 300 10.114 300 22.5904V277.41C300 289.886 289.886 300 277.41 300H22.5904C10.1141 300 0 289.886 0 277.41V22.5904Z"
                fill={color ?? "#C7E1FF"}
            />
            <path
                d="M235.849 121.384V214.387C235.849 220.079 233.588 225.538 229.563 229.563C225.538 233.588 220.079 235.849 214.387 235.849H85.6131C79.921 235.849 74.462 233.588 70.437 229.563C66.4121 225.538 64.1509 220.079 64.1509 214.387V121.384H235.849Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59 116.233H241V214.387C241 221.445 238.196 228.214 233.205 233.205C228.214 238.196 221.445 241 214.387 241H85.6132C78.5549 241 71.7858 238.196 66.7948 233.205C61.8039 228.214 59 221.445 59 214.387V116.233ZM69.3019 126.535V214.387C69.3019 218.713 71.0204 222.862 74.0794 225.921C77.1383 228.98 81.2872 230.698 85.6132 230.698H214.387C218.713 230.698 222.862 228.98 225.921 225.921C228.98 222.862 230.698 218.713 230.698 214.387V126.535H69.3019Z"
                fill="white"
            />
            <path
                d="M235.849 85.6133V121.384H64.1509V85.6133C64.1509 79.9211 66.4121 74.4621 70.437 70.4371C74.462 66.4122 79.921 64.151 85.6131 64.151H214.387C220.079 64.151 225.538 66.4122 229.563 70.4371C233.588 74.4621 235.849 79.9211 235.849 85.6133Z"
                fill="#4A7398"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.6132 69.3019C81.2872 69.3019 77.1383 71.0204 74.0794 74.0794C71.0204 77.1383 69.3019 81.2872 69.3019 85.6132V116.233H230.698V85.6132C230.698 81.2872 228.98 77.1383 225.921 74.0794C222.862 71.0204 218.713 69.3019 214.387 69.3019H85.6132ZM66.7948 66.7948C71.7858 61.8039 78.5549 59 85.6132 59H214.387C221.445 59 228.214 61.8039 233.205 66.7948C238.196 71.7858 241 78.5549 241 85.6132V126.535H59V85.6132C59 78.5549 61.8039 71.7858 66.7948 66.7948Z"
                fill="#4A7398"
            />
            <path
                d="M99.9212 99.9212H92.7671C90.8697 99.9212 89.0501 99.1675 87.7084 97.8258C86.3668 96.4842 85.613 94.6645 85.613 92.7671C85.613 90.8697 86.3668 89.0501 87.7084 87.7084C89.0501 86.3668 90.8697 85.613 92.7671 85.613H99.9212C101.819 85.613 103.638 86.3668 104.98 87.7084C106.322 89.0501 107.075 90.8697 107.075 92.7671C107.075 94.6645 106.322 96.4842 104.98 97.8258C103.638 99.1675 101.819 99.9212 99.9212 99.9212Z"
                fill="white"
            />
            <path
                d="M135.692 99.9212H128.538C126.64 99.9212 124.821 99.1675 123.479 97.8258C122.137 96.4842 121.384 94.6645 121.384 92.7671C121.384 90.8697 122.137 89.0501 123.479 87.7084C124.821 86.3668 126.64 85.613 128.538 85.613H135.692C137.589 85.613 139.409 86.3668 140.75 87.7084C142.092 89.0501 142.846 90.8697 142.846 92.7671C142.846 94.6645 142.092 96.4842 140.75 97.8258C139.409 99.1675 137.589 99.9212 135.692 99.9212Z"
                fill="white"
            />
            <path
                d="M130.422 158.12C128.505 155.837 125.767 155.837 123.85 158.12L105.504 179.972C103.588 182.255 103.588 185.517 105.504 187.8L123.85 209.652C125.767 211.609 128.505 211.609 130.148 209.652C131.791 207.695 132.065 204.108 130.148 202.151L115.088 184.212L130.148 166.274C132.065 163.665 132.065 160.077 130.422 158.12Z"
                fill="#4A7398"
            />
            <path
                d="M169.578 209C171.495 211.283 174.233 211.283 176.15 209L194.495 187.148C196.412 184.865 196.412 181.603 194.495 179.32L176.15 157.468C174.233 155.511 171.495 155.511 169.852 157.468C168.209 159.425 167.935 163.012 169.852 164.969L184.912 182.908L169.852 200.846C167.935 203.455 167.935 207.043 169.578 209Z"
                fill="#4A7398"
            />
            <path
                d="M155.615 156.489C153.15 155.837 150.686 157.468 150.138 160.403L141.102 203.781C140.828 205.086 141.102 206.717 141.65 208.021C142.197 209.326 143.292 210.304 144.388 210.631C144.662 210.631 145.209 210.957 145.483 210.631C147.674 210.631 149.316 209 149.864 206.391L158.9 163.012C159.722 160.077 158.079 157.142 155.615 156.489Z"
                fill="#4A7398"
            />
        </svg>
    );
}

import { SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { openNotificationWithIcon } from "../design-system";
import { DEFAULT_LANG } from "../lang";

export function strBetween(str: string, startStr: string, endStr: string): string {
    return str.substring(str.indexOf(startStr) + (startStr ? startStr.length : 0), str.indexOf(endStr));
}

/**
 * It takes a string, replaces all the words with their first letter in uppercase, and then removes all
 * the spaces
 * @param {string} str - The string to be processed.
 * @returns The first letter of each word in the string.
 */
export function getWordsInitials(str: string): string {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase()).replace(/\s/g, "");
}

export function getWordInitial(str: string): string {
    return getWordsInitials(str).charAt(0);
}

/**
 * @param str sentence to break
 * @description Add carriage return to sentence each time the previous chunk is larger than 'maxLineCount' characters
 */
export function lineBreaksSentence(str: string, maxLineCount = 20): string {
    return str
        .split(" ")
        .reduce((text, word) => [text, word].join(word.length > maxLineCount || text.substr(text.lastIndexOf("\n") + 1).length > maxLineCount ? "\n\n" : " "));
}

function formatNumber(
    number: number,
    style: "decimal" | "currency" | "percent" = "decimal",
    locale: SupportedLanguageCode = DEFAULT_LANG,
    maximumFractionDigits = 0
): string {
    return number.toLocaleString(locale, { style, currency: "EUR", maximumFractionDigits });
}

export function formatAsDecimal(number: number, locale: SupportedLanguageCode = DEFAULT_LANG): string {
    return formatNumber(number, "decimal", locale);
}

export function formatAsCurrency(number: number, locale: SupportedLanguageCode = DEFAULT_LANG, maximumFractionDigits?: number): string {
    return formatNumber(number, "currency", locale, maximumFractionDigits);
}

export function formatAsPercent(number: number, locale: SupportedLanguageCode = DEFAULT_LANG): string {
    return formatNumber(number, "percent", locale);
}

export async function copyToClipboard(value: string | undefined): Promise<void> {
    if (!value) {
        return openNotificationWithIcon({ type: "info", message: "Nothing to copy" });
    }
    await navigator.clipboard.writeText(value);
    return openNotificationWithIcon({ type: "success", message: "Copied to clipboard" });
}

export function cropTextFromMiddle(text: string, maxLength = 200, separator = "[...]"): string {
    if (text.length <= maxLength) {
        return text;
    }
    const cropLength = maxLength - separator.length;
    const startCrop = Math.ceil((text.length - cropLength) / 2);
    const endCrop = Math.floor((text.length + cropLength) / 2);
    return `${text.slice(0, startCrop)}${separator}${text.slice(endCrop)}`;
}

export function trimTrailingNewLines(text: string): string {
    return text.replace(/^\s*[\r\n]+|[\r\n]+\s*$/g, "");
}

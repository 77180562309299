import { IconSvgProps } from "../Icons";

export default function CheckCircleFilledSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.296 7.89603C17.3972 7.93742 17.489 7.99807 17.5662 8.07448C17.6504 8.15083 17.7177 8.24348 17.7637 8.34661C17.8097 8.44975 17.8334 8.56111 17.8334 8.6737C17.8334 8.78629 17.8097 8.89766 17.7637 9.00079C17.7177 9.10393 17.6504 9.19658 17.5662 9.27293L10.7922 15.9255C10.7151 16.0019 10.6232 16.0626 10.5221 16.104C10.4209 16.1454 10.3124 16.1667 10.2028 16.1667C10.0932 16.1667 9.98473 16.1454 9.88357 16.104C9.78241 16.0626 9.6906 16.0019 9.61343 15.9255L6.42569 12.7949C6.34556 12.7189 6.28146 12.6281 6.23706 12.5278C6.19265 12.4274 6.1688 12.3195 6.16687 12.2101C6.16495 12.1007 6.18498 11.992 6.22583 11.8902C6.26667 11.7884 6.32753 11.6955 6.40493 11.6168C6.48233 11.5381 6.57476 11.4752 6.67693 11.4316C6.7791 11.388 6.88902 11.3645 7.00041 11.3626C7.1118 11.3608 7.22248 11.3804 7.32613 11.4205C7.42977 11.4607 7.52436 11.5204 7.60449 11.5964L10.2028 14.1564L16.3874 8.07448C16.4645 7.99807 16.5564 7.93742 16.6575 7.89603C16.7587 7.85464 16.8672 7.83333 16.9768 7.83333C17.0864 7.83333 17.1949 7.85464 17.296 7.89603Z"
            />
        </svg>
    );
}

import { IconSvgProps } from "../Icons";

export default function ScopeSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.345 14.4937C13.7845 14.4937 12.3174 13.8861 11.2139 12.7828C10.1104 11.6795 9.50269 10.2125 9.50269 8.65213C9.50269 7.09175 10.1104 5.6248 11.214 4.52145C12.3174 3.41816 13.7845 2.81052 15.345 2.81052C16.9056 2.81052 18.3727 3.41811 19.4762 4.52145C20.5797 5.62484 21.1875 7.0918 21.1875 8.65217C21.1875 10.2125 20.5797 11.6795 19.4762 12.7828C18.3727 13.8861 16.9056 14.4937 15.345 14.4937ZM15.345 4.21681C14.1601 4.21681 13.0461 4.67816 12.2082 5.51591C11.3704 6.35361 10.9089 7.46741 10.9089 8.65213C10.9089 9.83684 11.3704 10.9506 12.2082 11.7883C13.0461 12.6261 14.1601 13.0874 15.345 13.0874C16.53 13.0874 17.644 12.6261 18.4819 11.7883C19.3198 10.9506 19.7812 9.83689 19.7812 8.65217C19.7812 7.46745 19.3198 6.35366 18.4819 5.51595C17.644 4.67816 16.53 4.21681 15.345 4.21681Z" />
            <path d="M21.465 2.53307C18.0905 -0.84094 12.5998 -0.84094 9.22519 2.53307C7.59047 4.16756 6.69019 6.34068 6.69019 8.65218C6.69019 10.1897 7.089 11.6658 7.8368 12.9631L5.2673 15.5322C5.2673 15.5322 5.26725 15.5322 5.2672 15.5322C5.26716 15.5322 5.26716 15.5323 5.26716 15.5323L0.662766 20.1361C0.235359 20.5634 0 21.1316 0 21.7359C0 22.3384 0.233953 22.9049 0.658875 23.3318C0.660234 23.3332 0.661594 23.3346 0.662906 23.3359C1.104 23.7769 1.68342 23.9974 2.2628 23.9974C2.84217 23.9974 3.42159 23.7769 3.86269 23.3359L8.46736 18.7319L8.46741 18.7319L8.46745 18.7318L11.04 16.1597C12.3682 16.9207 13.8563 17.3017 15.345 17.3017C17.5611 17.3017 19.7779 16.458 21.465 14.7712C23.0997 13.1368 24 10.9636 24 8.65218C24 6.34068 23.0997 4.16751 21.465 2.53307ZM2.86842 22.3415C2.53528 22.6745 1.99378 22.6754 1.6597 22.344C1.65881 22.3431 1.65792 22.3422 1.65703 22.3413C1.49531 22.1796 1.40625 21.9645 1.40625 21.7359C1.40625 21.5072 1.49531 21.2922 1.65708 21.1305L5.76455 17.0237L6.9758 18.2347L2.86842 22.3415ZM7.97025 17.2404L6.759 16.0294L8.65055 14.1381C8.83059 14.357 9.02222 14.5683 9.22523 14.7712C9.43017 14.9761 9.64303 15.1683 9.86259 15.3484L7.97025 17.2404ZM20.4707 13.7768C19.0574 15.1899 17.2015 15.8964 15.3451 15.8962C13.4891 15.8961 11.6325 15.1896 10.2195 13.7768C8.85042 12.408 8.09644 10.588 8.09644 8.65218C8.09644 6.71634 8.85038 4.89637 10.2195 3.52753C11.6326 2.11462 13.4888 1.40817 15.3451 1.40817C17.2013 1.40817 19.0575 2.11462 20.4707 3.52753C21.8398 4.89637 22.5938 6.71634 22.5938 8.65218C22.5938 10.588 21.8398 12.408 20.4707 13.7768Z" />
        </svg>
    );
}

import { Account, AccountRights } from "@vaultinum/vaultinum-api";
import {
    AddIcon,
    Button,
    Color,
    DisconnectedIcon,
    GithubIcon,
    IconTag,
    List,
    RowCards,
    Tag,
    TrashIcon,
    addQueryParamsToUrl,
    formatDate,
    openNotificationWithIcon,
    separateWithBullet,
    useAuthContext,
    useHasAccountRights,
    useLang,
    useModal,
    useUrlSearch
} from "@vaultinum/vaultinum-sdk";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ViewWrapper } from "../../../components";
import { SubMenuItemKeys } from "../../../helpers";
import { AccountLang } from "../../../lang/AccountLang";
import { URL, createGitConnection, getGitConnections } from "../../../services";
import AddGitConnectionModal from "./components/AddGitConnectionModal";
import DeleteGitConnectionModal from "./components/DeleteGitConnectionModal";

function gitConnectionStatusToColor(status: Account.GitConnectionStatus | undefined): Color | undefined {
    switch (status) {
        case Account.GitConnectionStatus.ACTIVE:
            return "green";
        case Account.GitConnectionStatus.INACTIVE:
        case Account.GitConnectionStatus.FETCH_FAILED:
            return "red";
        case Account.GitConnectionStatus.FETCHING:
            return "orange";
        default:
            return undefined;
    }
}

function GitConnectionStatusTag({ status }: { status?: Account.GitConnectionStatus }): JSX.Element {
    const lang = useLang<AccountLang>();
    switch (status) {
        case Account.GitConnectionStatus.ACTIVE:
            return <IconTag.Success message={lang.accountSettings.gitView.installed} />;
        case Account.GitConnectionStatus.INACTIVE:
            return <Tag type="danger" icon={DisconnectedIcon} message={lang.accountSettings.gitView.disconnected} />;
        case Account.GitConnectionStatus.FETCHING:
            return <IconTag.Pending message={lang.accountSettings.gitView.fetching} />;
        case Account.GitConnectionStatus.FETCH_FAILED:
            return <Tag type="danger" icon={DisconnectedIcon} message={lang.accountSettings.gitView.fetchFailed} />;
        default:
            return <></>;
    }
}

export default function GitConnectionsView(): JSX.Element {
    const hasRightWRITE = useHasAccountRights(AccountRights.WRITE);
    const lang = useLang<AccountLang>();
    const { selectedAccount } = useAuthContext();
    const accountId = selectedAccount?.id;
    const { isOpen: isAddModalOpen, doOpen: openAddModal, doClose: closeAddModal } = useModal();
    const { isOpen: isDeleteModalOpen, doOpen: openDeleteModal, doClose: closeDeleteModal } = useModal();
    const [gitConnections, setGitConnections] = useState<Account.GitConnection[]>([]);
    const { installation_id: installationId } = useUrlSearch() as { installation_id?: string };
    const isInstallingConnection = !!installationId;
    const navigate = useNavigate();

    useEffect(() => {
        if (accountId) {
            return getGitConnections(accountId, setGitConnections);
        }
        return () => {};
    }, [accountId]);

    useEffect(() => {
        void (async () => {
            if (installationId && accountId) {
                try {
                    await createGitConnection(accountId, Number.parseInt(installationId), Account.GitConnectionProvider.GITHUB);
                    navigate(addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.GIT }));
                } catch {
                    openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, installationId]);

    return (
        <ViewWrapper title={lang.accountSettings.gitView.title}>
            <AddGitConnectionModal isOpen={isAddModalOpen} doClose={closeAddModal} />
            <div className="ml-auto">
                <Button icon={AddIcon} onClick={openAddModal} isLoading={isInstallingConnection} isDisabled={!hasRightWRITE}>
                    {isInstallingConnection ? lang.accountSettings.gitView.installingConnection : lang.accountSettings.gitView.newConnection}
                </Button>
            </div>
            <List
                list={gitConnections}
                render={gitConnection => (
                    <RowCards.WithActions
                        color={gitConnectionStatusToColor(gitConnection.status)}
                        icon={<GithubIcon color="slate" size="lg" />}
                        actions={
                            hasRightWRITE
                                ? [
                                      {
                                          icon: TrashIcon,
                                          "data-id": "remove-connection",
                                          onClick: openDeleteModal,
                                          label: lang.accountSettings.gitView.deleteConnection
                                      }
                                  ]
                                : []
                        }
                    >
                        <DeleteGitConnectionModal isOpen={isDeleteModalOpen} doClose={closeDeleteModal} gitConnection={gitConnection} />
                        <div className="flex-1">
                            <div>{capitalize(gitConnection.provider)}</div>
                            <div className="text-grey-primary">
                                {[
                                    gitConnection.lastConnectionDate
                                        ? [lang.accountSettings.gitView.lastConnected(formatDate(gitConnection.lastConnectionDate, lang.code))]
                                        : [],
                                    gitConnection.accountLogin
                                ].map(separateWithBullet)}
                            </div>
                        </div>
                        <GitConnectionStatusTag status={gitConnection.status} />
                    </RowCards.WithActions>
                )}
                emptyText={lang.accountSettings.gitView.noResultsSearch}
                disableAnimation
            />
        </ViewWrapper>
    );
}

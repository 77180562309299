import { UserProfile } from "@vaultinum/vaultinum-api";
import {
    Buttons,
    Controller,
    DEFAULT_DATE,
    Form,
    getLangCode,
    hasLegalAge,
    Input,
    NationalitySelect,
    openNotificationWithIcon,
    useAuthContext,
    useForm,
    useLang,
    usePhoneValidation,
    useRequiredString,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { ViewWrapper } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";
import { getPhoneData, updateUserProfileInfo } from "../../../services/userProfileService";

type UserProfileFormValues = Pick<UserProfile, "firstName" | "lastName" | "cellPhone" | "nationality" | "birthDate">;

const PersonalInformationView = (): JSX.Element => {
    const { userProfile } = useAuthContext();
    const lang = useLang<AccountLang>();

    const [working, setWorking] = useState(false);
    const schema = yup.object({
        firstName: useRequiredString(),
        lastName: useRequiredString(),
        cellPhone: usePhoneValidation()
    });

    const {
        handleSubmit,
        control,
        formState: { errors, isValid }
    } = useForm<UserProfileFormValues>({ schema, defaultValues: { ...userProfile }, mode: "onChange" });

    const onSubmit = async (values: UserProfileFormValues) => {
        setWorking(true);
        try {
            if (userProfile?.id) {
                await updateUserProfileInfo(userProfile.id, values);
                openNotificationWithIcon({ type: "success", description: lang.shared.saveSuccessMessage });
            }
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: lang.shared.saveErrorMessage });
        } finally {
            setWorking(false);
        }
    };

    return (
        <ViewWrapper title={lang.profileSettings.form.personalInformation} dataId="personal-information-form">
            <Form onSubmit={handleSubmit(onSubmit)} className="rounded-md bg-white p-4">
                <Input.Email data-id="email-input" value={userProfile?.email} label={lang.profileSettings.form.email} disabled />
                <Controller
                    data-id="first-name-control"
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            label={lang.profileSettings.form.firstName}
                            data-id="first-name-input"
                            errorMessage={errors.firstName?.message}
                            disabled={working}
                            required
                        />
                    )}
                />
                <Controller
                    data-id="last-name-control"
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            label={lang.profileSettings.form.lastName}
                            data-id="last-name-input"
                            errorMessage={errors.lastName?.message}
                            disabled={working}
                            required
                        />
                    )}
                />
                <Controller
                    data-id="phone-control"
                    name="cellPhone"
                    control={control}
                    render={({ field }) => (
                        <Input.Phone
                            {...field}
                            label={lang.profileSettings.form.cellPhone}
                            data-id="phone-input"
                            countriesPhoneData={getPhoneData(getLangCode(userProfile))}
                            disabled={working}
                            errorMessage={errors.cellPhone?.phoneNumber?.message}
                        />
                    )}
                />
                <Controller
                    data-id="birthdate-control"
                    name="birthDate"
                    control={control}
                    render={({ field }) => (
                        <Input.DatePicker
                            {...field}
                            data-id="birthdate-input"
                            disabled={working}
                            label={lang.profileSettings.form.birthdate}
                            filterDate={hasLegalAge}
                            openToDate={field.value || DEFAULT_DATE}
                            selected={field.value}
                            placeholderText={lang.shared.dateFormat}
                            locale={getLangCode(userProfile)}
                        />
                    )}
                />
                <Controller
                    data-id="nationality-control"
                    name="nationality"
                    control={control}
                    render={({ field }) => (
                        <NationalitySelect
                            {...field}
                            label={lang.profileSettings.form.nationality}
                            data-id="nationality-select"
                            langCode={getLangCode(userProfile)}
                        />
                    )}
                />
                <div className="ml-auto">
                    <Buttons.Save isLoading={working} isDisabled={!isValid} />
                </div>
            </Form>
        </ViewWrapper>
    );
};

export default PersonalInformationView;

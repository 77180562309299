import { Root } from "@radix-ui/react-separator";
import classNames from "classnames";
import { ComponentProps } from "react";
import { Color } from "../../referentials";

export type SeparatorProps = {
    orientation?: ComponentProps<typeof Root>["orientation"];
    color?: Color;
    isDark?: boolean;
};

export function Separator({ orientation = "horizontal", color = "grey", isDark = false }: SeparatorProps): JSX.Element {
    return (
        <Root
            orientation={orientation}
            className={classNames(`shrink-0 bg-${color}-extra-light`, {
                "w-px": orientation === "vertical",
                "h-px": orientation === "horizontal",
                "bg-opacity-30": isDark
            })}
        />
    );
}

import { FullAuditUser } from "@vaultinum/vaultinum-api";
import { Badge } from "../../design-system";
import { getWordInitial } from "../../helpers";
import { formatName } from "../helpers";

export default function ContributorBadge({ contributor }: { contributor: FullAuditUser }): JSX.Element {
    const firstNameInitial = getWordInitial(contributor.firstName);
    const lastNameInitial = getWordInitial(contributor.lastName);
    const name = formatName(contributor);
    return <Badge key={name} size="small" title={name} children={`${firstNameInitial}${lastNameInitial}`} />;
}

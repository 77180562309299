import classNames from "classnames";
import { Progress } from "../../../../../common";
import { formatScoreColor, formatScoreToBenchmarkColor } from "../../../tools/ReportFormattingTools";
import BenchmarkScore from "./BenchmarkScore";
import "./ScoreBadge.css";

function ScoreTitle({ label, size }: { size: number; label?: string }): JSX.Element | null {
    if (!label) {
        return null;
    }
    return <div className={classNames("bottom-1 font-bold sm:bottom-5", { "text-4xl": size >= 150, "text-xl": size < 150 })}>{label}</div>;
}

function benchmarkAndScore(score: number | undefined, benchmarkColor?: string, benchmarkScore = 0, size = 0): JSX.Element {
    return (
        <>
            {benchmarkScore > 0 && size > 0 && <BenchmarkScore score={benchmarkScore} size={size} strokeWidth={15} strokeColor={benchmarkColor} />}
            <div
                className={classNames("flex h-full flex-col justify-center align-middle", {
                    "pb-3": size > 70
                })}
            >
                {score !== undefined ? (
                    <div>
                        <span>{score}</span>
                        <small>
                            <small>%</small>
                        </small>
                    </div>
                ) : (
                    "-"
                )}
            </div>
        </>
    );
}

export default function ScoreBadge({
    score,
    benchmarkScore,
    benchmarkColor,
    label,
    size = 200,
    labelPosition = "bottom"
}: {
    score: number;
    benchmarkScore?: number;
    benchmarkColor?: string;
    label?: string;
    size?: number;
    labelPosition?: "top" | "bottom";
}) {
    const format = (percentScore: number | undefined) => benchmarkAndScore(percentScore, benchmarkColor, benchmarkScore, size);
    const strokeColor = benchmarkScore ? formatScoreToBenchmarkColor(score, benchmarkScore) : formatScoreColor(score);
    return (
        <div className="score-badge mx-auto mb-6 flex flex-col gap-3 text-center last:mb-0 sm:mb-0">
            {labelPosition === "top" && <ScoreTitle label={label} size={size} />}
            <Progress
                type="dashboard"
                percent={score}
                format={format}
                width={size}
                gapDegree={90}
                strokeWidth={size > 70 ? 6 : 10}
                strokeColor={strokeColor}
                trailColor="#ddd"
            />
            {labelPosition === "bottom" && <ScoreTitle label={label} size={size} />}
        </div>
    );
}

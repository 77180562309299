import classNames from "classnames";
import { ElementType } from "react";
import { Size } from "../../referentials";
import {
    AccessClauseSvg,
    AgreementSvg,
    BillingSvg,
    BipartiteSvg,
    DefaultSvg,
    DepositOutlinedSvg,
    DepositSvg,
    DragAndDropSvg,
    EscrowOutlinedSvg,
    EscrowSvg,
    FullAuditOutlinedSvg,
    FullAuditSvg,
    NewContractSvg,
    OnlineAssessmentOutlinedSvg,
    OnlineAssessmentSvg,
    PartnerSvg,
    PaymentSvg,
    PeopleSvg,
    PersonSvg,
    SaasSvg,
    SummarySvg,
    SupplierSvg,
    TimestampingSvg,
    TripartiteSvg,
    VaultSvg
} from "./business";

export const LogoSize: Record<Size, number> = {
    xxs: 24,
    xs: 36,
    sm: 48,
    md: 64,
    lg: 96,
    xl: 128
};

export interface LogoProps extends Pick<React.HTMLAttributes<HTMLSpanElement>, "onClick" | "title"> {
    size?: keyof typeof LogoSize;
}

export type LogoSvgProps = {
    size: (typeof LogoSize)[keyof typeof LogoSize];
};

export const logo =
    (CustomIconComponent: ElementType) =>
    ({ size = "md", ...props }: LogoProps) => (
        <span
            {...props}
            className={classNames({
                "hover:cursor-pointer": !!props.onClick
            })}
            children={<CustomIconComponent size={LogoSize[size]} />}
        />
    );

export const AgreementLogo = logo(AgreementSvg);
export const AccessClauseLogo = logo(AccessClauseSvg);
export const BillingLogo = logo(BillingSvg);
export const BipartiteLogo = logo(BipartiteSvg);
export const DefaultLogo = logo(DefaultSvg);
export const DepositOutlinedLogo = logo(DepositOutlinedSvg);
export const DepositLogo = logo(DepositSvg);
export const DragAndDropLogo = logo(DragAndDropSvg);
export const EscrowOutlinedLogo = logo(EscrowOutlinedSvg);
export const EscrowLogo = logo(EscrowSvg);
export const FullAuditOutlinedLogo = logo(FullAuditOutlinedSvg);
export const FullAuditLogo = logo(FullAuditSvg);
export const NewContractLogo = logo(NewContractSvg);
export const OnlineAssessmentOutlinedLogo = logo(OnlineAssessmentOutlinedSvg);
export const OnlineAssessmentLogo = logo(OnlineAssessmentSvg);
export const PartnerLogo = logo(PartnerSvg);
export const PaymentLogo = logo(PaymentSvg);
export const PeopleLogo = logo(PeopleSvg);
export const PersonLogo = logo(PersonSvg);
export const SaasLogo = logo(SaasSvg);
export const SummaryLogo = logo(SummarySvg);
export const SupplierLogo = logo(SupplierSvg);
export const TimestampingLogo = logo(TimestampingSvg);
export const TripartiteLogo = logo(TripartiteSvg);
export const VaultLogo = logo(VaultSvg);

import { Button } from "@vaultinum/vaultinum-sdk";
import { useNavigate } from "react-router-dom";

export function FeedbackButton({ label, dest }: { label: string; dest: string }): JSX.Element {
    const navigate = useNavigate();

    return (
        <div className="flex w-full justify-center">
            <Button onClick={() => navigate(dest)} isLoading={false} type="default" children={label} />
        </div>
    );
}

import { Account, AccountInvitation, AccountRole, AccountUser, InvitationStatus } from "@vaultinum/vaultinum-api";
import {
    Button,
    LoadingIcon,
    RowCard,
    RowCards,
    Tag,
    formatDisplayName,
    getAccountUser,
    getAccountUsers,
    message,
    openNotificationWithIcon,
    setSelectedAccount,
    useAuthContext,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { AccountLang } from "../lang/AccountLang";
import { acceptInvitation, formatRightsToAccountRole, getAccountUserRole, rejectInvitation } from "../services";

function CompanyName({
    account,
    isSelected,
    invitation
}: {
    account: Account;
    isSelected: boolean;
    invitation?: { invitationSenderAccountUser: AccountUser | null };
}): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const lang = useLang<AccountLang>();

    async function doChooseAccount(): Promise<void> {
        if (!isSelected) {
            setIsLoading(true);
            try {
                await setSelectedAccount(account.id);
                // Reload page to avoid any ids issues
                location.reload();
            } catch {
                void message.error(lang.shared.failMessage);
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div>
            <div className="flex items-center gap-2">
                <div>
                    {!invitation ? (
                        <a onClick={doChooseAccount} className="link">
                            {account.companyName}
                        </a>
                    ) : (
                        account.companyName
                    )}
                </div>
                {isLoading && (
                    <div>
                        <LoadingIcon size="xs" />
                    </div>
                )}
            </div>
            {invitation?.invitationSenderAccountUser && (
                <div className="text-grey-primary">
                    {lang.profileSettings.companyAccounts.invitedBy(formatDisplayName(invitation.invitationSenderAccountUser))}
                </div>
            )}
        </div>
    );
}

export default function CompanyAccountRow({
    account,
    accountInvitation,
    onLeaveClicked,
    onDeleteAccountClicked,
    setIsReplyingToInvitation
}: {
    account: Account;
    accountInvitation?: AccountInvitation;
    onLeaveClicked?: () => void;
    onDeleteAccountClicked?: () => void;
    setIsReplyingToInvitation?: (value: boolean) => void;
}): JSX.Element {
    const { userProfile, selectedAccount } = useAuthContext();
    const [isWorking, setIsWorking] = useState(false);
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>([]);
    const [invitationSenderAccountUser, setInvitationSenderAccountUser] = useState<AccountUser | null>(null);
    const lang = useLang<AccountLang>();

    useEffect(() => {
        void (async () => {
            const users = await getAccountUsers(account);
            setAccountUsers(users);
        })();
    }, [account]);

    useEffect(() => {
        if (accountInvitation?.sentByUID) {
            return getAccountUser(account, accountInvitation.sentByUID, setInvitationSenderAccountUser);
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInvitation]);

    const accountUser = !accountInvitation ? accountUsers.find(({ id }) => id === userProfile?.id) : null;
    const isSelected = selectedAccount?.id === account.id;
    const accountUserRights = accountInvitation?.rights || accountUser?.rights;
    const isLastAccountUser = accountUsers.length === 1;
    const isRepresentative = getAccountUserRole(accountUserRights) === AccountRole.REPRESENTATIVE;

    async function doRejectInvitation(): Promise<void> {
        if (accountInvitation) {
            setIsWorking(true);
            setIsReplyingToInvitation?.(true);
            try {
                await rejectInvitation(accountInvitation);
            } catch (err) {
                openNotificationWithIcon({ type: "error", description: `${lang.profileSettings.companyAccounts.failedToRejectInvitation}: ${err}` });
            } finally {
                setIsReplyingToInvitation?.(false);
                setIsWorking(false);
            }
        }
    }

    async function doAcceptInvitation(): Promise<void> {
        if (accountInvitation) {
            setIsWorking(true);
            setIsReplyingToInvitation?.(true);
            try {
                await acceptInvitation(accountInvitation);
            } catch (err) {
                openNotificationWithIcon({ type: "error", description: `${lang.profileSettings.companyAccounts.failedToJoinAccount}: ${err}` });
            } finally {
                setIsReplyingToInvitation?.(false);
                setIsWorking(false);
            }
        }
    }

    const invitationActions: JSX.Element = (
        <div className="flex gap-2">
            <Button
                data-id="reject-invitation"
                onClick={doRejectInvitation}
                isLoading={isWorking}
                children={lang.profileSettings.companyAccounts.rejectInvitation}
            />
            <Button
                data-id="accept-invitation"
                onClick={doAcceptInvitation}
                isLoading={isWorking}
                children={lang.profileSettings.companyAccounts.acceptInvitation}
            />
        </div>
    );

    const invitationStatus: JSX.Element = <div className="text-right italic text-primary">{capitalize(accountInvitation?.status)}</div>;

    const children: JSX.Element = (
        <div className="flex flex-1 items-center justify-between">
            <CompanyName account={account} isSelected={isSelected} {...(invitationSenderAccountUser && { invitation: { invitationSenderAccountUser } })} />
            <Tag message={formatRightsToAccountRole(lang, accountUserRights)} />
        </div>
    );

    if (accountInvitation) {
        return (
            <RowCard
                data-id="company-account"
                rightChildren={accountInvitation?.status === InvitationStatus.PENDING ? invitationActions : invitationStatus}
                children={children}
            />
        );
    }
    const actions = [
        ...(isLastAccountUser && !isRepresentative && onDeleteAccountClicked
            ? [{ "data-id": "delete", onClick: onDeleteAccountClicked, label: lang.shared.delete, danger: true }]
            : []),
        ...((!isLastAccountUser || (isLastAccountUser && isRepresentative)) && onLeaveClicked
            ? [{ "data-id": "leave", onClick: onLeaveClicked, label: lang.profileSettings.companyAccounts.leave, danger: true }]
            : [])
    ];
    return <RowCards.WithActions data-id="company-account" actions={actions} {...(isSelected && { color: "slate" })} children={children} />;
}

import { Controller, Input, message, useAuthContext, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { AccountLang } from "../../../lang/AccountLang";
import { UserProfileFields, updateUserProfileInfo } from "../../../services";
import { Step } from "./Step";
import { OnboardingContext } from "./onboardingMachine";

export default function UserProfileStep({
    context,
    onChange,
    canDoNext,
    doNext
}: {
    context: OnboardingContext;
    onChange: (userProfile: UserProfileFields) => void;
    canDoNext: boolean;
    doNext: () => void;
}): JSX.Element {
    const { userProfile } = useAuthContext();
    const [working, setWorking] = useState(false);
    const lang = useLang<AccountLang>();

    const schema = yup.object({
        firstName: useRequiredString(),
        lastName: useRequiredString()
    } satisfies Partial<Record<keyof UserProfileFields, yup.AnySchema>>);

    const form = useForm<UserProfileFields>({ schema, defaultValues: context.userProfileFields, mode: "onChange" });

    useEffect(() => {
        const { unsubscribe } = form.watch(() => onChange(form.getValues()));
        return () => unsubscribe();
    }, [form, onChange]);

    async function onSubmit(values: UserProfileFields) {
        setWorking(true);
        try {
            if (userProfile?.id) {
                await updateUserProfileInfo(userProfile.id, values);
                doNext();
            }
        } catch (err) {
            void message.error(err.message);
        } finally {
            setWorking(false);
        }
    }

    return (
        <Step title={lang.onboarding.userProfile.title} nextButtonDisabled={!canDoNext} onSubmit={onSubmit} stepKey="user-profile-step" form={form}>
            <div className="flex gap-8">
                <Controller
                    data-id="first-name-control"
                    name="firstName"
                    control={form.control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            required
                            data-id="first-name-input"
                            label={lang.profileSettings.form.firstName}
                            disabled={working}
                            errorMessage={form.formState.errors.firstName?.message}
                            className="w-full"
                        />
                    )}
                />
                <Controller
                    data-id="last-name-control"
                    name="lastName"
                    control={form.control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            required
                            data-id="last-name-input"
                            label={lang.profileSettings.form.lastName}
                            disabled={working}
                            errorMessage={form.formState.errors.lastName?.message}
                            className="w-full"
                        />
                    )}
                />
            </div>
        </Step>
    );
}

import { Account, ACCOUNT_COLLECTION, ACCOUNT_GIT_CONNECTION_COLLECTION } from "@vaultinum/vaultinum-api";
import { collection, DocumentSnapshot, doPost, getFirestore, onSnapshot, query, where } from "@vaultinum/vaultinum-sdk";

const convertDocToGitConnection = (doc: DocumentSnapshot): Account.GitConnection =>
    ({
        ...doc.data(),
        installationDate: doc.data()?.installationDate?.toDate(),
        lastUpdateDate: doc.data()?.lastUpdateDate?.toDate(),
        lastConnectionDate: doc.data()?.lastConnectionDate?.toDate()
    } as Account.GitConnection);

export function getGitConnections(accountId: string, onUpdate: (gitConnections: Account.GitConnection[]) => void): () => void {
    return onSnapshot(
        query(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_GIT_CONNECTION_COLLECTION), where("accountId", "==", accountId)),
        querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToGitConnection)),
        () => onUpdate([])
    );
}

export async function createGitConnection(accountId: string, installationId: number, provider: Account.GitConnectionProvider): Promise<void> {
    return doPost(`account/${accountId}/gitConnection`, {
        installationId,
        provider
    });
}

import { CSSProperties } from "react";

const THICKNESS = 3;

function getPathProps(offset: number, percent: number, gapDegree: number, strokeWidth: number, strokeColor = ""): { d: string; style: CSSProperties } {
    const radius = 50 - strokeWidth / 2;
    const beginPositionX = 0;
    const beginPositionY = radius;
    const endPositionX = 0;
    const endPositionY = 2 * radius;
    const d = `M 50,50 m ${beginPositionX},${beginPositionY}
   a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
   a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;
    const len = Math.PI * 2 * radius;

    const style = {
        stroke: strokeColor,
        strokeDasharray: `${(percent / 100) * (len - gapDegree)}px ${len}px`,
        strokeDashoffset: `-${gapDegree / 2 + (offset / 100) * (len - gapDegree)}px`
    };

    return { d, style };
}

export default function BenchmarkScore({
    score,
    size = 120,
    gapDegree = 80,
    strokeWidth,
    strokeColor = "#325574"
}: {
    score: number;
    size?: number;
    gapDegree?: number;
    strokeWidth: number;
    strokeColor?: string;
}) {
    const circleStyle: React.CSSProperties = {
        width: size,
        height: size,
        fontSize: size * 0.15 + 6
    };

    const offset = score - THICKNESS / 2;

    return (
        <div className="absolute">
            <svg viewBox="0 0 100 100" style={circleStyle}>
                <path strokeWidth={strokeWidth} fillOpacity="0" {...getPathProps(offset, THICKNESS, gapDegree, strokeWidth, strokeColor)} />
            </svg>
        </div>
    );
}

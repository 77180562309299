import { CrossCircleIcon } from "../Icons";

export function ErrorMessage({ message }: { message: string }) {
    return (
        <div className="flex items-center gap-1" data-id="alert-error">
            <CrossCircleIcon size="xs" color="red" />
            <span className="text-danger">{message}</span>
        </div>
    );
}

import { Link } from "react-router-dom";

type BreadcrumbItem = {
    label: string;
    href?: string;
};

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type BreadcrumbItems = [...WithRequired<BreadcrumbItem, "href">[], BreadcrumbItem];

export default function Breadcrumb({ items }: { items: BreadcrumbItems }): JSX.Element {
    const lastItemIndex = items.length - 1;
    return (
        <nav>
            <ol className="flex items-center gap-1 whitespace-nowrap font-light">
                {items
                    .map(({ label, href }, index) => (
                        <li key={label} className={index > 0 && index < lastItemIndex ? "truncate" : ""}>
                            {href && (
                                <Link to={href} className={"cursor-pointer text-grey-primary hover:underline"}>
                                    {label}
                                </Link>
                            )}
                            {!href && <span className="cursor-default font-medium">{label}</span>}
                        </li>
                    ))
                    .map((item, i) => [i > 0 && "/", item])}
            </ol>
        </nav>
    );
}

import { IconSvgProps } from "../Icons";

function LockSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 8.25C5.77166 8.24733 5.54799 8.31468 5.35908 8.44298C5.17018 8.57128 5.02511 8.75437 4.94341 8.9676C4.86171 9.18084 4.8473 9.41399 4.90211 9.63567C4.95693 9.85735 5.07834 10.0569 5.25 10.2075V11.25C5.25 11.4489 5.32902 11.6397 5.46967 11.7803C5.61032 11.921 5.80109 12 6 12C6.19891 12 6.38968 11.921 6.53033 11.7803C6.67098 11.6397 6.75 11.4489 6.75 11.25V10.2075C6.92166 10.0569 7.04307 9.85735 7.09789 9.63567C7.1527 9.41399 7.13829 9.18084 7.05659 8.9676C6.97489 8.75437 6.82982 8.57128 6.64092 8.44298C6.45201 8.31468 6.22834 8.24733 6 8.25ZM9.75 5.25V3.75C9.75 2.75544 9.35491 1.80161 8.65165 1.09835C7.94839 0.395088 6.99456 0 6 0C5.00544 0 4.05161 0.395088 3.34835 1.09835C2.64509 1.80161 2.25 2.75544 2.25 3.75V5.25C1.65326 5.25 1.08097 5.48705 0.65901 5.90901C0.237053 6.33097 0 6.90326 0 7.5V12.75C0 13.3467 0.237053 13.919 0.65901 14.341C1.08097 14.7629 1.65326 15 2.25 15H9.75C10.3467 15 10.919 14.7629 11.341 14.341C11.7629 13.919 12 13.3467 12 12.75V7.5C12 6.90326 11.7629 6.33097 11.341 5.90901C10.919 5.48705 10.3467 5.25 9.75 5.25ZM3.75 3.75C3.75 3.15326 3.98705 2.58097 4.40901 2.15901C4.83097 1.73705 5.40326 1.5 6 1.5C6.59674 1.5 7.16903 1.73705 7.59099 2.15901C8.01295 2.58097 8.25 3.15326 8.25 3.75V5.25H3.75V3.75ZM10.5 12.75C10.5 12.9489 10.421 13.1397 10.2803 13.2803C10.1397 13.421 9.94891 13.5 9.75 13.5H2.25C2.05109 13.5 1.86032 13.421 1.71967 13.2803C1.57902 13.1397 1.5 12.9489 1.5 12.75V7.5C1.5 7.30109 1.57902 7.11032 1.71967 6.96967C1.86032 6.82902 2.05109 6.75 2.25 6.75H9.75C9.94891 6.75 10.1397 6.82902 10.2803 6.96967C10.421 7.11032 10.5 7.30109 10.5 7.5V12.75Z" />
        </svg>
    );
}

export default LockSvg;

import { ReactNode } from "react";
// eslint-disable-next-line import/no-named-default
import { default as ReactCookieConsent } from "react-cookie-consent";
import { useLang } from "../../../lang";
import { BaseTrackingEvents, trackingService } from "../../analytics";

export default function CookieConsent({
    onAccept,
    onDecline,
    acceptButtonText,
    declineButtonText,
    children
}: {
    onAccept: () => void;
    onDecline?: () => void;
    acceptButtonText?: string;
    declineButtonText?: string;
    children?: ReactNode;
}): JSX.Element {
    const lang = useLang();

    function doAccept() {
        trackingService.sendEvent(BaseTrackingEvents.CookieConsent, { accepted: true });
        onAccept();
    }

    function doDecline() {
        trackingService.sendEvent(BaseTrackingEvents.CookieConsent, { accepted: false });
        onDecline?.();
    }

    return (
        <ReactCookieConsent
            enableDeclineButton
            buttonText={acceptButtonText ?? lang.cookieConsent?.accept}
            declineButtonText={declineButtonText ?? lang.cookieConsent?.decline}
            expires={180}
            onAccept={doAccept}
            onDecline={doDecline}
            overlay
            overlayStyle={{
                background: "hsla(0, 0%, 0%, 0.5)",
                display: "grid",
                placeItems: "center"
            }}
            style={{
                alignItems: "center",
                background: "white",
                color: "black",
                borderRadius: 5,
                justifyContent: "center",
                margin: "auto",
                maxWidth: "900px",
                position: "static",
                top: "30px",
                width: "75%",
                display: "block",
                textAlign: "center"
            }}
            contentStyle={{
                display: "block",
                justifyContent: "center"
            }}
            buttonStyle={{
                background: "var(--color-primary)",
                color: "white",
                borderRadius: "5px",
                outlined: "none"
            }}
            declineButtonStyle={{
                background: "white",
                color: "var(--color-primary)",
                borderRadius: "5px",
                outline: "solid 2px var(--color-primary)",
                boxSizing: "border-box"
            }}
        >
            {children ?? lang.cookieConsent?.description}
        </ReactCookieConsent>
    );
}

import { IconSvgProps } from "../../../../../common";

export default function DueDiligenceSvg({ size, color }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.5904C0 10.1141 10.114 0 22.5904 0H277.41C289.886 0 300 10.114 300 22.5904V277.41C300 289.886 289.886 300 277.41 300H22.5904C10.1141 300 0 289.886 0 277.41V22.5904Z"
                fill={color ?? "#C7E1FF"}
            />
            <path
                d="M206.752 198.463L255.639 234.511C258.207 236.402 260.112 239.058 261.08 242.097C262.048 245.137 262.03 248.405 261.029 251.433C260.027 254.462 258.093 257.096 255.504 258.96C252.915 260.823 249.803 261.82 246.613 261.807C242.583 261.806 238.717 260.212 235.856 257.373L191.022 212.689L206.752 198.463Z"
                fill="#163046"
            />
            <path
                d="M134.578 230.964C187.81 230.964 230.964 187.811 230.964 134.578C230.964 81.346 187.81 38.1927 134.578 38.1927C81.3459 38.1927 38.1926 81.346 38.1926 134.578C38.1926 187.811 81.3459 230.964 134.578 230.964Z"
                fill="#4A7398"
            />
            <path
                d="M134.578 215.542C179.293 215.542 215.542 179.294 215.542 134.578C215.542 89.8633 179.293 53.6146 134.578 53.6146C89.8633 53.6146 53.6145 89.8633 53.6145 134.578C53.6145 179.294 89.8633 215.542 134.578 215.542Z"
                fill="#C7E1FF"
            />
            <path
                d="M180.843 119.157V169.277C180.843 172.345 179.625 175.287 177.456 177.456C175.287 179.625 172.345 180.843 169.277 180.843H99.8795C96.8119 180.843 93.87 179.625 91.7009 177.456C89.5318 175.287 88.3132 172.345 88.3132 169.277V119.157H180.843Z"
                fill="#FCFDFF"
            />
            <path
                d="M180.843 99.8795V119.157H88.3132V99.8795C88.3132 96.8119 89.5318 93.87 91.7009 91.7009C93.87 89.5318 96.8119 88.3132 99.8795 88.3132H169.277C172.345 88.3132 175.287 89.5318 177.456 91.7009C179.625 93.87 180.843 96.8119 180.843 99.8795Z"
                fill="#294966"
            />
            <path
                d="M107.59 107.59H103.735C102.712 107.59 101.732 107.184 101.009 106.461C100.286 105.738 99.8794 104.758 99.8794 103.735C99.8794 102.712 100.286 101.732 101.009 101.009C101.732 100.286 102.712 99.8796 103.735 99.8796H107.59C108.613 99.8796 109.593 100.286 110.316 101.009C111.039 101.732 111.446 102.712 111.446 103.735C111.446 104.758 111.039 105.738 110.316 106.461C109.593 107.184 108.613 107.59 107.59 107.59Z"
                fill="white"
            />
            <path
                d="M126.868 107.59H123.012C121.99 107.59 121.009 107.184 120.286 106.461C119.563 105.738 119.157 104.758 119.157 103.735C119.157 102.712 119.563 101.732 120.286 101.009C121.009 100.286 121.99 99.8796 123.012 99.8796H126.868C127.89 99.8796 128.871 100.286 129.594 101.009C130.317 101.732 130.723 102.712 130.723 103.735C130.723 104.758 130.317 105.738 129.594 106.461C128.871 107.184 127.89 107.59 126.868 107.59Z"
                fill="white"
            />
            <path
                d="M121.467 131.992C120.184 130.463 118.35 130.463 117.066 131.992L104.781 146.626C103.497 148.155 103.497 150.339 104.781 151.868L117.066 166.502C118.35 167.813 120.184 167.813 121.284 166.502C122.384 165.192 122.568 162.789 121.284 161.479L111.199 149.465L121.284 137.452C122.568 135.705 122.567 133.303 121.467 131.992Z"
                fill="#294966"
            />
            <path
                d="M147.69 166.065C148.973 167.594 150.807 167.594 152.091 166.065L164.376 151.431C165.66 149.902 165.66 147.718 164.376 146.189L152.091 131.555C150.807 130.245 148.973 130.245 147.873 131.555C146.773 132.866 146.589 135.268 147.873 136.579L157.958 148.592L147.873 160.605C146.589 162.352 146.589 164.755 147.69 166.065Z"
                fill="#294966"
            />
            <path
                d="M138.338 130.9C136.688 130.463 135.037 131.555 134.671 133.521L128.619 162.571C128.436 163.444 128.619 164.536 128.986 165.41C129.353 166.284 130.086 166.939 130.82 167.157C131.003 167.157 131.37 167.376 131.553 167.157C133.02 167.157 134.12 166.065 134.487 164.318L140.538 135.268C141.089 133.303 139.988 131.337 138.338 130.9Z"
                fill="#294966"
            />
        </svg>
    );
}

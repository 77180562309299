import { IconSvgProps } from "../Icons";

export default function QuestionInfoSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6786 17.1786C10.6786 17.4154 10.7726 17.6425 10.9401 17.8099C11.1075 17.9774 11.3346 18.0714 11.5714 18.0714C11.8082 18.0714 12.0353 17.9774 12.2028 17.8099C12.3702 17.6425 12.4643 17.4154 12.4643 17.1786C12.4643 16.9418 12.3702 16.7147 12.2028 16.5472C12.0353 16.3798 11.8082 16.2857 11.5714 16.2857C11.3346 16.2857 11.1075 16.3798 10.9401 16.5472C10.7726 16.7147 10.6786 16.9418 10.6786 17.1786ZM14.0625 7.90848C13.3929 7.32366 12.5089 7 11.5714 7C10.6339 7 9.75 7.32143 9.08036 7.90848C8.38393 8.51786 8 9.33705 8 10.2143V10.3839C8 10.4821 8.08036 10.5625 8.17857 10.5625H9.25C9.34821 10.5625 9.42857 10.4821 9.42857 10.3839V10.2143C9.42857 9.22991 10.3906 8.42857 11.5714 8.42857C12.7522 8.42857 13.7143 9.22991 13.7143 10.2143C13.7143 10.9085 13.2232 11.5446 12.4643 11.8371C11.9911 12.0179 11.5893 12.3348 11.3013 12.75C11.0067 13.1741 10.8571 13.683 10.8571 14.1987V14.6786C10.8571 14.7768 10.9375 14.8571 11.0357 14.8571H12.1071C12.2054 14.8571 12.2857 14.7768 12.2857 14.6786V14.1719C12.2869 13.9552 12.3533 13.7439 12.4763 13.5655C12.5994 13.3871 12.7733 13.2499 12.9754 13.1719C14.2924 12.6652 15.1429 11.5045 15.1429 10.2143C15.1429 9.33705 14.7589 8.51786 14.0625 7.90848Z" />
            <path d="M21.2233 8.125C20.7189 6.92634 19.9957 5.85045 19.0738 4.92634C18.1584 4.00758 17.0716 3.27728 15.8751 2.77679C14.6474 2.26116 13.3439 2 12.0001 2H11.9555C10.6028 2.0067 9.29254 2.27455 8.06039 2.80134C6.87414 3.30697 5.79759 4.03856 4.89075 4.95536C3.97781 5.87723 3.26129 6.94866 2.76575 8.14286C2.25236 9.37946 1.99343 10.6942 2.00013 12.0469C2.0077 13.597 2.37444 15.1243 3.07156 16.5089V19.9018C3.07156 20.1741 3.17973 20.4353 3.37229 20.6278C3.56485 20.8204 3.82602 20.9286 4.09834 20.9286H7.49343C8.87802 21.6257 10.4053 21.9924 11.9555 22H12.0024C13.3394 22 14.6363 21.7411 15.8573 21.2344C17.0477 20.7398 18.1304 20.0181 19.0448 19.1094C19.9666 18.1964 20.6921 17.1295 21.1988 15.9397C21.7256 14.7076 21.9934 13.3973 22.0001 12.0446C22.0068 10.6853 21.7434 9.36607 21.2233 8.125ZM17.8506 17.9018C16.2858 19.4509 14.2099 20.3036 12.0001 20.3036H11.9622C10.6162 20.2969 9.27914 19.9621 8.09834 19.3326L7.91084 19.2321H4.76798V16.0893L4.66754 15.9018C4.03807 14.721 3.70325 13.3839 3.69656 12.0379C3.68763 9.8125 4.53807 7.72321 6.09834 6.14955C7.65638 4.57589 9.73897 3.70536 11.9644 3.69643H12.0024C13.1184 3.69643 14.201 3.91295 15.2211 4.34152C16.2166 4.75893 17.1095 5.35938 17.8774 6.12723C18.643 6.89286 19.2457 7.78795 19.6631 8.78348C20.0961 9.81473 20.3126 10.9085 20.3082 12.0379C20.2948 14.2612 19.422 16.3438 17.8506 17.9018Z" />
        </svg>
    );
}

import { Dispatch, SetStateAction, useState } from "react";

export default function useToggle(initialState = true): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
    const [isOn, setIsOn] = useState(initialState);

    function doToggle() {
        return setIsOn(prev => !prev);
    }

    return [isOn, doToggle, setIsOn];
}

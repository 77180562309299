import { AccountInvitation, AccountRights, AccountUser, InvitationStatus } from "@vaultinum/vaultinum-api";
import {
    Alert,
    Button,
    ContentLoader,
    DeleteIcon,
    Input,
    List,
    ModalHelper,
    RowCards,
    SectionTitle,
    Tag,
    formatDisplayName,
    formatName,
    getAccountUsers,
    openNotificationWithIcon,
    useAuthContext,
    useHasAccountRights,
    useLang,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { ComponentProps, ReactNode, useEffect, useState } from "react";
import { ViewWrapper } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";
import {
    cancelAccountInvitation,
    formatRightsToAccountRole,
    getInvitationsByAccountIdAndDomainId,
    isAccountInvitation,
    sendAccountInvitation
} from "../../../services";
import { deleteAccountUser } from "../../../services/accountService";
import ChangeRoleDialog from "./ChangeRoleDialog";
import InviteUserDialog from "./InviteUserDialog";

const UserAccessView = (): JSX.Element => {
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>([]);
    const [accountInvitations, setAccountInvitations] = useState<AccountInvitation[]>([]);
    const [showInviteDialog, setShowInviteDialog] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [accountUserToChange, setAccountUserToChange] = useState<AccountUser | AccountInvitation | null>(null);
    const { user, selectedAccount: account } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const lang = useLang<AccountLang>();
    useEffect(() => getAccountUsers(account, setAccountUsers), [account]);
    useEffect(() => {
        if (account?.id) {
            return getInvitationsByAccountIdAndDomainId(account.id, whiteLabelDomain?.id, setAccountInvitations);
        }
        return () => {};
    }, [account?.id, whiteLabelDomain?.id]);

    const isLoggedUserAdmin = useHasAccountRights(AccountRights.ADMIN);
    const canLoggedUserInvite = useHasAccountRights(AccountRights.INVITE);
    function isMe(accountUser: AccountUser | AccountInvitation) {
        return accountUser.email === user?.email;
    }

    const filteredAccountUsers = accountUsers.filter(accountUser => {
        const lowerCaseSearchValue = searchValue?.toLowerCase();
        return (
            accountUser.email?.toLowerCase().includes(lowerCaseSearchValue) ||
            accountUser.firstName.toLowerCase().includes(lowerCaseSearchValue) ||
            accountUser.lastName.toLowerCase().includes(lowerCaseSearchValue)
        );
    });

    const filteredAccountInvitations = accountInvitations
        .filter(invitation => [InvitationStatus.PENDING, InvitationStatus.ACCEPTED_NOT_REGISTERED].includes(invitation.status))
        .filter(invitation => invitation.email?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));

    function getAccountUserCompareValue(accountUser: AccountUser): string {
        return `${accountUser.firstName || ""} ${accountUser.lastName || ""} ${accountUser.email}`?.trim().toLowerCase();
    }

    async function removeAccountUser(accountUser: AccountUser | AccountInvitation) {
        try {
            if (isAccountInvitation(accountUser)) {
                await cancelAccountInvitation(accountUser);
            } else if (account) {
                await deleteAccountUser(account, accountUser.id);
            } else {
                openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
            }
            openNotificationWithIcon({ type: "success", description: lang.shared.successMessage });
        } catch (err) {
            openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
        }
    }

    function showRemoveFromAccountConfirmation(accountUser: AccountUser | AccountInvitation) {
        const targetUserDisplayName = isAccountInvitation(accountUser) ? accountUser.email : formatDisplayName(accountUser);
        ModalHelper.Confirm({
            title: lang.accountSettings.userAccessView.removeFromAccountConfirmationTitle(targetUserDisplayName),
            icon: DeleteIcon,
            children: lang.accountSettings.userAccessView.removeFromAccountConfirmationContent(targetUserDisplayName, account?.companyName || ""),
            onConfirm: async () => {
                await removeAccountUser(accountUser);
            },
            lang
        });
    }

    async function resendInvitation(accountUser: AccountUser | AccountInvitation) {
        try {
            if (account) {
                await sendAccountInvitation(account, accountUser.email, accountUser.rights);
                openNotificationWithIcon({ type: "success", description: lang.shared.inviteSuccessMessage });
            }
        } catch (err) {
            openNotificationWithIcon({ type: "error", description: lang.shared.inviteErrorMessage });
        }
    }

    function getAccountUserActions(accountUser: AccountUser | AccountInvitation): ComponentProps<typeof RowCards.WithActions>["actions"] {
        if (isMe(accountUser)) {
            return [];
        }
        const canEditInvitation = isLoggedUserAdmin || canLoggedUserInvite;
        const onChangeRoleClick = () => setAccountUserToChange(accountUser);
        const onResendInvitationClick = () => resendInvitation(accountUser);
        const onRemoveAccountClick = () => showRemoveFromAccountConfirmation(accountUser);
        return [
            ...(canEditInvitation ? [{ onClick: onChangeRoleClick, label: lang.accountSettings.userAccessView.changeRole }] : []),
            ...(isAccountInvitation(accountUser) ? [{ onClick: onResendInvitationClick, label: lang.accountSettings.userAccessView.resendInvite }] : []),
            ...(canEditInvitation
                ? [
                      {
                          onClick: onRemoveAccountClick,
                          label: isAccountInvitation(accountUser)
                              ? lang.accountSettings.userAccessView.cancelInvitation
                              : lang.accountSettings.userAccessView.removeFromAccount
                      }
                  ]
                : [])
        ];
    }

    function getAccountUserTitle(accountUser: AccountUser | AccountInvitation): ReactNode {
        if (isAccountInvitation(accountUser)) {
            return <div className="italic text-warning">{lang.accountSettings.userAccessView.invitePending}</div>;
        }
        return formatName(accountUser);
    }

    function onInviteClicked() {
        setShowInviteDialog(true);
    }

    function userAccessRender(accountUser: AccountUser | AccountInvitation): JSX.Element {
        return (
            <RowCards.WithActions actions={getAccountUserActions(accountUser)} {...(isMe(accountUser) && { color: "slate" })}>
                <div className="flex-1">
                    {getAccountUserTitle(accountUser)}
                    <div className="text-grey-primary">{accountUser.email}</div>
                </div>
                <Tag message={formatRightsToAccountRole(lang, accountUser.rights)} />
            </RowCards.WithActions>
        );
    }

    const closeInviteDialog = () => setShowInviteDialog(false);
    const resetAccountUserToChange = () => setAccountUserToChange(null);

    const accountUsersEmails = accountUsers.map(({ email }) => email);
    const invitedUsersEmails = accountInvitations
        .filter(({ status }) => ![InvitationStatus.REJECTED, InvitationStatus.CANCELED].includes(status))
        .map(({ email }) => email);

    if (!account) {
        return <ContentLoader />;
    }

    return (
        <ViewWrapper title={lang.accountSettings.userAccessView.name}>
            <Alert.Info title={lang.accountSettings.userAccessView.whatIs} message={lang.accountSettings.userAccessView.definition} />
            <div className="mb-2 flex justify-between">
                <Input.Search
                    value={searchValue}
                    placeholder={lang.accountSettings.userAccessView.search}
                    onChange={e => setSearchValue(e.target.value)}
                    className="w-72"
                />
                <Button
                    {...(!canLoggedUserInvite && { title: lang.accountSettings.userAccessView.inviteUser.tooltip })}
                    data-id="invite-user"
                    onClick={onInviteClicked}
                    isDisabled={!canLoggedUserInvite}
                    isLoading={false}
                    children={lang.accountSettings.userAccessView.inviteUser.name}
                />
                {showInviteDialog && (
                    <InviteUserDialog
                        isLoggedUserAdmin={isLoggedUserAdmin}
                        account={account}
                        accountUsersEmails={accountUsersEmails}
                        invitedUsersEmails={invitedUsersEmails}
                        onClose={closeInviteDialog}
                    />
                )}
            </div>
            {accountUserToChange && (
                <ChangeRoleDialog isLoggedUserAdmin={isLoggedUserAdmin} accountUser={accountUserToChange} onClose={resetAccountUserToChange} />
            )}
            <div className="h-full space-y-2">
                {!!filteredAccountInvitations.length && (
                    <>
                        <SectionTitle title={lang.accountSettings.userAccessView.invitations} />
                        <List
                            list={[...filteredAccountInvitations.sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()))]}
                            render={userAccessRender}
                            emptyText={lang.sharedSettings.noResultsSearch}
                        />
                    </>
                )}
                {!!filteredAccountUsers.length && (
                    <>
                        <SectionTitle title={lang.accountSettings.userAccessView.name} />
                        <List
                            list={[...filteredAccountUsers.sort((a, b) => getAccountUserCompareValue(a).localeCompare(getAccountUserCompareValue(b)))]}
                            render={userAccessRender}
                            emptyText={lang.sharedSettings.noResultsSearch}
                        />
                    </>
                )}
            </div>
        </ViewWrapper>
    );
};

export default UserAccessView;

import { BaseApp, CookieConsent } from "@vaultinum/vaultinum-sdk";
import { useEffect } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import AppRouter from "./Router";
import { activateLinkedinAds } from "./analytics/tagManager";
import languages from "./lang/languages";

function App(): JSX.Element {
    const handleAcceptCookies = () => {
        activateLinkedinAds();
    };

    useEffect(() => {
        if (getCookieConsentValue() === "true") {
            handleAcceptCookies();
        }
    }, []);

    return (
        <BaseApp languages={languages}>
            <AppRouter />
            <CookieConsent onAccept={handleAcceptCookies} />
        </BaseApp>
    );
}

export default App;

import {
    AppCode,
    AppLayout,
    APPS_INFOS,
    HardRedirect,
    isDomainOwner,
    NaviguationMenuLayout,
    useAuthContext,
    useLang,
    useNavigationMenuContext,
    useUrlSearch,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { getMenuItems, getPrioritaryUrl } from "../../helpers";
import { AccountLang } from "../../lang/AccountLang";
import { PATH, URL } from "../../services/routingService";
import HomePage from "./home/HomePage";
import { OnboardingWorkflow } from "./onboarding/onboardingMachine";
import WelcomePage from "./onboarding/OnboardingPage";
import VerifyEmailPage from "./onboarding/VerifyEmailPage";
import { DomainOrganisationsPage } from "./organisations";
import SettingsPage from "./settings/SettingsPage";

function FullyRegisteredRoutes() {
    const { whiteLabelDomain } = useWhiteLabelContext();
    const { userProfile, claims } = useAuthContext();
    const { redirectTo } = useUrlSearch() as { redirectTo?: string };
    const { setMenuItems } = useNavigationMenuContext();
    const lang = useLang<AccountLang>();

    useEffect(() => {
        setMenuItems(getMenuItems(lang, isDomainOwner(claims, whiteLabelDomain?.id)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, claims, whiteLabelDomain?.id]);

    return (
        <Routes>
            <Route index element={<HomePage />} />
            <Route // Route matched when landing on the app by user who have checked the "remember me" option. Redirects to the selectedAppCode's url if set.
                path={PATH.login}
                element={
                    !redirectTo && userProfile?.selectedAppCode && userProfile.selectedAppCode in APPS_INFOS ? (
                        <HardRedirect path={APPS_INFOS[userProfile.selectedAppCode as unknown as AppCode].baseUrl} />
                    ) : (
                        <Navigate to={URL.index} replace />
                    )
                }
            />
            {isDomainOwner(claims, whiteLabelDomain?.id) && (
                <Route
                    path={PATH.domainOrganisations.index}
                    element={
                        <Routes>
                            <Route index element={<DomainOrganisationsPage />} />
                        </Routes>
                    }
                />
            )}
            <Route path={PATH.settings.index} element={<SettingsPage />} />
            <Route
                path={PATH.account.index}
                element={
                    <Routes>
                        <Route path={PATH.account.new} element={<WelcomePage workflow={OnboardingWorkflow.ADD_ACCOUNT} />} />
                    </Routes>
                }
            />
            <Route path={PATH.all} element={<Navigate to={URL.index} replace />} />
        </Routes>
    );
}

export default function LoggedPage(): JSX.Element {
    const { user, userProfile } = useAuthContext();
    const prioritaryUrl = getPrioritaryUrl(user, userProfile);
    const { pathname } = useLocation();

    let layout: NaviguationMenuLayout = "full";
    if (pathname.startsWith(URL.welcome)) {
        layout = "minimal";
    } else if (prioritaryUrl === URL.verify) {
        layout = "logout-only";
    }

    return (
        <AppLayout layout={layout} isMenuDefaultExpanded={layout === "full"} hideExpandButton={layout !== "full"}>
            <Routes>
                {prioritaryUrl === URL.verify && <Route path={PATH.verify} element={<VerifyEmailPage />} />}
                <Route path={PATH.welcome} element={<WelcomePage workflow={OnboardingWorkflow.NO_ACCOUNT} />} />
                {prioritaryUrl ? (
                    <Route path={PATH.all} element={<Navigate to={prioritaryUrl} replace />} />
                ) : (
                    <Route path={PATH.all} element={<FullyRegisteredRoutes />} />
                )}
            </Routes>
        </AppLayout>
    );
}

import { IconSvgProps } from "../Icons";

function CandyBoxMenuSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg viewBox="0 0 40 40" fill={color} width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" />
            <circle cx="4" cy="20" r="4" />
            <circle cx="4" cy="36" r="4" />
            <circle cx="20" cy="4" r="4" />
            <circle cx="20" cy="20" r="4" />
            <circle cx="20" cy="36" r="4" />
            <circle cx="36" cy="4" r="4" />
            <circle cx="36" cy="20" r="4" />
            <circle cx="36" cy="36" r="4" />
        </svg>
    );
}

export default CandyBoxMenuSvg;

import { ElementType } from "react";
import { CodeInput, Modal } from "../../../design-system";
import { useLang } from "../../../lang";

export default function CodeInputModal({
    validateCode,
    title,
    description,
    icon,
    codeInputUpgrade,
    onSuccess,
    isOpen,
    onClose
}: {
    validateCode: (codeInput: string) => Promise<void>;
    title: string;
    description: string;
    icon?: ElementType;
    codeInputUpgrade?: JSX.Element;
    onSuccess?: () => void;
    isOpen?: boolean;
    onClose?: () => void;
}): JSX.Element {
    const lang = useLang();

    const validate = async (codeInput?: string): Promise<void> => {
        if (codeInput) {
            await validateCode(codeInput);
            onSuccess?.();
        }
    };

    return (
        <Modal
            size="md"
            lang={lang}
            isOpen={isOpen}
            title={title}
            icon={icon}
            isLoading={false}
            onClose={onClose}
            hideFooter={true}
            children={
                <div className="space-y-4">
                    <p>{description}</p>
                    <CodeInput
                        codeLength={8}
                        validateCode={validate}
                        codeValidationMessage={lang.codeInput.codeValidationMessage}
                        codeValidationMessageError={lang.codeInput.codeValidationMessageError}
                        codeValidationMessageSuccess={lang.codeInput.codeValidationMessageSuccess}
                    />
                    <div>{codeInputUpgrade}</div>
                </div>
            }
        />
    );
}

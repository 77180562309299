import { LayoutPosition } from "chart.js";
import "chart.js/auto";
import dayjs from "dayjs";
import { Chart } from "react-chartjs-2";
import { CommonChartParams, Dataset } from "../config";

type LineDataset = Dataset & {
    backgroundColor?: string;
    borderColor?: string;
};

export type LineChartParams = Omit<CommonChartParams, "legendPosition" | "dataset"> & {
    dataset: LineDataset[];
    labels: (string | Date)[];
    title?: string;
    subtitle?: string;
    isFilled?: boolean;
    isPercentage?: boolean;
    curveType?: "function" | "none";
    isStacked?: boolean;
    dateFormat?: string;
    legendPosition?: LayoutPosition;
};

export function LineChart({
    labels,
    dataset,
    title,
    subtitle,
    isFilled = false,
    isStacked = false,
    legendPosition = "bottom",
    height,
    width,
    dateFormat = "MMM YYYY"
}: LineChartParams): JSX.Element {
    const areDateLabels = labels.every(label => label instanceof Date);
    return (
        <div
            style={{
                width: width ?? "100%",
                height: height ?? "auto"
            }}
        >
            <Chart
                type="line"
                data={{
                    labels: areDateLabels ? labels.map(label => dayjs(label).format(dateFormat)) : labels,
                    datasets: dataset.map(data => ({ ...data, backgroundColor: data.color, borderColor: data.color }))
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    elements: {
                        line: {
                            tension: 0,
                            borderWidth: 1,
                            fill: isFilled
                        },
                        point: {
                            radius: 0
                        }
                    },
                    spanGaps: true,
                    showLine: true,
                    plugins: {
                        legend: {
                            position: legendPosition,
                            labels: {
                                padding: 20
                            }
                        },
                        subtitle: {
                            display: !!subtitle,
                            text: subtitle
                        },
                        title: {
                            display: !!title,
                            text: title
                        }
                    },
                    scales: {
                        y: {
                            stacked: isStacked
                        }
                    }
                }}
            />
        </div>
    );
}

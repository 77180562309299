import { CardCommon, ColCard, ColCardCommon, ColCardInterface } from "../..";

export namespace ColCards {
    export function Full({
        "data-id": dataId,
        header,
        actions,
        footer,
        buttons,
        ...props
    }: CardCommon.WithActions & CardCommon.WithButtons & ColCardInterface) {
        return (
            <ColCard
                {...props}
                header={ColCardCommon.HeaderWithActions({ "data-id": dataId, header, actions })}
                footer={ColCardCommon.FooterWithButtons({ "data-id": dataId, footer, buttons })}
            />
        );
    }

    export function WithRadio({ icon: Icon, children, radio, ...props }: Omit<CardCommon.WithRadio, "label"> & ColCardInterface) {
        const isSelected = radio.id === radio.selectedId;
        return (
            <ColCard
                {...props}
                isSelected={isSelected}
                onClick={() => (!isSelected ? radio.onValueChange?.(radio.id) : null)}
                {...(Icon && {
                    header: (
                        <div
                            className="flex w-full flex-col items-center justify-center"
                            children={<Icon size="lg" color={isSelected ? "white" : "slate"} />}
                        />
                    )
                })}
                children={<div className="flex w-full items-center justify-center text-center" children={children} />}
                footer={
                    <div className="flex items-center justify-center">
                        <CardCommon.Radio radio={radio} data-id={props["data-id"]} isDisabled={props.isDisabled} />
                    </div>
                }
            />
        );
    }
}

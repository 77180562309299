import { SortDirection, Column as TanstackColumn } from "@tanstack/react-table";
import classNames from "classnames";
import { useState } from "react";
import {
    Button,
    Buttons,
    CheckIcon,
    ColumnFilter,
    Dropdown,
    DropdownCommon,
    EMPTY_VALUES,
    EmptyFilter,
    FilterSortBgFilledIcon,
    FilterSortIcon,
    FunnelBgFilledIcon,
    IconProps
} from "..";
import { useLang } from "../../../lang";
import { FiltersSortLocalStorageService, TableStorageKey } from "../../../services";
import { Filters } from "./Filters";

function getIcon(isFiltered: boolean, isSorted: boolean): (props: IconProps) => JSX.Element {
    if (isFiltered) {
        return FunnelBgFilledIcon;
    }
    if (isSorted) {
        return FilterSortBgFilledIcon;
    }
    return FilterSortIcon;
}

function SortItem({ label, isActive, onClick }: { label: string; isActive: boolean; onClick: () => void }) {
    return (
        <div
            onClick={onClick}
            className={classNames("flex cursor-pointer items-center gap-2 px-4 py-3 text-slate-dark", {
                "bg-blue-extra-light": isActive,
                "hover:bg-grey-extra-light": !isActive
            })}
        >
            <span>{label}</span>
            {isActive && (
                <span className="ml-auto">
                    <CheckIcon color="slate" />
                </span>
            )}
        </div>
    );
}

export function FiltersSortDropdown<T extends object>({
    column,
    storageKey
}: {
    column: TanstackColumn<T, unknown> & { filters?: ColumnFilter<T>[] };
    storageKey?: TableStorageKey;
}) {
    const filtersSortService = storageKey ? new FiltersSortLocalStorageService(storageKey) : undefined;
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const columnFilters = column.getFilterValue() as unknown[];
    const [existingFilters, setExistingFilters] = useState<unknown[]>(
        columnFilters?.map(val => (EMPTY_VALUES.includes(val as EmptyFilter) ? null : val)) || (filtersSortService?.loadFilter(column.id) ?? [])
    );
    const lang = useLang();

    const isSortable = column.getCanSort();
    const isFilterable = column.getCanFilter() || column.filters;
    const currentSortOrder: false | SortDirection = filtersSortService
        ? (filtersSortService.loadSort(column.id) as false | SortDirection)
        : column.getIsSorted();

    const onSort = (direction: SortDirection) => {
        if (currentSortOrder === direction) {
            column.clearSorting();
            filtersSortService?.saveSort(column.id, false);
        } else {
            column.toggleSorting(direction === "desc");
            filtersSortService?.saveSort(column.id, direction);
        }
        setIsOpened(false);
    };

    const setFilter = (value: unknown, isActive: boolean) => {
        if (isActive) {
            setExistingFilters(prev => [...prev, value]);
        } else {
            setExistingFilters(prev => prev.filter(val => val !== value));
        }
    };

    const onSubmit = () => {
        column.setFilterValue(existingFilters?.length ? existingFilters : undefined);
        filtersSortService?.saveFilter(column.id, existingFilters);
        setIsOpened(false);
    };

    return (
        <Dropdown open={isOpened} onOpenChange={setIsOpened}>
            <DropdownCommon.Trigger>
                {(isSortable || isFilterable) && (
                    <button type="button" className="flex h-full items-center p-1 focus:outline-none focus-visible:outline-none">
                        {getIcon(!!columnFilters?.length, !!column.getIsSorted())({ color: "slate" })}
                    </button>
                )}
            </DropdownCommon.Trigger>
            <DropdownCommon.Content>
                <>
                    {isSortable && (
                        <>
                            <SortItem label={lang.table.sortAsc} onClick={() => onSort("asc")} isActive={currentSortOrder === "asc"} />
                            <SortItem label={lang.table.sortDesc} onClick={() => onSort("desc")} isActive={currentSortOrder === "desc"} />
                            {isFilterable && <DropdownCommon.Separator />}
                        </>
                    )}
                    {isFilterable && (
                        <>
                            <div className="p-4">
                                <Filters
                                    column={column}
                                    existingFilters={existingFilters}
                                    onFilter={setFilter}
                                    onClear={() => setExistingFilters([])}
                                    onSelectAll={setExistingFilters}
                                />
                            </div>
                            <DropdownCommon.Separator />
                            <div className="flex justify-end gap-2 p-4">
                                <Buttons.Cancel size="sm" onClick={() => setIsOpened(false)} isLoading={false} />
                                <Button size="sm" isLoading={false} onClick={onSubmit} children={lang.table.apply} />
                            </div>
                        </>
                    )}
                </>
            </DropdownCommon.Content>
        </Dropdown>
    );
}

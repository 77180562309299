import { Direction, DIRECTION_TO_ROTATION_CLASS } from "../../../referentials";
import { IconSvgProps } from "../Icons";

export default function ChevronUpTripleSvg({ color, size, direction = "up" }: IconSvgProps & { direction?: Direction }): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} className={DIRECTION_TO_ROTATION_CLASS[direction]} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6293 9.60564C18.248 9.95301 17.665 9.95332 17.2833 9.60637L12.2191 5.00332L7.15499 9.60637C6.77327 9.95332 6.19029 9.95301 5.80895 9.60564L5.53068 9.35215C5.09524 8.95549 5.09524 8.27029 5.53068 7.87363L11.5457 2.39434C11.9274 2.04669 12.5109 2.04669 12.8926 2.39434L18.9076 7.87363C19.343 8.27029 19.343 8.95549 18.9076 9.35215L18.6293 9.60564Z" />
            <path d="M18.6293 15.6056C18.248 15.953 17.665 15.9533 17.2833 15.6064L12.2191 11.0033L7.15499 15.6064C6.77327 15.9533 6.19029 15.953 5.80895 15.6056L5.53068 15.3522C5.09524 14.9555 5.09524 14.2703 5.53068 13.8736L11.5457 8.39434C11.9274 8.04669 12.5109 8.04669 12.8926 8.39434L18.9076 13.8736C19.343 14.2703 19.343 14.9555 18.9076 15.3522L18.6293 15.6056Z" />
            <path d="M18.6293 21.6056C18.248 21.953 17.665 21.9533 17.2833 21.6064L12.2191 17.0033L7.15499 21.6064C6.77327 21.9533 6.19029 21.953 5.80895 21.6056L5.53068 21.3522C5.09524 20.9555 5.09524 20.2703 5.53068 19.8736L11.5457 14.3943C11.9274 14.0467 12.5109 14.0467 12.8926 14.3943L18.9076 19.8736C19.343 20.2703 19.343 20.9555 18.9076 21.3522L18.6293 21.6056Z" />
        </svg>
    );
}

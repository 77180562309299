import { LogoSvgProps } from "../Logos";

export default function DefaultSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.5904C0 10.1141 10.114 0 22.5904 0H277.41C289.886 0 300 10.114 300 22.5904V277.41C300 289.886 289.886 300 277.41 300H22.5904C10.1141 300 0 289.886 0 277.41V22.5904Z"
                fill="#D0E6FF"
            />
            <circle cx="99.5938" cy="200.406" r="39.5938" fill="white" />
            <rect x="60" y="59" width="79.1875" height="79.1875" fill="#4A7398" />
            <rect x="161.812" y="160.812" width="79.1875" height="79.1875" fill="#4A7398" />
            <circle cx="201.406" cy="98.5938" r="39.5938" fill="white" />
        </svg>
    );
}

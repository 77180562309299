import { IconSvgProps } from "../Icons";

function OverviewSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.70829 8.66673H3.45828C2.65415 8.66673 2 8.01258 2 7.20829V3.45828C2 2.65415 2.65415 2 3.45828 2H9.70829C10.5126 2 11.1667 2.65415 11.1667 3.45828V7.20829C11.1667 8.01258 10.5126 8.66673 9.70829 8.66673ZM3.45828 3.25C3.34339 3.25 3.25 3.34339 3.25 3.45828V7.20829C3.25 7.32334 3.34339 7.41672 3.45828 7.41672H9.70829C9.82334 7.41672 9.91673 7.32334 9.91673 7.20829V3.45828C9.91673 3.34339 9.82334 3.25 9.70829 3.25H3.45828Z" />
            <path d="M9.70829 22H3.45828C2.65415 22 2 21.3458 2 20.5417V11.7917C2 10.9874 2.65415 10.3333 3.45828 10.3333H9.70829C10.5126 10.3333 11.1667 10.9874 11.1667 11.7917V20.5417C11.1667 21.3458 10.5126 22 9.70829 22ZM3.45828 11.5833C3.34339 11.5833 3.25 11.6766 3.25 11.7917V20.5417C3.25 20.6566 3.34339 20.75 3.45828 20.75H9.70829C9.82334 20.75 9.91673 20.6566 9.91673 20.5417V11.7917C9.91673 11.6766 9.82334 11.5833 9.70829 11.5833H3.45828Z" />
            <path d="M20.5417 22H14.2917C13.4874 22 12.8333 21.3459 12.8333 20.5417V16.7917C12.8333 15.9874 13.4874 15.3333 14.2917 15.3333H20.5417C21.3458 15.3333 22 15.9874 22 16.7917V20.5417C22 21.3459 21.3458 22 20.5417 22ZM14.2917 16.5833C14.1766 16.5833 14.0833 16.6767 14.0833 16.7917V20.5417C14.0833 20.6566 14.1766 20.75 14.2917 20.75H20.5417C20.6566 20.75 20.75 20.6566 20.75 20.5417V16.7917C20.75 16.6767 20.6566 16.5833 20.5417 16.5833H14.2917Z" />
            <path d="M20.5417 13.6667H14.2917C13.4874 13.6667 12.8333 13.0126 12.8333 12.2083V3.45828C12.8333 2.65415 13.4874 2 14.2917 2H20.5417C21.3458 2 22 2.65415 22 3.45828V12.2083C22 13.0126 21.3458 13.6667 20.5417 13.6667ZM14.2917 3.25C14.1766 3.25 14.0833 3.34339 14.0833 3.45828V12.2083C14.0833 12.3233 14.1766 12.4167 14.2917 12.4167H20.5417C20.6566 12.4167 20.75 12.3233 20.75 12.2083V3.45828C20.75 3.34339 20.6566 3.25 20.5417 3.25H14.2917Z" />
        </svg>
    );
}

export default OverviewSvg;

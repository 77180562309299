import { Account, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { Alert, Buttons, Controller, Input, UseFormReturn } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { useWhiteLabelContext } from "../../contexts";
import { formatEmailHref } from "../../helpers";
import { SUPPORT_EMAIL } from "../../services";
import AddressSubForm from "./AddressSubForm";

export default function CompanyBillingDetailsForm({
    disableFields,
    langCode,
    form,
    onSubmit,
    loading,
    displayButton,
    requireAddress
}: {
    disableFields: boolean;
    langCode: SupportedLanguageCode;
    form: UseFormReturn<Account>;
    onSubmit: (values: Account) => void;
    loading?: boolean;
    displayButton?: boolean;
    requireAddress?: boolean;
}) {
    const lang = useLang<CommonLang>();
    const { whiteLabelDomain } = useWhiteLabelContext();

    function isFormValid(): Promise<boolean> {
        return form.trigger();
    }

    const isAddressRequired = !!form.watch("billingDetails.vatNumber") || requireAddress;

    return (
        <div className="flex flex-col gap-2">
            <Alert.Info message={lang.accountInformation.form.billing.billingInformationInvoicePostalAddress} />
            <div className="mb-2 italic">{lang.accountInformation.form.billing.billingInformationInvoiceCompanyName}</div>
            <Controller
                name="billingDetails.name"
                control={form.control}
                data-id="company-name-control"
                render={({ field }) => (
                    <Input.Text
                        {...field}
                        placeholder={lang.accountInformation.form.billing.invoiceCompanyName}
                        disabled={disableFields}
                        data-id="company-name-input"
                        required
                    />
                )}
            />
            <AddressSubForm
                disableFields={disableFields}
                langCode={langCode}
                control={form.control}
                inputNamePath="billingDetails.address"
                allFieldsRequired={isAddressRequired}
            />
            <div className="flex justify-between space-x-4">
                <Controller
                    name="billingDetails.vatNumber"
                    control={form.control}
                    data-id="vat-control"
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            label={lang.payment.billing.vatNumber}
                            placeholder={lang.payment.billing.vatNumberPlaceholder}
                            disabled={disableFields}
                            data-id="vat-input"
                            className="w-1/3"
                        />
                    )}
                />
                <Controller
                    name="billingDetails.contactEmail"
                    control={form.control}
                    data-id="billing-contact-control"
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            label={lang.payment.billing.billingContact}
                            placeholder={lang.payment.billing.billingContactPlaceholder}
                            disabled={disableFields}
                            data-id="billing-contact-input"
                            className="w-2/3"
                            errorMessage={form.formState.errors.billingDetails?.contactEmail?.message}
                        />
                    )}
                />
            </div>
            {displayButton && (
                <div className="flex w-full justify-end">
                    <Buttons.Save
                        isLoading={!!loading || disableFields}
                        onClick={async () => {
                            if (await isFormValid()) {
                                onSubmit(form.getValues());
                            }
                        }}
                        isDisabled={!form.formState.isValid}
                    />
                </div>
            )}
            {!whiteLabelDomain && (
                <div className="my-4 space-x-1 italic">
                    <span>{lang.accountInformation.form.billing.billingInformationQuestions}</span>
                    <a
                        href={formatEmailHref(SUPPORT_EMAIL, lang.email.subject.billingSupportRequest)}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        children={lang.shared.contactUs}
                    />
                </div>
            )}
        </div>
    );
}

import { IconSvgProps } from "../Icons";

export default function CrossCircleSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z" />
            <path d="M12.9451 11.9896L15.4182 9.54925C15.482 9.48316 15.5321 9.40515 15.5658 9.31967C15.5994 9.23419 15.6159 9.14292 15.6143 9.05106C15.6127 8.9592 15.593 8.86855 15.5564 8.7843C15.5198 8.70004 15.467 8.62383 15.401 8.56001C15.3349 8.49618 15.257 8.446 15.1716 8.41233C15.0861 8.37865 14.9949 8.36215 14.9031 8.36375C14.8113 8.36535 14.7208 8.38502 14.6366 8.42166C14.5524 8.45829 14.4762 8.51116 14.4124 8.57724L11.9738 11.0176L9.53516 8.57724C9.40338 8.46431 9.23386 8.40529 9.06049 8.41199C8.88712 8.41869 8.72265 8.49062 8.59997 8.61339C8.47728 8.73616 8.40541 8.90074 8.39871 9.07424C8.39202 9.24773 8.45099 9.41737 8.56385 9.54925L11.0025 11.9896L8.56385 14.4644C8.43554 14.5936 8.36353 14.7683 8.36353 14.9504C8.36353 15.1326 8.43554 15.3073 8.56385 15.4364C8.62821 15.5003 8.70455 15.5509 8.78849 15.5852C8.87242 15.6195 8.96229 15.6369 9.05295 15.6364C9.23367 15.6356 9.40685 15.5638 9.53516 15.4364L11.9738 12.9616L14.4469 15.4364C14.5752 15.5638 14.7484 15.6356 14.9291 15.6364C15.0197 15.6369 15.1096 15.6195 15.1935 15.5852C15.2775 15.5509 15.3538 15.5003 15.4182 15.4364C15.487 15.372 15.5419 15.294 15.5794 15.2075C15.6169 15.1209 15.6363 15.0276 15.6363 14.9332C15.6363 14.8389 15.6169 14.7455 15.5794 14.6589C15.5419 14.5724 15.487 14.4944 15.4182 14.43L12.9451 11.9896Z" />
        </svg>
    );
}

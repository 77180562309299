import { Elements } from "@stripe/react-stripe-js";
import { Account, AccountUser, Claims, SupportedLanguageCode, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { getAccountIdFromClaims } from "../../../helpers";
import { CommonLang, getLang, getLangCode, getLangContext } from "../../../lang";
import { AppInterface, AppProvider, AuthContextProvider, AuthProps, BrandContextProvider, BrandProps, WhiteLabelContextProvider } from "../../contexts";
import { useTracker } from "../../hooks";
import {
    User,
    UserProfileWithAccounts,
    getAccount,
    getAccountUserByAccountId,
    getAuth,
    getLogoByDomainId,
    getUserProfile,
    getWhiteLabelDomainByFQDN,
    setSelectedAccount,
    setSelectedAppCode,
    stripePromise
} from "../../services";
import { ContentLoader } from "../ContentLoader";

const initialLangCode = getLangCode(null);

export type CommonAppProps<TLang extends CommonLang, TAppItem> = {
    languages: Map<SupportedLanguageCode, TLang>;
    children: ReactNode;
    hideLoading?: boolean;
    getItem?: (itemId: string, accountId: string | undefined, onUpdate: (item: TAppItem | null) => void) => () => void;
};

export default function CommonApp<TLang extends CommonLang, TAppItem extends { id: string }, TAppCode extends string>({
    languages,
    children,
    hideLoading,
    getItem,
    appCode,
    appsInfos,
    ...props
}: CommonAppProps<TLang, TAppItem> & AppInterface<TAppItem, TAppCode> & BrandProps): JSX.Element {
    const [loading, setLoading] = useState(true);
    const [whiteLabelDomain, setWhiteLabelDomain] = useState<WhiteLabelDomain | null>(null);
    const [domainLogo, setDomainLogo] = useState<string>();
    const [authSettings, setAuthSettings] = useState<AuthProps>({
        user: null,
        claims: {},
        userProfile: null,
        accountUser: null,
        selectedAccount: null
    });
    useTracker(authSettings);
    const LangContext = getLangContext<TLang>();
    const [lang, setLang] = useState(getLang(initialLangCode, languages));

    function onSetUserProfile(updatedUserProfile: UserProfileWithAccounts | null): void {
        setAuthSettings(prev => ({ ...prev, userProfile: updatedUserProfile }));
    }

    function onSetAccountUser(updatedAccountUser: AccountUser | null): void {
        setAuthSettings(prev => ({ ...prev, accountUser: updatedAccountUser }));
    }

    function onSetAccount(updatedAccount: Account | null): void {
        setAuthSettings(prev => ({ ...prev, selectedAccount: updatedAccount }));
    }

    useEffect(() => {
        dayjs.extend(localizedFormat);
        return getAuth().onAuthStateChanged(async (updatedUser: User | null) => {
            setAuthSettings(prev => ({ ...prev, user: updatedUser }));
            if (updatedUser) {
                const tokenResult = await updatedUser.getIdTokenResult(true);
                setAuthSettings(prev => ({ ...prev, claims: tokenResult.claims as Claims }));
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        const updatedLangCode = getLangCode(authSettings.userProfile);
        dayjs.locale(updatedLangCode);
        setLang(getLang(updatedLangCode, languages));
    }, [authSettings.userProfile?.settings?.preferredLang]);

    useEffect(() => {
        void (async () => {
            let hostname = window.location.hostname;
            if (process.env.REACT_APP_PLATFORM === "development") {
                hostname = new URLSearchParams(window.location.search).get("domain") ?? hostname;
            }
            const domain = await getWhiteLabelDomainByFQDN(hostname);
            if (domain) {
                setWhiteLabelDomain(domain);
                const logo = await getLogoByDomainId(domain.id);
                setDomainLogo(logo);
            }
        })();
    }, []);

    const selectedAccountId = useMemo(() => getAccountIdFromClaims(authSettings.claims, whiteLabelDomain?.id), [authSettings.claims, whiteLabelDomain?.id]);

    useEffect(() => {
        void (async function () {
            if (selectedAccountId) {
                // refresh selectedAccount on domain change
                await setSelectedAccount(selectedAccountId);
            }
        })();
    }, [selectedAccountId]);

    useEffect(() => {
        if (selectedAccountId && authSettings.userProfile) {
            return getAccountUserByAccountId(selectedAccountId, authSettings.userProfile.id, onSetAccountUser);
        }
        return () => {};
    }, [selectedAccountId, authSettings.userProfile?.id]);

    useEffect(() => getUserProfile(authSettings.user, onSetUserProfile, whiteLabelDomain), [authSettings.user, authSettings.selectedAccount, whiteLabelDomain]);

    useEffect(
        () => getAccount(selectedAccountId, account => onSetAccount(account?.whiteLabelDomainId === (whiteLabelDomain?.id ?? null) ? account : null)),
        [selectedAccountId, whiteLabelDomain?.id]
    );

    useEffect(() => {
        void (async () => {
            if (authSettings.userProfile && ![authSettings.userProfile.selectedAppCode, appsInfos.account.appCode].includes(appCode)) {
                await setSelectedAppCode(authSettings.userProfile.id, appCode);
            }
        })();
    }, [authSettings?.userProfile?.id]);

    return (
        <LangContext.Provider value={lang}>
            <BrowserRouter>
                <AuthContextProvider value={authSettings}>
                    <BrandContextProvider value={props}>
                        <AppProvider value={{ getItem, appCode, appsInfos }}>
                            <Elements stripe={stripePromise}>
                                <WhiteLabelContextProvider value={{ whiteLabelDomain, domainLogo }}>
                                    {hideLoading ? (
                                        children
                                    ) : (
                                        <div className="h-screen">
                                            <ContentLoader loading={loading} size="large" fadeInContent initialState="spinning">
                                                {children}
                                            </ContentLoader>
                                        </div>
                                    )}
                                </WhiteLabelContextProvider>
                            </Elements>
                        </AppProvider>
                    </BrandContextProvider>
                </AuthContextProvider>
            </BrowserRouter>
        </LangContext.Provider>
    );
}

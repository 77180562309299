import { isDefined } from "@vaultinum/vaultinum-api";
import { Chart } from "react-google-charts";
import { Tooltip } from "../../Tooltip";
import { CommonChartParams } from "../config";

type Dataset = {
    row: string;
    name: string;
    from: Date;
    to: Date;
    color?: string;
};

export type TimelineChartParams = Pick<CommonChartParams, "height" | "width"> & {
    dataset: Dataset[];
    legend?: { color: string; label: string }[];
    minValue?: Date;
    maxValue?: Date;
};

export function TimelineChart({ dataset, minValue, maxValue, legend, width = "100%", height = "100%" }: TimelineChartParams): JSX.Element {
    const data = [["Row", "Name", { type: "date" }, { type: "date" }], ...dataset.map(({ row, name, from, to }) => [row, name, from, to])];
    const colors = dataset.map(({ color }) => color).filter(isDefined);
    return (
        <div className="flex flex-col gap-2 items-center">
            {!!legend?.length && (
                <div className="flex flex-wrap gap-2 justify-center">
                    {legend.map(({ color, label }) => (
                        <Tooltip key={label} title={label} placement="top">
                            <div className="flex items-center gap-2">
                                <div className="w-7 h-3.5" style={{ background: color }} />
                                <span className="max-w-sm truncate">{label}</span>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            )}
            <Chart
                chartType="Timeline"
                options={{
                    ...(colors.length && { colors }),
                    alternatingRowStyle: false,
                    hAxis: {
                        minValue,
                        maxValue
                    }
                }}
                width={width}
                height={height}
                data={data}
            />
        </div>
    );
}

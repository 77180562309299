import { ContentLoader, getLangCode, plural, Tabs, useAuthContext, useLang } from "@vaultinum/vaultinum-sdk";
import { CompanyBillingDetails, InvoiceList, PaymentMethodList, PlanAndSubscriptions } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";

enum BillingTabKey {
    BILLING_INFORMATION = "BILLING_INFORMATION",
    PAYMENT_METHODS = "PAYMENT_METHODS",
    PLANS_SUBSCRIPTIONS = "PLANS_SUBSCRIPTIONS",
    INVOICES = "INVOICES"
}

export default function BillingSubscriptionView(): JSX.Element {
    const lang = useLang<AccountLang>();
    const { userProfile, selectedAccount: account } = useAuthContext();
    const langCode = getLangCode(userProfile);

    if (!account) {
        return <ContentLoader />;
    }

    return (
        <Tabs defaultActiveKey="1" className="h-full flex-1">
            <Tabs.TabPane className="icon" tab={lang.accountSettings.billingView.billingInformationTitle} key={BillingTabKey.BILLING_INFORMATION}>
                <CompanyBillingDetails />
            </Tabs.TabPane>
            <Tabs.TabPane className="icon flex-1 space-y-8" tab={lang.accountSettings.billingView.paymentMethods.title} key={BillingTabKey.PAYMENT_METHODS}>
                <PaymentMethodList />
            </Tabs.TabPane>
            <Tabs.TabPane
                className="icon h-full flex-1"
                tab={lang.accountSettings.billingView.plansAndSubscriptionsTitle}
                key={BillingTabKey.PLANS_SUBSCRIPTIONS}
            >
                <PlanAndSubscriptions langCode={langCode} />
            </Tabs.TabPane>
            <Tabs.TabPane className="icon space-y-8" tab={plural(lang.accountSettings.billingView.invoice.label, 2)} key={BillingTabKey.INVOICES}>
                <InvoiceList langCode={langCode} />
            </Tabs.TabPane>
        </Tabs>
    );
}

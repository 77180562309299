import { APPS_INFOS, prependIndex, Routes } from "@vaultinum/vaultinum-sdk";

export const APP_PATH = process.env.PUBLIC_URL as "/app";
export const ALLOWED_DOMAIN = "vaultinum.com";
export const ALLOWED_SUBDOMAIN_SUFFIX = ".vaultinum.net";
export const VAULTINUM_BRAND_NAME = "Vaultinum";

export function isCleanUrlNeeded(redirectLocation: string): boolean {
    return Object.values(APPS_INFOS).some(appInfos => redirectLocation.startsWith(appInfos.baseUrl) && appInfos.baseUrl !== APP_PATH);
}

export const PATH = {
    index: `${APP_PATH}/*`,
    // public pages
    login: "/login",
    register: "/register",
    forgotPassword: "/forgot-password",
    authAction: "/auth-action",
    // private pages
    verify: "/verify",
    welcome: "/welcome",
    account: {
        index: "/account/*",
        new: "/new",
        all: "/*"
    },
    domainOrganisations: {
        index: "/organisations/*",
        id: "/:id",
        all: "/*"
    },
    settings: {
        index: "/settings/*",
        all: "/*"
    },
    all: "/*"
} as const satisfies Routes;

export const URL = prependIndex(PATH);

import { IconSvgProps } from "../Icons";

export default function WarningSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.4732 19.898L13.9222 3.36735C13.7799 3.12168 13.5342 3 13.2863 3C13.0383 3 12.7903 3.12168 12.6503 3.36735L3.09931 19.898C2.81691 20.3893 3.17048 21 3.73527 21H22.8373C23.402 21 23.7556 20.3893 23.4732 19.898ZM5.48476 19.2574L13.2863 5.75281L21.0878 19.2574H5.48476V19.2574Z" />
            <path d="M12.1841 16.7755C12.1841 17.0678 12.3002 17.3481 12.5069 17.5548C12.7135 17.7614 12.9939 17.8775 13.2861 17.8775C13.5784 17.8775 13.8587 17.7614 14.0654 17.5548C14.2721 17.3481 14.3882 17.0678 14.3882 16.7755C14.3882 16.4832 14.2721 16.2029 14.0654 15.9962C13.8587 15.7896 13.5784 15.6735 13.2861 15.6735C12.9939 15.6735 12.7135 15.7896 12.5069 15.9962C12.3002 16.2029 12.1841 16.4832 12.1841 16.7755ZM12.5514 9.79592V14.0204C12.5514 14.1214 12.6341 14.2041 12.7351 14.2041H13.8372C13.9382 14.2041 14.0208 14.1214 14.0208 14.0204V9.79592C14.0208 9.6949 13.9382 9.61224 13.8372 9.61224H12.7351C12.6341 9.61224 12.5514 9.6949 12.5514 9.79592Z" />
        </svg>
    );
}

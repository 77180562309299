import { Account } from "@vaultinum/vaultinum-api";
import { IconTag } from "../../design-system";
import { CommonLang, useLang } from "../../lang";

export function formatFrequency(method: Account.GitOperation.Frequency, lang: CommonLang): string {
    switch (method) {
        case Account.GitOperation.Frequency.WEEKLY:
            return lang.git.frequency.weekly;
        case Account.GitOperation.Frequency.MONTHLY:
            return lang.git.frequency.monthly;
        case Account.GitOperation.Frequency.EVERY_THREE_MONTHS:
            return lang.git.frequency.every3Month;
        case Account.GitOperation.Frequency.EVERY_SIX_MONTHS:
            return lang.git.frequency.every6month;
        case Account.GitOperation.Frequency.ONE_SHOT:
            return lang.git.frequency.oneShot;
        default:
            return lang.shared.unknown;
    }
}

function formatError(error: string, lang: CommonLang): string {
    switch (error) {
        case Account.Event.Key.GitOperation.failed.connectionSuspended:
            return lang.git.errors.connectionSuspended;
        case Account.Event.Key.GitOperation.failed.connectionRemoved:
            return lang.git.errors.connectionUninstalled;
        case Account.Event.Key.GitOperation.failed.tokenNotFound:
            return lang.git.errors.connectionNotAccessible;
        case Account.Event.Key.GitOperation.failed.repositoryNotAccessible:
            return lang.git.errors.repositoryNotAccessible;
        case Account.Event.Key.GitOperation.failed.repositoryNotFound:
            return lang.git.errors.repositoryNotFound;
        case Account.Event.Key.GitOperation.failed.branchDeleted:
            return lang.git.errors.branchDeleted;
        case Account.Event.Key.GitOperation.failed.archiveSizeExceeded:
            return lang.git.errors.archiveSizeExceeded;
        case Account.Event.Key.GitOperation.failed.someRepositoriesFailed:
            return lang.git.errors.operationFailed;
        default:
            return error;
    }
}

export function GitOperationTag({ gitOperation }: { gitOperation: Account.GitOperation }): JSX.Element {
    const lang = useLang<CommonLang>();
    if (!gitOperation.lastErrorEvent) {
        return <IconTag.Success message={lang.git.connectionActive} />;
    }
    return <IconTag.Danger message={formatError(gitOperation.lastErrorEvent.messageKey, lang)} />;
}

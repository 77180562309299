import { IconSvgProps } from "../Icons";

export default function FunnelBgFilledSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6188_1327)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM20.4545 4C20.6656 4 20.8579 4.1175 20.9479 4.3017C21.038 4.48583 21.0097 4.70364 20.8752 4.86069L14.3749 12.4637L14.3677 17.729C14.3674 17.9287 14.25 18.111 14.0645 18.1997L10.4124 19.9453C10.3359 19.9818 10.2529 20 10.1702 20C10.0701 20 9.97038 19.9735 9.88251 19.9209C9.7222 19.825 9.62465 19.6559 9.62465 19.4739V12.4635L3.12463 4.86076C2.99028 4.70364 2.96206 4.4859 3.05211 4.3017C3.14224 4.1175 3.33441 4 3.54557 4H20.4545Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_6188_1327">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

import { Account, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { Control } from "react-hook-form";
import { Controller, Input } from "../../../design-system";
import { useLang } from "../../../lang";
import NationalitySelect from "./NationalitySelect";

export default function AddressSubForm({
    langCode,
    disableFields,
    control,
    inputNamePath,
    allFieldsRequired
}: {
    langCode: SupportedLanguageCode;
    disableFields: boolean;
    control: Control<Account>;
    inputNamePath: "billingDetails.address" | "companyAddress";
    allFieldsRequired?: boolean;
}): JSX.Element {
    const lang = useLang();
    return (
        <>
            <Controller
                name={`${inputNamePath}.line1`}
                control={control}
                data-id="address-line1-control"
                render={({ field }) => (
                    <Input.Text
                        label={lang.accountInformation.form.address.line1}
                        placeholder={lang.accountInformation.form.address.line1PlaceHolder}
                        disabled={disableFields}
                        data-id="address-line1-input"
                        required={allFieldsRequired}
                        {...field}
                    />
                )}
            />
            <Controller
                name={`${inputNamePath}.line2`}
                control={control}
                data-id="address-line2-control"
                render={({ field }) => (
                    <Input.Text
                        label={lang.accountInformation.form.address.line2}
                        placeholder={lang.accountInformation.form.address.line2PlaceHolder}
                        disabled={disableFields}
                        data-id="address-line2-input"
                        {...field}
                    />
                )}
            />
            <div className="flex justify-between space-x-4">
                <Controller
                    name={`${inputNamePath}.city`}
                    control={control}
                    data-id="city-control"
                    render={({ field }) => (
                        <Input.Text
                            label={lang.accountInformation.form.address.city}
                            placeholder={lang.accountInformation.form.address.city}
                            disabled={disableFields}
                            data-id="city-input"
                            className="w-2/3"
                            required={allFieldsRequired}
                            {...field}
                        />
                    )}
                />

                <Controller
                    name={`${inputNamePath}.postalCode`}
                    control={control}
                    data-id="postal-code-control"
                    render={({ field }) => (
                        <Input.Text
                            placeholder={lang.accountInformation.form.address.postalCodePlaceHolder}
                            label={lang.accountInformation.form.address.postalCode}
                            disabled={disableFields}
                            data-id="postal-code-input"
                            className="w-1/3"
                            required={allFieldsRequired}
                            {...field}
                        />
                    )}
                />
            </div>
            <Controller
                name={`${inputNamePath}.country`}
                control={control}
                data-id="country-control"
                render={({ field }) => (
                    <NationalitySelect
                        {...field}
                        disabled={disableFields}
                        langCode={langCode}
                        data-id="country-input"
                        label={lang.accountInformation.form.address.country}
                        isRequired={allFieldsRequired}
                    />
                )}
            />
        </>
    );
}

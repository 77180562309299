import { IconSvgProps } from "../Icons";

function StackSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.447219 9.42207L11.648 14.9264C11.7571 14.9816 11.8777 15.0104 12 15.0104C12.1223 15.0104 12.2429 14.9816 12.352 14.9264L23.5528 9.42207C23.687 9.35613 23.8 9.2539 23.8791 9.12699C23.9581 9.00007 24 8.85354 24 8.70402C24 8.5545 23.9581 8.40797 23.8791 8.28106C23.8 8.15414 23.687 8.05191 23.5528 7.98597L12.352 2.4816C12.2424 2.42791 12.122 2.39999 12 2.39999C11.878 2.39999 11.7576 2.42791 11.648 2.4816L0.447219 7.98597C0.313024 8.05191 0.199994 8.15414 0.120947 8.28106C0.0418996 8.40797 0 8.5545 0 8.70402C0 8.85354 0.0418996 9.00007 0.120947 9.12699C0.199994 9.2539 0.313024 9.35613 0.447219 9.42207ZM12 4.09771L21.3846 8.71402L12 13.3263L2.61537 8.71402L12 4.09771Z" />
            <path d="M0.447228 12.6222L11.648 18.1266C11.7576 18.1803 11.878 18.2082 12 18.2082C12.122 18.2082 12.2425 18.1803 12.352 18.1266L23.5528 12.6222C23.7368 12.5251 23.8757 12.3601 23.9401 12.1623C24.0045 11.9645 23.9892 11.7493 23.8977 11.5625C23.8061 11.3757 23.6453 11.2319 23.4495 11.1617C23.2537 11.0914 23.0382 11.1002 22.8487 11.1861L12 16.5305L1.15128 11.1981C0.966807 11.133 0.764879 11.1378 0.583666 11.2114C0.402454 11.2851 0.254516 11.4227 0.167821 11.598C0.0811266 11.7734 0.0616832 11.9744 0.113167 12.1631C0.164651 12.3519 0.283494 12.5152 0.447228 12.6222Z" />
            <path d="M22.8486 14.4024L11.9998 19.7307L1.15111 14.4024C0.967886 14.3411 0.768661 14.3481 0.590237 14.4223C0.411814 14.4964 0.266247 14.6326 0.180425 14.8057C0.0946037 14.9788 0.0743256 15.1771 0.123337 15.364C0.172348 15.5509 0.287337 15.7138 0.447064 15.8225L11.6478 21.3228C11.7574 21.3765 11.8778 21.4045 11.9998 21.4045C12.1219 21.4045 12.2423 21.3765 12.3519 21.3228L23.5526 15.8225C23.7124 15.7138 23.8273 15.5509 23.8764 15.364C23.9254 15.1771 23.9051 14.9788 23.8193 14.8057C23.7334 14.6326 23.5879 14.4964 23.4095 14.4223C23.231 14.3481 23.0318 14.3411 22.8486 14.4024Z" />
        </svg>
    );
}

export default StackSvg;

import mixpanel, { Dict, Mixpanel } from "mixpanel-browser";

export enum BaseTrackingEvents {
    UserLogin = "User login",
    PageVisited = "Page visited",
    AppLogoClicked = "App logo clicked",
    CookieConsent = "Cookie consent"
}

class TrackingService {
    mixpanel: Mixpanel | undefined;

    constructor() {
        const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
        if (mixPanelToken) {
            mixpanel.init(mixPanelToken, {
                debug: process.env.REACT_APP_PLATFORM === "development",
                loaded: mixpanelInstance => {
                    this.mixpanel = mixpanelInstance;
                }
            });
        }
    }

    startTrackingUser(userUid?: string): void {
        this.mixpanel?.identify(userUid);
    }

    setUserProperties(prop: Dict): void {
        this.mixpanel?.people.set(prop);
    }

    attachEventProperties(prop: Dict): void {
        this.mixpanel?.register(prop);
    }

    sendEvent(eventName: string, properties?: Dict): void {
        this.mixpanel?.track(eventName, properties);
    }

    stopTrackingUser(): void {
        this.mixpanel?.reset();
    }
}

export default new TrackingService();

import { AccountInvitation } from "@vaultinum/vaultinum-api";
import { addQueryParamsToUrl, AppCode, APPS_INFOS } from "@vaultinum/vaultinum-sdk";
import { assign, createMachine } from "xstate";
import { SubMenuItemKeys } from "../../../helpers";
import { CollaboratorsFields, CreateAsFields, InvitationsFields, OrganisationInformationsFields, URL, UserProfileFields } from "../../../services";

export enum OnboardingWorkflow {
    NO_ACCOUNT = "NO_ACCOUNT",
    ADD_ACCOUNT = "ADD_ACCOUNT"
}

export type OnboardingContext = {
    userProfileFields: UserProfileFields;
    invitationsFields: InvitationsFields;
    createAsFields: CreateAsFields;
    organisationInformationsFields: OrganisationInformationsFields;
    collaboratorsFields: CollaboratorsFields;
    selectedAppCode: AppCode | undefined;
    invitations: AccountInvitation[] | undefined;
    accountsCount: number;
    acceptedInvitationsCount: number;
};

const schema = {
    context: {} as OnboardingContext,
    events: {} as
        | { type: "cancel" }
        | { type: "back" }
        | { type: "updateUserProfileFields"; value: UserProfileFields }
        | { type: "updateInvitations"; value: AccountInvitation[] | undefined }
        | { type: "updateInvitationsFields"; value: InvitationsFields }
        | { type: "updateAcceptedInvitationsCount"; value: number }
        | { type: "updateCreateAsFields"; value: CreateAsFields }
        | { type: "updateOrganisationInformationsFields"; value: OrganisationInformationsFields }
        | { type: "updateOrganisationCollaboratorsFields"; value: CollaboratorsFields }
        | { type: "updateSelectedAppCode"; value: AppCode | undefined }
        | { type: "next" }
};

function areUserProfileFieldsValid(value: UserProfileFields | null): boolean {
    return !!value?.firstName && !!value?.lastName;
}

export function createOnboardingMachine(workflow: OnboardingWorkflow) {
    return createMachine(
        {
            /** @xstate-layout N4IgpgJg5mDOIC5QHsB2AjZBDAThAlqlAHQCusYOACjsgGb4A2YAxKQA4RYAuYAqhWq0GzAGL4wjCLADaABgC6iUO2Sx83fGmUgAHogDMcgEzEAbAHYAnGavGAjABYzzufYA0IAJ6JjJ4nIGLsYAHCGO9lZyAKwGxgC+8Z5omLgERGSCNPRMrKhgutzySkggquqa2qX6CEamljZ2Ti5mbp4+tfZy5k7RseGRMQaJyRjYeIQkyDhQWKj4sDxaqABKyMwsAMZzm5LFOuUayzo1xhaOxI4GFtHthhYGxPa9-RFRsSMgKePpUzNzCyWaDWGw4XF4AHl-vNFpVVuswOJJNJ9qVDnCTr5zpdrrdvIgrtEeo4+gYBu9hkkvmM0pNiNNZjCgfCNvlCqiVGojlVQKdsVcbncENFHBdIsZLGE3kNPt9aRkGQDYctsiJWOgsJsANYcspcjHVQxWR6BexnOQhMwimJC+wGKzEWLBKWDWIJKlyiYK6GAuGq3JsTg8MBQxm+lXCXJIqSyRQHfXHUodezhYhWWJdMkhLPhYyeGoWEL2ALOEJWKVFmyOWU0r1-MPKtD+1kFIpxtEJnl6RCF4tyUvl8KVly2oJpjOBMI5xzu0apOv0n2N1AAYXWjCwmBwPGmsBYGu1uvRid5hkc3VCUTMxjxHVaZnMdkl5JlHtrv0XDeZa8YG63O5wPcwWDUMlW-ddN2mADYGjFF205CoT27Wpz2IS85GvW9EDMWwnifQsXw+N95w-RUmThH8-yg7hdxYNk2xKBDuVQTEUIvMsMJvIULB44geItSdpSIqlUGQCA4B0T1fnjRCuxqABaVNrgtAxRUcQtRWiPN8QQeStOIMty2saIzDJWxHBCGsSLpchKGbMAZOY1jFMdKx7CtQsogwkU3KFVTzDkNxjHtaIHmiEJQisn46TI8NgQRRyDVPXSx2U7M1I0kl3KFK5uh4kLC0nMxnmiKL5XrMC-UjZhEqQmozBCborHPUIcIsBrohTPyLlaNxzwiiybgasqF1i5dKMg7caMA2q5MQOQhVaC50wMTMpzCcIRo-ShaBwUQwEgA8tVmljDQQBadIeB0SVWydsw26tiOijJYFITZdlgGCDogI6TtYi6OhCB5x1urNp0SRIgA */
            id: "onboarding",
            initial: workflow === OnboardingWorkflow.ADD_ACCOUNT ? "createAs" : "userProfile",
            predictableActionArguments: true, // This flag is an opt into some fixed behaviors that will be the default in v5.
            schema,
            context: {
                userProfileFields: {} as UserProfileFields,
                invitationsFields: {} as InvitationsFields,
                createAsFields: {} as CreateAsFields,
                organisationInformationsFields: {} as OrganisationInformationsFields,
                collaboratorsFields: {
                    emails: [{ email: "" }],
                    shouldNotifyByEmail: true
                },
                selectedAppCode: undefined,
                invitations: [],
                accountsCount: 0,
                acceptedInvitationsCount: 0
            },
            states: {
                userProfile: {
                    on: {
                        updateUserProfileFields: {
                            actions: assign({
                                userProfileFields: (_, event) => event.value
                            })
                        },
                        updateInvitations: {
                            actions: assign({
                                invitations: (_, event) => event.value
                            })
                        },
                        next: [
                            { target: "invitations", cond: ctx => areUserProfileFieldsValid(ctx.userProfileFields) && !!ctx.invitations?.length },
                            { target: "organisationInformations", cond: ctx => areUserProfileFieldsValid(ctx.userProfileFields) && !ctx.accountsCount },
                            { target: "userProfileSuccessFeedback", cond: ctx => areUserProfileFieldsValid(ctx.userProfileFields) && !!ctx.accountsCount }
                        ]
                    }
                },
                userProfileSuccessFeedback: {
                    on: {
                        updateSelectedAppCode: {
                            actions: assign({
                                selectedAppCode: (_, event) => event.value
                            })
                        },
                        next: {
                            internal: true,
                            actions: ["done"]
                        }
                    }
                },
                invitations: {
                    on: {
                        back: { target: "userProfile" },
                        updateInvitationsFields: {
                            actions: assign({
                                invitationsFields: (_, event) => event.value
                            })
                        },
                        updateAcceptedInvitationsCount: {
                            actions: assign({
                                acceptedInvitationsCount: (_, event) => event.value
                            })
                        },
                        next: [
                            { target: "organisationInformations", cond: ctx => !ctx.invitationsFields.selectedInvitations.length },
                            { target: "invitationsSuccessFeedback", cond: ctx => !!ctx.invitationsFields.selectedInvitations.length }
                        ]
                    }
                },
                invitationsSuccessFeedback: {
                    on: {
                        updateSelectedAppCode: {
                            actions: assign({
                                selectedAppCode: (_, event) => event.value
                            })
                        },
                        next: {
                            internal: true,
                            actions: ["done"]
                        }
                    }
                },
                createAs: {
                    on: {
                        cancel: {
                            actions: ["done"]
                        },
                        updateCreateAsFields: {
                            actions: assign({
                                createAsFields: (_, event) => event.value
                            })
                        },
                        next: {
                            cond: ctx => !!ctx.createAsFields.createAs,
                            target: "organisationInformations"
                        }
                    }
                },
                organisationInformations: {
                    on: {
                        back: [
                            { target: "invitations", cond: ctx => !!ctx.invitations?.length },
                            { target: workflow === OnboardingWorkflow.NO_ACCOUNT ? "userProfile" : "createAs" }
                        ],
                        updateOrganisationInformationsFields: {
                            actions: assign({
                                organisationInformationsFields: (_, event) => event.value
                            })
                        },
                        next: {
                            cond: ctx =>
                                !!ctx.organisationInformationsFields.companyName &&
                                !!ctx.organisationInformationsFields.companyIndustry &&
                                !!ctx.organisationInformationsFields.companySize,
                            target: "organisationCollaborators"
                        }
                    }
                },
                organisationCollaborators: {
                    on: {
                        back: { target: "organisationInformations" },
                        updateOrganisationCollaboratorsFields: {
                            actions: assign({
                                collaboratorsFields: (_, event) => event.value
                            })
                        },
                        updateSelectedAppCode: {
                            actions: assign({
                                selectedAppCode: (_, event) => event.value
                            })
                        },
                        next: {
                            target: "organisationSuccessFeedback"
                        }
                    }
                },
                organisationSuccessFeedback: {
                    on: {
                        updateSelectedAppCode: {
                            actions: assign({
                                selectedAppCode: (_, event) => event.value
                            })
                        },
                        next: {
                            internal: true,
                            actions: ["done"]
                        }
                    }
                }
            }
        },
        {
            actions: {
                done: ctx => {
                    if (workflow === OnboardingWorkflow.ADD_ACCOUNT) {
                        return window.location.assign(addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.ACCOUNTS }));
                    }
                    if (ctx.selectedAppCode && ctx.selectedAppCode in APPS_INFOS) {
                        return window.location.assign(APPS_INFOS[ctx.selectedAppCode].baseUrl);
                    }
                    return window.location.assign(URL.index);
                }
            }
        }
    );
}

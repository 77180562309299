import classNames from "classnames";
import { ReactNode } from "react";

export default function ViewWrapper({
    title,
    children,
    className,
    dataId
}: {
    title: string;
    children: ReactNode;
    className?: string;
    dataId?: string;
}): JSX.Element {
    return (
        <div data-id={dataId} className={classNames("flex h-full w-full flex-col gap-8", className)}>
            <h1>{title}</h1>
            {children}
        </div>
    );
}

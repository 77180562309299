import { IconSvgProps } from "../Icons";

function HomeSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.4429 8.95459L12.8714 2.29958C12.3579 1.90014 11.6414 1.90014 11.1286 2.29958L2.55714 8.95459C2.20857 9.2257 2 9.65326 2 10.0981V20.558C2 21.3532 2.64071 22 3.42857 22H20.5714C21.3593 22 22 21.3532 22 20.558V10.0981C22 9.65326 21.7914 9.2257 21.4429 8.95459ZM9.85714 20.558V16.2318C9.85714 15.0393 10.8186 14.0688 12 14.0688C13.1814 14.0688 14.1429 15.0393 14.1429 16.2318V20.558H9.85714ZM20.5714 20.558H15.5714V16.2318C15.5714 14.244 13.9693 12.6267 12 12.6267C10.0307 12.6267 8.42857 14.244 8.42857 16.2318V20.558H3.42857V10.0974L12 3.4424L20.5714 10.0981V20.558Z" />
        </svg>
    );
}

export default HomeSvg;

import { IconSvgProps } from "../Icons";

function VisaSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 100 64" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 7C0 3.13401 3.13401 0 7 0H93C96.866 0 100 3.13401 100 7V56.1443C100 60.0103 96.866 63.1443 93 63.1443H7C3.13401 63.1443 0 60.0103 0 56.1443V7Z"
                fill="#0E4595"
            />
            <path
                d="M37.3788 44.045L41.7284 18.5218H48.6855L44.3328 44.045H37.3788ZM69.4667 19.072C68.0885 18.5549 65.9285 18 63.2316 18C56.3573 18 51.515 21.4616 51.474 26.4229C51.435 30.0905 54.9309 32.1365 57.5695 33.3573C60.2776 34.6083 61.1878 35.4061 61.175 36.5234C61.1579 38.2341 59.0125 39.0157 57.013 39.0157C54.2285 39.0157 52.7492 38.6289 50.4644 37.6759L49.5679 37.2704L48.5915 42.9841C50.2164 43.6965 53.2213 44.3137 56.341 44.3456C63.6541 44.3456 68.4012 40.9237 68.4554 35.6256C68.4814 32.7221 66.628 30.5126 62.6143 28.6909C60.1827 27.5103 58.6935 26.7223 58.7093 25.5268C58.7093 24.4659 59.9698 23.3314 62.6933 23.3314C64.9683 23.2962 66.6164 23.7923 67.9004 24.3092L68.5238 24.6039L69.4667 19.072ZM87.3692 18.5214H81.9933C80.328 18.5214 79.0817 18.976 78.3504 20.638L68.0185 44.0282H75.3239C75.3239 44.0282 76.5181 40.883 76.7883 40.1926C77.5869 40.1926 84.6835 40.2036 85.6982 40.2036C85.9063 41.0971 86.5444 44.0282 86.5444 44.0282H93L87.3692 18.5206V18.5214ZM78.84 35.0027C79.4152 33.5322 81.6118 27.8679 81.6118 27.8679C81.5706 27.936 82.1828 26.3902 82.5341 25.4319L83.0042 27.6326C83.0042 27.6326 84.3365 33.7251 84.6149 35.0027H78.84ZM31.4729 18.5214L24.6618 35.9271L23.936 32.3899C22.668 28.3125 18.7175 23.895 14.3012 21.6834L20.5289 44.0044L27.8896 43.9961L38.8421 18.5211H31.4729"
                fill="white"
            />
            <path
                d="M18.3068 18.5205H7.08879L7 19.0515C15.7274 21.1642 21.5022 26.2698 23.8999 32.4037L21.4605 20.6748C21.0393 19.0587 19.8181 18.5764 18.3071 18.52"
                fill="#F2AE14"
            />
        </svg>
    );
}

export default VisaSvg;

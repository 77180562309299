import { IconSvgProps } from "../Icons";

export default function DuplicationSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1.5C11.8011 1.5 11.6103 1.57902 11.4697 1.71967C11.3291 1.86032 11.25 2.05108 11.25 2.25V21.75C11.25 21.9489 11.3291 22.1397 11.4697 22.2803C11.6103 22.4209 11.8011 22.5 12 22.5C12.1989 22.5 12.3897 22.4209 12.5303 22.2803C12.6709 22.1397 12.75 21.9489 12.75 21.75V2.25C12.75 2.05108 12.6709 1.86032 12.5303 1.71967C12.3897 1.57902 12.1989 1.5 12 1.5Z" />
            <path d="M21.75 4.5H15C14.8011 4.5 14.6103 4.57902 14.4697 4.71967C14.3291 4.86032 14.25 5.05108 14.25 5.25V18.75C14.25 18.9489 14.3291 19.1397 14.4697 19.2803C14.6103 19.4209 14.8011 19.5 15 19.5H21.75C21.9489 19.5 22.1397 19.4209 22.2803 19.2803C22.4209 19.1397 22.5 18.9489 22.5 18.75V5.25C22.5 5.05108 22.4209 4.86032 22.2803 4.71967C22.1397 4.57902 21.9489 4.5 21.75 4.5ZM21 18H15.75V6H21V18Z" />
            <path d="M9 4.5H2.25C2.05108 4.5 1.86032 4.57902 1.71967 4.71967C1.57902 4.86032 1.5 5.05108 1.5 5.25V18.75C1.5 18.9489 1.57902 19.1397 1.71967 19.2803C1.86032 19.4209 2.05108 19.5 2.25 19.5H9C9.1989 19.5 9.3897 19.4209 9.53032 19.2803C9.67095 19.1397 9.75 18.9489 9.75 18.75V5.25C9.75 5.05108 9.67095 4.86032 9.53032 4.71967C9.3897 4.57902 9.1989 4.5 9 4.5ZM8.25 18H3V6H8.25V18Z" />
            <path d="M17.0925 14.0326C17.1622 14.1029 17.2452 14.1587 17.3366 14.1968C17.4279 14.2348 17.526 14.2545 17.625 14.2545C17.724 14.2545 17.8221 14.2348 17.9134 14.1968C18.0048 14.1587 18.0878 14.1029 18.1575 14.0326L19.6575 12.5326C19.7278 12.4629 19.7836 12.3799 19.8216 12.2886C19.8597 12.1972 19.8793 12.0991 19.8793 12.0001C19.8793 11.9011 19.8597 11.8031 19.8216 11.7117C19.7836 11.6203 19.7278 11.5373 19.6575 11.4676L18.1575 9.96765C18.0876 9.89767 18.0045 9.84225 17.9132 9.80437C17.8218 9.76657 17.7239 9.74707 17.625 9.74707C17.4252 9.74707 17.2337 9.82642 17.0925 9.96765C17.0226 10.0375 16.9671 10.1206 16.9293 10.2119C16.8914 10.3033 16.8719 10.4012 16.8719 10.5001C16.8719 10.6999 16.9512 10.8914 17.0925 11.0326L18.0675 12.0001L17.0925 12.9676C17.0222 13.0373 16.9664 13.1203 16.9283 13.2117C16.8903 13.3031 16.8706 13.4011 16.8706 13.5001C16.8706 13.5991 16.8903 13.6972 16.9283 13.7886C16.9664 13.8799 17.0222 13.9629 17.0925 14.0326Z" />
            <path d="M5.84246 14.0326C5.91218 14.1029 5.99513 14.1587 6.08652 14.1968C6.17792 14.2348 6.27595 14.2545 6.37496 14.2545C6.47396 14.2545 6.572 14.2348 6.66339 14.1968C6.75479 14.1587 6.83774 14.1029 6.90746 14.0326C6.97775 13.9629 7.03355 13.8799 7.07162 13.7886C7.1097 13.6972 7.12931 13.5991 7.12931 13.5001C7.12931 13.4011 7.1097 13.3031 7.07162 13.2117C7.03355 13.1203 6.97775 13.0373 6.90746 12.9676L5.93246 12.0001L6.90746 11.0326C7.04868 10.8914 7.12802 10.6999 7.12802 10.5001C7.12802 10.3004 7.04868 10.1089 6.90746 9.96765C6.76623 9.82642 6.57468 9.74707 6.37496 9.74707C6.17523 9.74707 5.98368 9.82642 5.84246 9.96765L4.34246 11.4676C4.27216 11.5373 4.21637 11.6203 4.17829 11.7117C4.14021 11.8031 4.12061 11.9011 4.12061 12.0001C4.12061 12.0991 4.14021 12.1972 4.17829 12.2886C4.21637 12.3799 4.27216 12.4629 4.34246 12.5326L5.84246 14.0326Z" />
        </svg>
    );
}

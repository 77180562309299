export enum SortDirection {
    ASCENDING = "asc",
    DESCENDING = "desc"
}

export interface FiltersSortService {
    saveFilter: (key: string, filters?: unknown[]) => void;
    loadFilter: (key: string) => unknown[];
    loadFilters: () => { [key: string]: unknown[] };
    saveSort: (key: string, direction: string | boolean) => void;
    loadSort: (key: string) => string | boolean;
    loadSorts: () => { [key: string]: SortDirection };
}

export enum TableStorageKey {
    FULL_AUDIT = "table-full-audit",
    PROMOTION = "table-promotion",
    DEPOSIT = "table-deposit",
    ESCROW = "table-escrow",
    USER_PROFILE = "table-user-profile",
    ORGANISATION = "table-organisation",
    STAFF_USER = "table-staff-user",
    EVENT = "table-event",
    DOMAIN = "table-domain",
    FULL_AUDIT_EVENT = "table-full-audit-event"
}

export enum ListStorageKey {
    FULL_AUDIT = "list-full-audit"
}

import { COLOR_SCHEME } from "../../../referentials";
import { LogoSvgProps } from "../Logos";

/* eslint max-len: 0 */
function BipartiteSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 132 71" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M117.377 40.7939H107.333C99.2637 40.7939 92.7109 47.3467 92.7109 55.4161V68.4644C92.7109 69.6376 93.6838 70.6105 94.857 70.6105H129.853C131.026 70.6105 131.999 69.6376 131.999 68.4644V55.4161C131.999 47.3467 125.446 40.7939 117.377 40.7939Z"
                fill="#EBEEF2"
            />
            <path
                d="M117.378 40.7939H110.939C119.009 40.7939 125.562 47.3467 125.562 55.4161V68.4644C125.562 69.6376 124.589 70.6105 123.415 70.6105H129.854C131.027 70.6105 132 69.6376 132 68.4644V55.4161C132 47.3467 125.447 40.7939 117.378 40.7939Z"
                fill="#D1D6DE"
            />
            <path
                d="M113.916 40.7939H110.768L106.676 54.7293C106.533 55.2158 106.676 55.7595 107.02 56.1315L111.312 60.5381C111.884 61.1104 112.8 61.1104 113.372 60.5381L117.664 56.1315C118.036 55.7595 118.151 55.2158 118.008 54.7293L113.916 40.7939Z"
                fill="#FBAA5F"
            />
            <path
                d="M112.341 0.0180664C104.129 0.0180664 97.4902 6.6567 97.4902 14.8691V22.0228C97.4902 30.2353 104.129 36.8739 112.341 36.8739C120.554 36.8739 127.192 30.2353 127.192 22.0228V14.8691C127.192 6.6567 120.554 0.0180664 112.341 0.0180664Z"
                fill="#F9C295"
            />
            <path
                d="M110.453 0.132468C116.09 1.07676 120.44 7.28616 120.44 14.8405V21.9942C120.44 29.5485 116.09 35.7865 110.453 36.7021C111.083 36.788 111.712 36.8166 112.37 36.8166C120.583 36.8166 127.221 30.178 127.221 21.9655V14.8118C127.193 5.96988 119.41 -1.01212 110.453 0.132468Z"
                fill="#E59F6A"
            />
            <path
                d="M122.014 3.59494C112.399 -4.64612 97.5195 2.19282 97.5195 14.8692V16.2427C99.6943 17.2156 102.127 17.7593 104.673 17.7593C109.881 17.7593 114.574 15.4987 117.836 11.8932C119.896 15.0408 123.301 17.2442 127.222 17.6734C126.993 16.443 128.481 9.11759 122.014 3.59494Z"
                fill="#444966"
            />
            <path
                d="M122.013 3.59478C121.899 3.53755 117.464 -0.754659 110.453 0.132398C116.062 1.07669 120.411 7.28609 120.44 14.8118C122.328 16.357 124.646 17.3871 127.193 17.6733C126.964 16.3856 128.566 9.20327 122.013 3.59478Z"
                fill="#2E344C"
            />
            <path
                d="M66.0151 67.8351C58.518 67.8351 51.0782 65.9465 44.554 62.3696C43.5239 61.7974 43.1233 60.5097 43.6956 59.4509C44.2679 58.3922 45.5555 58.0202 46.6143 58.5925C52.5375 61.826 59.2334 63.5428 66.0151 63.5428C72.7396 63.5428 79.4354 61.8546 85.3015 58.6497C86.3316 58.0774 87.6479 58.478 88.2202 59.5082C88.7925 60.5383 88.3918 61.8546 87.3617 62.4269C80.8375 65.9751 73.4549 67.8351 66.0151 67.8351Z"
                fill="#325574"
            />
            <path
                d="M82.3537 24.7697C82.411 24.1974 82.4396 23.6251 82.4396 23.0814C82.4396 22.5377 82.411 21.9654 82.3537 21.3931L86.2167 17.5874C86.9035 16.9006 87.0466 15.8419 86.5601 14.9834L83.2694 9.3177C82.783 8.45926 81.7815 8.05865 80.8372 8.31619L75.6293 9.74693C74.7136 9.08879 73.7407 8.51649 72.7106 8.05866L71.3371 2.82216C71.0795 1.87787 70.2497 1.21973 69.2482 1.21973H62.6954C61.7225 1.21973 60.8641 1.87787 60.6065 2.82216L59.233 8.05866C58.2029 8.51649 57.23 9.08879 56.3143 9.74693L51.1064 8.31619C50.1621 8.05865 49.1606 8.45926 48.6742 9.3177L45.4121 14.9834C44.9256 15.8419 45.0687 16.9006 45.7555 17.5874L49.6184 21.3931C49.5612 21.9654 49.5326 22.5377 49.5326 23.0814C49.5326 23.6251 49.5612 24.1974 49.6184 24.7697L45.7841 28.5755C45.0973 29.2622 44.9542 30.321 45.4407 31.1794L48.7028 36.8451C49.1892 37.7036 50.1907 38.1042 51.135 37.8466L56.3429 36.4159C57.2586 37.074 58.2315 37.6463 59.2616 38.1042L60.6351 43.3407C60.8927 44.285 61.7225 44.9431 62.724 44.9431H69.2768C70.2497 44.9431 71.1081 44.285 71.3657 43.3407L72.7392 38.1042C73.7693 37.6463 74.7422 37.074 75.6579 36.4159L80.8658 37.8466C81.8101 38.1042 82.8116 37.7036 83.298 36.8451L86.5887 31.1794C87.0752 30.321 86.9321 29.2622 86.2454 28.5755L82.3537 24.7697ZM66.0147 30.6357C61.837 30.6357 58.4604 27.2306 58.4604 23.0814C58.4604 18.9037 61.8656 15.5271 66.0147 15.5271C70.1925 15.5271 73.569 18.9323 73.569 23.0814C73.569 27.2592 70.1639 30.6357 66.0147 30.6357Z"
                fill="url(#paint0_radial_2805_18860)"
            />
            <path
                d="M66.0147 12.0649C59.9198 12.0649 54.998 17.0153 54.998 23.0816C54.998 29.148 59.9484 34.0983 66.0147 34.0983C72.0811 34.0983 77.0314 29.148 77.0314 23.0816C77.0314 17.0153 72.0811 12.0649 66.0147 12.0649ZM66.0147 30.6359C61.837 30.6359 58.4604 27.2308 58.4604 23.0816C58.4604 18.9039 61.8656 15.5273 66.0147 15.5273C70.1925 15.5273 73.569 18.9325 73.569 23.0816C73.569 27.2594 70.1639 30.6359 66.0147 30.6359Z"
                fill="#325574"
            />
            <path
                d="M24.6659 40.7939H14.6221C6.55278 40.7939 0 47.3467 0 55.4161V68.4644C0 69.6376 0.972902 70.6105 2.14611 70.6105H37.142C38.3152 70.6105 39.2881 69.6376 39.2881 68.4644V55.4161C39.2881 47.3467 32.7353 40.7939 24.6659 40.7939Z"
                fill="#EBEEF2"
            />
            <path
                d="M24.6649 40.7939H18.2266C26.2959 40.7939 32.8487 47.3467 32.8487 55.4161V68.4644C32.8487 69.6376 31.8758 70.6105 30.7026 70.6105H37.1409C38.3141 70.6105 39.287 69.6376 39.287 68.4644V55.4161C39.287 47.3467 32.7343 40.7939 24.6649 40.7939Z"
                fill="#D1D6DE"
            />
            <path
                d="M21.2028 40.7939H18.0552L13.9633 54.7293C13.8202 55.2158 13.9633 55.7595 14.3067 56.1315L18.5989 60.5381C19.1712 61.1104 20.0868 61.1104 20.6591 60.5381L24.9514 56.1315C25.3233 55.7595 25.4378 55.2158 25.2947 54.7293L21.2028 40.7939Z"
                fill="#FBAA5F"
            />
            <path
                d="M19.6304 0.0180664C11.4179 0.0180664 4.7793 6.6567 4.7793 14.8691V22.0228C4.7793 30.2353 11.4179 36.8739 19.6304 36.8739C27.8428 36.8739 34.4814 30.2353 34.4814 22.0228V14.8691C34.4814 6.6567 27.8428 0.0180664 19.6304 0.0180664Z"
                fill="#F9C295"
            />
            <path
                d="M17.7422 0.132468C23.3793 1.07676 27.7287 7.28616 27.7287 14.8405V21.9942C27.7287 29.5485 23.3793 35.7865 17.7422 36.7021C18.3717 36.788 19.0012 36.8166 19.6594 36.8166C27.8718 36.8166 34.5104 30.178 34.5104 21.9655V14.8118C34.4818 5.96988 26.6986 -1.01212 17.7422 0.132468Z"
                fill="#E59F6A"
            />
            <path
                d="M29.3028 3.59494C19.6883 -4.64612 4.80859 2.19282 4.80859 14.8692V16.2427C6.98332 17.2156 9.41557 17.7593 11.9623 17.7593C17.1702 17.7593 21.863 15.4987 25.1251 11.8932C27.1853 15.0408 30.5905 17.2442 34.5107 17.6734C34.2818 16.443 35.7698 9.11759 29.3028 3.59494Z"
                fill="#444966"
            />
            <path
                d="M29.3026 3.59478C29.1881 3.53755 24.7528 -0.754659 17.7422 0.132398C23.3507 1.07669 27.7001 7.28609 27.7287 14.8118C29.6173 16.357 31.9351 17.3871 34.4818 17.6733C34.2529 16.3856 35.8553 9.20327 29.3026 3.59478Z"
                fill="#2E344C"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_2805_18860"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(66.0044 23.0842) scale(17.6094)"
                >
                    <stop stopColor={COLOR_SCHEME.slate.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.slate.primary} />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default BipartiteSvg;

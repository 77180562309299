import { CartProduct } from "@vaultinum/vaultinum-api";
import { useMemo } from "react";
import { Tooltip } from "../../../../design-system";
import { formatAsCurrency } from "../../../../helpers";
import { CommonLang, getLangCode } from "../../../../lang";
import { useAuthContext } from "../../../contexts";
import { getPriceLabel, getProductList } from "../../../tools";

type CartProductWithName = CartProduct & { priceName?: string };

export default function PricingNames({ products, lang }: { products: CartProductWithName[]; lang: CommonLang }): JSX.Element {
    const { userProfile } = useAuthContext();
    const langCode = getLangCode(userProfile);
    const priceLists = getProductList(lang);

    const labelledProducts = useMemo(
        () => products.map(product => ({ ...product, priceName: getPriceLabel(priceLists, product.name, lang, product.recurring) })),
        [products]
    );

    return (
        <>
            {labelledProducts.map(product => (
                <div key={product.name} className="flex">
                    <Tooltip placement="top" title={product.priceName}>
                        <span className="font-light">{product.priceName}</span>
                    </Tooltip>
                    <span className="ml-auto font-bold">{formatAsCurrency(product.amount, langCode)}</span>
                </div>
            ))}
        </>
    );
}

import { useMemo, useState } from "react";
import { AddIcon, CompanyIcon, Dropdowns, LoadingIcon, message } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { useAuthContext } from "../../contexts";
import { SHARED_URL, addQueryParamsToUrl, setSelectedAccount } from "../../services";

export default function AccountDropDownMenu(): JSX.Element {
    const { userProfile, selectedAccount } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const lang = useLang<CommonLang>();
    const otherAccounts =
        userProfile?.accounts?.filter(account => account.id !== selectedAccount?.id).sort((a, b) => a.companyName.localeCompare(b.companyName)) || [];

    async function changeAccount(accountId?: string) {
        if (userProfile && accountId) {
            setIsLoading(true);
            try {
                await setSelectedAccount(accountId);
                // Reload page to avoid any ids issues
                location.reload();
            } catch {
                void message.error(lang.shared.failMessage);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const actions = useMemo(() => {
        return [
            ...otherAccounts.map(account => ({ icon: CompanyIcon, label: account.companyName, onClick: () => changeAccount(account.id) })),
            { icon: AddIcon, label: lang.settingsMenu.newOrganization, href: addQueryParamsToUrl(SHARED_URL.app.account.new, { step: "register-as" }) }
        ];
    }, [userProfile, otherAccounts]);

    return (
        <Dropdowns.WithChevron
            data-id="header-company-name"
            children={
                <div className="flex gap-2">
                    <div>
                        <div className="truncate text-xs font-bold text-slate-light">{lang.settingsMenu.companyAccount}</div>
                        <div className="truncate font-light text-slate-primary">{selectedAccount?.companyName}</div>
                    </div>
                    <div className="self-end">{isLoading && <LoadingIcon size="xs" />}</div>
                </div>
            }
            actions={actions}
        />
    );
}

import { IconSvgProps } from "../../../../../common";

export default function ScopeGDPRSvg({ size, color }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.5904C0 10.1141 10.114 0 22.5904 0H277.41C289.886 0 300 10.114 300 22.5904V277.41C300 289.886 289.886 300 277.41 300H22.5904C10.1141 300 0 289.886 0 277.41V22.5904Z"
                fill={color ?? "#C7E1FF"}
            />
            <path
                d="M169.835 145.254C167.674 145.254 165.922 143.502 165.922 141.341V130.934C165.922 122.306 158.902 115.286 150.274 115.286C141.646 115.286 134.626 122.306 134.626 130.934V141.341C134.626 143.502 132.874 145.254 130.713 145.254C128.551 145.254 126.799 143.502 126.799 141.341V130.934C126.799 117.99 137.33 107.459 150.274 107.459C163.218 107.459 173.749 117.99 173.749 130.934V141.341C173.749 143.502 171.997 145.254 169.835 145.254Z"
                fill="#4A7398"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M127.78 192.541H172.22C175.485 192.541 178.132 189.883 178.132 186.604V147.016C178.132 143.738 175.485 141.08 172.22 141.08H127.78C124.515 141.08 121.868 143.737 121.868 147.016V186.604C121.868 189.883 124.514 192.541 127.78 192.541ZM153.504 168.176L157.45 177.192C157.787 177.961 157.212 178.818 156.36 178.818H143.641C142.788 178.818 142.213 177.961 142.55 177.192L146.496 168.176C144.258 166.962 142.741 164.622 142.741 161.933C142.741 157.728 146.448 154.373 150.827 154.848C154.151 155.209 156.845 157.854 157.213 161.12C157.554 164.145 155.965 166.841 153.504 168.176Z"
                fill="#4A7398"
            />
            <path
                d="M150 24.6523L158.201 41.9332L176.361 44.8622L163.181 58.3354L166.403 77.3737L150 68.2939L133.598 77.3737L136.82 58.3354L123.64 44.8622L141.799 41.9332L150 24.6523Z"
                fill="white"
            />
            <path
                d="M150 222.626L158.201 239.907L176.361 242.836L163.181 256.31L166.403 275.348L150 266.268L133.598 275.348L136.82 256.31L123.64 242.836L141.799 239.907L150 222.626Z"
                fill="white"
            />
            <path
                d="M248.987 123.639L257.188 140.92L275.348 143.849L262.167 157.323L265.389 176.361L248.987 167.281L232.585 176.361L235.807 157.323L222.626 143.849L240.786 140.92L248.987 123.639Z"
                fill="white"
            />
            <path
                d="M51.013 123.639L59.2141 140.92L77.3737 143.849L64.1934 157.323L67.4152 176.361L51.013 167.281L34.6108 176.361L37.8327 157.323L24.6523 143.849L42.8119 140.92L51.013 123.639Z"
                fill="white"
            />
            <path
                d="M76.9972 54.3484L85.1983 71.6293L103.358 74.5583L90.1775 88.0315L93.3994 107.07L76.9972 97.99L60.595 107.07L63.8168 88.0315L50.6365 74.5583L68.7961 71.6293L76.9972 54.3484Z"
                fill="white"
            />
            <path
                d="M223.003 54.3484L231.204 71.6293L249.363 74.5583L236.183 88.0315L239.405 107.07L223.003 97.99L206.601 107.07L209.822 88.0315L196.642 74.5583L214.802 71.6293L223.003 54.3484Z"
                fill="white"
            />
            <path
                d="M76.9972 189.218L85.1983 206.499L103.358 209.428L90.1775 222.901L93.3994 241.94L76.9972 232.86L60.595 241.94L63.8168 222.901L50.6365 209.428L68.7961 206.499L76.9972 189.218Z"
                fill="white"
            />
            <path
                d="M223.003 189.218L231.204 206.499L249.363 209.428L236.183 222.901L239.405 241.94L223.003 232.86L206.601 241.94L209.822 222.901L196.642 209.428L214.802 206.499L223.003 189.218Z"
                fill="white"
            />
        </svg>
    );
}

import { ColCards, CompanyIcon, Controller, TeamIcon, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useEffect } from "react";
import { AccountLang } from "../../../lang/AccountLang";
import { CreateAs, CreateAsFields } from "../../../services";
import { Step } from "./Step";
import { OnboardingContext } from "./onboardingMachine";

export default function CreateAsStep({
    context,
    onChange,
    doCancel,
    canDoNext,
    doNext
}: {
    context: OnboardingContext;
    onChange: (update: CreateAsFields) => void;
    doCancel: () => void;
    canDoNext: boolean;
    doNext: () => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();

    const schema = yup.object({
        createAs: useRequiredString()
    } satisfies Record<keyof CreateAsFields, yup.AnySchema>);

    const form = useForm<CreateAsFields>({
        schema,
        defaultValues: context.createAsFields,
        mode: "onChange"
    });

    useEffect(() => {
        const { unsubscribe } = form.watch(() => {
            onChange(form.getValues());
        });
        return () => unsubscribe();
    }, [form, onChange]);

    const OPTIONS = [
        {
            id: CreateAs.ORGANISATION_MEMBER,
            icon: CompanyIcon,
            title: lang.onboarding.account.createAs.organisationMember.title,
            children: lang.onboarding.account.createAs.organisationMember.description,
            "data-id": "create-as-organisation-member"
        },
        {
            id: CreateAs.REPRESENTATIVE,
            icon: TeamIcon,
            title: lang.onboarding.account.createAs.representative.title,
            children: lang.onboarding.account.createAs.representative.description,
            "data-id": "create-as-representative"
        }
    ];

    return (
        <Step
            title={lang.onboarding.account.createAs.title}
            doCancel={doCancel}
            nextButtonDisabled={!canDoNext}
            onSubmit={doNext}
            stepKey="create-as-step"
            form={form}
            isCard={false}
        >
            <Controller
                name="createAs"
                control={form.control}
                rules={{ required: lang.shared.requiredErrorMessage }}
                render={({ field: { value, onChange: onValueChange } }) => (
                    <div className="flex gap-2 flex-col sm:flex-row">
                        {OPTIONS.map(({ id, title, ...option }) => (
                            <ColCards.WithRadio
                                {...option}
                                title={{
                                    text: title,
                                    position: "center"
                                }}
                                radio={{
                                    id,
                                    selectedId: value,
                                    onValueChange
                                }}
                            />
                        ))}
                    </div>
                )}
            />
        </Step>
    );
}

import { Account } from "@vaultinum/vaultinum-api";
import { Alert, Modal, useLang } from "@vaultinum/vaultinum-sdk";
import { capitalize } from "lodash";
import { AccountLang } from "../../../../lang/AccountLang";

function getDisconnectionLink(gitProvider: Account.GitConnectionProvider, installationId?: number) {
    switch (gitProvider) {
        case Account.GitConnectionProvider.GITHUB:
            return `${process.env.REACT_APP_GITHUB_DISCONNECTION}/${installationId}`;
        case Account.GitConnectionProvider.GITLAB: // to define
            return `${process.env.REACT_APP_GITLAB_DISCONNECTION}`;
        default:
            return "";
    }
}

export default function DeleteGitConnectionModal({
    isOpen,
    doClose,
    gitConnection
}: {
    isOpen: boolean;
    doClose: () => void;
    gitConnection: Account.GitConnection;
}): JSX.Element {
    const lang = useLang<AccountLang>();

    function handleGitRedirection(connection: Account.GitConnection) {
        const link = getDisconnectionLink(connection.provider, connection.installationId);
        window.open(link, "_blank");
    }

    return (
        <Modal
            title={lang.accountSettings.gitView.deleteConnection}
            isOpen={isOpen}
            onConfirm={() => handleGitRedirection(gitConnection)}
            onClose={doClose}
            lang={lang}
        >
            <div className="space-y-2">
                <p>{lang.accountSettings.gitView.deleteConnectionConfirmation}</p>
                <Alert.Info message={lang.accountSettings.gitView.deletionConfirmationMessage(capitalize(gitConnection?.provider))} />
            </div>
        </Modal>
    );
}

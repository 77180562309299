import { IconSvgProps } from "../Icons";

export default function ClockCircleSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z" />
            <path d="M15.8995 14.8259L12.7164 12.5246V6.99998C12.7164 6.90177 12.6361 6.82141 12.5379 6.82141H11.4642C11.366 6.82141 11.2856 6.90177 11.2856 6.99998V13.1473C11.2856 13.2054 11.3124 13.2589 11.3593 13.2924L15.0513 15.9844C15.1316 16.0424 15.2432 16.0246 15.3013 15.9464L15.9396 15.0759C15.9977 14.9933 15.9798 14.8817 15.8995 14.8259V14.8259Z" />
        </svg>
    );
}

import { IconSvgProps } from "../Icons";

function CrossSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8716 11.9794L18.7683 7.1475C18.8946 7.01665 18.9938 6.86219 19.0605 6.69294C19.1271 6.52369 19.1598 6.34297 19.1566 6.16109C19.1534 5.97921 19.1145 5.79973 19.042 5.63291C18.9695 5.46608 18.8649 5.31518 18.7342 5.18881C18.6034 5.06244 18.4491 4.96308 18.2799 4.8964C18.1108 4.82973 17.9302 4.79704 17.7485 4.80021C17.5667 4.80338 17.3874 4.84234 17.2207 4.91487C17.0539 4.9874 16.9031 5.09208 16.7769 5.22293L11.9484 10.0548L7.11989 5.22293C6.85896 4.99932 6.52332 4.88247 6.18004 4.89574C5.83676 4.90901 5.51112 5.05141 5.26821 5.2945C5.02529 5.53759 4.88298 5.86346 4.86972 6.20698C4.85646 6.55051 4.97323 6.88639 5.19668 7.1475L10.0252 11.9794L5.19668 16.8796C4.94264 17.1353 4.80005 17.4813 4.80005 17.8419C4.80005 18.2025 4.94264 18.5484 5.19668 18.8041C5.32413 18.9306 5.47529 19.0307 5.64147 19.0987C5.80765 19.1666 5.9856 19.201 6.16511 19.2C6.52294 19.1985 6.86584 19.0563 7.11989 18.8041L11.9484 13.904L16.8451 18.8041C17.0991 19.0563 17.442 19.1985 17.7999 19.2C17.9794 19.201 18.1573 19.1666 18.3235 19.0987C18.4897 19.0307 18.6408 18.9306 18.7683 18.8041C18.9046 18.6765 19.0132 18.5222 19.0875 18.3508C19.1617 18.1794 19.2 17.9945 19.2 17.8077C19.2 17.6209 19.1617 17.4361 19.0875 17.2647C19.0132 17.0933 18.9046 16.939 18.7683 16.8113L13.8716 11.9794Z" />
        </svg>
    );
}

export default CrossSvg;

import { en } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../AccountLang";

/* eslint max-len: 0 */
const enLang: AccountLang = {
    ...en,
    account: {
        apps: {
            title: "Apps",
            plan: "Plan",
            payment: "Payment method"
        }
    },
    invitation: {
        invitationNotFound: "Invitation not found",
        wrongMatchingEmail: "Invitation does not match your email",
        invitationAlreadyAccepted: "Invitation already accepted",
        accountNoLongerExists: "Organisation no longer exists",
        invitationRejected: "Invitation rejected",
        invitationCanceled: "Invitation canceled",
        missingData: "Some data is missing",
        unknownCode: "Unknown code"
    },
    authentication: {
        login: "Login",
        rememberMe: "Remember me",
        forgotPassword: "Forgot password?",
        resetPassword: "Reset your password",
        resetPasswordRetry: "Reset your password again",
        recoverPasswordInstructions: "Please enter your email address and we'll send you instructions on how to reset your password.",
        resetPasswordInstructions: "Please choose a new password.",
        resetPasswordSuccessMessage: "Password reset successfully",
        verifyEmailSuccessMessage: "Your email has been verified",
        notRegistered: "Not registered yet?",
        logout: "Log out",
        errors: {
            "166": "Wrong credentials, email and password mismatch.",
            loginFailed: "Failed to login."
        },
        loginFailedErrorMessage: "Failed to login.",
        tryAgain: "Try again later. An email has already been sent.",
        actionModeErrorMessage: "Invalid mode",
        oobCodeErrorMessage: "Your request has expired or the link has already been used",
        resetPasswordErrorMessage: "Failed to reset password",
        resetPasswordInfoMessage: "If you have an account on the platform, go to your email inbox to reset your password",
        or: "Or"
    },
    homePage: {
        title: "Which action do you wish to perform?",
        manageAccountSettings: "Manage organisation settings"
    },
    domain: {
        title: "Domain",
        failedToGetDomain: "An error occurred while retrieving domain information"
    },
    organisations: {
        title: "Organisations",
        searchOrganisation: "Search by organisation name",
        noOrganisationFound: "No organisation found on this domain",
        viewOrganisation: "View organisation",
        creationDate: "Creation date",
        registeredDate: "Registered date",
        users: "Users"
    },
    sharedSettings: {
        noResultsSearch: "No members were found for your query.",
        adminRole: "Admin",
        representativeRole: "Representative",
        contributorRole: "Contributor",
        readOnlyRole: "Read-Only"
    },
    profileSettings: {
        warning: {
            emailNotVerified: "Your email address was not confirmed yet ."
        },
        title: {
            personalAccount: "Profile Settings"
        },
        menu: {
            profile: "Profile",
            preferences: "Preferences",
            companyAccounts: "My Organisations"
        },
        form: {
            birthdate: "Birthdate",
            cellPhone: "Cell phone",
            email: "Email",
            currentPassword: "Current password",
            firstName: "First Name",
            lastName: "Last Name",
            nationality: "Nationality",
            personalInformation: "Personal Information",
            preferences: "Preferences",
            preferredLanguage: "Preferred Language"
        },
        companyAccounts: {
            title: "Organisations",
            whatIs: "What is an organisation?",
            definition: "An organisation is the company you belong to or represent.",
            newAccount: "New organisation",
            search: "Search by organisation name",
            leave: "Leave",
            leaveAccount: companyName => `Leave organisation ${companyName}?`,
            leaveAccountDescription: companyName =>
                `Do you confirm you want to leave the "${companyName}" organisation? You will no longer be able to access any information related to this organisation.`,
            leaveAccountNonAdminDescription: "A new invitation to this organisation will be required if you wish to join this organisation again.",
            leaveAccountAdminDescription:
                "Since you are the only Admin user of this organisation, you can either delete the organisation entirely for all users or assign the Admin role to an existing user.",
            newAdminToAssign: "Assign new admin",
            doNotAssignNewAdmin: "Do not assign new admin",
            deleteAccount: "Delete organisation",
            deleteAccountConfirmation: companyName => `Are you sure you want to delete the organisation "${companyName}"?`,
            deleteAccountConfirmationDescription: "All data and users related to this organisation will be permanently removed.",
            acceptInvitation: "Accept",
            rejectInvitation: "Reject",
            invitedBy: senderName => `Invited by ${senderName}`,
            failedToJoinAccount: "Failed to join organisation",
            failedToRejectInvitation: "Failed to reject invitation"
        }
    },
    accountSettings: {
        title: {
            organisationAccount: "Organisation Settings"
        },
        rightContent: {
            switchTo: "Switch to another organisation"
        },
        menu: {
            organisation: "Organisation",
            generalInformation: "General Information",
            userAccess: "User Access",
            parties: "Parties",
            billingAndSubscriptions: "Billing & Subscriptions",
            git: "Git",
            apiKeys: "Api Keys"
        },
        userAccessView: {
            name: "User Access",
            whatIs: "What is a user?",
            definition:
                "A user is a direct collaborator of your company (e.g., a colleague or a legal representative). When sending them an invitation, ensure you provide them with the appropriate user rights.",
            invitations: "Invitations",
            changeRole: "Change role",
            removeFromAccount: "Remove from organisation",
            removeFromAccountConfirmationTitle: user => `Confirm removal of ${user}`,
            removeFromAccountConfirmationContent: (user, account) => `This will remove ${user} from the organisation ${account}.`,
            inviteUser: {
                name: "Invite user",
                tooltip: "Admin role is required for sending an invitation.",
                emailPlaceholder: "Email address to invite",
                rolePlaceholder: "Role",
                errors: {
                    alreadyInAccountErrorMessage: "This user is already part of the organisation.",
                    alreadyInvitedErrorMessage: "This user has already received an invitation."
                }
            },
            invitePending: "Invitation pending...",
            sendInvite: "Send",
            resendInvite: "Re-send invitation",
            cancelInvitation: "Cancel Invitation",
            search: "Search by email or name",
            findMember: "Find member",
            changeRoleTitle: user => `Change role for ${user}?`,
            selectRole: "Select Role",
            adminRoleDescription: "Has full administrative access. Can invite and remove users and do payments",
            representativeRoleDescription: "Can invite and can contribute to provide information and data to all services accessible by this organisation.",
            contributorRoleDescription: "Can contribute to provide information and data to all services accessible by this organisation.",
            readOnlyRoleDescription: "Can view all services data in a read-only mode",
            changeRoleSuccessMessage: "Role updated successfully",
            changeRoleErrorMessage: "Failed to update the role"
        },
        billingView: {
            plansAndSubscriptionsTitle: "Plans & Subscriptions",
            billingInformationTitle: "Billing information",
            updateSubscription: "Update subscription",
            updateSubscriptions: "Update subscription(s)",
            noPlanOrSubscription: "Your organisation currently has no active plans or subscriptions.",
            invoice: {
                failed: "An error occurred while retrieving invoices", // to review
                invoiceNumber: "Invoice number", // to review
                status: {
                    name: "Status", // to review
                    draft: "Draft", // to review
                    void: "Void", // to review
                    uncollectible: "Uncollectible", // to review
                    open: "Open", // to review
                    paid: "Paid" // to review
                },
                date: "Date", // to review
                amount: "Amount", // to review
                label: {
                    "1": "Invoice", // to review
                    plural: () => "Invoices" // to review
                },
                product: "Product", // to review
                failedToDownloadInvoice: "An error occured while downloading invoice" // to review
            },
            subscription: {
                failed: "An error occurred when retrieving subscriptions",
                status: {
                    name: "Status",
                    active: "Active",
                    canceled: "Canceled",
                    incomplete_expired: "Expired",
                    past_due: "Overdue"
                },
                date: "Date",
                amount: "Amount",
                label: {
                    "1": "Subscription",
                    plural: () => "Subscriptions"
                },
                canceledAt: "Canceled at"
            },
            paymentMethods: {
                title: "Payment methods",
                expirationDate: "Expiration date",
                deletePaymentMethod: "Delete payment method",
                deletePaymentMethodConfirmation: "Are you sure you want to delete this payment method?",
                deletePaymentMethodFailed: "An error occured while deleting the payment method",
                listPaymentMethodFailed: "An error occured while listing payment methods"
            }
        },
        partiesView: {
            name: "Parties",
            whatIs: "What is a party?",
            definition:
                "A party is a supplier or a beneficiary who you will be working with on audits / escrows. An invited party is able to view shared information on audits / escrows and your company name, address and registration number",
            invitations: "Invitations",
            sentYouAPartyRequest: companyName => `${companyName} invites you to be a party`,
            isNowYourParty: companyName => `${companyName} is now one of your party`,
            confirmReject: companyName => `Are you sure you want to reject the invitation from ${companyName}?`,
            linkPartyAccount: "Link a party",
            startWorkingTogether: partnerCompanyName =>
                `Please confirm that you accept to be added as a new party of “${partnerCompanyName}”. As a party, you will be able to view shared information on audits and/or escrows and see each other’s company name, address, and registration number.`,
            selectAnotherAccount: "If you want to link another organisation, select the proper one below",
            noPartyYet: "You have not yet invited others to be a party or received an invitation.",
            invitationRejected: "Invitation rejected",
            invitationCancelled: "Invitation cancelled",
            errorOccured: "An error occurred while retrieving one of your party",
            invitationAlreadySent: "Invitation already sent",
            reportToAdmin: "You do not have the proper rights to invite or accept a party invitation. Please report to your organisation administrator"
        },
        gitView: {
            title: "Version control connections",
            newConnection: "Add new connection",
            installingConnection: "Installing connection...",
            deleteConnection: "Delete connection",
            deleteConnectionConfirmation: "Are you sure you want to delete this git connection?",
            selectConnection: "Select connection source:",
            featureComingSoon: "Coming Soon!",
            lastConnectionDate: "Connected",
            noResultsSearch: "No git connection found",
            installationConfirmationMessage: provider => `By clicking "Confirm", you will be redirected to the ${provider} website to finish the install.`,
            deletionConfirmationMessage: provider => `By clicking "Confirm", you will be redirected to the ${provider} website to delete the connection.`,
            installed: "Installed", // to review
            disconnected: "Disconnected", // to review
            fetching: "Fetching code repositories", // to review
            fetchFailed: "Failed to fetch code repositories", // to review
            lastConnected: date => `Last connected ${date}` // to review
        },
        apiKeysView: {
            title: "API Access keys",
            newAccessToken: "Generate new access token",
            description: brandName => `Tokens you have generated that can be used to access the ${brandName} API`,
            newApiKeyTitle: "New API Access token",
            nameFieldPlaceholder: "Api Key name...",
            keyEnvironments: {
                sandbox: "Sandbox Key",
                production: "Production Key"
            },
            generate: "Generate",
            recapDescription: "Warning: Save this key, this will be the only time you see it.",
            noResultFound: "No API key found.",
            deleteApiKeyTitle: "Delete API Access Token",
            deleteConfirmation: name => `Are you sure you want to delete the API Access Token "${name}"?`,
            maxReached: "You have reached the maximum number of API keys."
        }
    },
    onboarding: {
        ...en.onboarding,
        verifyEmail: {
            sent: email => `We have sent you an activation email at ${email}.`,
            confirm: "Please verify your email to continue."
        },
        userProfile: {
            title: "Your profile",
            successOnSave: "Your profile information has been saved successfully.",
            errorOnSave: "Failed to save your profile information."
        },
        invitations: {
            title: "Your invitations",
            description: {
                "1": "You have already been invited by an organisation. Select it if you wish to join:",
                plural: count => `You have already been invited by ${count} organisations. Select the ones you wish to join:`
            },
            joinOrganisation: {
                "0": "Ignore and create my organisation",
                "1": "Join this organisation",
                plural: count => `Join these ${count} organisations`
            },
            successOnJoin: {
                "1": "You have joined one organisation successfully.",
                plural: count => `You have joined ${count} organisations successfully.`
            },
            errorOnJoin: {
                "1": "Failed to join one organisation.",
                plural: count => `Failed to join ${count} organisations.`
            }
        },
        account: {
            createAs: {
                title: "Create as",
                organisationMember: {
                    title: "Organisation member",
                    description: "Create and manage your organisation."
                },
                representative: {
                    title: "Representative",
                    description: "Create and manage an organisation on behalf of your client(s)."
                }
            },
            accountInfo: {
                title: isRepresentative => `About your ${isRepresentative ? "client's " : ""}organisation`
            },
            collaborate: {
                title: isRepresentative => (isRepresentative ? "Client contact" : "Invite your colleagues"),
                description: isRepresentative => {
                    if (isRepresentative) {
                        return "Please provide the contact email of your client. They'll be invited to join this organisation as administrator and be granted with full control over this new organisation.";
                    }
                    return "Invite your colleagues to work with you. You can always change their roles later in the organisation settings page, as well as send other invitations.";
                },
                collaboratorEmail: isRepresentative => `${isRepresentative ? "Client contact" : "Collaborator"} email`,
                addAnotherCollaborator: isRepresentative => `Add another ${isRepresentative ? "client contact" : "colleague"}`,
                sendInvitation: {
                    "1": "Send invitation by email",
                    plural: () => "Send invitations by email"
                }
            },
            successOnSave: isRepresentative => `Your ${isRepresentative ? "client's " : ""}organisation has been created successfully.`,
            errorOnSave: isRepresentative => `Failed to create your ${isRepresentative ? "client's " : ""}organisation.`
        },
        moreInfoLink: "More about why and how we use your information."
    }
};
export default enLang;

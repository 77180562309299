/* eslint-disable import/no-unused-modules */
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import { motion } from "framer-motion";
import { isEqual, uniq } from "lodash";
import { ElementType, ReactNode, useMemo, useState } from "react";
import { useLang } from "../../../lang";
import { SortDirection } from "../../../services";
import { ROTATE_OPEN_VARIANT } from "../../referentials";
import { Buttons } from "../Button";
import { Button as ButtonAlias, ButtonProps } from "../Button/Button";
import { ChevronDownIcon } from "../Icons";
import { Input } from "../Input";
import { Tooltip } from "../Tooltip/Tooltip";
import { DropdownActionProps } from "./Dropdown";

type Value<T> = T;
type ValueAndLabel<T> = { value: Value<T>; label: string };

export type FilterProps<T> = {
    key: string;
    options: { label: string; value: string | null }[];
    label: string;
    onFilter: (item: T, selectedOptions: string[]) => T | null;
};

export type SortProps<T> = {
    key: string;
    label: string;
    onSort?: (a: T, b: T, direction?: SortDirection) => number;
    direction?: SortDirection;
    directionLabel?: { [key in SortDirection]: string };
};

function SortRadio({
    sortKey,
    onChange,
    direction,
    selectedSort
}: {
    sortKey: string;
    onChange: (updatedSort: { [key: string]: SortDirection } | undefined) => void;
    direction: SortDirection;
    selectedSort?: { [key: string]: SortDirection };
}): JSX.Element {
    return <Input.Radio onChange={() => onChange({ [sortKey]: direction })} checked={selectedSort?.[sortKey] === direction} />;
}

export namespace DropdownCommon {
    type DropdownTriggerProps = Omit<DropdownMenu.DropdownMenuTriggerProps, "asChild">;

    export function Trigger({ children, ...props }: DropdownTriggerProps) {
        return <DropdownMenu.Trigger asChild children={<div className="cursor-pointer truncate">{children}</div>} {...props} />;
    }

    export namespace Trigger {
        export function WithChevron({
            isOpen,
            children,
            "data-id": dataId,
            isDark
        }: {
            isOpen: boolean;
            children: ReactNode;
            "data-id"?: string;
            isDark?: boolean;
        }) {
            return (
                <Trigger
                    children={
                        <div
                            data-id={dataId}
                            className={classNames("flex max-w-[225px] cursor-pointer gap-2 rounded p-2 sm:min-w-[212px] sm:max-w-full", {
                                "hover:bg-grey-extra-light": !isDark
                            })}
                        >
                            <div className="flex-1 truncate">{children}</div>
                            <motion.div
                                className="flex items-center text-lg"
                                initial={isOpen ? "open" : "closed"}
                                animate={isOpen ? "open" : "closed"}
                                variants={ROTATE_OPEN_VARIANT}
                            >
                                <ChevronDownIcon size="sm" color={isDark ? "white" : "slate"} />
                            </motion.div>
                        </div>
                    }
                />
            );
        }

        export function Button(props: ButtonProps) {
            return <Trigger children={<ButtonAlias {...props} type={props.type || "default"} />} disabled={props.isDisabled || props.isLoading} />;
        }

        export function MenuIcon({ icon: Icon, "data-id": dataId, title }: { icon: ElementType; "data-id"?: string; title: string }) {
            const menuIcon = (
                <div
                    data-id={dataId}
                    className="flex h-9 w-9 items-center justify-center rounded border border-blue-extra-light bg-blue-extra-light p-2 transition-colors hover:border-slate-light"
                    children={<Icon color="slate" shade="light" />}
                />
            );
            return <Trigger>{title ? <Tooltip title={title} children={menuIcon} delayDuration="long" /> : menuIcon}</Trigger>;
        }
    }

    export type DropdownContentProps = {
        "data-id"?: string;
        header?: ReactNode;
        footer?: ReactNode;
    };

    export function Content({
        header,
        footer,
        children,
        "data-id": dataId,
        align = "end",
        sideOffset = 5,
        ...props
    }: Omit<DropdownMenu.DropdownMenuContentProps, "className"> & DropdownContentProps) {
        return (
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="z-50 max-h-[500px] overflow-auto rounded-md bg-white drop-shadow-lg"
                    align={align}
                    sideOffset={sideOffset}
                    {...props}
                >
                    {header && <Label children={header} data-id={dataId ? `${dataId}-header` : "header"} />}
                    <DropdownMenu.Group {...(header && { className: "shadow-inner" })} children={children} />
                    <DropdownMenu.Arrow className="fill-white" />
                    {footer && <div children={footer} className="flex justify-end gap-2 border-t border-t-grey-extra-light p-4" />}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        );
    }

    export type DropdownContentActionsProps = {
        actions: DropdownActionProps[];
    } & DropdownContentProps;

    export namespace Content {
        export function WithActions({
            "data-id": dataId,
            actions,
            ...props
        }: Omit<DropdownMenu.DropdownMenuContentProps, "className" | "children"> & DropdownContentActionsProps) {
            return (
                <Content
                    data-id={dataId}
                    children={actions.map((action, i) => [
                        i > 0 && <Separator key={`${action.label}-separator`} />,
                        <Action
                            key={action.label}
                            {...action}
                            {...(action["data-id"] && { "data-id": dataId ? `${dataId}-${action["data-id"]}` : action["data-id"] })}
                        />
                    ])}
                    {...props}
                />
            );
        }

        export function WithFilter<T extends string | null>({
            list,
            defaultList,
            onSubmit,
            onClose
        }: {
            list: ValueAndLabel<T>[];
            defaultList: Value<T>[];
            onSubmit: (updatedList: typeof defaultList) => unknown;
            onClose: () => void;
        }): JSX.Element {
            const lang = useLang();
            const [search, setSearch] = useState<string>("");
            const [filteredList, setFilteredList] = useState<typeof defaultList>(defaultList);
            const filteredListWithSearch = useMemo(() => {
                const sanitizedSearch = search.trim().toLowerCase();
                if (sanitizedSearch) {
                    return list.filter(item => item.value?.toLowerCase().includes(sanitizedSearch) || item.label.toLowerCase().includes(sanitizedSearch));
                }
                return list;
            }, [list, search]);

            function doApply(): void {
                onSubmit(filteredList);
                onClose();
                setSearch("");
            }

            function doCancel(): void {
                setFilteredList(defaultList);
                onClose();
                setSearch("");
            }

            function doClear(): void {
                setFilteredList([]);
                setSearch("");
            }

            function onFilter(item: T, isActive: boolean): void {
                setFilteredList(prev => {
                    if (isActive) {
                        return [...(prev ?? []), item];
                    } else {
                        return prev?.filter(val => val !== item);
                    }
                });
            }

            function onSelectAll(checked: boolean): void {
                setFilteredList(checked ? list.map(item => item.value) : []);
            }

            return (
                <DropdownCommon.Content
                    align="start"
                    onCloseAutoFocus={doCancel}
                    footer={
                        <>
                            <Buttons.Cancel size="sm" onClick={doCancel} isLoading={false} />
                            <Buttons.Save
                                size="sm"
                                onClick={doApply}
                                isLoading={false}
                                children={lang.table.apply}
                                isDisabled={isEqual(filteredList, defaultList)}
                            />
                        </>
                    }
                >
                    <div className="w-72 space-y-4 py-4">
                        <div className="space-y-4 px-4">
                            <div>
                                <div className="font-semibold">{lang.table.filter}</div>
                                <Input.Search onChange={event => setSearch(event.target.value)} placeholder={lang.table.search} value={search} />
                            </div>
                            <div className="space-y-2">
                                <div className="flex gap-1">
                                    <label className="flex cursor-pointer items-center space-x-1">
                                        <Input.Checkbox
                                            onChange={event => onSelectAll(event.target.checked)}
                                            checked={filteredList.length === list.length}
                                            disabled={!!search}
                                        />
                                        <div className="text-slate-dark">{lang.table.selectAll}</div>
                                    </label>
                                </div>
                                <div className="rounded-md border border-grey-extra-light p-2">
                                    <div className="max-h-32 overflow-auto">
                                        {uniq(filteredListWithSearch).map(item => {
                                            const value = item.value;
                                            return (
                                                <label key={value} className="flex cursor-pointer items-center space-x-1">
                                                    <Input.Checkbox
                                                        onChange={event => onFilter(value, event.target.checked)}
                                                        checked={filteredList.includes(value)}
                                                    />
                                                    <div className="truncate text-slate-dark">{item.label}</div>
                                                </label>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div onClick={doClear} className="cursor-pointer text-slate-dark underline" children={lang.table.clearAll} />
                            </div>
                        </div>
                    </div>
                </DropdownCommon.Content>
            );
        }

        export function WithSort<T>({
            sorts,
            onSubmit,
            onClose,
            defaultSort
        }: {
            sorts: SortProps<T>[];
            onSubmit: (updatedSort: { [key: string]: SortDirection } | undefined) => void;
            onClose: () => void;
            defaultSort?: { [key: string]: SortDirection };
        }): JSX.Element {
            const lang = useLang();
            const [selectedSort, setSelectedSort] = useState<{ [key: string]: SortDirection } | undefined>(defaultSort);

            function doApply(): void {
                onSubmit(selectedSort);
                onClose();
            }

            function doClear(): void {
                setSelectedSort(undefined);
            }

            return (
                <DropdownCommon.Content
                    align="start"
                    onCloseAutoFocus={onClose}
                    footer={
                        <>
                            <Buttons.Cancel size="sm" onClick={onClose} isLoading={false} />
                            <Buttons.Save size="sm" onClick={doApply} isLoading={false} children={lang.table.apply} />
                        </>
                    }
                >
                    <div className="w-72 space-y-4 py-4">
                        <div className="space-y-4 px-4">
                            <div className="font-semibold">{lang.table.sort}</div>
                            <div className="space-y-2">
                                <div className="rounded-md border border-grey-extra-light p-2">
                                    <div className="max-h-32 overflow-auto">
                                        {sorts.map(sort => {
                                            const commonRadioProps = { sortKey: sort.key, onChange: setSelectedSort, selectedSort: selectedSort };
                                            return (
                                                <div key={sort.key}>
                                                    <div className="font-light text-grey-primary">{sort.label}</div>
                                                    <label className="flex cursor-pointer items-center space-x-2">
                                                        <div className="text-slate-dark text-sm">{sort?.directionLabel?.asc ?? lang.shared.ascending}</div>
                                                        <SortRadio direction={SortDirection.ASCENDING} {...commonRadioProps} />
                                                    </label>
                                                    <label className="flex cursor-pointer items-center space-x-2">
                                                        <div className="text-slate-dark text-sm">{sort?.directionLabel?.desc ?? lang.shared.descending}</div>
                                                        <SortRadio direction={SortDirection.DESCENDING} {...commonRadioProps} />
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div onClick={doClear} className="cursor-pointer text-slate-dark underline" children={lang.table.clearAll} />
                            </div>
                        </div>
                    </div>
                </DropdownCommon.Content>
            );
        }
    }

    type DropdownLabelProps = Omit<DropdownMenu.DropdownMenuLabelProps, "className">;

    export function Label(props: DropdownLabelProps) {
        return <DropdownMenu.Label className="p-4 text-sm font-light text-slate-primary" {...props} />;
    }

    export function Action({ href, icon: Icon, label, onClick, isDisabled, ...props }: DropdownActionProps) {
        const actionRender = (
            <DropdownMenu.Item
                className={classNames("select-none p-4 text-sm font-light outline-none", {
                    "pointer-none text-grey-light": isDisabled,
                    "cursor-pointer text-slate-primary hover:bg-grey-extra-light": !isDisabled
                })}
                children={
                    Icon ? (
                        <div className="flex items-center gap-2">
                            <Icon color="slate" size="xs" />
                            <div>{label}</div>
                        </div>
                    ) : (
                        label
                    )
                }
                disabled={isDisabled}
                {...(!isDisabled && { onClick })}
                {...props}
            />
        );
        return href && !isDisabled ? <a href={href}>{actionRender}</a> : actionRender;
    }

    export function Separator() {
        return <DropdownMenu.Separator className="border-t border-grey-extra-light" />;
    }
}

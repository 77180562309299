import { Account } from "@vaultinum/vaultinum-api";
import { CellContext, Table } from "../../../design-system";
import { useLang } from "../../../lang";

function renderRepository(cell: CellContext<Account.GitOperation.Repository, unknown>, gitRepositories: Account.GitRepository[]) {
    const repositoryId = cell.getValue<string>();
    const repository = gitRepositories.find(element => element.id === repositoryId);
    return repository?.name;
}

export default function GitOperationSummary({
    gitOperation,
    gitRepositories
}: {
    gitOperation: Omit<Account.GitOperation, "id" | "creationDate">;
    gitRepositories: Account.GitRepository[];
}): JSX.Element {
    const lang = useLang();

    return (
        <Table
            data={gitOperation.repositories || []}
            columns={[
                {
                    header: lang.git.codeRepository,
                    accessorKey: "id",
                    cell: cell => renderRepository(cell, gitRepositories)
                },
                {
                    header: lang.git.branch,
                    accessorKey: "branch"
                }
            ]}
        />
    );
}

import * as ToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames";
import { ChangeEvent, useState } from "react";
import { Button, ChevronDownIcon, ChevronUpIcon, EyeIcon, EyeInvisibleIcon, LockIcon, MailIcon, SearchIcon, Select } from "../..";
import { startYear } from "../../../helpers";
import { CommonLang, plural, useLang } from "../../../lang";
import { BaseInputs } from "./BaseInput";
import { DatePickerHeader } from "./DatePickerHeader";
import { ErrorMessage } from "./ErrorMessage";
import { TextAreaProps } from "./InputProps";
export namespace Input {
    export function Text(props: BaseInputs.TextProps) {
        return <BaseInputs.Text type="text" {...props} />;
    }

    export function Email(props: Omit<BaseInputs.TextProps, "icon">) {
        return <BaseInputs.Text type="email" icon={MailIcon} {...props} />;
    }

    export function Password(props: Omit<BaseInputs.TextProps, "icon">) {
        const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

        const togglePasswordVisibility = () => {
            setIsPasswordVisible(prev => !prev);
        };

        let icon;
        if (!props.value) {
            icon = LockIcon;
        } else if (isPasswordVisible) {
            icon = EyeIcon;
        } else {
            icon = EyeInvisibleIcon;
        }

        return (
            <BaseInputs.Text
                icon={icon}
                type={isPasswordVisible ? "text" : "password"}
                {...(props.value && {
                    onIconClick: togglePasswordVisibility
                })}
                {...props}
            />
        );
    }

    export function Search({ navigation, ...props }: Omit<BaseInputs.SearchProps, "icon">) {
        return (
            <BaseInputs.Text
                type="search"
                {...props}
                icon={SearchIcon}
                {...(navigation && {
                    innerRightChildren: (
                        <div className="flex">
                            <Button onClick={navigation.onPrevious} type="default" fill="link" size="sm" icon={ChevronUpIcon} isLoading={false} />
                            <Button onClick={navigation.onNext} type="default" fill="link" size="sm" icon={ChevronDownIcon} isLoading={false} />
                        </div>
                    )
                })}
            />
        );
    }

    export function Checkbox(props: BaseInputs.ChoiceProps) {
        return <BaseInputs.Choice type="checkbox" {...props} />;
    }

    export function Radio(props: BaseInputs.ChoiceProps) {
        return <BaseInputs.Choice type="radio" {...props} />;
    }

    export function RadioButton({
        currentValue,
        selectedValue
    }: {
        currentValue: {
            label: string;
            value: string;
        };
        selectedValue: string;
    }) {
        return (
            <ToggleGroup.Item
                key={currentValue.value}
                value={currentValue.value}
                className={classNames("flex items-center justify-center px-4 py-2 text-sm first:ml-0 first:rounded-l last:rounded-r", {
                    "bg-slate-primary text-white transition-colors hover:bg-slate-light": selectedValue === currentValue.value,
                    "bg-white text-grey-dark hover:text-slate-primary": selectedValue !== currentValue.value
                })}
                style={{ marginLeft: 1 }}
            >
                {currentValue.label}
            </ToggleGroup.Item>
        );
    }

    export function TextArea({ displayCount, ...props }: TextAreaProps & BaseInputs.AdditionalTextAreaProps) {
        const lang = useLang<CommonLang>();
        const remainingCharacters = (props.maxLength || 0) - ((props.value as string[])?.length || 0);
        return (
            <div className="w-full">
                <BaseInputs.TextArea {...props} />
                {displayCount && props.maxLength && (
                    <span className="flex justify-end text-xs text-grey-primary">{plural(lang.shared.characters, remainingCharacters)}</span>
                )}
            </div>
        );
    }

    export function DatePicker(props: BaseInputs.DatePickerProps) {
        return (
            <BaseInputs.DatePicker
                {...props}
                renderCustomHeader={headerProps => (
                    <DatePickerHeader {...headerProps} range={props.maxDate ? [startYear, props.maxDate.getFullYear()] : props.yearRange} />
                )}
                isClearable
                showMonthDropdown
                showYearDropdown
            />
        );
    }

    export function RangePicker(props: BaseInputs.DatePickerRangeProps) {
        return (
            <BaseInputs.RangePicker {...props} renderCustomHeader={headerProps => <DatePickerHeader {...headerProps} />} showMonthDropdown showYearDropdown />
        );
    }

    export function Phone({
        countriesPhoneData,
        value = {},
        defaultValue = {},
        label,
        onChange,
        errorMessage
    }: BaseInputs.PhoneProps & Pick<BaseInputs.TextProps, "label" | "errorMessage">): JSX.Element {
        const triggerChange = (changedValue: BaseInputs.InputPhoneValue) => {
            onChange?.({ target: { value: { ...value, ...changedValue } } });
        };

        const handleCountryCallingCodeChange = (newValue: string) => {
            triggerChange({ countryCode: newValue });
        };

        const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
            triggerChange({ phoneNumber: event.target.value });
        };

        return (
            <div className="w-full">
                <div className="flex items-end gap-2">
                    <Select.CountriesCode
                        data={countriesPhoneData}
                        value={value.countryCode}
                        onChange={handleCountryCallingCodeChange}
                        defaultValue={defaultValue.countryCode}
                        label={label}
                    />
                    <Text
                        data-id="phone-input"
                        className="w-full"
                        onChange={handlePhoneNumberChange}
                        value={value.phoneNumber}
                        defaultValue={defaultValue.phoneNumber}
                    />
                </div>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }
}

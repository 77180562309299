import { ReactNode, useEffect, useState } from "react";
import { LogoSpinner } from "../LogoSpinner";
import "./ContentLoader.css";

type ContentLoaderProps = {
    loading?: boolean;
    size?: "small" | "middle" | "large" | "xlarge";
    children?: ReactNode;
    delay?: number;
    fadeInContent?: boolean;
    initialState?: "open" | "spinning" | "done";
};

export default function ContentLoader({ loading, size, children, delay = 300, fadeInContent = true, initialState = "open" }: ContentLoaderProps): JSX.Element {
    const [state, setState] = useState<"open" | "spinning" | "close" | "done">(initialState);
    useEffect(() => {
        if (!loading) {
            setState("close");
            setTimeout(() => setState("done"), delay);
        } else {
            if (state === "done") {
                setState(initialState);
            }
            setTimeout(() => setState("spinning"), delay);
        }
    }, [loading]);
    if (state === "done") {
        if (fadeInContent) {
            return <div className="fade-in h-full">{children}</div>;
        }
        return <>{children}</>;
    }
    return (
        <div className="flex h-full items-center justify-center">
            <LogoSpinner size={size} state={state} />
        </div>
    );
}

import classNames from "classnames";
import { ReactNode } from "react";

export default function TitleContent({
    title,
    subtitle,
    content,
    rightContent,
    isBordered
}: {
    title: string;
    subtitle?: string;
    content?: ReactNode;
    rightContent?: ReactNode;
    isBordered?: boolean;
}): JSX.Element {
    return (
        <div className={classNames("flex flex-col gap-5 p-4", { "border-b": isBordered })}>
            <div className="flex justify-between">
                <div className="flex items-center gap-1">
                    <h2 className="text-base font-semibold">{title}</h2>
                    {subtitle && <span className="text-base">{subtitle}</span>}
                </div>
                {rightContent && <div>{rightContent}</div>}
            </div>
            {content}
        </div>
    );
}

import { yupResolver } from "@hookform/resolvers/yup";
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from "@vaultinum/vaultinum-api";
import { FieldValues, UseFormProps, useForm as useFormHook } from "react-hook-form";
import { CommonLang } from "../../lang";
import { yup } from "../services";

export function getPasswordErrorMessage(messageId: string, lang: CommonLang) {
    switch (messageId) {
        case "password-length":
            return lang.shared.minErrorMessage(PASSWORD_MIN_LENGTH);
        case "case":
            return lang.shared.atLeastOneUpperLowercase;
        case "digit":
            return lang.shared.atLeastOneDigit;
        case "special-character":
            return lang.shared.atLeastOneSpecialCharacter;
        default:
            return "";
    }
}

export function useForm<T extends FieldValues>(options?: UseFormProps<T> & { schema?: yup.ObjectSchema<yup.AnyObject> }) {
    const { schema, ...rest } = options || {};

    if (schema) {
        const resolver = yupResolver(schema);
        return useFormHook<T>({ ...rest, resolver });
    }

    return useFormHook<T>(options);
}

export const passwordValidation = (lang: CommonLang) =>
    yup.string().matches(PASSWORD_REGEX).min(PASSWORD_MIN_LENGTH, lang.shared.minErrorMessage(PASSWORD_MIN_LENGTH));

export const confirmValidation = (lang: CommonLang) =>
    yup
        .string()
        .required(lang.register.confirmPasswordErrorMessage)
        .oneOf([yup.ref("password")], lang.register.confirmPasswordErrorMessage);

import { Account } from "@vaultinum/vaultinum-api";
import { CardSelector } from "../../../design-system";
import { useLang } from "../../../lang";
import { formatFrequency } from "../../tools";

const availableFrequencies = [
    Account.GitOperation.Frequency.ONE_SHOT,
    Account.GitOperation.Frequency.WEEKLY,
    Account.GitOperation.Frequency.MONTHLY,
    Account.GitOperation.Frequency.EVERY_THREE_MONTHS,
    Account.GitOperation.Frequency.EVERY_SIX_MONTHS
];

export default function FrequencySelector({
    setSelectedFrequency,
    className
}: {
    setSelectedFrequency: (frequency: Account.GitOperation.Frequency) => void;
    className?: string;
}): JSX.Element {
    const lang = useLang();
    const frequencies = availableFrequencies.map(frequency => ({
        id: frequency,
        label: formatFrequency(frequency, lang),
        isSelected: frequency === Account.GitOperation.Frequency.MONTHLY,
        labelClassName: "font-normal text-base"
    }));

    return (
        <CardSelector
            cards={frequencies}
            onCardSelected={(frequencyKey: string) => {
                setSelectedFrequency(frequencyKey as Account.GitOperation.Frequency);
            }}
            className={className}
        />
    );
}

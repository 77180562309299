import classNames from "classnames";
import { ElementType } from "react";
import { Color, Size, Type } from "../../referentials";
export interface TagInterface {
    icon?: ElementType;
    message?: string | number;
    onClick?: () => void;
    type?: Extract<Type, "accent" | "success" | "warning" | "info" | "danger" | "default">;
    size?: Extract<Size, "sm" | "md" | "lg">;
    isOutlined?: boolean;
}

const SIZE_STYLE: Record<NonNullable<TagInterface["size"]>, string> = {
    sm: "text-xs leading-3 py-0.5 px-2",
    md: "text-sm leading-4 py-1 px-3",
    lg: "text-sm leading-4 py-2 px-3"
};

const TYPE_STYLE: Record<NonNullable<TagInterface["type"]>, string> = {
    accent: "text-pink-dark bg-pink-extra-light border-pink-primary",
    success: "text-green-dark bg-green-extra-light border-green-primary",
    warning: "text-orange-dark bg-orange-extra-light border-orange-primary",
    info: "text-blue-dark bg-blue-extra-light border-blue-primary",
    danger: "text-red-dark bg-red-extra-light border-red-primary",
    default: "text-slate-dark bg-grey-extra-light border-grey-light"
};

const TYPE_COLOR: Record<NonNullable<TagInterface["type"]>, Color> = {
    accent: "pink",
    success: "green",
    warning: "orange",
    info: "blue",
    danger: "red",
    default: "slate"
};

export function Tag({ icon: Icon, message, onClick, type = "default", size = "md", isOutlined = false }: TagInterface): JSX.Element {
    return (
        <div
            onClick={onClick}
            className={classNames(SIZE_STYLE[size], TYPE_STYLE[type], "flex w-max items-center gap-2 rounded-3xl font-light tabular-nums", {
                "border !bg-transparent": isOutlined,
                "cursor-pointer transition duration-500 hover:opacity-75": !!onClick
            })}
        >
            {Icon && <span>{<Icon color={TYPE_COLOR[type]} shade="dark" />}</span>}
            {message && <span>{message}</span>}
        </div>
    );
}

import DataEventSource, { DataEventListener, Unsubscribe } from "./DataEventSource";

export default class IndexedDataEventSource<T, TArg extends string> {
    private indexedSubscribe?: (arg: TArg, onUpdate: DataEventListener<T>) => Unsubscribe;

    private eventSources: {
        [key: string]: DataEventSource<T>;
    } = {};

    private refCacheClearDelay = 1000;

    public get cacheClearDelay() {
        return this.refCacheClearDelay;
    }

    public set cacheClearDelay(value: number) {
        this.refCacheClearDelay = value;
        for (const eventSource of Object.values(this.eventSources)) {
            eventSource.cacheClearDelay = value;
        }
    }

    constructor(subscribe?: (arg: TArg, onUpdate: DataEventListener<T>) => Unsubscribe) {
        this.indexedSubscribe = subscribe;
    }

    private getEventSource(arg: TArg): DataEventSource<T> {
        const key = JSON.stringify(arg, Object.keys(arg).sort());
        if (!this.eventSources[key]) {
            if (this.indexedSubscribe) {
                this.eventSources[key] = new DataEventSource(() => {
                    if (this.indexedSubscribe) {
                        return this.indexedSubscribe(arg, data => this.onUpdate(arg, data));
                    }
                    return () => {};
                });
            } else {
                this.eventSources[key] = new DataEventSource();
            }
        }
        return this.eventSources[key];
    }

    addListener(arg: TArg, onUpdate: DataEventListener<T>): Unsubscribe {
        return this.getEventSource(arg).addListener(onUpdate);
    }

    onUpdate(arg: TArg, data: T) {
        this.getEventSource(arg).onUpdate(data);
    }

    getData(arg: TArg): Promise<T> {
        return this.getEventSource(arg).getData();
    }
}

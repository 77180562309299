import { IconSvgProps } from "../Icons";

/* eslint max-len: 0 */
function CompanySvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg viewBox="0 0 37 48" fill={color} width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.48501 9.75C8.48501 8.92125 9.15909 8.25 9.99133 8.25H14.5103C15.3388 8.25 16.0167 8.92125 16.0167 9.75V14.25C16.0167 15.075 15.3388 15.75 14.5103 15.75H9.99133C9.15909 15.75 8.48501 15.075 8.48501 14.25V9.75ZM26.561 8.25C27.3894 8.25 28.0673 8.92125 28.0673 9.75V14.25C28.0673 15.075 27.3894 15.75 26.561 15.75H22.042C21.2135 15.75 20.5356 15.075 20.5356 14.25V9.75C20.5356 8.92125 21.2135 8.25 22.042 8.25H26.561ZM8.48501 21.75C8.48501 20.925 9.15909 20.25 9.99133 20.25H14.5103C15.3388 20.25 16.0167 20.925 16.0167 21.75V26.25C16.0167 27.075 15.3388 27.75 14.5103 27.75H9.99133C9.15909 27.75 8.48501 27.075 8.48501 26.25V21.75ZM26.561 20.25C27.3894 20.25 28.0673 20.925 28.0673 21.75V26.25C28.0673 27.075 27.3894 27.75 26.561 27.75H22.042C21.2135 27.75 20.5356 27.075 20.5356 26.25V21.75C20.5356 20.925 21.2135 20.25 22.042 20.25H26.561ZM0.200195 6C0.200195 2.68594 2.89747 0 6.22551 0H30.3268C33.6501 0 36.3521 2.68594 36.3521 6V42C36.3521 45.3094 33.6501 48 30.3268 48H6.22551C2.89747 48 0.200195 45.3094 0.200195 42V6ZM4.71918 6V42C4.71918 42.825 5.39326 43.5 6.22551 43.5H13.7572V37.5C13.7572 35.0156 15.7813 33 18.2761 33C20.771 33 22.7951 35.0156 22.7951 37.5V43.5H30.3268C31.1553 43.5 31.8331 42.825 31.8331 42V6C31.8331 5.17125 31.1553 4.5 30.3268 4.5H6.22551C5.39326 4.5 4.71918 5.17125 4.71918 6Z"
                fill={color}
            />
        </svg>
    );
}

export default CompanySvg;

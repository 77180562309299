import { FullAudit, query, Question, Section, Survey, SurveyVariantRecord } from "@vaultinum/vaultinum-api";

export enum QuestionWithKeys {
    Notes = "notes",
    Recommendations = "recommendations"
}

// recursive function to check if a question and its subquestions have notes
function hasNotes(question: Question, notes: FullAudit.SurveyRecordNote[]): boolean {
    if (notes.some(note => note.target.questionId === question.id)) {
        return true;
    }
    for (const option of question.options) {
        if (option.questions?.some(subQuestion => hasNotes(subQuestion, notes))) {
            return true;
        }
    }

    return false;
}

// recursive function to check if a section and its subsections have recommendations
function hasRecommendations(question: Question, surveyVariantRecord: SurveyVariantRecord): boolean {
    if (surveyVariantRecord.questions[question.id]?.selectedIndex?.some(index => question.options[index]?.context?.fixes?.length)) {
        return true;
    }
    for (const option of question.options) {
        if (option.questions?.some(subQuestion => hasRecommendations(subQuestion, surveyVariantRecord))) {
            return true;
        }
    }

    return false;
}

export function sectionHasNotes(section: Section, surveyVersion: Survey.Version, notes?: FullAudit.SurveyRecordNote[]): boolean {
    if (!section.sections?.length) {
        return !!notes
            ?.map(note => query(surveyVersion).getSectionWithQuestion(note.target.questionId))
            .find(sectionWithNotes => sectionWithNotes?.id === section.id);
    } else {
        const subSectionWithNotes = section.sections.find(subSection => sectionHasNotes(subSection, surveyVersion, notes));
        return !!subSectionWithNotes;
    }
}

export function isQuestionHidden(
    question: Question,
    notes: FullAudit.SurveyRecordNote[],
    surveyVariantRecord: SurveyVariantRecord,
    showQuestionWithNotesOnly?: boolean,
    showQuestionWithRecommendationsOnly?: boolean
): boolean {
    if (showQuestionWithNotesOnly && showQuestionWithRecommendationsOnly) {
        return !hasNotes(question, notes) && !hasRecommendations(question, surveyVariantRecord);
    } else if (showQuestionWithRecommendationsOnly) {
        return !hasRecommendations(question, surveyVariantRecord);
    } else if (showQuestionWithNotesOnly) {
        return !hasNotes(question, notes);
    }
    return false;
}

export function filterQuestions(
    questions: Question[],
    notes: FullAudit.SurveyRecordNote[] | undefined,
    surveyVariantRecord: SurveyVariantRecord,
    showQuestionsWithNotesOnly?: boolean,
    showQuestionWithRecommendations?: boolean
): Question[] {
    return questions
        .map(question => {
            if (question.options) {
                const filteredOptions = question.options.map(option => {
                    if (option.questions) {
                        const filteredSubQuestions = filterQuestions(
                            option.questions,
                            notes,
                            surveyVariantRecord,
                            showQuestionsWithNotesOnly,
                            showQuestionWithRecommendations
                        );
                        return { ...option, questions: filteredSubQuestions };
                    }
                    return option;
                });
                return { ...question, options: filteredOptions };
            }
            return question;
        })
        .filter(question => !isQuestionHidden(question, notes ?? [], surveyVariantRecord, showQuestionsWithNotesOnly, showQuestionWithRecommendations));
}

type SearchParamValues = {
    [key: string]: string | string[] | undefined;
};

export default function useUrlSearch(): SearchParamValues {
    const urlParams = new URLSearchParams(window.location.search);
    const searchParams: SearchParamValues = {};
    for (const key of urlParams.keys()) {
        const values = urlParams.getAll(key);
        if (values.length) {
            searchParams[key] = values.length === 1 ? values[0] : values;
        }
    }
    return searchParams;
}

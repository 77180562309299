import { SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { formatAsCurrency } from "../../../../helpers";
import { useLang } from "../../../../lang";

export default function TaxLine({ percentage, amount, langCode }: { percentage: number; amount: number; langCode: SupportedLanguageCode }) {
    const lang = useLang();
    return (
        <div className="flex">
            <span className="font-light">{lang.payment.payment.taxes(percentage)}</span>
            <span className="ml-auto font-bold">{formatAsCurrency(amount, langCode)}</span>
        </div>
    );
}

import { Direction, DIRECTION_TO_ROTATION_CLASS } from "../../../referentials";
import { IconSvgProps } from "../Icons";

export default function ChevronUpSvg({ color, size, direction = "up" }: IconSvgProps & { direction?: Direction }): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} className={DIRECTION_TO_ROTATION_CLASS[direction]} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1538 15.1917C17.7878 15.5252 17.2281 15.5255 16.8616 15.1924L12.0001 10.7735L7.13847 15.1924C6.77203 15.5255 6.21237 15.5252 5.84628 15.1917L5.57914 14.9484C5.16112 14.5676 5.16112 13.9098 5.57914 13.529L11.3536 8.26889C11.72 7.93515 12.2802 7.93515 12.6465 8.26889L18.421 13.529C18.839 13.9098 18.839 14.5676 18.421 14.9484L18.1538 15.1917Z" />
        </svg>
    );
}

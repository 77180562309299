import classNames from "classnames";
import { Type, TYPE_TO_COLOR } from "../../referentials";

const TYPE_TO_STYLE = {
    primary: "text-primary",
    success: "text-success",
    danger: "text-danger"
};

export default function IconWithText({
    icon: Icon,
    text,
    type = "primary"
}: {
    icon: React.ElementType;
    text: string;
    type?: Extract<Type, "primary" | "success" | "danger">;
}): JSX.Element {
    return (
        <div className="flex items-center gap-2">
            {<Icon color={TYPE_TO_COLOR[type]} />}
            <span className={classNames(TYPE_TO_STYLE[type], "text-sm")}>{text}</span>
        </div>
    );
}

import { ComponentProps, ElementType, ReactNode, useState } from "react";
import { ButtonProps, Dropdown, OptionsIcon } from "../../..";
import { DropdownCommon } from "../DropdownCommon";

export namespace Dropdowns {
    export function WithChevron({
        children,
        "data-id": dataId,
        ...props
    }: { children?: ReactNode } & ComponentProps<typeof DropdownCommon.Content.WithActions>): JSX.Element {
        const [isOpen, setIsOpen] = useState(false);
        return (
            <Dropdown onOpenChange={() => setIsOpen(prev => !prev)}>
                <DropdownCommon.Trigger.WithChevron isOpen={isOpen} children={children} data-id={dataId} />
                <DropdownCommon.Content.WithActions data-id={dataId} {...props} />
            </Dropdown>
        );
    }

    type WithButtonProps = ButtonProps & DropdownCommon.DropdownContentActionsProps;

    export function WithButton({ actions, "data-id": dataId, header, ...props }: WithButtonProps): JSX.Element {
        return (
            <Dropdown>
                <DropdownCommon.Trigger.Button data-id={dataId} {...props} />
                <DropdownCommon.Content.WithActions data-id={dataId} actions={actions} header={header} />
            </Dropdown>
        );
    }

    export function Options(props: Omit<WithButtonProps, "icon">): JSX.Element {
        return <WithButton {...props} icon={OptionsIcon} />;
    }

    export function Menu({
        icon,
        "data-id": dataId,
        sideOffset = 14,
        ...props
    }: { icon: ElementType } & Omit<ComponentProps<typeof DropdownCommon.Content.WithActions>, "title"> & { title: string }): JSX.Element {
        return (
            <Dropdown>
                <DropdownCommon.Trigger.MenuIcon data-id={dataId ? `${dataId}-actions` : "actions"} icon={icon} title={props.title} />
                <DropdownCommon.Content.WithActions data-id={dataId} sideOffset={sideOffset} {...props} />
            </Dropdown>
        );
    }
}

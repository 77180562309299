import { IconSvgProps } from "../../../../../common";

export default function ScopeCyberOutlinedSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7606 2.43678C12.8379 2.56538 14.7598 5.67295 19.1283 4.67382C19.6781 4.54785 20.2029 4.96581 20.2029 5.52885V12.6591C20.2029 17.8473 17.2546 20.5731 12.2284 21.9704C12.0742 22.0133 11.9112 22.0133 11.7571 21.9704C6.32928 20.4614 3.80127 17.5027 3.80127 12.6591V5.52885C3.80127 4.96546 4.32644 4.54814 4.87593 4.67382C9.28683 5.68266 11.2245 2.46935 11.2435 2.43678C11.5821 1.85794 12.4219 1.85771 12.7606 2.43678ZM11.992 20.8396C16.8953 19.4085 18.9923 16.9363 18.9923 12.6121V5.979C16.1699 6.30193 13.7196 5.3352 12.0021 3.50021C10.2861 5.33361 7.835 6.30212 5.01187 5.979V12.6121C5.01187 16.9399 7.10303 19.4122 11.992 20.8396Z"
            />
            <path d="M13.8303 11.5652C13.6311 11.5652 13.4695 11.4037 13.4695 11.2045V10.2453C13.4695 9.45011 12.8226 8.80317 12.0274 8.80317C11.2322 8.80317 10.5852 9.45011 10.5852 10.2453V11.2045C10.5852 11.4037 10.4237 11.5652 10.2245 11.5652C10.0253 11.5652 9.86377 11.4037 9.86377 11.2045V10.2453C9.86377 9.05231 10.8344 8.08173 12.0274 8.08173C13.2204 8.08173 14.191 9.05231 14.191 10.2453V11.2045C14.191 11.4037 14.0295 11.5652 13.8303 11.5652Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.95428 15.9234H14.0502C14.3511 15.9234 14.5951 15.6784 14.5951 15.3762V11.7276C14.5951 11.4254 14.3511 11.1804 14.0502 11.1804H9.95428C9.65339 11.1804 9.40942 11.4254 9.40942 11.7276V15.3762C9.40942 15.6784 9.65334 15.9234 9.95428 15.9234ZM12.325 13.6775L12.6885 14.5082C12.7195 14.5791 12.6666 14.658 12.5881 14.6581H11.4161C11.3375 14.6581 11.2846 14.5791 11.3156 14.5082L11.6792 13.6775C11.473 13.5656 11.3332 13.35 11.3332 13.1022C11.3332 12.7147 11.6748 12.4056 12.0783 12.4494C12.3846 12.4826 12.6328 12.7264 12.6667 13.0273C12.6981 13.306 12.5517 13.5544 12.325 13.6775Z"
            />
        </svg>
    );
}

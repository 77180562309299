import classNames from "classnames";
import { Button } from "../Button/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "../Icons";

export default function StepCard({
    element,
    onNext,
    onPrevious,
    disableRight = false,
    disableLeft = false,
    removeRight = false,
    removeLeft = false,
    heightClassName
}: {
    element: JSX.Element;
    onNext?: () => void;
    onPrevious?: () => void;
    disableRight?: boolean;
    disableLeft?: boolean;
    removeRight?: boolean;
    removeLeft?: boolean;
    heightClassName?: string;
}): JSX.Element {
    return (
        <div className={classNames(`flex flex-row items-center rounded-2xl border-0 bg-white p-4 shadow-lg ${heightClassName || "h-96"}`)}>
            {!removeLeft && <Button type="default" onClick={onPrevious} icon={ChevronLeftIcon} isLoading={false} isDisabled={disableLeft} />}
            <div className="flex grow flex-col items-center">{element}</div>
            {!removeRight && <Button type="default" onClick={onNext} icon={ChevronRightIcon} isLoading={false} isDisabled={disableRight} />}
        </div>
    );
}

import { IconSvgProps } from "../Icons";

export default function CheckCircleSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z" />
            <path d="M17.5662 8.07449C17.489 7.99808 17.3972 7.93743 17.296 7.89604C17.1949 7.85465 17.0864 7.83334 16.9768 7.83334C16.8672 7.83334 16.7587 7.85465 16.6575 7.89604C16.5564 7.93743 16.4645 7.99808 16.3874 8.07449L10.2028 14.1564L7.60449 11.5964C7.52436 11.5204 7.42977 11.4607 7.32613 11.4205C7.22248 11.3804 7.1118 11.3608 7.00041 11.3627C6.88902 11.3645 6.7791 11.388 6.67693 11.4316C6.57476 11.4752 6.48233 11.5381 6.40493 11.6168C6.32753 11.6955 6.26667 11.7884 6.22583 11.8902C6.18498 11.992 6.16495 12.1007 6.16687 12.2101C6.1688 12.3195 6.19265 12.4274 6.23706 12.5278C6.28146 12.6281 6.34556 12.7189 6.42569 12.7949L9.61343 15.9255C9.6906 16.0019 9.78241 16.0626 9.88357 16.104C9.98473 16.1454 10.0932 16.1667 10.2028 16.1667C10.3124 16.1667 10.4209 16.1454 10.5221 16.104C10.6232 16.0626 10.7151 16.0019 10.7922 15.9255L17.5662 9.27293C17.6504 9.19659 17.7177 9.10393 17.7637 9.0008C17.8097 8.89767 17.8334 8.7863 17.8334 8.67371C17.8334 8.56112 17.8097 8.44975 17.7637 8.34662C17.7177 8.24349 17.6504 8.15083 17.5662 8.07449V8.07449Z" />
        </svg>
    );
}

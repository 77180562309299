import { ReactNode } from "react";
import EditableField from "../EditableField";

export interface Header {
    title: string;
    subTitlePrefix: string;
    subTitleSuffix: string;
    headerRight?: ReactNode;
    titlePrefix?: ReactNode;
    onTitleUpdate?: (newValue: string) => void;
}

export default function DetailsLayoutPage({
    header,
    tabs,
    rightSection,
    highlight
}: {
    header: Header;
    tabs: ReactNode;
    rightSection: ReactNode;
    highlight?: ReactNode;
}): JSX.Element {
    const { title, titlePrefix, onTitleUpdate, subTitlePrefix, subTitleSuffix, headerRight } = header;

    return (
        <div className="rounded-lg border border-neutral-200 bg-white">
            <div className="flex-row justify-between border-b p-5 md:flex space-x-2">
                <div className="w-full">
                    <div className="text-2xl flex items-center w-full space-x-2">
                        {titlePrefix && <span>{titlePrefix}</span>}
                        {onTitleUpdate ? <EditableField value={title} onUpdate={onTitleUpdate} /> : title}
                    </div>
                    <div className="flex items-center space-x-1">
                        <span className="text-sm font-light">{subTitlePrefix}</span>
                        <span className="text-xs md:text-base">{subTitleSuffix}</span>
                    </div>
                </div>
                {headerRight && <div>{headerRight}</div>}
            </div>
            <div className="flex flex-col-reverse md:flex-row">
                <div className="basis-3/4 p-8">
                    {highlight}
                    {tabs}
                </div>
                <div className="basis-1/4 border-l">{rightSection}</div>
            </div>
        </div>
    );
}

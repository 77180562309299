import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { CheckIcon, CrossIcon, Input } from "../../design-system";

export default function EditableField({ value, onUpdate }: { value: string; onUpdate: (newValue: string) => void }) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [fieldValue, setFieldValue] = useState<string>(value);

    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    function updateField() {
        setIsEditing(false);
        onUpdate(fieldValue);
    }

    function onKeyUp(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            updateField();
        }
        if (e.key === "Escape") {
            setIsEditing(false);
        }
    }

    function editField() {
        setIsEditing(true);
        setFieldValue(value);
    }

    function handleClickOutside(event: MouseEvent) {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsEditing(false);
        }
    }

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isEditing]);

    return isEditing ? (
        <div ref={containerRef} className="relative flex w-full flex-col">
            <Input.Text forwardedRef={inputRef} onKeyUp={onKeyUp} value={fieldValue} onChange={e => setFieldValue(e.target.value)} />
            <div className="absolute right-0 -bottom-4 flex">
                <CrossIcon onClick={() => setIsEditing(false)} />
                <CheckIcon onClick={updateField} />
            </div>
        </div>
    ) : (
        <div ref={containerRef} className="w-full rounded-md transition delay-150 hover:cursor-pointer hover:bg-grey-extra-light" onClick={editField}>
            {value}
        </div>
    );
}

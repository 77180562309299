import { IconSvgProps } from "../Icons";

function MastercardSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 100 64" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 7C0 3.13401 3.13401 0 7 0H93C96.866 0 100 3.13401 100 7V56.1443C100 60.0103 96.866 63.1443 93 63.1443H7C3.13401 63.1443 0 60.0103 0 56.1443V7Z"
                fill="black"
            />
            <circle cx="61.4181" cy="31.1822" r="17.1822" fill="#F79E1B" />
            <circle cx="39.1823" cy="31.1822" r="17.1822" fill="#EB001B" />
            <mask id="mask0_1_61" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="22" y="14" width="35" height="35">
                <circle cx="39.1823" cy="31.1822" r="17.1822" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1_61)">
                <circle cx="61.4181" cy="31.1822" r="17.1822" fill="#FF5F00" />
            </g>
        </svg>
    );
}

export default MastercardSvg;

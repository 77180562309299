import { cpp } from "@codemirror/lang-cpp";
import { css } from "@codemirror/lang-css";
import { go } from "@codemirror/lang-go";
import { html } from "@codemirror/lang-html";
import { java } from "@codemirror/lang-java";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { liquid } from "@codemirror/lang-liquid";
import { markdown } from "@codemirror/lang-markdown";
import { php } from "@codemirror/lang-php";
import { python } from "@codemirror/lang-python";
import { rust } from "@codemirror/lang-rust";
import { sass } from "@codemirror/lang-sass";
import { vue } from "@codemirror/lang-vue";
import { xml } from "@codemirror/lang-xml";
import { yaml } from "@codemirror/lang-yaml";
import { wgsl } from "@iizukak/codemirror-lang-wgsl";
import { nix } from "@replit/codemirror-lang-nix";
import { solidity } from "@replit/codemirror-lang-solidity";
import { svelte } from "@replit/codemirror-lang-svelte";
import { elixir } from "codemirror-lang-elixir";
import { golfScript } from "codemirror-lang-golfscript";
import { hcl } from "codemirror-lang-hcl";
import { j } from "codemirror-lang-j";
import { janet } from "codemirror-lang-janet";
import { sparql } from "codemirror-lang-sparql";

export const LANGUAGES_SUPPORT = {
    typescript: javascript,
    javascript,
    jsx: javascript,
    tsx: javascript,
    css,
    cpp,
    go,
    html,
    java,
    json,
    liquid,
    markdown,
    php,
    python,
    rust,
    sass,
    vue,
    xml,
    yaml,
    elixir,
    golfScript,
    hcl,
    j,
    janet,
    nix,
    solidity,
    sparql,
    svelte,
    wgsl
} as const;

import classnames from "classnames";
import Switch from "../Input/Switch";

export type ToggleButtonProps = {
    text: string;
    isChecked: boolean;
    onClick: () => void;
    size?: "small" | "default";
};

export default function ToggleButton({
    text,
    isChecked,
    size,
    onClick
}: {
    text: string;
    isChecked: boolean;
    onClick: () => void;
    size?: "small" | "default";
}): JSX.Element {
    return (
        <div className="flex cursor-pointer items-center space-x-2 rounded border py-1 px-2" onClick={onClick}>
            <div className={classnames("font-semibold uppercase text-primary", { "text-xs": size === "small", "text-sm": !size || size === "default" })}>
                {text}
            </div>
            <Switch isChecked={isChecked} onClick={() => {}} />
        </div>
    );
}

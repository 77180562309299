import { Account, AccountApiKey, App } from "@vaultinum/vaultinum-api";
import { Alert, getAccountAppSettings, Input, Modal, useAuthContext, useLang } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { AccountLang } from "../../../../lang/AccountLang";

export function AddApiKeyModal({
    isOpen,
    doClose,
    onConfirm
}: {
    isOpen: boolean;
    doClose: () => void;
    onConfirm: (data: Pick<AccountApiKey, "name" | "environment">) => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    const { selectedAccount } = useAuthContext();
    const [apiKeyName, setApiKeyName] = useState("");
    const [appSettings, setAppSettings] = useState<Account.AppSettings[]>([]);

    const tsSettings = appSettings.find(setting => setting.code === App.Code.TIMESTAMPING);

    // TODO next: Target is to fully remove the "env" notion of the ApiKey. Waiting for backend to remove checks on env before removing this part. 
    const apiKeyEnv = process.env.REACT_APP_APIKEY_ENV === "sandbox" ? AccountApiKey.Environment.SANDBOX : AccountApiKey.Environment.PRODUCTION;

    useEffect(() => {
        if (selectedAccount?.id) {
            getAccountAppSettings(selectedAccount.id, setAppSettings);
        }
    }, [selectedAccount]);

    function onClose() {
        setApiKeyName("");
        doClose();
    }

    return (
        <Modal
            title={lang.accountSettings.apiKeysView.newApiKeyTitle}
            isOpen={isOpen}
            onConfirm={() =>
                onConfirm({
                    name: apiKeyName,
                    environment: apiKeyEnv
                })
            }
            isDisabled={!apiKeyName.length}
            okText={lang.accountSettings.apiKeysView.generate}
            onClose={onClose}
            lang={lang}
        >
            <Input.Text
                data-id="apikey-name-input"
                placeholder={lang.accountSettings.apiKeysView.nameFieldPlaceholder}
                onChange={e => setApiKeyName(e.target.value)}
                value={apiKeyName}
                required
                className="my-1"
            />
            {tsSettings?.plan === App.Timestamping.Plan.NONE && <Alert.Warning message={lang.accountSettings.billingView.noPlanOrSubscription} />}
        </Modal>
    );
}

import { LogoSvgProps } from "../Logos";

export default function DepositSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 206 232" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M163.262 197.408H37.3924C31.9178 197.408 27.4794 192.97 27.4794 187.495V10.2504C27.4798 4.77534 31.9178 0.337341 37.3928 0.337341H137.645L173.176 40.4962V187.495C173.175 192.97 168.737 197.408 163.262 197.408Z"
                fill="#F2F3F4"
            />
            <path
                d="M148.949 61.6604H60.8578C58.9299 61.6604 57.3669 60.0975 57.3669 58.1696C57.3669 56.2417 58.9299 54.6788 60.8578 54.6788H148.95C150.878 54.6788 152.441 56.2417 152.441 58.1696C152.44 60.0979 150.877 61.6604 148.949 61.6604Z"
                fill="#72ACE1"
            />
            <path
                d="M148.949 85.5474H79.4754C77.5476 85.5474 75.9846 83.9845 75.9846 82.0566C75.9846 80.1288 77.5476 78.5658 79.4754 78.5658H148.95C150.878 78.5658 152.441 80.1288 152.441 82.0566C152.44 83.9845 150.877 85.5474 148.949 85.5474Z"
                fill="#72ACE1"
            />
            <path
                d="M164.233 26.9249V187.495C164.233 192.969 159.795 197.408 154.32 197.408H167.891C173.365 197.408 177.804 192.969 177.804 187.495V40.4963L164.233 26.9249Z"
                fill="#F2F3F4"
            />
            <path d="M177.804 40.4963H147.556C142.082 40.4963 137.645 36.0587 137.645 30.5846V0.337341L177.804 40.4963Z" fill="#72ACE1" />
            <path
                d="M10.6434 231.663H194.48C200.304 231.663 205.024 226.942 205.024 221.119V116.487C205.024 110.664 200.304 105.943 194.48 105.943H69.3717L63.3423 87.8863C61.9055 83.5832 57.8775 80.6821 53.3413 80.6821H10.6434C4.82026 80.6821 0.0997314 85.4027 0.0997314 91.2258V221.119C0.100197 226.942 4.82072 231.663 10.6434 231.663Z"
                fill="#294966"
            />
            <path
                d="M122.351 162.914C120.423 162.914 118.86 161.351 118.86 159.424V150.142C118.86 142.446 112.599 136.185 104.904 136.185C97.2079 136.185 90.9472 142.446 90.9472 150.142V159.424C90.9472 161.351 89.3843 162.914 87.4564 162.914C85.5285 162.914 83.9656 161.351 83.9656 159.424V150.142C83.9656 138.596 93.3582 129.204 104.904 129.204C116.449 129.204 125.841 138.596 125.841 150.142V159.424C125.841 161.351 124.279 162.914 122.351 162.914Z"
                fill="#72ACE1"
            />
            <path
                d="M124.364 204.912H84.9179C82.0196 204.912 79.6705 202.562 79.6705 199.664V164.671C79.6705 161.773 82.0201 159.424 84.9179 159.424H124.364C127.263 159.424 129.612 161.773 129.612 164.671V199.664C129.612 202.562 127.263 204.912 124.364 204.912Z"
                fill="#72ACE1"
            />
            <path
                d="M111.491 191.513L108.003 183.397C110.178 182.195 111.584 179.768 111.282 177.045C110.957 174.105 108.575 171.723 105.635 171.398C101.762 170.971 98.4847 173.991 98.4847 177.777C98.4847 180.198 99.8256 182.304 101.805 183.397L98.3157 191.513C98.0178 192.206 98.5261 192.978 99.2801 192.978H110.528C111.282 192.977 111.789 192.206 111.491 191.513Z"
                fill="#294966"
            />
        </svg>
    );
}

import { Claims } from "@vaultinum/vaultinum-api";

export const VAULTINUM_DOMAIN_ID = "vaultinum";

export function isDomainOwner(userClaims: Claims, domainId?: string): boolean {
    if (!userClaims) {
        return false;
    }
    return !!userClaims.domains?.[domainId ?? VAULTINUM_DOMAIN_ID]?.isOwner;
}

export function getAccountIdFromClaims(claims: Claims, domainId?: string): string | undefined {
    return claims.domains?.[domainId ?? VAULTINUM_DOMAIN_ID]?.accountId;
}

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useDebounce from "./useDebounce";

export default function usePathTracker(
    onPathChanged: (path: string) => void,
    options?: {
        delay?: number;
        mapPath?: (path: string) => string;
    }
): void {
    const location = useLocation();
    const [path, setPath] = useState<string>("");
    const debouncedPath = useDebounce<string>(path, options?.delay || 100);

    useEffect(() => onPathChanged(debouncedPath), [debouncedPath]);
    useEffect(() => {
        let newPath = `${location.pathname}${location.search}`;
        if (options?.mapPath) {
            newPath = options.mapPath(newPath);
        }
        if (newPath && newPath !== path) {
            setPath(newPath);
        }
    }, [location]);
}

import { Account } from "@vaultinum/vaultinum-api";
import { Alert, CardSelector, CustomCardProps, GithubIcon, Modal, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { AccountLang } from "../../../../lang/AccountLang";

const GIT_PROVIDERS: CustomCardProps<Account.GitConnectionProvider>[] = [
    {
        id: Account.GitConnectionProvider.GITHUB,
        label: "GitHub",
        icon: GithubIcon,
        isSelected: true
    }
];

function getConnectionLink(key?: Account.GitConnectionProvider) {
    switch (key) {
        case Account.GitConnectionProvider.GITHUB:
            return process.env.REACT_APP_GITHUB_CONNECTION;
        case Account.GitConnectionProvider.GITLAB: // to define
            return process.env.REACT_APP_GITLAB_CONNECTION;
        default:
            return "";
    }
}

export default function AddGitConnectionModal({ isOpen, doClose }: { isOpen: boolean; doClose: () => void }): JSX.Element {
    const lang = useLang<AccountLang>();
    const [selectedItem, setSelectedItem] = useState<string>(Account.GitConnectionProvider.GITHUB);
    const selectedGitProvider = GIT_PROVIDERS.find(provider => provider.id === selectedItem);

    function handleGitRedirection() {
        const link = getConnectionLink(selectedGitProvider?.id);
        window.open(link, "_blank");
    }

    return (
        <Modal title={lang.accountSettings.gitView.newConnection} isOpen={isOpen} onConfirm={handleGitRedirection} onClose={doClose} lang={lang}>
            <div className="space-y-2">
                <p>{lang.accountSettings.gitView.selectConnection}</p>
                <div className="flex justify-evenly">
                    <CardSelector cards={GIT_PROVIDERS} onCardSelected={setSelectedItem} />
                </div>
                {selectedGitProvider && <Alert.Info message={lang.accountSettings.gitView.installationConfirmationMessage(selectedGitProvider.label || "")} />}
            </div>
        </Modal>
    );
}

import { WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { AppInfo, AppsInfos, DepositLogo, EscrowLogo, FullAuditLogo, PersonLogo, SHARED_URL, TimestampingLogo } from "../../../common";

export enum AppCode {
    TIMESTAMPING = "timestamping",
    FULL_AUDIT = "full-audit",
    DEPOSIT = "deposit",
    ESCROW = "escrow",
    ACCOUNT = "account"
}

function getAppCode(): AppCode {
    const baseUrl = window.location.pathname;
    if (baseUrl.startsWith(SHARED_URL.app.timestamping)) {
        return AppCode.TIMESTAMPING;
    }
    if (baseUrl.startsWith(SHARED_URL.app.deposit)) {
        return AppCode.DEPOSIT;
    }
    if (baseUrl.startsWith(SHARED_URL.app.escrow)) {
        return AppCode.ESCROW;
    }
    if (baseUrl.startsWith(SHARED_URL.app.kys.index)) {
        return AppCode.FULL_AUDIT;
    }
    return AppCode.ACCOUNT;
}

export const APPS_INFOS: AppsInfos<AppCode> = {
    [AppCode.ACCOUNT]: {
        appCode: AppCode.ACCOUNT,
        baseUrl: SHARED_URL.app.index,
        logo: PersonLogo
    },
    [AppCode.DEPOSIT]: {
        appCode: AppCode.DEPOSIT,
        baseUrl: SHARED_URL.app.deposit,
        logo: DepositLogo
    },
    [AppCode.ESCROW]: {
        appCode: AppCode.ESCROW,
        baseUrl: SHARED_URL.app.escrow,
        logo: EscrowLogo
    },
    [AppCode.FULL_AUDIT]: {
        appCode: AppCode.FULL_AUDIT,
        baseUrl: SHARED_URL.app.kys.fullAudit,
        logo: FullAuditLogo
    },
    [AppCode.TIMESTAMPING]: {
        appCode: AppCode.TIMESTAMPING,
        baseUrl: SHARED_URL.app.timestamping,
        logo: TimestampingLogo
    }
};

export const APP_INFO: AppInfo<AppCode> = APPS_INFOS[getAppCode()];

export function mapAppsToDefaultConfiguration(apps: { [AppCode.FULL_AUDIT]?: boolean; [AppCode.DEPOSIT]?: boolean }): WhiteLabelDomain["configuration"] {
    const configuration: WhiteLabelDomain["configuration"] = {};
    if (apps[AppCode.FULL_AUDIT]) {
        configuration[AppCode.FULL_AUDIT] = {
            isEnabled: true,
            isCodeScanAllowed: true
        };
    }
    if (apps[AppCode.DEPOSIT]) {
        configuration[AppCode.DEPOSIT] = {
            isEnabled: true
        };
    }
    return configuration;
}

export const BRAND_NAME = "vaultinum";

import { Account, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { Alert, Buttons, Controller, Form, Input, Select, UseFormReturn } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { useIndustryList } from "../../hooks";
import { COMPANY_SIZES } from "../../services";
import AddressSubForm from "./AddressSubForm";
import NationalitySelect from "./NationalitySelect";

export default function CompanyInformationForm({
    disableFields,
    isAdminOrRepresentative,
    langCode,
    form,
    onSubmit,
    working,
    displayButton,
    isWhiteLabelOwner,
    allFieldsRequired
}: {
    disableFields: boolean;
    isAdminOrRepresentative: boolean;
    langCode: SupportedLanguageCode;
    form: UseFormReturn<Account>;
    onSubmit: (values: Account) => void;
    working?: boolean;
    displayButton?: boolean;
    isWhiteLabelOwner?: boolean;
    allFieldsRequired?: boolean;
}) {
    const industryList = useIndustryList();
    const lang = useLang<CommonLang>();

    return (
        <>
            {!isWhiteLabelOwner && !isAdminOrRepresentative && <Alert.Info message={lang.accountInformation.roleAdminIsRequired} />}
            <Form onSubmit={form.handleSubmit(onSubmit)} data-id="update-account-information-form" className="bg-white p-4">
                <Controller
                    name="companyName"
                    control={form.control}
                    data-id="company-name-control"
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            data-id="company-name-input"
                            label={lang.accountInformation.form.companyName}
                            placeholder={lang.accountInformation.form.companyName}
                            disabled={disableFields}
                            errorMessage={form.formState.errors.companyName?.message}
                            required
                        />
                    )}
                />
                <Controller
                    name="companyIndustry"
                    control={form.control}
                    data-id="company-industry-control"
                    render={({ field }) => (
                        <Select.Basic
                            {...field}
                            label={lang.accountInformation.form.companyIndustry}
                            options={industryList}
                            isDisabled={disableFields}
                            data-id="company-industry-input"
                            required
                            errorMessage={form.formState.errors.companyIndustry?.message}
                        />
                    )}
                />
                <div className="flex items-center gap-4">
                    <Controller
                        name="companySize"
                        control={form.control}
                        data-id="company-size-control"
                        render={({ field }) => (
                            <Select.Basic
                                {...field}
                                label={lang.accountInformation.form.companySize}
                                options={COMPANY_SIZES.map(size => ({ value: size, label: size }))}
                                placeholder={lang.accountInformation.form.companySize}
                                isDisabled={disableFields}
                                data-id="company-size-input"
                                className="w-1/2"
                                required
                                errorMessage={form.formState.errors.companySize?.message}
                            />
                        )}
                    />
                    <Controller
                        name="companyRegistrationNumber"
                        control={form.control}
                        data-id="company-registration-number-control"
                        render={({ field }) => (
                            <Input.Text
                                {...field}
                                label={lang.accountInformation.form.companyRegistrationNumber}
                                disabled={disableFields}
                                data-id="company-registration-number-input"
                                className="w-full"
                                required={allFieldsRequired}
                            />
                        )}
                    />
                </div>
                <Controller
                    name="companyNationality"
                    control={form.control}
                    data-id="company-nationality-control"
                    render={({ field }) => (
                        <NationalitySelect
                            {...field}
                            label={lang.accountInformation.form.companyNationality}
                            data-id="company-nationality-input"
                            disabled={disableFields}
                            langCode={langCode}
                            isRequired={allFieldsRequired}
                        />
                    )}
                />
                <h2 className="my-8">{lang.accountInformation.form.address.title}</h2>
                <AddressSubForm
                    langCode={langCode}
                    disableFields={disableFields}
                    control={form.control}
                    inputNamePath="companyAddress"
                    allFieldsRequired={allFieldsRequired}
                />
                {displayButton && (
                    <div className="flex w-full justify-end">
                        <Buttons.Save htmlType="submit" isLoading={!!working} isDisabled={!form.formState.isValid} />
                    </div>
                )}
            </Form>
        </>
    );
}

import { IconSvgProps } from "../Icons";

function FolderSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.04688 2.8125C2.86039 2.8125 2.68155 2.88658 2.54969 3.01844C2.41783 3.1503 2.34375 3.32914 2.34375 3.51562V5.625C2.34375 5.88388 2.13388 6.09375 1.875 6.09375C1.61612 6.09375 1.40625 5.88388 1.40625 5.625V3.51562C1.40625 3.0805 1.5791 2.6632 1.88678 2.35553C2.19445 2.04785 2.61175 1.875 3.04688 1.875H5.27021C5.59414 1.87501 5.91082 1.9709 6.18033 2.1506C6.18034 2.15061 6.18035 2.15062 6.18036 2.15063L6.99599 2.69437C7.11149 2.77139 7.24721 2.81249 7.38603 2.8125C7.38604 2.8125 7.38603 2.8125 7.38603 2.8125H11.9531C12.3882 2.8125 12.8055 2.98535 13.1132 3.29303C13.4209 3.6007 13.5938 4.018 13.5938 4.45312V5.625C13.5938 5.88388 13.3839 6.09375 13.125 6.09375C12.8661 6.09375 12.6562 5.88388 12.6562 5.625V4.45312C12.6562 4.26664 12.5822 4.0878 12.4503 3.95594C12.3184 3.82408 12.1396 3.75 11.9531 3.75H7.38603C7.06211 3.74999 6.74543 3.6541 6.47592 3.4744C6.47591 3.47439 6.4759 3.47438 6.47589 3.47438L5.66026 2.93063C5.54476 2.85361 5.40904 2.81251 5.27021 2.8125C5.27021 2.8125 5.27022 2.8125 5.27021 2.8125H3.04688Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.87503 6.09375C1.81032 6.09375 1.74631 6.10714 1.68703 6.13309C1.62776 6.15903 1.57449 6.19697 1.5306 6.24451C1.4867 6.29206 1.45313 6.34817 1.43198 6.40933C1.41122 6.46938 1.40289 6.53302 1.40748 6.59638L1.88219 11.4386C1.88368 11.4538 1.88443 11.4691 1.88443 11.4844C1.88443 11.6706 1.95834 11.8493 2.08993 11.9811C2.22143 12.1128 2.39978 12.1871 2.58589 12.1875H12.4142C12.6003 12.1871 12.7787 12.1128 12.9102 11.9811C13.0418 11.8493 13.1157 11.6706 13.1157 11.4844C13.1157 11.4691 13.1164 11.4538 13.1179 11.4386L13.5926 6.59638C13.5972 6.53314 13.5889 6.46963 13.5682 6.40967C13.5472 6.34861 13.5137 6.29257 13.47 6.24505C13.4263 6.19753 13.3732 6.15956 13.3141 6.13352C13.2552 6.10757 13.1915 6.09403 13.1271 6.09375H1.87503ZM14.0596 6.63721L14.5261 6.68294L14.053 11.5092C14.0466 11.9349 13.8749 12.3417 13.5737 12.6435C13.2666 12.9511 12.85 13.1242 12.4154 13.125L12.4146 13.125H2.5855L2.58468 13.125C2.15006 13.1242 1.7335 12.9511 1.42644 12.6435C1.12524 12.3417 0.953551 11.9349 0.947115 11.5092L0.473968 6.68294C0.473694 6.68015 0.473446 6.67736 0.473222 6.67456C0.457753 6.48106 0.482515 6.28645 0.545948 6.10298C0.609381 5.91951 0.710112 5.75117 0.841798 5.60854C0.973484 5.46592 1.13327 5.3521 1.3111 5.27426C1.48893 5.19642 1.68094 5.15624 1.87505 5.15625C1.87506 5.15625 1.87504 5.15625 1.87505 5.15625H13.128L13.1294 5.15625C13.3232 5.15685 13.5147 5.19747 13.692 5.27558C13.8693 5.35369 14.0286 5.4676 14.1598 5.61016C14.291 5.75272 14.3913 5.92085 14.4545 6.10402C14.5177 6.28718 14.5423 6.48143 14.5269 6.67456L14.0596 6.63721Z"
            />
        </svg>
    );
}

export default FolderSvg;

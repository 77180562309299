import { IconSvgProps } from "../Icons";

function GithubSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9884 0.356935C5.81228 0.354033 0 6.16341 0 13.3337C0 19.0038 3.63594 23.8237 8.69955 25.5938C9.38147 25.765 9.27701 25.2804 9.27701 24.9496V22.7007C5.33929 23.1621 5.17969 20.5563 4.91562 20.121C4.3817 19.2098 3.11942 18.9777 3.49665 18.5424C4.3933 18.081 5.30737 18.6585 6.36652 20.2226C7.13259 21.3572 8.62701 21.1656 9.38437 20.977C9.54978 20.2951 9.90379 19.6857 10.3913 19.2127C6.31138 18.4815 4.61094 15.9918 4.61094 13.0319C4.61094 11.5955 5.08393 10.2752 6.0125 9.21028C5.42054 7.4547 6.06763 5.95158 6.15469 5.72814C7.84062 5.57725 9.5933 6.93528 9.72969 7.04265C10.6873 6.78439 11.7812 6.64801 13.0058 6.64801C14.2362 6.64801 15.333 6.79019 16.2993 7.05135C16.6272 6.8018 18.2522 5.63528 19.8192 5.77747C19.9033 6.00091 20.5359 7.46921 19.9788 9.20158C20.919 10.2694 21.3978 11.6014 21.3978 13.0406C21.3978 16.0063 19.6857 18.4989 15.5942 19.2185C15.9446 19.5632 16.2229 19.9742 16.4127 20.4276C16.6026 20.881 16.7001 21.3676 16.6998 21.8592V25.1237C16.723 25.3848 16.6998 25.6431 17.135 25.6431C22.2741 23.9107 25.9739 19.056 25.9739 13.3366C25.9739 6.16341 20.1587 0.356935 12.9884 0.356935Z" />
        </svg>
    );
}

export default GithubSvg;

import classNames from "classnames";
import { Accept, useDropzone } from "react-dropzone";
import { Button, Column, DeleteIcon, InfoCircleIcon, Table, UploadIcon, message } from "../../design-system";
import { CommonLang, useLang } from "../../lang";

const DEFAULT_FILE_TYPE = ".pdf";
const DEFAULT_MIME_TYPE = "application/pdf";

export type UploadedFile = {
    name: string;
    size: number;
};

function getFileTableColumns(lang: CommonLang, onRemove: (file?: UploadedFile) => void, preview?: JSX.Element | null): Column<UploadedFile>[] {
    return [
        {
            header: lang.uploadFile.file,
            enableSorting: false,
            enableColumnFilter: false,
            accessorKey: "name"
        },
        {
            header: lang.uploadFile.size,
            accessorKey: "size",
            enableSorting: false,
            enableColumnFilter: false,
            size: 100,
            cell: cell => lang.uploadFile.formatSize(cell.getValue<number>())
        },
        ...(preview
            ? [
                  {
                      header: lang.uploadFile.preview,
                      enableSorting: false,
                      enableColumnFilter: false,
                      size: 100,
                      accessorKey: "preview",
                      cell: () => preview
                  }
              ]
            : []),
        {
            header: lang.uploadFile.actions,
            enableSorting: false,
            enableColumnFilter: false,
            size: 100,
            cell: cell => {
                const file = cell.row.original;
                return <Button type="default" size="sm" onClick={() => onRemove(file)} children={<DeleteIcon />} isLoading={false} />;
            }
        }
    ];
}

export default function UploadForm({
    files,
    onDrop,
    onRemove,
    disabled,
    fileTypes = [DEFAULT_FILE_TYPE],
    accept = { [DEFAULT_MIME_TYPE]: [DEFAULT_FILE_TYPE] },
    preview,
    isMultiple,
    information
}: {
    files: File[];
    onDrop: (files: File[]) => void;
    onRemove: (file?: UploadedFile) => void;
    disabled?: boolean;
    fileTypes?: string[];
    accept?: Accept;
    preview?: JSX.Element | null;
    isMultiple?: boolean;
    information?: string;
}): JSX.Element {
    const lang = useLang();
    const { getInputProps, getRootProps, open } = useDropzone({
        accept,
        noClick: true,
        noKeyboard: true,
        multiple: isMultiple,
        onDrop,
        onDropRejected: (fileRejections: { file: { name: string }; errors: { code: string }[] }[]) => {
            const { file, errors } = fileRejections[0];
            if (errors.some(({ code }) => code === "file-invalid-type")) {
                return message.error(lang.uploadFile.invalidFileType(file.name, fileTypes.join(",")));
            }
            if (errors.some(({ code }) => code === "too-many-files")) {
                return message.error(lang.uploadFile.tooManyFiles);
            }
            return undefined;
        },
        disabled
    });

    return (
        <div className="flex flex-col gap-4">
            <div
                {...getRootProps()}
                onClick={open}
                className={classNames("flex flex-col items-center justify-center rounded border-2 border-dashed p-4 text-center text-grey-primary transition", {
                    "cursor-copy delay-150 ease-in": !disabled,
                    "cursor-not-allowed bg-neutral-100": disabled
                })}
            >
                <input {...getInputProps()} />
                <div className="flex flex-col justify-center items-center gap-2">
                    <UploadIcon size="md" color="grey" shade="dark" />
                    {lang.uploadFile.description(fileTypes.join(", "))}
                    {information && (
                        <div className="flex gap-1 items-center text-grey-dark font-medium text-xs">
                            <InfoCircleIcon color="grey" shade="dark" size="xs" />
                            <p>{information}</p>
                        </div>
                    )}
                </div>
            </div>
            {!!files.length && <Table<UploadedFile> data={files} columns={getFileTableColumns(lang, onRemove, preview)} />}
        </div>
    );
}

import classNames from "classnames";
import { useState } from "react";
import { CustomCard, CustomCardProps } from "../Card/CustomCard";

export function CardSelector<T extends string = string>({
    cards,
    className,
    onCardSelected,
    disabled
}: {
    cards: CustomCardProps<T>[];
    className?: string;
    onCardSelected?: (id: T) => void;
    disabled?: boolean;
}): JSX.Element {
    const [cardsList, setCardLists] = useState<CustomCardProps<T>[]>(cards);

    const selectCard = (id: T) => {
        setCardLists(prevCardsList =>
            prevCardsList.map(element => ({
                ...element,
                isSelected: element.id === id
            }))
        );

        onCardSelected?.(id);
    };

    return (
        <div className={classNames("flex flex-col justify-between gap-2 md:flex-row md:gap-4", className)}>
            {(cardsList || []).map(card => (
                <CustomCard key={card.id} onClick={selectCard} disabled={disabled} {...card} />
            ))}
        </div>
    );
}

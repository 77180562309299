import { Button, ButtonProps, ConfirmModalProps, InfoModalProps, ModalHelper, ModalProps } from "../..";

function RenderModalWithButton<T extends ModalProps>(Modal: (props: Exclude<T, "lang">) => void, props: Exclude<T, "lang">, buttonProps: ButtonProps) {
    function openModal() {
        return Modal(props);
    }
    return <Button {...buttonProps} onClick={openModal} />;
}

export function ModalButton({ buttonProps, ...props }: ModalProps & { buttonProps: ButtonProps }): JSX.Element {
    return RenderModalWithButton(ModalHelper.Modal, props, buttonProps);
}

export namespace ModalButton {
    export function Info({ buttonProps, ...props }: InfoModalProps & { buttonProps: ButtonProps }): JSX.Element {
        return RenderModalWithButton(ModalHelper.Info, props, buttonProps);
    }
    export function Confirm({ buttonProps, ...props }: ConfirmModalProps & { buttonProps: ButtonProps }): JSX.Element {
        return RenderModalWithButton(ModalHelper.Confirm, props, buttonProps);
    }
}

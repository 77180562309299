import { Account } from "@vaultinum/vaultinum-api";
import { Tag, useLang } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../../../../lang/AccountLang";

function ProductTag({ product, number }: { product: string; number: number }): JSX.Element {
    return <Tag message={`${number} ${product.toLowerCase()}`} />;
}

function isVaultinumApp(lang: AccountLang): lang is AccountLang & { apps: Record<"escrow" | "full-audit", { name: string }> } {
    return "escrow" in lang.apps && "full-audit" in lang.apps;
}

export function SharedProducts({ partner }: { partner: Account.Partner }): JSX.Element {
    const lang = useLang<AccountLang>();
    if (!isVaultinumApp(lang)) {
        return <></>;
    }
    return (
        <div className="flex gap-2">
            {!!partner.escrowIds.length && <ProductTag product={lang.apps.escrow.name} number={partner.escrowIds.length} />}
            {!!partner.fullAuditIds.length && <ProductTag product={lang.apps["full-audit"].name} number={partner.fullAuditIds.length} />}
        </div>
    );
}

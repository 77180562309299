import { ReactNode } from "react";

export default function ScrollableDiv({
    linesToShow,
    isPDF,
    className,
    children
}: {
    linesToShow: number;
    children: ReactNode;
    isPDF?: boolean;
    className?: string;
}) {
    const lines = isPDF ? Math.floor(linesToShow) : linesToShow;
    const fontSize = isPDF ? 21 : 14;
    const lineHeight = 1.45;
    const lineBoxHeight = lineHeight * fontSize;
    const maxHeight = lines * lineBoxHeight;
    return (
        <div
            className={className}
            style={{
                maxHeight,
                overflow: "auto",
                ...(isPDF && {
                    display: "-webkit-box",
                    WebkitLineClamp: lines,
                    WebkitBoxOrient: "vertical"
                })
            }}
        >
            {children}
        </div>
    );
}

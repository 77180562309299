import { Button, ButtonProps } from "../../design-system";
import EmptyCTABackground from "../../design-system/images/EmptyCTABackground.svg";

export default function EmptyCTA({
    title,
    buttonProps,
    containerDataId,
    content
}: {
    title: string;
    buttonProps?: ButtonProps;
    containerDataId?: string;
    content?: string;
}): JSX.Element {
    return (
        <div
            className="flex mx-auto h-full flex-col items-center justify-center space-y-6 rounded-lg bg-white bg-cover p-12 text-center text-dark"
            data-id={containerDataId}
            style={{ backgroundImage: `url(${EmptyCTABackground})` }}
        >
            <h3 className="font-bold">{title}</h3>
            {content && <p className="md:max-w-1/2">{content}</p>}
            {buttonProps && <Button data-id="btn-empty-cta" {...buttonProps} />}
        </div>
    );
}

import { LogoSvgProps } from "../Logos";

export default function OnlineAssessmentSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.3 0H476.7C496.2 0 512 15.8 512 35.3V476.7C512 496.2 496.2 512 476.7 512H35.3C15.8 512 0 496.2 0 476.7V35.3C0 15.8 15.8 0 35.3 0Z"
                fill="#ECEEF2"
            />
            <path
                d="M512 35.3V105.9H0V35.3C0 25.9 3.7 17 10.3 10.3C16.9 3.6 25.9 0 35.3 0H476.7C486.1 0 495 3.7 501.7 10.3C508.3 17 512 25.9 512 35.3Z"
                fill="#325574"
            />
            <path
                d="M377.7 70.7C387.475 70.7 395.4 62.7755 395.4 53C395.4 43.2246 387.475 35.3 377.7 35.3C367.925 35.3 360 43.2246 360 53C360 62.7755 367.925 70.7 377.7 70.7Z"
                fill="#ECEEF2"
            />
            <path
                d="M448.3 70.7C458.075 70.7 466 62.7755 466 53C466 43.2246 458.075 35.3 448.3 35.3C438.524 35.3 430.6 43.2246 430.6 53C430.6 62.7755 438.524 70.7 448.3 70.7Z"
                fill="#ECEEF2"
            />
            <path
                d="M70.5997 185.4H141.2C146.1 185.4 150 189.4 150 194.2V264.8C150 269.7 146 273.6 141.2 273.6H70.5997C65.6997 273.6 61.7997 269.6 61.7997 264.8V194.2C61.6997 189.3 65.6997 185.4 70.5997 185.4Z"
                fill="#E5E5E5"
            />
            <path
                d="M70.5997 344.3H141.2C146.1 344.3 150 348.3 150 353.1V423.7C150 428.6 146 432.5 141.2 432.5H70.5997C65.6997 432.5 61.7997 428.5 61.7997 423.7V353.1C61.6997 348.2 65.6997 344.3 70.5997 344.3Z"
                fill="#E5E5E5"
            />
            <path
                d="M298.7 203H228C223.1 203 219.2 199 219.2 194.2C219.2 189.4 223.2 185.4 228 185.4H298.6C303.5 185.4 307.4 189.4 307.4 194.2C307.4 199 303.5 203 298.7 203Z"
                fill="#4A7398"
            />
            <path
                d="M439.9 203H334C329.1 203 325.2 199 325.2 194.2C325.2 189.4 329.2 185.4 334 185.4H439.9C444.8 185.4 448.7 189.4 448.7 194.2C448.7 199 444.8 203 439.9 203Z"
                fill="#4A7398"
            />
            <path
                d="M439.9 238.3H228C223.1 238.3 219.2 234.3 219.2 229.5C219.2 224.7 223.2 220.7 228 220.7H439.9C444.8 220.7 448.7 224.7 448.7 229.5C448.7 234.3 444.8 238.3 439.9 238.3Z"
                fill="#4A7398"
            />
            <path
                d="M334 273.7H228C223.1 273.7 219.2 269.7 219.2 264.9C219.2 260.1 223.2 256.1 228 256.1H334C338.9 256.1 342.8 260.1 342.8 264.9C342.8 269.7 338.8 273.7 334 273.7Z"
                fill="#4A7398"
            />
            <path
                d="M439.9 273.7H369.3C364.4 273.7 360.5 269.7 360.5 264.9C360.5 260.1 364.5 256.1 369.3 256.1H439.9C444.8 256.1 448.7 260.1 448.7 264.9C448.7 269.7 444.8 273.7 439.9 273.7Z"
                fill="#4A7398"
            />
            <path
                d="M298.7 361.9H228C223.1 361.9 219.2 357.9 219.2 353.1C219.2 348.3 223.2 344.3 228 344.3H298.6C303.5 344.3 307.4 348.3 307.4 353.1C307.4 357.9 303.5 361.9 298.7 361.9Z"
                fill="#4A7398"
            />
            <path
                d="M439.9 361.9H334C329.1 361.9 325.2 357.9 325.2 353.1C325.2 348.3 329.2 344.3 334 344.3H439.9C444.8 344.3 448.7 348.3 448.7 353.1C448.7 357.9 444.8 361.9 439.9 361.9Z"
                fill="#4A7398"
            />
            <path
                d="M439.9 397.2H228C223.1 397.2 219.2 393.2 219.2 388.4C219.2 383.6 223.2 379.6 228 379.6H439.9C444.8 379.6 448.7 383.6 448.7 388.4C448.7 393.2 444.8 397.2 439.9 397.2Z"
                fill="#4A7398"
            />
            <path
                d="M334 432.6H228C223.1 432.6 219.2 428.6 219.2 423.8C219.2 419 223.2 415 228 415H334C338.9 415 342.8 419 342.8 423.8C342.8 428.6 338.8 432.6 334 432.6Z"
                fill="#4A7398"
            />
            <path
                d="M439.9 432.6H369.3C364.4 432.6 360.5 428.6 360.5 423.8C360.5 419 364.5 415 369.3 415H439.9C444.8 415 448.7 419 448.7 423.8C448.7 428.6 444.8 432.6 439.9 432.6Z"
                fill="#4A7398"
            />
            <path
                d="M114.7 406.1C112.4 406.1 110.1 405.2 108.5 403.5L90.7996 385.8C87.4996 382.3 87.4996 376.8 90.8996 373.4C94.2996 370 99.7996 369.9 103.3 373.3L113.8 383.8L160.7 321.3C163.6 317.4 169.2 316.6 173.1 319.5C177 322.4 177.8 328 174.9 331.9L121.9 402.5C120.4 404.5 118 405.8 115.5 406C115.1 406.1 114.9 406.1 114.7 406.1Z"
                fill="#60CFFF"
            />
            <path
                d="M114.7 246.9C112.4 246.9 110.1 246 108.5 244.3L90.7996 226.6C87.4996 223.1 87.4996 217.6 90.8996 214.2C94.2996 210.8 99.7996 210.7 103.3 214.1L113.8 224.6L160.7 162.1C163.6 158.2 169.2 157.4 173.1 160.3C177 163.2 177.8 168.8 174.9 172.7L121.9 243.3C120.4 245.3 118 246.6 115.5 246.8C115.1 246.9 114.9 246.9 114.7 246.9Z"
                fill="#60CFFF"
            />
        </svg>
    );
}

import { IconSvgProps } from "../Icons";

export default function KeySvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} color={color} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6 1.20001C11.64 1.20001 8.39995 4.44001 8.39995 8.40001C8.39995 9.21001 8.57995 10.02 8.75995 10.74L1.19995 18.3V22.8H5.69995C5.69995 21.45 5.69995 20.1 5.69995 20.1H7.49995C7.49995 19.11 7.49995 18.3 7.49995 18.3H9.29995C9.29995 17.31 9.29995 16.5 9.29995 16.5H12L13.26 15.24C13.98 15.51 14.79 15.6 15.6 15.6C19.56 15.6 22.8 12.36 22.8 8.40001C22.8 4.44001 19.56 1.20001 15.6 1.20001Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5 9.30001C17.4941 9.30001 18.3 8.49412 18.3 7.50001C18.3 6.5059 17.4941 5.70001 16.5 5.70001C15.5058 5.70001 14.7 6.5059 14.7 7.50001C14.7 8.49412 15.5058 9.30001 16.5 9.30001Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

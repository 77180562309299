import { Chart } from "react-google-charts";
import { commonAxisOption, CommonChartParams, getLegendConfig } from "../config";

export enum ORIENTATION {
    VERTICAL = "vertical",
    HORIZONTAL = "horizontal"
}

export type BarChartParams = CommonChartParams & {
    titles: string[]; // title under each bar
    colors?: string[];
    orientation?: ORIENTATION;
    xTitle?: string;
    yTitle?: string;
    xMaxValue?: number;
    yMaxValue?: number;
    isStacked?: boolean;
};

export function BarChart({
    titles,
    dataset,
    colors,
    orientation = ORIENTATION.VERTICAL,
    xTitle,
    yTitle,
    height,
    width,
    isStacked = false,
    legendPosition = "top",
    xMaxValue,
    yMaxValue
}: BarChartParams): JSX.Element {
    const hasMultipleBar = dataset.length > 1;
    return (
        <Chart
            chartType={orientation === ORIENTATION.VERTICAL ? "ColumnChart" : "BarChart"}
            width={width ?? "100%"}
            height={height ?? "auto"}
            options={{
                legend: getLegendConfig(legendPosition, "center", { fontSize: 14, margin: 15 }),
                vAxis: {
                    title: yTitle,
                    ...(yMaxValue
                        ? {
                              viewWindowMode: "explicit",
                              viewWindow: {
                                  max: yMaxValue,
                                  min: 0
                              }
                          }
                        : {
                              minValue: 5
                          }),
                    ...commonAxisOption
                },
                hAxis: {
                    title: xTitle,
                    ...(xMaxValue
                        ? {
                              viewWindowMode: "explicit",
                              viewWindow: {
                                  max: xMaxValue,
                                  min: 0
                              }
                          }
                        : {
                              minValue: 5
                          }),
                    ...commonAxisOption
                },
                bar: { groupWidth: orientation === ORIENTATION.VERTICAL ? "90%" : "70%" },
                isStacked,
                colors
            }}
            data={[
                ["key", ...dataset.map(data => data.label), ...(!hasMultipleBar ? [{ role: "style" }] : [])],
                ...titles.map((title, i) => [
                    title,
                    ...dataset.map(data => data.data[i]),
                    ...(!hasMultipleBar ? dataset.map(() => (colors ? colors[i] : null)) : [])
                ])
            ]}
        />
    );
}

import { UserProfile } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { Dropdowns, PoweroffIcon, SettingsIcon, UserIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { SHARED_URL, SettingsKey, addQueryParamsToUrl } from "../../services/routingService";

function LoggedUser({ lang, userProfile }: { lang: CommonLang; userProfile?: UserProfile }): JSX.Element {
    if (!userProfile) {
        return <></>;
    }
    const userProfileHasName = !!(userProfile?.firstName && userProfile?.lastName);
    return (
        <div className="flex flex-col gap-2">
            <span className="text-xs font-bold text-slate-primary">{lang.settingsMenu.signedInAs}</span>
            <div className="flex gap-2">
                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-blue-extra-light">
                    <UserIcon color="slate" />
                </div>
                <div className={classNames({ "flex items-center": !userProfileHasName })}>
                    {userProfileHasName && (
                        <div className="text-sm font-normal text-slate-primary">
                            {userProfile?.firstName} {userProfile?.lastName}
                        </div>
                    )}
                    <div className="text-xs font-light text-grey-primary">{userProfile?.email}</div>
                </div>
            </div>
        </div>
    );
}

export default function SettingsDropdownMenu({
    userProfile,
    onLogout,
    layout
}: {
    userProfile: UserProfile;
    onLogout: () => Promise<void>;
    layout?: "logout-only" | "minimal" | "full";
}): JSX.Element {
    const lang = useLang();
    return (
        <Dropdowns.Menu
            icon={SettingsIcon}
            header={<LoggedUser lang={lang} userProfile={userProfile} />}
            actions={[
                ...(layout === "full"
                    ? [
                          { label: lang.settingsMenu.profileSettings, href: addQueryParamsToUrl(SHARED_URL.app.settings.index, { tab: SettingsKey.PROFILE }) },
                          {
                              label: lang.settingsMenu.accountSettings,
                              href: addQueryParamsToUrl(SHARED_URL.app.settings.index, { tab: SettingsKey.ORGANISATION })
                          }
                      ]
                    : []),
                { label: lang.settingsMenu.signOut, icon: PoweroffIcon, onClick: onLogout }
            ]}
            title={lang.settingsMenu.settings}
        />
    );
}

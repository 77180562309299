import { Referential, REFERENTIAL, SURVEY_INDUSTRY_STANDARDS } from "@vaultinum/vaultinum-api";
import { collection, doc, getDoc, getFirestore } from "./firebaseService";

let industryStandards: Referential.IndustryStandard;

export async function getIndustryStandards(): Promise<Referential.IndustryStandard | undefined> {
    if (!industryStandards) {
        const standards = await getDoc(doc(collection(getFirestore(), REFERENTIAL), SURVEY_INDUSTRY_STANDARDS));
        if (standards.exists()) {
            industryStandards = standards.data() as Referential.IndustryStandard;
        }
    }
    return industryStandards;
}

export async function getIndustryStandardForSurvey(surveyKey: string): Promise<Referential.SurveyStandard | undefined> {
    const standards = await getIndustryStandards();
    return standards?.surveys?.[surveyKey];
}

import { Survey, SURVEY_COLLECTION } from "@vaultinum/vaultinum-api";
import {
    collection,
    CollectionReference,
    converter,
    doc,
    DocumentReference,
    getFirestore,
    getItem,
    getItems,
    query,
    Unsubscribe,
    where
} from "../../../common";

export const DEFAULT_GRADE_SCORE_RANGE: Survey.GradeScoreRange = [100, 80, 60, 40, 20, 0];

export function surveyCollection(): CollectionReference<Survey> {
    return collection(getFirestore(), SURVEY_COLLECTION).withConverter(converter<Survey>({ idKey: "key" }));
}

export function surveyDoc(surveyKey: string): DocumentReference<Survey> {
    return doc(surveyCollection(), surveyKey);
}

export function getSurvey(surveyKey: string): Promise<Survey | null>;
export function getSurvey(surveyKey: string, onUpdate: (survey: Survey | null) => void): Unsubscribe;
export function getSurvey(surveyKey: string, onUpdate?: (survey: Survey | null) => void): Promise<Survey | null> | Unsubscribe {
    if (onUpdate) {
        return getItem(surveyDoc(surveyKey), onUpdate);
    }
    return getItem(surveyDoc(surveyKey));
}

export function getVisibleSurveys(): Promise<Survey[]>;
export function getVisibleSurveys(onUpdate: (surveys: Survey[]) => void): Unsubscribe;
export function getVisibleSurveys(onUpdate?: (surveys: Survey[]) => void): Promise<Survey[]> | Unsubscribe {
    const q = query(surveyCollection(), where("visible", "==", true));
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}

export function getSurveys(): Promise<Survey[]>;
export function getSurveys(onUpdate: (surveys: Survey[]) => void): Unsubscribe;
export function getSurveys(onUpdate?: (surveys: Survey[]) => void): Promise<Survey[]> | Unsubscribe {
    if (onUpdate) {
        return getItems(surveyCollection(), onUpdate);
    }
    return getItems(surveyCollection());
}

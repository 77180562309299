import { Popover as AntPopover, Button, PopoverProps } from "antd";
import { PresetColorType } from "antd/lib/_util/colors";
import { ReactNode, useEffect, useState } from "react";
import "./Popover.css";

function Content({
    content,
    setShown,
    closeText,
    onlyOnceKey
}: {
    content: ReactNode;
    setShown: (shown: boolean) => void;
    closeText?: string;
    onlyOnceKey?: string;
}) {
    const closePopover = () => {
        if (onlyOnceKey) {
            localStorage.setItem(onlyOnceKey, "true");
        }
        setShown(false);
    };

    return (
        <div className="space-y-4">
            {content}
            {closeText && (
                <div className="flex justify-end">
                    <Button type="ghost" onClick={closePopover}>
                        {closeText}
                    </Button>
                </div>
            )}
        </div>
    );
}

export default function Popover({
    content,
    closeText,
    visible,
    className,
    color,
    showOverlay,
    onlyOnceKey,
    ...restProps
}: {
    content: ReactNode;
    closeText?: string;
    visible?: boolean;
    className?: string;
    color?: PresetColorType;
    showOverlay?: boolean;
    onlyOnceKey?: string;
} & PopoverProps): JSX.Element {
    const [shown, setShown] = useState(false);

    useEffect(() => {
        const alreadyShown = onlyOnceKey ? localStorage.getItem(onlyOnceKey) === "true" : false;
        setShown(!!visible && !alreadyShown);
    }, [visible, onlyOnceKey]);

    const handleHoverChange = (newVisibleState: boolean) => {
        if (visible === undefined && (onlyOnceKey ? localStorage.getItem(onlyOnceKey) !== "true" : true)) {
            setShown(newVisibleState);
        }
    };

    return (
        <>
            {showOverlay && shown && <div className="fixed inset-0 z-10 h-full w-full bg-black opacity-30" />}
            <AntPopover
                {...restProps}
                overlayClassName={`mx-2 custom-popover ${className || ""}`}
                color={color}
                overlayInnerStyle={{ backgroundColor: color }}
                content={<Content content={content} setShown={setShown} closeText={closeText} onlyOnceKey={onlyOnceKey} />}
                visible={shown}
                onVisibleChange={handleHoverChange}
            />
        </>
    );
}

import { IconSvgProps } from "../../../../../../common";

export default function TechnologiesSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2580_94)">
                <path d="M17.558 7.9313L16.9633 1.65139C16.9332 1.32595 16.6741 1.0688 16.3486 1.03666L10.0687 0.442017H10.0607C9.99638 0.442017 9.94616 0.462106 9.90799 0.500276L0.500179 9.90809C0.481556 9.92667 0.46678 9.94875 0.456699 9.97305C0.446618 9.99735 0.441429 10.0234 0.441429 10.0497C0.441429 10.076 0.446618 10.1021 0.456699 10.1264C0.46678 10.1507 0.481556 10.1728 0.500179 10.1913L7.80866 17.4998C7.84683 17.538 7.89705 17.5581 7.95129 17.5581C8.00554 17.5581 8.05576 17.538 8.09393 17.4998L17.5017 8.09202C17.5419 8.04983 17.562 7.99157 17.558 7.9313V7.9313ZM7.94929 15.4829L2.51714 10.0507L10.5468 2.02103L15.5089 2.49112L15.979 7.45318L7.94929 15.4829ZM12.375 3.8572C11.4006 3.8572 10.6071 4.65072 10.6071 5.62505C10.6071 6.59938 11.4006 7.39291 12.375 7.39291C13.3493 7.39291 14.1428 6.59938 14.1428 5.62505C14.1428 4.65072 13.3493 3.8572 12.375 3.8572ZM12.375 6.26791C12.0194 6.26791 11.7321 5.98063 11.7321 5.62505C11.7321 5.26947 12.0194 4.9822 12.375 4.9822C12.7305 4.9822 13.0178 5.26947 13.0178 5.62505C13.0178 5.98063 12.7305 6.26791 12.375 6.26791Z" />
            </g>
        </svg>
    );
}

import classNames from "classnames";
import { ElementType } from "react";

export { Tabs } from "antd";

export function TabLabel({ Icon, label, isActive }: { Icon: ElementType | null; label: string; isActive: boolean }): JSX.Element {
    return (
        <span className={classNames("flex items-center gap-2", { "text-pink-primary": isActive })}>
            {Icon && <Icon {...(isActive && { color: "pink" })} />}
            <span>{label}</span>
        </span>
    );
}

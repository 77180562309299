import { ReactNode } from "react";

export default function Step({ title, children, index }: { title: string; children: ReactNode; index?: number }): JSX.Element {
    return (
        <div className="space-y-4 rounded-md border bg-white p-6 shadow-md">
            <span className="text-base font-medium text-primary">
                {index ? `${index}.` : ""} {title}
            </span>
            {children}
        </div>
    );
}

import { SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { Column, Spin, Table, Tag, formatAsCurrency, formatDate, openNotificationWithIcon, plural, useAuthContext, useLang } from "@vaultinum/vaultinum-sdk";
import { sumBy } from "lodash";
import { useEffect, useState } from "react";
import Stripe from "stripe";
import { AccountLang } from "../lang/AccountLang";
import { getSubscriptions } from "../services";
import ViewWrapper from "./ViewWrapper";

type SubscriptionStatus = Extract<Stripe.Subscription.Status, "canceled" | "incomplete_expired" | "past_due" | "active">;

function convertStatusToType(status: SubscriptionStatus): "default" | "danger" | "success" {
    switch (status) {
        case "canceled":
        case "incomplete_expired":
            return "default";
        case "past_due":
            return "danger";
        default:
            return "success";
    }
}

function getColumns(langCode: SupportedLanguageCode, lang: AccountLang): Column<Stripe.Subscription>[] {
    return [
        {
            header: lang.accountSettings.billingView.invoice.status.name,
            accessorKey: "status",
            cell: cell => {
                const status = cell.getValue<SubscriptionStatus>();
                return <Tag message={lang.accountSettings.billingView.subscription.status[status]} type={convertStatusToType(status)} />;
            }
        },
        {
            header: lang.accountSettings.billingView.subscription.date,
            accessorFn: row => formatDate(new Date(row.created), langCode)
        },
        {
            header: lang.accountSettings.billingView.subscription.amount,
            accessorFn: row => sumBy(row.items.data, item => (item.price.unit_amount ?? 0) / 100),
            cell: cell => formatAsCurrency(cell.getValue<number>(), langCode)
        },
        {
            header: lang.accountSettings.billingView.subscription.canceledAt,
            accessorFn: row => row.canceled_at ?? "-",
            cell: cell => cell.getValue<string>()
        }
    ];
}

export default function PlanAndSubscriptions({ langCode }: { langCode: SupportedLanguageCode }): JSX.Element {
    const [subscriptions, setSubscriptions] = useState<Stripe.Subscription[]>([]);
    const [working, setWorking] = useState<boolean>(false);
    const { selectedAccount: account } = useAuthContext();

    const lang = useLang<AccountLang>();

    useEffect(() => {
        void (async function () {
            setWorking(true);
            try {
                if (account) {
                    const subscriptionList = await getSubscriptions(account.id);
                    setSubscriptions(subscriptionList);
                }
            } catch (e) {
                openNotificationWithIcon({ type: "error", description: lang.accountSettings.billingView.invoice.failed });
            } finally {
                setWorking(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account?.id]);

    if (working) {
        return (
            <div className="flex w-full items-center justify-center">
                <Spin />
            </div>
        );
    }

    const columns = getColumns(langCode, lang);

    return (
        <ViewWrapper title={plural(lang.accountSettings.billingView.subscription.label, 2)}>
            <Table<Stripe.Subscription> data={subscriptions} columns={columns} />
        </ViewWrapper>
    );
}

import { CardCommon, RowCard, RowCardInterface } from "../..";

export namespace RowCards {
    export function WithActions({ actions, ...props }: CardCommon.WithActions & RowCardInterface) {
        return <RowCard {...props} rightChildren={CardCommon.Actions({ actions, "data-id": props["data-id"] })} />;
    }

    export function WithRadio<T extends string = string>({ radio, ...props }: CardCommon.WithRadio<T> & RowCardInterface) {
        const isSelected = radio.id === radio.selectedId;
        return (
            <RowCard
                {...props}
                onClick={() => (!isSelected ? radio.onValueChange?.(radio.id) : null)}
                isSelected={isSelected}
                leftChildren={
                    <div className="flex ml-5">
                        <CardCommon.Radio<T> radio={radio} data-id={props["data-id"]} isDisabled={props.isDisabled} />
                    </div>
                }
            />
        );
    }

    export function WithCheckbox<T extends string = string>({ checkbox, ...props }: CardCommon.WithCheckbox<T> & RowCardInterface) {
        return (
            <RowCard
                {...props}
                onClick={() => {
                    checkbox.onValueChange(checkbox.id);
                }}
                isSelected={checkbox.isSelected}
                leftChildren={
                    <div className="flex ml-5">
                        <CardCommon.Checkbox<T> checkbox={checkbox} data-id={props["data-id"]} isDisabled={props.isDisabled} />
                    </div>
                }
            />
        );
    }

    export function WithImage({ img, ...props }: { img: CardCommon.WithImage } & RowCardInterface) {
        return <RowCard {...props} leftChildren={<CardCommon.Image {...img} />} />;
    }
}

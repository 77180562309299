import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import {
    AddIcon,
    Alert,
    Button,
    Controller,
    DeleteIcon,
    Input,
    openNotificationWithIcon,
    plural,
    useAuthContext,
    useFieldArray,
    useForm,
    useLang,
    useWhiteLabelContext,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { AccountLang } from "../../../lang/AccountLang";
import { CollaboratorsFields, CreateAs, createAccount } from "../../../services";
import { Step } from "./Step";
import { OnboardingContext } from "./onboardingMachine";

export default function OrganisationCollaboratorsStep({
    context,
    onChange,
    doBack,
    doNext
}: {
    context: OnboardingContext;
    onChange: (update: CollaboratorsFields) => void;
    doBack: () => void;
    doNext: () => void;
}): JSX.Element {
    const { userProfile } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const [isLoading, setIsLoading] = useState(false);
    const lang = useLang<AccountLang>();
    const isRepresentative = context.createAsFields.createAs === CreateAs.REPRESENTATIVE;
    const emailSchema = yup
        .string()
        .matches(EMAIL_REGEX, { message: lang.shared.invalidEmail, excludeEmptyString: true })
        .notOneOf([userProfile?.email], lang.shared.emailMustBeDifferentFromYours);
    const schema = yup.object().shape({
        emails: yup.array().of(
            yup.object().shape({
                email: isRepresentative ? emailSchema.required(lang.shared.requiredErrorMessage) : emailSchema.notRequired()
            })
        )
    } satisfies Partial<Record<keyof CollaboratorsFields, yup.AnySchema>>);

    const form = useForm({ schema, defaultValues: context.collaboratorsFields, mode: "onChange" });
    const { fields, append, remove } = useFieldArray({ control: form.control, name: "emails" });

    async function onSubmit() {
        try {
            setIsLoading(true);
            await createAccount(
                {
                    ...context.organisationInformationsFields,
                    whiteLabelDomainId: whiteLabelDomain?.id ?? null
                },
                context.collaboratorsFields.emails.map(({ email }) => email.trim()).filter(email => email),
                isRepresentative,
                context.collaboratorsFields.shouldNotifyByEmail
            );
            doNext();
        } catch (error) {
            openNotificationWithIcon({
                type: "error",
                description: lang.onboarding.account.errorOnSave(isRepresentative)
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const { unsubscribe } = form.watch(() => {
            onChange(form.getValues());
        });
        return () => unsubscribe();
    }, [form, onChange]);

    const hasSomeEmails = form.watch("emails").some(({ email }) => !!email.trim().length);

    return (
        <Step
            title={lang.onboarding.account.collaborate.title(isRepresentative)}
            doBack={doBack}
            onSubmit={onSubmit}
            submitText={isRepresentative || hasSomeEmails ? lang.shared.next : lang.shared.skip}
            stepKey="organisation-collaborators-step"
            form={form}
        >
            <div className="space-y-4">
                {fields.map((field, index) => (
                    <Controller
                        {...field}
                        key={field.id}
                        control={form.control}
                        name={`emails.${index}.email`}
                        render={({ field: currentField }) => (
                            <Input.Email
                                {...currentField}
                                data-id="email-input"
                                label={lang.shared.email}
                                disabled={isLoading}
                                placeholder={lang.onboarding.account.collaborate.collaboratorEmail(isRepresentative)}
                                errorMessage={form.formState.errors?.emails?.[index]?.email?.message}
                                {...(isRepresentative && { required: true })}
                                rightChildren={
                                    fields.length > 1 && <DeleteIcon onClick={() => remove(index)} data-id="remove-collaborator" size="sm" color="slate" />
                                }
                            />
                        )}
                    />
                ))}
            </div>
            {!isRepresentative && (
                <div className="flex justify-center">
                    <Button data-id="add-collaborator" type="default" size="sm" onClick={() => append({ email: "" })} isLoading={false}>
                        <AddIcon />
                        {lang.onboarding.account.collaborate.addAnotherCollaborator(isRepresentative)}
                    </Button>
                </div>
            )}
            <Alert.Info message={lang.onboarding.account.collaborate.description(isRepresentative)} />
            {hasSomeEmails && (
                <div className="flex justify-end">
                    <Controller
                        name="shouldNotifyByEmail"
                        control={form.control}
                        data-id="notify-by-email-control"
                        render={({ field: { value, ...currentField } }) => (
                            <Input.Checkbox
                                id="notify-by-email"
                                {...currentField}
                                checked={value}
                                label={plural(lang.onboarding.account.collaborate.sendInvitation, fields?.length || 0)}
                                disabled={isLoading}
                            />
                        )}
                    />
                </div>
            )}
        </Step>
    );
}

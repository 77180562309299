import { AccountRights, AccountRole } from "@vaultinum/vaultinum-api";
import { AccountLang } from "../lang/AccountLang";
import { getAccountUserRole } from "./accountService";

export function formatAccountRole(lang: AccountLang, role: AccountRole | undefined): string {
    switch (role) {
        case AccountRole.ADMIN:
            return lang.sharedSettings.adminRole;
        case AccountRole.REPRESENTATIVE:
            return lang.sharedSettings.representativeRole;
        case AccountRole.CONTRIBUTOR:
            return lang.sharedSettings.contributorRole;
        case AccountRole.READ:
        default:
            return lang.sharedSettings.readOnlyRole;
    }
}
export function formatAccountRoleDescription(lang: AccountLang, role: AccountRole | undefined): string {
    switch (role) {
        case AccountRole.ADMIN:
            return lang.accountSettings.userAccessView.adminRoleDescription;
        case AccountRole.REPRESENTATIVE:
            return lang.accountSettings.userAccessView.representativeRoleDescription;
        case AccountRole.CONTRIBUTOR:
            return lang.accountSettings.userAccessView.contributorRoleDescription;
        case AccountRole.READ:
        default:
            return lang.accountSettings.userAccessView.readOnlyRoleDescription;
    }
}

export function formatRightsToAccountRole(lang: AccountLang, rights: AccountRights[] | undefined): string {
    return formatAccountRole(lang, getAccountUserRole(rights));
}

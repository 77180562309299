import { motion } from "framer-motion";
import { IconSvgProps } from "../Icons";

export default function CrossCircleSvgAnimated({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            stroke={color}
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="transparent"
            xmlns="http://www.w3.org/2000/svg"
        >
            <motion.path
                d="M2.92493 12.0116C2.92493 16.9195 6.94985 20.9166 11.9377 20.9166C16.9255 20.9166 20.9504 16.9195 20.9504 12.0116C20.9504 7.10369 16.9255 3.10661 11.9377 3.10661C6.94985 3.10661 2.92493 7.10368 2.92493 12.0116Z"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 0.8 }}
            />
            <motion.path d="M9.10498 9.16787L14.8948 14.9578" initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 0.8 }} />
            <motion.path
                d="M14.8948 9.16791L9.1049 14.9577"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
            />
        </svg>
    );
}

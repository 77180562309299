import {
    AccountUser,
    Claims,
    FULL_AUDIT_COLLECTION,
    FULL_AUDIT_FILES_COLLECTION,
    FULL_AUDIT_PDF_REPORTS_COLLECTION,
    FULL_AUDIT_REPORTS_COLLECTION,
    FullAudit,
    FullAuditActors,
    PdfReport,
    Report,
    SurveyReport,
    Writable
} from "@vaultinum/vaultinum-api";
import {
    CollectionReference,
    DocumentReference,
    FieldValue,
    Query,
    Unsubscribe,
    VAULTINUM_DOMAIN_ID,
    addDoc,
    collection,
    collectionGroup,
    converter,
    deleteDoc,
    deleteField,
    doGet,
    doc,
    getFirestore,
    getItem,
    getItems,
    or,
    orderBy,
    query,
    updateDoc,
    where
} from "../../../common";

/*
 *  Full Audit
 */

function fullAuditCollection(): CollectionReference<FullAudit> {
    return collection(getFirestore(), FULL_AUDIT_COLLECTION).withConverter(converter<FullAudit>());
}

export function fullAuditDoc(fullAuditId: string): DocumentReference<FullAudit> {
    return doc(fullAuditCollection(), fullAuditId);
}

function filterFullAuditByUser(fullAudits: FullAudit[], accountId: string | undefined, userId: string): FullAudit[] {
    return fullAudits.filter(fullAudit => {
        // post filter to avoid array-contains limitations
        if (fullAudit.reviewerAccount && accountId && fullAudit.roles[accountId].includes(FullAudit.Role.REVIEWER)) {
            return fullAudit.reviewerAccount.accountUserIds.includes(userId);
        }
        return true;
    });
}

export function getFullAudit(fullAuditId: string): Promise<FullAudit | null>;
export function getFullAudit(fullAuditId: string, onUpdate: (fullAudit: FullAudit | null) => void): Unsubscribe;
export function getFullAudit(fullAuditId: string, onUpdate?: (fullAudit: FullAudit | null) => void): Promise<FullAudit | null> | Unsubscribe {
    if (onUpdate) {
        return getItem(fullAuditDoc(fullAuditId), onUpdate);
    }
    return getItem(fullAuditDoc(fullAuditId));
}

export function getFullAudits(): Promise<FullAudit[]>;
export function getFullAudits(onUpdate: (fullAudits: FullAudit[]) => void): Unsubscribe;
export function getFullAudits(onUpdate?: (fullAudits: FullAudit[]) => void): Unsubscribe | Promise<FullAudit[]> {
    if (onUpdate) {
        return getItems(fullAuditCollection(), onUpdate);
    }
    return getItems(fullAuditCollection());
}

export function getFullAuditsByExpert(userId: string, accountId: string | undefined): Promise<FullAudit[]>;
export function getFullAuditsByExpert(userId: string, accountId: string | undefined, onUpdate: (fullAudits: FullAudit[]) => void): Unsubscribe;
export function getFullAuditsByExpert(
    userId: string,
    accountId: string | undefined,
    onUpdate?: (fullAudits: FullAudit[]) => void
): Unsubscribe | Promise<FullAudit[]> {
    const constraint = where("reviewerIds", "array-contains", userId);
    const q = query(fullAuditCollection(), or(...[accountId ? (where("reviewerAccount.accountId", "==", accountId), constraint) : constraint]));
    if (onUpdate) {
        return getItems(q, fullAudits => onUpdate(filterFullAuditByUser(fullAudits, accountId, userId)));
    }
    return getItems(q).then(fullAudits => filterFullAuditByUser(fullAudits, accountId, userId));
}

export function getFullAuditsByAccountIdAndDomain(
    accountId: string,
    whiteLabelDomainId: string | null,
    isCrossDomainOwner: boolean,
    onUpdate: (fullAudits: FullAudit[]) => void
): Unsubscribe {
    // Cannot use multiple constraints as it requires an index on dynamic fields which is not possible
    // https://stackoverflow.com/questions/59008167
    const q = query(fullAuditCollection(), where(`roles.${accountId}`, "!=", null));
    return getItems(q, fullAudits =>
        onUpdate(isCrossDomainOwner ? fullAudits : fullAudits.filter(fullAudit => fullAudit.whiteLabelDomainId === whiteLabelDomainId))
    );
}

export function getFullAuditsByAccountUserAndDomain(
    accountUser: AccountUser,
    whiteLabelDomainId: string | null,
    claims: Claims,
    onUpdate: (fullAudits: FullAudit[]) => void
): Unsubscribe {
    // is on vaultinum.com & is owner of the domain, check all audits then
    const isCrossDomainOwner = whiteLabelDomainId === null && !!claims.domains?.[VAULTINUM_DOMAIN_ID]?.isOwner;
    return getFullAuditsByAccountIdAndDomain(accountUser.accountId, whiteLabelDomainId, isCrossDomainOwner, fullAudits =>
        onUpdate(
            fullAudits
                .filter(fullAudit => {
                    // post filter to avoid array-contains limitations
                    const currentAccountRoles = fullAudit.roles[accountUser.accountId] ?? [];
                    const isOnlyReviewer = currentAccountRoles.includes(FullAudit.Role.REVIEWER) && currentAccountRoles.length === 1;
                    // handle special case for REVIEWER role
                    if (fullAudit.reviewerAccount && isOnlyReviewer) {
                        return fullAudit.reviewerAccount.accountUserIds.includes(accountUser.id);
                    }
                    return true;
                })
                .sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime())
        )
    );
}

export function updateFullAuditStatus(fullAuditId: string, status: FullAudit.Status, comment: string): Promise<void> {
    return updateDoc(fullAuditDoc(fullAuditId), { status, comment });
}

export function updateFullAuditCompletionDate(fullAudit: FullAudit, completionDate: FieldValue): Promise<void> {
    return updateDoc(fullAuditDoc(fullAudit.id), { completionDate });
}

export function deleteFullAuditSurveyReportId(fullAuditId: string): Promise<void> {
    return updateDoc(fullAuditDoc(fullAuditId), { ["reportIds.surveyReportId"]: deleteField() });
}

export function deleteFullAuditCodeAnalysisReportId(fullAuditId: string): Promise<void> {
    return updateDoc(fullAuditDoc(fullAuditId), { ["reportIds.codeAuditReportId"]: deleteField() });
}

export function getFullAuditUsers(fullAuditId: string): Promise<FullAuditActors> {
    return doGet<FullAuditActors>(`kys-fullAudit/${fullAuditId}/users`);
}

/*
 *  Full Audit PDF Reports
 */

export function fullAuditPdfReportsCollection(fullAuditId: string): CollectionReference<PdfReport> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_PDF_REPORTS_COLLECTION).withConverter(converter<PdfReport>());
}

/*
 *  Full Audit Reports
 */

const CONVERTER = converter<SurveyReport>();

export function reportGroupCollection(): Query<SurveyReport> {
    return collectionGroup(getFirestore(), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

function reportCollection(fullAuditId: string): CollectionReference<SurveyReport> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

export function reportDoc(fullAuditId: string, reportId: string): DocumentReference<SurveyReport> {
    return doc(reportCollection(fullAuditId), reportId);
}

export function getSurveyReports(onUpdate: (surveyReports: SurveyReport[]) => void): () => void;
export function getSurveyReports(fullAuditId: string): Promise<SurveyReport[]>;
export function getSurveyReports(fullAuditId: string, onUpdate: (surveyReports: SurveyReport[]) => void): () => void;
export function getSurveyReports(
    idOrUpdate: string | ((surveyReports: SurveyReport[]) => void),
    onUpdate?: (surveyReports: SurveyReport[]) => void
): Promise<SurveyReport[]> | (() => void) {
    const constraint = where("reportType", "==", Report.ReportType.SURVEY);
    if (typeof idOrUpdate === "function") {
        const q = query(reportGroupCollection(), constraint);
        return getItems(q, idOrUpdate);
    }
    const q = query(reportCollection(idOrUpdate), constraint);
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}

export async function getSurveyReport(fullAuditId: string, reportId: string): Promise<SurveyReport | null> {
    return getItem(reportDoc(fullAuditId, reportId));
}

/*
 *  Full Audit Files
 */

function fullAuditFilesCollection(fullAuditId: string): CollectionReference<FullAudit.File> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_FILES_COLLECTION).withConverter(converter<FullAudit.File>());
}

function fullAuditFilesDoc(fullAuditId: string, fileId: string): DocumentReference<FullAudit.File> {
    return doc(fullAuditFilesCollection(fullAuditId), fileId);
}

export function getFullAuditFiles(fullAuditId: string): Promise<FullAudit.File[]>;
export function getFullAuditFiles(fullAuditId: string, onUpdate: (files: FullAudit.File[]) => void): Unsubscribe;
export function getFullAuditFiles(fullAuditId: string, onUpdate?: (files: FullAudit.File[]) => void): Unsubscribe | Promise<FullAudit.File[]> {
    const q = query(fullAuditFilesCollection(fullAuditId), orderBy("name"));
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}

export async function addFullAuditFile(fullAuditId: string, file: Writable<FullAudit.File>): Promise<FullAudit.File> {
    const fileToAdd = { ...file, fullAuditId };
    const addedFile = await addDoc(fullAuditFilesCollection(fullAuditId), fileToAdd);
    return { ...fileToAdd, id: addedFile.id };
}

export async function updateFullAuditFile(fullAuditId: string, file: FullAudit.File, name: string, description: string): Promise<FullAudit.File> {
    await updateDoc(fullAuditFilesDoc(fullAuditId, file.id), { name, description });
    return { ...file, name, description };
}

export function deleteFullAuditFile(fullAuditId: string, fileId: string): Promise<void> {
    return deleteDoc(fullAuditFilesDoc(fullAuditId, fileId));
}

import { DrawerService } from "./drawer.service";

export class DrawerLocalStorageService implements DrawerService {
    public saveWidth(storageKey: string, width: number): void {
        localStorage.setItem(storageKey, width.toString());
    }

    public loadWidth(storageKey: string): number | null {
        const width = localStorage.getItem(storageKey);
        return width ? Number(width) : null;
    }
}

import { IconSvgProps } from "../Icons";

function SortSvg({ color, size }: IconSvgProps) {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4.5L2.85714 0.5L5.71429 4.5H0ZM0 5.5L2.85714 9.5L5.71429 5.5H0Z" />
        </svg>
    );
}

export default SortSvg;

import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import {
    APPS_INFOS,
    AppCode,
    AuthenticationLogo,
    BaseLang,
    Button,
    CardWithFeedback,
    Controller,
    Form,
    Input,
    Separator,
    getUserProfileFromUID,
    login,
    message,
    useForm,
    useLang,
    useRequiredString,
    useUrlSearch,
    yup
} from "@vaultinum/vaultinum-sdk";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPrioritaryUrl } from "../../helpers";
import { AccountLang } from "../../lang/AccountLang";
import { ALLOWED_DOMAIN, ALLOWED_SUBDOMAIN_SUFFIX, APP_PATH, URL as RoutingUrl, isCleanUrlNeeded } from "../../services/routingService";

interface Login {
    email: string;
    password: string;
    "remember-me": boolean;
}

function isSafeUrl(redirectTo: string): boolean {
    try {
        const parsedUrl = new URL(redirectTo);
        return parsedUrl.hostname === ALLOWED_DOMAIN || parsedUrl.hostname.endsWith(ALLOWED_SUBDOMAIN_SUFFIX);
    } catch {
        return redirectTo.startsWith(APP_PATH);
    }
}

function isSafeAppCode(appCode: string | undefined): appCode is AppCode {
    return !!appCode && appCode in APPS_INFOS;
}

export default function LoginPage({ logoSubtitle }: { logoSubtitle?: ReactNode }): JSX.Element {
    const [working, setWorking] = useState(false);
    const lang = useLang<AccountLang & BaseLang>();
    const schema = yup.object().shape({
        email: useRequiredString().matches(EMAIL_REGEX, lang.shared.invalidEmail),
        password: useRequiredString()
    });
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<Login>({
        schema,
        mode: "onChange"
    });
    const { redirectTo, email: initialEmailValue } = useUrlSearch() as { redirectTo?: string; email?: string };

    useEffect(() => {
        // clear url needed only for apps that are not the account app
        if (redirectTo && isCleanUrlNeeded(redirectTo)) {
            window.history.replaceState({}, "", window.location.pathname);
        }
    }, [redirectTo]);

    async function doLogin({ email, password, "remember-me": rememberMe }: Login) {
        try {
            setWorking(true);
            const userCredential = await login(email, password, rememberMe);
            getUserProfileFromUID(userCredential.user.uid, userProfile => {
                if (!getPrioritaryUrl(userCredential.user, userProfile)) {
                    const selectedAppCode: string | undefined = userProfile?.selectedAppCode;
                    if (redirectTo && isSafeUrl(redirectTo)) {
                        window.location.assign(redirectTo);
                    } else if (isSafeAppCode(selectedAppCode)) {
                        window.location.assign(APPS_INFOS[selectedAppCode].baseUrl);
                    }
                }
            });
        } catch (error) {
            if (error.response?.data) {
                const { data } = error.response;
                if (data?.redirectTo) {
                    if (data?.redirectTo && isSafeUrl(data.redirectTo)) {
                        window.location.assign(data.redirectTo);
                    }
                } else {
                    void message.error(lang.shared.loginFailed);
                }
            } else {
                void message.error(lang.shared.loginFailed);
            }
        } finally {
            setWorking(false);
        }
    }

    return (
        <CardWithFeedback extra={<AuthenticationLogo logoSubtitle={logoSubtitle} />} title={<h1 className="text-lg">{lang.authentication.login}</h1>}>
            <Form onSubmit={handleSubmit(doLogin)}>
                <Controller
                    name="email"
                    control={control}
                    defaultValue={initialEmailValue}
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            data-id="email-input"
                            label={lang.shared.email}
                            autoFocus={!initialEmailValue}
                            disabled={working}
                            errorMessage={errors.email?.message}
                        />
                    )}
                />
                <Controller
                    name="password"
                    data-id="password-control"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <Input.Password
                                {...field}
                                data-id="password-input"
                                label={lang.shared.password}
                                autoFocus={!!initialEmailValue}
                                disabled={working}
                                errorMessage={errors.password?.message}
                            />
                        </div>
                    )}
                />
                <div className="flex items-center justify-between">
                    <Controller
                        name="remember-me"
                        data-id="remember-control"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <Input.Checkbox label={lang.authentication.rememberMe} data-id="remember-input" onChange={onChange} id="remember-me" />
                        )}
                    />
                    <Link to={RoutingUrl.forgotPassword} className="link float-right">
                        {lang.authentication.forgotPassword}
                    </Link>
                </div>
                <Button data-id="login-button" htmlType="submit" isLoading={working}>
                    {lang.authentication.login}
                </Button>
                <Separator />
                <div className="flex flex-wrap justify-center">
                    {lang.authentication.notRegistered}&nbsp;
                    <Link to={RoutingUrl.register} className="link">
                        {lang.register.register}
                    </Link>
                </div>
            </Form>
        </CardWithFeedback>
    );
}

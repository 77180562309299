import {
    Buttons,
    Controller,
    Form,
    getLang,
    openNotificationWithIcon,
    Select,
    SUPPORTED_LANGUAGES,
    useAuthContext,
    useForm,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { ViewWrapper } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";
import languages from "../../../lang/languages";
import { updateUserProfileSettings } from "../../../services/userProfileService";
const PreferencesView = (): JSX.Element => {
    const { userProfile } = useAuthContext();
    const lang = useLang<AccountLang>();
    const supportedLanguages = SUPPORTED_LANGUAGES.map(language => ({ value: language.code, label: language.label }));
    const [working, setWorking] = useState(false);

    const { handleSubmit, control } = useForm<{ preferredLang: string }>({
        defaultValues: { preferredLang: userProfile?.settings?.preferredLang }
    });

    const onSubmit = async (values: { preferredLang: string }) => {
        setWorking(true);
        try {
            if (userProfile?.id) {
                await updateUserProfileSettings({ id: userProfile?.id, settings: { preferredLang: values.preferredLang } });
                openNotificationWithIcon({ type: "success", description: getLang(values.preferredLang, languages).shared.saveSuccessMessage });
            }
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: lang.shared.saveErrorMessage });
        } finally {
            setWorking(false);
        }
    };

    return (
        <ViewWrapper title={lang.profileSettings.form.preferences}>
            <Form onSubmit={handleSubmit(onSubmit)} className="rounded-md bg-white p-4">
                <Controller
                    name="preferredLang"
                    control={control}
                    render={({ field }) => (
                        <Select.Basic
                            {...field}
                            label={lang.profileSettings.form.preferredLanguage}
                            data-id="language-select"
                            options={supportedLanguages}
                            isDisabled={working}
                        />
                    )}
                />
                <div className="ml-auto">
                    <Buttons.Save isLoading={working} />
                </div>
            </Form>
        </ViewWrapper>
    );
};
export default PreferencesView;

// eslint-disable-next-line import/no-unresolved
import axios, { AxiosResponse, CancelToken } from "axios";
import { useEffect, useState } from "react";
import { openNotificationWithIcon } from "../../../design-system";
import { calculateChecksum } from "../../../helpers";
import { useLang } from "../../../lang";
import { UploadedFileRow } from "./UploadedFileRow";

const CHUNK_SIZE = 1048576 * 3; // Chunks of 3 Mb
const CLEANUP_MESSAGE = "cleanup";

export function NewFile({
    file,
    setUploadDone,
    isReadyToUpload,
    onUploadFailed,
    buildFormData,
    uploadChunkFile
}: Readonly<{
    file: File;
    setUploadDone: () => void;
    isReadyToUpload: boolean;
    onUploadFailed: (file: File) => void;
    buildFormData: (file: File, i: number, chunk: Blob, checksum: string, totalCount: number) => FormData;
    uploadChunkFile: (formData: FormData, cancelToken: CancelToken) => Promise<AxiosResponse>;
}>): JSX.Element {
    const [progress, setProgress] = useState(0);
    const lang = useLang();
    const totalCount = file.size % CHUNK_SIZE === 0 ? file.size / CHUNK_SIZE : Math.floor(file.size / CHUNK_SIZE) + 1;

    useEffect(() => {
        // eslint-disable-next-line import/no-named-as-default-member
        const source = axios.CancelToken.source();

        async function uploadChunk(formData: FormData) {
            const response: AxiosResponse = await uploadChunkFile(formData, source.token);
            if (response.status !== 200) {
                throw new Error("error while uploading");
            }
        }

        async function uploadFile() {
            const checksum = await calculateChecksum(file);
            let startOfChunk = 0;
            let endOfChunk = CHUNK_SIZE;
            try {
                if (file.size) {
                    for (let i = 0; i <= totalCount - 1; i += 1) {
                        const chunk = file.slice(startOfChunk, endOfChunk);
                        const formData = buildFormData(file, i, chunk, checksum, totalCount);
                        // eslint-disable-next-line no-await-in-loop
                        await uploadChunk(formData);
                        setProgress((i / totalCount) * 100);
                        startOfChunk = endOfChunk;
                        endOfChunk += CHUNK_SIZE;
                    }
                    setUploadDone();
                }
            } catch (error) {
                if (error.message !== CLEANUP_MESSAGE) {
                    openNotificationWithIcon({ type: "error", description: lang.uploadFile.errorWhileUploading });
                    onUploadFailed(file);
                }
            }
        }

        if (isReadyToUpload) {
            void uploadFile();
        }

        return () => {
            source.cancel(CLEANUP_MESSAGE);
        };
    }, [file, totalCount, lang, isReadyToUpload]);

    return <UploadedFileRow file={file} progress={progress} />;
}

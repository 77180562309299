import { LogoSvgProps } from "../Logos";

export default function OnlineAssessmentOutlinedSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1915_1717)">
                <path
                    d="M469.3 0H42.7C19.1 0 0 19.1 0 42.7V469.4C0 492.9 19.1 512 42.7 512H469.4C493 512 512 492.9 512.1 469.3V42.7C512 19.1 492.9 0 469.3 0ZM42.7 17.1H469.4C483.5 17.1 495 28.6 495 42.7V102.4H17.1V42.7C17.1 28.5 28.5 17.1 42.7 17.1ZM469.3 494.9H42.7C28.6 494.9 17.1 483.4 17.1 469.3V119.5H495V469.4C494.9 483.5 483.5 494.9 469.3 494.9Z"
                    fill="url(#paint0_linear_1915_1717)"
                />
                <path
                    d="M372.6 85.3001C386.7 85.3001 398.2 73.8001 398.2 59.7001C398.2 45.6001 386.7 34.1001 372.6 34.1001C358.5 34.1001 347 45.6001 347 59.7001C347 73.9001 358.5 85.3001 372.6 85.3001ZM372.6 51.2001C377.3 51.2001 381.1 55.0001 381.1 59.7001C381.1 64.4001 377.3 68.2001 372.6 68.2001C367.9 68.2001 364.1 64.4001 364.1 59.7001C364.1 55.0001 367.9 51.2001 372.6 51.2001Z"
                    fill="url(#paint1_linear_1915_1717)"
                />
                <path
                    d="M440.9 85.3001C455 85.3001 466.5 73.8001 466.5 59.7001C466.5 45.6001 455 34.1001 440.9 34.1001C426.8 34.1001 415.3 45.6001 415.3 59.7001C415.3 73.9001 426.7 85.3001 440.9 85.3001ZM440.9 51.2001C445.6 51.2001 449.4 55.0001 449.4 59.7001C449.4 64.4001 445.6 68.2001 440.9 68.2001C436.2 68.2001 432.4 64.4001 432.4 59.7001C432.3 55.0001 436.2 51.2001 440.9 51.2001Z"
                    fill="url(#paint2_linear_1915_1717)"
                />
                <path
                    d="M155.9 392.5C151.2 392.5 147.4 396.3 147.4 401V418.1H79.1V349.8H121.8C126.5 349.8 130.3 346 130.3 341.3C130.3 336.6 126.5 332.8 121.8 332.8H79.1C69.7 332.8 62 340.4 62 349.9V418.2C62 427.6 69.6 435.3 79.1 435.3H147.4C156.8 435.3 164.5 427.7 164.5 418.2V401.1C164.4 396.4 160.6 392.5 155.9 392.5Z"
                    fill="url(#paint3_linear_1915_1717)"
                />
                <path
                    d="M236.3 204.8H304.6C309.3 204.8 313.1 201 313.1 196.3C313.1 191.6 309.3 187.8 304.6 187.8H236.3C231.6 187.8 227.8 191.6 227.8 196.3C227.8 201 231.6 204.8 236.3 204.8Z"
                    fill="url(#paint4_linear_1915_1717)"
                />
                <path
                    d="M441.1 187.7H338.7C334 187.7 330.2 191.5 330.2 196.2C330.2 200.9 334 204.7 338.7 204.7H441.1C445.8 204.7 449.6 200.9 449.6 196.2C449.6 191.5 445.8 187.7 441.1 187.7Z"
                    fill="url(#paint5_linear_1915_1717)"
                />
                <path
                    d="M441.1 221.9H236.3C231.6 221.9 227.8 225.7 227.8 230.4C227.8 235.1 231.6 238.9 236.3 238.9H441.1C445.8 238.9 449.6 235.1 449.6 230.4C449.6 225.7 445.8 221.9 441.1 221.9Z"
                    fill="url(#paint6_linear_1915_1717)"
                />
                <path
                    d="M236.3 273.1H338.7C343.4 273.1 347.2 269.3 347.2 264.6C347.2 259.9 343.4 256.1 338.7 256.1H236.3C231.6 256.1 227.8 259.9 227.8 264.6C227.8 269.3 231.6 273.1 236.3 273.1Z"
                    fill="url(#paint7_linear_1915_1717)"
                />
                <path
                    d="M441.1 256H372.8C368.1 256 364.3 259.8 364.3 264.5C364.3 269.2 368.1 273 372.8 273H441.1C445.8 273 449.6 269.2 449.6 264.5C449.6 259.8 445.8 256 441.1 256Z"
                    fill="url(#paint8_linear_1915_1717)"
                />
                <path
                    d="M236.3 358.4H304.6C309.3 358.4 313.1 354.6 313.1 349.9C313.1 345.2 309.3 341.4 304.6 341.4H236.3C231.6 341.4 227.8 345.2 227.8 349.9C227.8 354.6 231.6 358.4 236.3 358.4Z"
                    fill="url(#paint9_linear_1915_1717)"
                />
                <path
                    d="M441.1 341.3H338.7C334 341.3 330.2 345.1 330.2 349.8C330.2 354.5 334 358.3 338.7 358.3H441.1C445.8 358.3 449.6 354.5 449.6 349.8C449.6 345.2 445.8 341.3 441.1 341.3Z"
                    fill="url(#paint10_linear_1915_1717)"
                />
                <path
                    d="M441.1 375.5H236.3C231.6 375.5 227.8 379.3 227.8 384C227.8 388.7 231.6 392.5 236.3 392.5H441.1C445.8 392.5 449.6 388.7 449.6 384C449.6 379.3 445.8 375.5 441.1 375.5Z"
                    fill="url(#paint11_linear_1915_1717)"
                />
                <path
                    d="M338.7 409.6H236.3C231.6 409.6 227.8 413.4 227.8 418.1C227.8 422.8 231.6 426.6 236.3 426.6H338.7C343.4 426.6 347.2 422.8 347.2 418.1C347.2 413.4 343.4 409.6 338.7 409.6Z"
                    fill="url(#paint12_linear_1915_1717)"
                />
                <path
                    d="M441.1 409.6H372.8C368.1 409.6 364.3 413.4 364.3 418.1C364.3 422.8 368.1 426.6 372.8 426.6H441.1C445.8 426.6 449.6 422.8 449.6 418.1C449.6 413.4 445.8 409.6 441.1 409.6Z"
                    fill="url(#paint13_linear_1915_1717)"
                />
                <path
                    d="M166.1 319.1L120.8 379.5L110.7 369.4C107.4 366.2 102 366.2 98.6996 369.5C95.3996 372.8 95.3996 378.1 98.5996 381.5L115.7 398.6C117.3 400.2 119.5 401.1 121.7 401.1C121.9 401.1 122.1 401.1 122.3 401.1C124.8 400.9 127 399.7 128.5 397.7L179.7 329.4C182.5 325.6 181.8 320.3 178 317.5C174.3 314.6 168.9 315.4 166.1 319.1Z"
                    fill="url(#paint14_linear_1915_1717)"
                />
                <path
                    d="M155.9 239.5C151.2 239.5 147.4 243.3 147.4 248V265.1H79.1V196.8H121.8C126.5 196.8 130.3 193 130.3 188.3C130.3 183.6 126.5 179.8 121.8 179.8H79.1C69.7 179.8 62 187.4 62 196.9V265.2C62 274.6 69.6 282.3 79.1 282.3H147.4C156.8 282.3 164.5 274.7 164.5 265.2V248C164.4 243.3 160.6 239.5 155.9 239.5Z"
                    fill="url(#paint15_linear_1915_1717)"
                />
                <path
                    d="M166.1 166.1L120.8 226.5L110.7 216.4C107.4 213.2 102 213.2 98.6996 216.5C95.3996 219.8 95.3996 225.1 98.5996 228.5L115.7 245.6C117.3 247.2 119.5 248.1 121.7 248.1C121.9 248.1 122.1 248.1 122.3 248.1C124.8 247.9 127 246.7 128.5 244.7L179.7 176.4C182.5 172.6 181.8 167.3 178 164.5C174.2 161.7 168.9 162.3 166.1 166.1Z"
                    fill="url(#paint16_linear_1915_1717)"
                />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1915_1717" x1="-11.2022" y1="-16" x2="513.264" y2="-14.8416" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint1_linear_1915_1717" x1="345.88" y1="32.5001" x2="398.323" y2="32.6227" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint2_linear_1915_1717" x1="414.18" y1="32.5001" x2="466.623" y2="32.6227" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint3_linear_1915_1717" x1="59.7578" y1="329.597" x2="164.736" y2="329.832" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint4_linear_1915_1717" x1="225.934" y1="187.269" x2="313.294" y2="188.294" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint5_linear_1915_1717" x1="327.588" y1="187.169" x2="449.823" y2="188.89" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint6_linear_1915_1717" x1="222.948" y1="221.369" x2="449.914" y2="227.762" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint7_linear_1915_1717" x1="225.188" y1="255.569" x2="347.439" y2="257.434" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint8_linear_1915_1717" x1="362.434" y1="255.469" x2="449.757" y2="256.289" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint9_linear_1915_1717" x1="225.934" y1="340.869" x2="313.294" y2="341.894" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint10_linear_1915_1717" x1="327.588" y1="340.769" x2="449.823" y2="342.49" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint11_linear_1915_1717" x1="222.948" y1="374.969" x2="449.914" y2="381.362" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint12_linear_1915_1717" x1="225.188" y1="409.069" x2="347.439" y2="410.934" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint13_linear_1915_1717" x1="362.434" y1="409.069" x2="449.757" y2="409.889" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint14_linear_1915_1717" x1="94.3483" y1="313.076" x2="181.601" y2="313.27" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint15_linear_1915_1717" x1="59.7578" y1="176.597" x2="164.736" y2="176.832" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint16_linear_1915_1717" x1="94.3483" y1="160.089" x2="181.601" y2="160.283" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <clipPath id="clip0_1915_1717">
                    <rect width="512" height="512" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

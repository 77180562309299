import classNames from "classnames";
import { Size } from "../../referentials";
import { Separator, SeparatorProps } from "../Separator/Separator";
export default function SectionTitle({
    title,
    color,
    size = "sm"
}: { title: string; size?: Extract<Size, "sm" | "lg"> } & Pick<SeparatorProps, "color">): JSX.Element {
    return (
        <div className={classNames("flex items-center gap-4", { "py-1": size === "sm", "py-4": size === "lg" })}>
            <div
                className={classNames({
                    "text-grey-primary": size === "sm",
                    "text-lg font-bold": size === "lg"
                })}
            >
                {title}
            </div>
            {color && (
                <div className="flex-1">
                    <Separator color={color} />
                </div>
            )}
        </div>
    );
}

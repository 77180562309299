export default function LogoSpinnerSvg(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            {...props}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="126px"
            height="96px"
            viewBox="0 0 126 96"
            enableBackground="new 0 0 96 96"
        >
            <defs>
                <linearGradient id="logo-gradient">
                    <stop offset="5%" stopColor="#145576" />
                    <stop offset="95%" stopColor="#10779c" />
                </linearGradient>
                <linearGradient id="logo-inner-gradient">
                    <stop offset="100%" stopColor="#1383ac" />
                    <stop offset="5%" stopColor="#114a66" />
                </linearGradient>
            </defs>
            <circle className="outter" cx="63" cy="48" r="41" fill="url(#logo-gradient)" />
            <svg id="cog-path" width="70px" height="70px" viewBox="0 0 96 96" x="28" y="13" fill="white">
                <path
                    stroke="url(#logo-inner-gradient)"
                    strokeWidth="1"
                    style={{ transformOrigin: "3rem 3rem" }}
                    d="M96,55.919V40.081c-3.104-0.646-6.545-1.228-10.255-1.739c-0.927-3.629-2.353-7.049-4.217-10.189
        c2.281-3.012,4.307-5.865,6.013-8.494L76.342,8.459c-2.629,1.706-5.482,3.731-8.493,6.013c-3.138-1.861-6.552-3.286-10.175-4.213
        C57.157,6.517,56.571,3.065,55.919,0H40.081c-0.652,3.065-1.238,6.517-1.755,10.259c-3.623,0.927-7.037,2.352-10.175,4.213
        c-3.01-2.281-5.864-4.307-8.493-6.013L8.459,19.658c1.706,2.629,3.731,5.482,6.013,8.494c-1.861,3.137-3.286,6.551-4.213,10.174
        C6.517,38.843,3.065,39.429,0,40.081v15.838c3.065,0.653,6.517,1.238,10.259,1.755c0.927,3.623,2.352,7.037,4.213,10.175
        c-2.281,3.011-4.307,5.864-6.013,8.493l11.199,11.199c2.629-1.706,5.483-3.731,8.493-6.013c3.138,1.861,6.552,3.286,10.175,4.213
        c0.517,3.742,1.103,7.193,1.755,10.259h15.838c0.652-3.065,1.238-6.517,1.755-10.259c3.628-0.928,7.047-2.355,10.188-4.221
        c2.985,2.261,5.829,4.282,8.48,6.021l11.199-11.199c-1.738-2.651-3.76-5.495-6.021-8.48c1.867-3.145,3.297-6.568,4.225-10.203"
                />
            </svg>
            <rect x="15" y="39" height="17" width="96" fill="white" />
            <svg x="4" y="42" width="117.451" height="11" viewBox="0 0 117.451 14" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path strokeWidth="0.5" stroke="black" d="M 7.607 14 L 5.4 14 L 0 0 L 2.148 0 L 6.504 11.523 L 10.869 0 L 13.008 0 L 7.607 14 Z" />
                {/* eslint-disable max-len */}
                <path
                    strokeWidth="0.5"
                    stroke="black"
                    d="M 18.203 0 L 20.205 0 L 25.605 14 L 23.604 14 L 22.363 10.781 L 16.045 10.781 L 14.805 14 L 12.803 14 L 18.203 0 Z M 19.209 2.607 L 16.807 8.779 L 21.592 8.779 L 19.209 2.607 Z"
                />
                <path
                    strokeWidth="0.5"
                    stroke="black"
                    d="M 28.008 12 L 28.008 0 L 30.01 0 L 30.01 12 L 38.008 12 L 38.008 0 L 40.01 0 L 40.01 12 A 1.958 1.958 0 0 1 39.854 12.778 A 2.011 2.011 0 0 1 39.424 13.418 A 2.011 2.011 0 0 1 38.784 13.848 A 1.958 1.958 0 0 1 38.008 14 L 30.01 14 A 1.958 1.958 0 0 1 29.233 13.848 A 2.011 2.011 0 0 1 28.594 13.418 A 2.011 2.011 0 0 1 28.164 12.778 A 1.958 1.958 0 0 1 28.008 12.002 Z"
                    id="2"
                />
                <path strokeWidth="0.5" stroke="black" d="M 43.604 0 L 45.605 0 L 45.605 12.002 L 52.803 12.002 L 52.803 14 L 43.604 14 L 43.604 0 Z" id="3" />
                <path
                    strokeWidth="0.5"
                    stroke="black"
                    d="M 49.602 2 L 49.602 0 L 67.803 0 L 67.803 2 L 58.203 2 L 58.203 14 L 56.201 14 L 56.201 2 L 51.602 2 Z"
                    id="4"
                />
                <path strokeWidth="0.5" stroke="black" d="M 65.205 5 L 67.207 5 L 67.207 14 L 65.205 14 L 65.205 5 Z" id="5" />
                <path
                    strokeWidth="0.5"
                    stroke="black"
                    d="M 72.803 3.145 L 72.803 14 L 70.801 14 L 70.801 0 L 72.803 0 L 81.221 10.859 L 81.221 0 L 83.223 0 L 83.223 14 L 81.221 14 L 72.803 3.145 Z"
                    id="6"
                />
                <path
                    strokeWidth="0.5"
                    stroke="black"
                    d="M 86.816 12 L 86.816 0 L 88.818 0 L 88.818 12 L 96.816 12 L 96.816 0 L 98.818 0 L 98.818 12 A 1.958 1.958 0 0 1 98.662 12.778 A 2.011 2.011 0 0 1 98.232 13.418 A 2.011 2.011 0 0 1 97.593 13.848 A 1.958 1.958 0 0 1 96.816 14 L 88.818 14 A 1.958 1.958 0 0 1 88.042 13.848 A 2.011 2.011 0 0 1 87.402 13.418 A 2.011 2.011 0 0 1 86.973 12.778 A 1.958 1.958 0 0 1 86.816 12 Z"
                    id="7"
                />
                <path
                    strokeWidth="0.5"
                    stroke="black"
                    d="M 110.234 14 L 109.639 14 L 104.414 3.74 L 104.414 14 L 102.412 14 L 102.412 0 L 104.6 0 L 109.932 10.547 L 115.273 0 L 117.451 0 L 117.451 14 L 115.459 14 L 115.459 3.74 L 110.234 14 Z"
                    id="8"
                />
                {/* eslint-enable */}
            </svg>
            <text x="48" y="60" fontWeight="lighter" fontSize="6px" fontFamily="Segoe UI, sans-serif">
                SINCE 1976
            </text>
        </svg>
    );
}

import { FileInfo } from "@vaultinum/vaultinum-api";
import { get, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import {
    AddIcon,
    Button,
    Buttons,
    Column,
    FileTreeRecord,
    FilterProps,
    FiltersSortLocalStorageService,
    ListStorageKey,
    SortDirection,
    SortProps,
    Table as TableComponent,
    TableProps,
    Tables,
    TreeTableProps,
    isNotNull
} from "../../../../../common";
import { FileTree as FileTreeComponent, FileTreeProps } from "../../../components";
import { Layout } from "../Layout";

export interface ListLayoutProps<T, U> {
    list: T[];
    render: (list: T[]) => JSX.Element;
    add?: {
        onAdd: () => void;
        label?: string;
    };
    // Won't have effect if used with searchNavigation
    searchableKeys?: (keyof T | `${string}.${string}`)[];
    filters?: FilterProps<T>[];
    sorts?: SortProps<T>[];
    searchNavigation?: {
        getMatchingItems: (list: T[], searchText: string) => U[];
        onCurrentMatchingItemChange: (item: U | undefined) => void;
    };
    storageKey?: ListStorageKey;
}

export namespace Layouts {
    export function Table<T extends object>({ columns, data, buttons, ...props }: TableProps<T> & { buttons?: JSX.Element }): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>();

        return (
            <Layout
                setSearchText={setSearchText}
                buttons={buttons}
                resultsCount={resultsCount}
                render={
                    <TableComponent
                        {...props}
                        columns={columns}
                        data={props.onLazyLoad ? [] : data ?? []}
                        searchText={searchText}
                        onFilter={count => setResultsCount(count)}
                    />
                }
            />
        );
    }

    export function TableTree<T extends { children?: T[] }>({ columns, data, buttons, ...props }: TreeTableProps<T> & { buttons?: JSX.Element }): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>(0);
        return (
            <Layout
                setSearchText={setSearchText}
                resultsCount={resultsCount}
                buttons={buttons}
                render={<Tables.Tree columns={columns} data={data} searchText={searchText} onFilter={setResultsCount} {...props} />}
            />
        );
    }

    export function FileTree<T extends FileInfo>({
        structure,
        extraColumns = [] as Column<FileTreeRecord<T>>[],
        onExpand,
        onFileNameClicked,
        buttons,
        isTooltipEnabled,
        ...props
    }: FileTreeProps<T>): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>(0);

        return (
            <Layout
                setSearchText={setSearchText}
                resultsCount={resultsCount}
                buttons={buttons}
                render={
                    <FileTreeComponent
                        structure={structure}
                        extraColumns={extraColumns}
                        onExpand={onExpand}
                        onFileNameClicked={onFileNameClicked}
                        searchText={searchText}
                        onFilter={setResultsCount}
                        isTooltipEnabled={isTooltipEnabled}
                        {...props}
                    />
                }
            />
        );
    }

    export function List<T, U>({ add, searchNavigation, searchableKeys, filters, sorts, list, render, storageKey }: ListLayoutProps<T, U>): JSX.Element {
        const filtersSortService = storageKey ? new FiltersSortLocalStorageService(storageKey) : undefined;
        const [searchText, setSearchText] = useState<string>("");
        const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: unknown[] }>(filtersSortService?.loadFilters() ?? {});
        const [selectedSorts, setSelectedSorts] = useState<{ [key: string]: SortDirection } | undefined>(filtersSortService?.loadSorts() ?? {});
        const [matchingItems, setMatchingItems] = useState<U[]>();
        const [currentItemIndex, setCurrentItemIndex] = useState<number>();

        function onNext(): void {
            if (!matchingItems?.length) {
                return;
            }
            const newIndex = ((currentItemIndex ?? 0) + 1) % matchingItems.length;
            setCurrentItemIndex(newIndex);
            searchNavigation?.onCurrentMatchingItemChange(matchingItems[newIndex]);
        }

        function onPrevious(): void {
            if (!matchingItems?.length) {
                return;
            }
            const newIndex = ((currentItemIndex ?? 0) + matchingItems.length - 1) % matchingItems.length;
            setCurrentItemIndex(newIndex);
            searchNavigation?.onCurrentMatchingItemChange(matchingItems[newIndex]);
        }

        useEffect(() => {
            if (searchText) {
                setMatchingItems(searchNavigation?.getMatchingItems(listFilteredAndSorted, searchText));
                setCurrentItemIndex(0);
                searchNavigation?.onCurrentMatchingItemChange(matchingItems?.[0]);
            } else {
                setMatchingItems(undefined);
                setCurrentItemIndex(undefined);
                searchNavigation?.onCurrentMatchingItemChange(undefined);
            }
        }, [searchText]);

        function onFilterChange(filterKey: string, selectedOptions: (string | null)[]): void {
            setSelectedFilters(prev => ({
                ...prev,
                [filterKey]: selectedOptions
            }));
            filtersSortService?.saveFilter(filterKey, selectedOptions);
        }

        function doSort(updatedSort: { [key: string]: SortDirection } | undefined): void {
            setSelectedSorts(updatedSort);
            // only one sort for now
            const [key, direction] = Object.entries(updatedSort ?? {})[0];
            filtersSortService?.saveSort(key, direction);
        }

        const listFilteredByText = list.filter(item => {
            if (searchableKeys?.length) {
                return searchableKeys.some(key => String(get(item, key))?.toLowerCase().includes(searchText.toLowerCase()));
            }
            return true;
        });

        function applyFilters(item: T): T | null {
            return Object.entries(selectedFilters).reduce((acc: T | null, [key, value]: [string, unknown[]]) => {
                if (!acc || !value?.length) {
                    return acc;
                }
                const selectedFilter = filters?.find(filter => filter.key === key);
                if (!selectedFilter) {
                    return acc;
                }
                return selectedFilter.onFilter(acc, value as string[]);
            }, item);
        }

        function applySorts(itemA: T, itemB: T): number {
            const currentSorts = Object.keys(selectedSorts ?? {});
            // only one sort for now
            if (!currentSorts.length) {
                return 0;
            }
            const selectedSort = sorts?.find(sort => sort.key === currentSorts[0]);
            if (!selectedSort) {
                return 0;
            }
            return selectedSort.onSort?.(itemA, itemB, selectedSorts?.[currentSorts[0]]) ?? 0;
        }

        const listFilteredAndSorted = (searchNavigation ? list : listFilteredByText).map(applyFilters).filter(isNotNull).sort(applySorts);

        const results = searchNavigation ? searchNavigation.getMatchingItems(listFilteredAndSorted, searchText).length : listFilteredAndSorted?.length;

        return (
            <Layout
                render={render(listFilteredAndSorted)}
                setSearchText={setSearchText}
                filters={
                    <>
                        {filters?.map(({ label, options, key }) => (
                            <Buttons.Filter
                                key={label}
                                label={label}
                                list={uniqBy(options, "label")}
                                onSubmit={selectedValues => onFilterChange(key, selectedValues ?? [])}
                                defaultList={(selectedFilters[key] as string[]) ?? []}
                            />
                        ))}
                        {sorts && <Buttons.Sort sorts={sorts} onSubmit={doSort} defaultSort={selectedSorts} />}
                    </>
                }
                buttons={add && <Button children={add.label} icon={AddIcon} onClick={add.onAdd} data-id="btn-add-item" isLoading={false} />}
                resultsCount={results}
                {...(searchNavigation && matchingItems?.length && { searchProps: { navigation: { onNext, onPrevious } } })}
            />
        );
    }
}

import classNames from "classnames";
import { Skeleton } from "../Skeleton";

export namespace Skeletons {
    export function Row({ row }: { row: string[] }) {
        return (
            <div className="flex flex-1 items-center gap-5">
                {row.map((className, i) => (
                    <Skeleton key={i} className={className} />
                ))}
            </div>
        );
    }

    export function Col({ className, col }: { className?: string; col: string[] }) {
        return (
            <div className={classNames("flex flex-1 flex-col", className ?? "gap-2.5")}>
                {col.map((colClassName, i) => (
                    <Skeleton key={i} className={colClassName} />
                ))}
            </div>
        );
    }
}

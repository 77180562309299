import { BaseTrackingEvents, HardRedirect, trackingService, useAuthContext, useLang, usePathTracker, useWhiteLabelContext } from "@vaultinum/vaultinum-sdk";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import AuthenticationLayout from "./components/AuthenticationLayout";
import { PATH, URL, VAULTINUM_BRAND_NAME } from "./services";
import LoggedPage from "./views/private/LoggedPage";
import { EmailActionHandlers, ForgotPasswordPage, LoginPage, RegisterPage } from "./views/public";

function WhiteLabelSubtitle(): JSX.Element | null {
    return (
        <span className="mt-4 flex space-x-1 normal-case italic">
            <span>Powered by</span>
            <span className="font-semibold">{process.env.REACT_APP_BRAND_NAME ?? VAULTINUM_BRAND_NAME}</span>
        </span>
    );
}

function PublicRoutes() {
    const lang = useLang();
    const { whiteLabelDomain } = useWhiteLabelContext();
    return (
        <Routes>
            <Route
                path={PATH.login}
                element={
                    <AuthenticationLayout>
                        <Helmet>
                            <title>
                                {lang.seo.loginTitle} | {process.env.REACT_APP_BRAND_NAME ?? VAULTINUM_BRAND_NAME}
                            </title>
                            <meta property="description" content={lang.seo.loginDescription} />
                        </Helmet>
                        <LoginPage logoSubtitle={whiteLabelDomain ? <WhiteLabelSubtitle /> : null} />
                    </AuthenticationLayout>
                }
            />
            <Route
                path={PATH.register}
                element={
                    <AuthenticationLayout>
                        <Helmet>
                            <title>
                                {lang.seo.registerTitle} | {process.env.REACT_APP_BRAND_NAME ?? VAULTINUM_BRAND_NAME}
                            </title>
                            <meta property="description" content={lang.seo.registerDescription} />
                        </Helmet>
                        <RegisterPage logoSubtitle={whiteLabelDomain ? <WhiteLabelSubtitle /> : null} />
                    </AuthenticationLayout>
                }
            />
            <Route
                path={PATH.forgotPassword}
                element={
                    <AuthenticationLayout>
                        <ForgotPasswordPage />
                    </AuthenticationLayout>
                }
            />
            <Route path={PATH.all} element={<HardRedirect path={URL.login} />} />
        </Routes>
    );
}

function PrivateRoutes() {
    usePathTracker(path => trackingService.sendEvent(BaseTrackingEvents.PageVisited, { path }));
    const lang = useLang();
    return (
        <>
            <LoggedPage />
            <Helmet>
                <title>
                    {lang.seo.homeTitle} | {process.env.REACT_APP_BRAND_NAME ?? VAULTINUM_BRAND_NAME}
                </title>
                <meta property="description" content={lang.seo.homeDescription} />
            </Helmet>
        </>
    );
}

function AppRouter(): JSX.Element {
    const { user, userProfile } = useAuthContext();
    return (
        <Routes>
            <Route
                path={PATH.index}
                element={
                    <Routes>
                        <Route
                            path={PATH.authAction}
                            element={
                                <AuthenticationLayout>
                                    <EmailActionHandlers />
                                </AuthenticationLayout>
                            }
                        />
                        <Route
                            path={PATH.all}
                            element={
                                <>
                                    {!user && <PublicRoutes />}
                                    {user && userProfile && <PrivateRoutes />}
                                </>
                            }
                        />
                    </Routes>
                }
            />
        </Routes>
    );
}

export default AppRouter;

/* eslint-disable import/no-unused-modules */
import classNames from "classnames";
import { ElementType, ReactNode } from "react";
import { Spin } from "..";
import { Color } from "../../referentials";
import { BaseCardContainer, BaseCardContainerInterface } from "../BaseCardContainer";
import { CardCommon } from "../Card/CardCommon";
import { IconProps } from "../Icons";

export interface ColCardInterface extends Omit<BaseCardContainerInterface, "border"> {
    color?: Color;
    img?: CardCommon.WithImage;
    footer?: ReactNode;
    title?: CardCommon.WithTitle;
    icon?: ElementType<IconProps>;
}

export function ColCard({ img, header, color, title, children, footer, ...props }: ColCardInterface): JSX.Element {
    const headerWithPadding = header ? <div className="p-5">{header}</div> : undefined;
    const headerElement = img?.url ? (
        <div
            className={classNames("h-28 w-full bg-center bg-no-repeat", img.isCover ? "bg-cover" : "bg-contain")}
            style={{ backgroundImage: `url("${img.url}")` }}
            children={headerWithPadding}
        />
    ) : (
        headerWithPadding
    );
    return (
        <BaseCardContainer
            header={
                img?.isLoading ? (
                    <div className="w-full h-28 flex items-center justify-center">
                        <Spin />
                    </div>
                ) : (
                    headerElement
                )
            }
            children={
                <div className="flex w-full flex-col gap-4 px-5 py-2">
                    <div className="flex flex-grow flex-col gap-2">
                        {title && <CardCommon.Title {...title} />}
                        <div className="flex w-full flex-1">{children}</div>
                    </div>
                </div>
            }
            {...(color && { border: { color, position: "top" } })}
            {...(footer && { footer: <div className="w-full">{footer}</div> })}
            {...props}
        />
    );
}

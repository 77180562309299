import { Account, AccountRights } from "@vaultinum/vaultinum-api";
import {
    Button,
    Modal,
    PeopleLogo,
    RowCard,
    displayMostRecentDate,
    openNotificationWithIcon,
    useAuthContext,
    useHasAccountRights,
    useLang,
    useModal
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { AccountLang } from "../lang/AccountLang";
import { acceptPartnershipInvitation, rejectPartnershipInvitation } from "../services";

function AcceptModalContent({ invitation }: { invitation: Account.PartnerInvitation }): JSX.Element {
    const lang = useLang<AccountLang>();
    const { userProfile } = useAuthContext();

    return (
        <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-4">
                <PeopleLogo size="xs" />
                <h2>{lang.accountSettings.partiesView.linkPartyAccount}</h2>
            </div>
            <span>{lang.accountSettings.partiesView.startWorkingTogether(invitation.sentByCompanyName)}</span>
            {userProfile && userProfile.accountIds.length > 1 && <>{/* TODO KYS-1711 */}</>}
        </div>
    );
}

function InvitationActions({ invitation, account }: { invitation: Account.PartnerInvitation; account: Account }): JSX.Element {
    const lang = useLang<AccountLang>();
    const rejectModal = useModal();
    const acceptModal = useModal();
    const [isWorking, setIsWorking] = useState(false);
    const canUserAccept = useHasAccountRights(AccountRights.WRITE);

    async function accept(): Promise<void> {
        try {
            setIsWorking(true);
            await acceptPartnershipInvitation(invitation.accountId, account.id);
            acceptModal.doClose();
            openNotificationWithIcon({ type: "success", description: lang.accountSettings.partiesView.isNowYourParty(invitation.sentByCompanyName) });
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
        } finally {
            setIsWorking(false);
        }
    }

    async function reject() {
        try {
            setIsWorking(true);
            await rejectPartnershipInvitation(account.id, invitation.accountId);
            rejectModal.doClose();
            openNotificationWithIcon({ type: "success", description: lang.accountSettings.partiesView.invitationRejected });
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
        } finally {
            setIsWorking(false);
        }
    }
    return (
        <div className="flex flex-col gap-2 md:flex-row">
            <Button
                data-id="reject-invitation"
                onClick={rejectModal.doOpen}
                isLoading={isWorking}
                children={lang.profileSettings.companyAccounts.rejectInvitation}
            />
            <Modal
                children={lang.accountSettings.partiesView.confirmReject(invitation.sentByCompanyName)}
                isOpen={rejectModal.isOpen}
                isLoading={isWorking}
                onConfirm={reject}
                onClose={rejectModal.doClose}
                lang={lang}
            />
            <Button
                data-id="accept-invitation"
                onClick={acceptModal.doOpen}
                isLoading={isWorking}
                children={lang.profileSettings.companyAccounts.acceptInvitation}
                {...(!canUserAccept && { title: lang.accountSettings.partiesView.reportToAdmin })}
            />
            <Modal
                children={<AcceptModalContent invitation={invitation} />}
                isOpen={acceptModal.isOpen}
                isLoading={isWorking}
                onConfirm={accept}
                onClose={acceptModal.doClose}
                lang={lang}
            />
        </div>
    );
}

export default function ReceivedInvitationCard({ invitation, account }: { invitation: Account.PartnerInvitation; account: Account }): JSX.Element {
    const lang = useLang<AccountLang>();

    return (
        <RowCard color="slate" rightChildren={<InvitationActions invitation={invitation} account={account} />}>
            <div className="flex flex-1 flex-col">
                {lang.accountSettings.partiesView.sentYouAPartyRequest(invitation?.sentByCompanyName)}
                <div className="text-grey-primary">
                    {displayMostRecentDate({ requestedDate: invitation.sentDate }, lang, true)} • {invitation.sentByEmail}
                </div>
            </div>
        </RowCard>
    );
}

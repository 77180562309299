import { ACCOUNT_API_KEYS_COLLECTION, ACCOUNT_COLLECTION, AccountApiKey } from "@vaultinum/vaultinum-api";
import { DocumentSnapshot, collection, deleteDoc, doPost, doc, getDoc, getFirestore, onSnapshot } from "@vaultinum/vaultinum-sdk";

export async function createApiKey(accountId: string, name: string, environment: AccountApiKey.Environment): Promise<AccountApiKey> {
    return doPost(`apikey/${accountId}`, { name, environment });
}

const convertDocToApiKey = (apiKeyDoc: DocumentSnapshot): AccountApiKey => ({
    ...(apiKeyDoc.data() as Omit<AccountApiKey, "id" | "creationDate" | "expirationDate">),
    id: apiKeyDoc.id,
    creationDate: apiKeyDoc.data()?.creationDate?.toDate(),
    expirationDate: apiKeyDoc.data()?.expirationDate?.toDate()
});

export function getApiKeys(accountId: string, onUpdate: (apiKeys: AccountApiKey[]) => void): () => void {
    return onSnapshot(
        collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_API_KEYS_COLLECTION),
        querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToApiKey)),
        () => onUpdate([])
    );
}

export async function getApiKey(accountId: string, apiKeyId: string): Promise<AccountApiKey | null> {
    const apiKeyDoc = await getDoc(doc(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_API_KEYS_COLLECTION, apiKeyId));
    return convertDocToApiKey(apiKeyDoc);
}

export async function deleteApiKey(accountId: string, apiKeyId: string): Promise<void> {
    return deleteDoc(doc(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_API_KEYS_COLLECTION, apiKeyId));
}

import { Account, AccountRights, AccountUser } from "@vaultinum/vaultinum-api";
import {
    CompanyInformationForm,
    getAccountUsers,
    getLangCode,
    openNotificationWithIcon,
    updateAccountInfo,
    useAuthContext,
    useForm,
    useHasAccountRights,
    useLang,
    useRequiredString,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { ViewWrapper } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";

const CompanyInformationView = (): JSX.Element | null => {
    const lang = useLang<AccountLang>();
    const [working, setWorking] = useState(false);
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>([]);
    const { userProfile, selectedAccount } = useAuthContext();
    const schema = yup.object({
        companyName: useRequiredString(),
        companyIndustry: useRequiredString(),
        companySize: useRequiredString()
    });
    const form = useForm<Account>({
        schema,
        defaultValues: selectedAccount as Account,
        mode: "onChange"
    });

    useEffect(() => getAccountUsers(selectedAccount, setAccountUsers), [selectedAccount]);

    const isAdmin = useHasAccountRights(AccountRights.ADMIN);
    const canInvite = useHasAccountRights(AccountRights.INVITE);
    const isRepresentative = !isAdmin && canInvite;
    const fieldsDisabled = !(isAdmin || isRepresentative) || working;

    if (!accountUsers.length || !userProfile || !selectedAccount) {
        return null;
    }

    const onSubmit = async (values: Account) => {
        setWorking(true);
        try {
            await updateAccountInfo({ ...selectedAccount, ...values });
            openNotificationWithIcon({ type: "success", description: lang.shared.saveSuccessMessage });
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: lang.shared.saveErrorMessage });
        } finally {
            setWorking(false);
        }
    };
    return (
        <ViewWrapper title={lang.accountSettings.menu.generalInformation}>
            <CompanyInformationForm
                form={form}
                disableFields={fieldsDisabled}
                isAdminOrRepresentative={isAdmin || isRepresentative}
                langCode={getLangCode(userProfile)}
                onSubmit={onSubmit}
                working={working}
                displayButton
            />
        </ViewWrapper>
    );
};
export default CompanyInformationView;

import classNames from "classnames";
import { ReactNode } from "react";
import { Type } from "../../referentials";
import { ChevronDownIcon, ChevronUpIcon } from "../Icons";

export default function LegacyRowCard({
    title,
    subTitle,
    headerRight,
    subHeaderRightPrefix,
    subHeaderRightSuffix,
    content,
    footerLeft,
    footerRight,
    prefixContent,
    isExpandable,
    isActive,
    toggleAccordion,
    shadow = "light",
    type = "default"
}: {
    title: string;
    subTitle?: ReactNode;
    headerRight?: ReactNode;
    subHeaderRightPrefix?: string;
    subHeaderRightSuffix?: string;
    content?: ReactNode;
    footerLeft?: ReactNode;
    footerRight?: ReactNode;
    prefixContent?: ReactNode;
    isExpandable?: boolean;
    isActive?: boolean;
    toggleAccordion?: () => void;
    shadow?: "light" | "medium" | "3xl";
    type?: Type;
}): JSX.Element {
    const subTitleSection = () => {
        return (
            <div className="flex flex-1 items-baseline truncate">
                <div>
                    <h3 className="truncate text-2xl">{title}</h3>
                    <div className="flex items-center space-x-2">{subTitle}</div>
                </div>
            </div>
        );
    };

    const headerRightSection = () => {
        return isExpandable ? (
            <>
                {isActive && <ChevronUpIcon color="slate" />}
                {!isActive && <ChevronDownIcon color="slate" />}
            </>
        ) : (
            <div className="flex flex-col space-y-2">
                {headerRight && <div className="flex space-x-3 md:justify-end">{headerRight}</div>}
                <div className="flex items-center space-x-1 md:justify-end">
                    {subHeaderRightPrefix && <span className="text-sm font-light">{subHeaderRightPrefix}</span>}
                    <span className="text-base">{subHeaderRightSuffix}</span>
                </div>
            </div>
        );
    };

    const contentSection = () => {
        return (
            <>
                {content && (
                    <div className={classNames("mb-2 h-full overflow-x-hidden text-sm font-light", { "opacity-0": !isActive && isExpandable })}>{content}</div>
                )}
                {(footerLeft || footerRight) && (
                    <div className="flex items-end gap-3">
                        {footerLeft && <div className="inline-flex flex-1 truncate">{footerLeft}</div>}
                        {footerRight && <div className="ml-auto md:text-right">{footerRight}</div>}
                    </div>
                )}
            </>
        );
    };

    return (
        <div
            className={`grid gap-3 rounded-l-xl bg-white p-3 md:flex shadow-${shadow} rounded-r-md border-l-8 border-y-neutral-300 border-r-neutral-300 border-l-${type}`}
        >
            {prefixContent && <div className="flex items-center justify-center">{prefixContent}</div>}
            <div className={classNames("flex w-full flex-col", { "cursor-pointer": isExpandable })} onClick={isExpandable ? toggleAccordion : undefined}>
                <div className={classNames("grid items-start md:flex", { "items-center duration-300": isExpandable })}>
                    {subTitleSection()}
                    {headerRightSection()}
                </div>
                {isExpandable ? (
                    <div className={classNames("overflow-hidden", { "h-full": isActive, "h-0": !isActive })}>{contentSection()}</div>
                ) : (
                    contentSection()
                )}
            </div>
        </div>
    );
}

import { App, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import {
    Buttons,
    Column,
    DownloadIcon,
    Spin,
    Table,
    Tag,
    downloadPDF,
    formatAsCurrency,
    formatDate,
    getProductList,
    openNotificationWithIcon,
    plural,
    useAuthContext,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import Stripe from "stripe";
import { AccountLang } from "../lang/AccountLang";
import { getInvoices, getProductName } from "../services";
import ViewWrapper from "./ViewWrapper";

type InvoiceStatus = Extract<Stripe.Invoice.Status, "open" | "void" | "uncollectible" | "paid">;

function convertStatusToType(status: InvoiceStatus): "default" | "danger" | "warning" | "success" {
    switch (status) {
        case "open":
            return "default";
        case "void":
            return "danger";
        case "uncollectible":
            return "warning";
        default:
            return "success";
    }
}

function getColumns(
    langCode: SupportedLanguageCode,
    lang: AccountLang,
    isDownloading: { [key: string]: boolean },
    downloadInvoice: (invoiceId: string | undefined, invoiceNumber: string | null) => Promise<void>
): Column<Stripe.Invoice>[] {
    const productList = getProductList(lang);

    return [
        {
            header: lang.accountSettings.billingView.invoice.status.name,
            accessorKey: "status",
            cell: cell => {
                const status = cell.getValue<InvoiceStatus>();
                return <Tag message={lang.accountSettings.billingView.invoice.status[status]} type={convertStatusToType(status)} />;
            },
            size: 80
        },
        {
            header: lang.accountSettings.billingView.invoice.invoiceNumber,
            accessorKey: "number",
            size: 140
        },
        {
            header: lang.accountSettings.billingView.invoice.date,
            accessorFn: row => formatDate(new Date(row.created), langCode),
            size: 100
        },
        {
            header: lang.accountSettings.billingView.invoice.product,
            accessorFn: row => row.lines.data.map(line => getProductName(line.price?.metadata.plan as App.PricingPlans, productList)),
            cell: cell => {
                const lines = cell.getValue<string[]>();
                return (
                    <div className="space-y-2">
                        {lines.map(line => (
                            <p key={line}>{line}</p>
                        ))}
                    </div>
                );
            }
        },
        {
            header: lang.accountSettings.billingView.invoice.amount,
            accessorFn: row => row.total / 100,
            cell: cell => formatAsCurrency(cell.getValue<number>(), langCode),
            size: 120
        },
        {
            header: plural(lang.accountSettings.billingView.invoice.label, 1),
            accessorFn: row => row,
            cell: cell => {
                const invoice = cell.getValue<Stripe.Invoice>();
                return (
                    <>
                        {invoice.invoice_pdf && invoice.number && (
                            <Buttons.Icon
                                isLoading={isDownloading[invoice.number] || false}
                                onClick={async () => {
                                    await downloadInvoice(invoice.id, invoice.number);
                                }}
                                icon={DownloadIcon}
                                title={lang.payment.downloadReceipt}
                            />
                        )}
                    </>
                );
            },
            enableColumnFilter: false,
            enableSorting: false,
            size: 80
        }
    ];
}

export default function InvoiceList({ langCode }: { langCode: SupportedLanguageCode }): JSX.Element {
    const [invoices, setInvoices] = useState<Stripe.Invoice[]>([]);
    const [working, setWorking] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<{ [key: string]: boolean }>();
    const { selectedAccount: account } = useAuthContext();

    const lang = useLang<AccountLang>();

    useEffect(() => {
        void (async function () {
            setWorking(true);
            try {
                if (account) {
                    const invoiceList = await getInvoices(account?.id);
                    setInvoices(invoiceList);
                }
            } catch (e) {
                openNotificationWithIcon({ type: "error", description: lang.accountSettings.billingView.invoice.failed });
            } finally {
                setWorking(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account?.id]);

    const downloadInvoice = async (invoiceId: string | undefined, invoiceNumber: string | null) => {
        if (invoiceId && invoiceNumber) {
            setIsDownloading(prev => ({ ...prev, [invoiceNumber]: true }));
            try {
                await downloadPDF(`account/${account?.id}/invoices/${invoiceId}`, `Invoice-${invoiceNumber}`);
            } catch (e) {
                openNotificationWithIcon({ type: "error", description: lang.accountSettings.billingView.invoice.failedToDownloadInvoice });
            } finally {
                setIsDownloading(prev => ({ ...prev, [invoiceNumber]: false }));
            }
        }
    };

    if (working) {
        return (
            <div className="flex w-full items-center justify-center">
                <Spin />
            </div>
        );
    }

    const columns = getColumns(langCode, lang, isDownloading || {}, downloadInvoice);

    return (
        <ViewWrapper title={plural(lang.accountSettings.billingView.invoice.label, 2)}>
            <Table<Stripe.Invoice> data={invoices} columns={columns} />
        </ViewWrapper>
    );
}

import { SupportedLanguageCode, Survey, SURVEY_VERSION_LANG_COLLECTION } from "@vaultinum/vaultinum-api";
import { collection, CollectionReference, converter, doc, DocumentReference, getItem, getItems, Unsubscribe } from "../../../common";
import { surveyVersionDoc } from "./surveyVersionService";

export function surveyLangCollection(surveyKey: string, version: number): CollectionReference<Survey.Lang> {
    return collection(surveyVersionDoc(surveyKey, version), SURVEY_VERSION_LANG_COLLECTION).withConverter(converter<Survey.Lang>({ idKey: "lang" }));
}

export function surveyLangDoc(surveyKey: string, version: number, lang: SupportedLanguageCode): DocumentReference<Survey.Lang> {
    return doc(surveyLangCollection(surveyKey, version), lang);
}

export function getSurveyLang(surveyKey: string, version: number, lang: SupportedLanguageCode): Promise<Survey.Lang | null>;
export function getSurveyLang(surveyKey: string, version: number, lang: SupportedLanguageCode, onUpdate: (surveyLang: Survey.Lang | null) => void): Unsubscribe;
export function getSurveyLang(
    surveyKey: string,
    version: number,
    lang: SupportedLanguageCode,
    onUpdate?: (surveyLang: Survey.Lang | null) => void
): Promise<Survey.Lang | null> | Unsubscribe {
    if (onUpdate) {
        return getItem(surveyLangDoc(surveyKey, version, lang), onUpdate);
    }
    return getItem(surveyLangDoc(surveyKey, version, lang));
}

export function getSurveyLangs(surveyKey: string | undefined, version: number): Promise<Survey.Lang[]> | undefined;
export function getSurveyLangs(surveyKey: string | undefined, version: number, onUpdate: (surveyLangs: Survey.Lang[]) => void): Unsubscribe;
export function getSurveyLangs(
    surveyKey: string | undefined,
    version: number,
    onUpdate?: (surveyLangs: Survey.Lang[]) => void
): Promise<Survey.Lang[]> | Unsubscribe | undefined {
    if (!surveyKey) {
        return undefined;
    }
    if (onUpdate) {
        return getItems(surveyLangCollection(surveyKey, version), onUpdate);
    }
    return getItems(surveyLangCollection(surveyKey, version));
}

import { Account, AccountRights, EMAIL_REGEX, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { useState } from "react";
import { Modal, openNotificationWithIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { useHasAccountRights, useRequiredString } from "../../hooks";
import { updateAccountInfo, updateBillingDetails, yup } from "../../services";
import { getBillingDetailsErrorMessage, useForm } from "../../tools";
import { CompanyBillingDetailsForm, CompanyInformationForm } from "../forms";

type CompanyInformationFields = Pick<Account, "billingDetails" | "companyName" | "companyIndustry" | "companySize">;

export default function UpdateCompanyInformationModal({
    isOpen = false,
    account,
    langCode,
    closeModal,
    onlyUpdateBillingInfo,
    requireAddress,
    isWhiteLabelOwner,
    allFieldsRequired
}: {
    isOpen: boolean;
    account: Account;
    langCode: SupportedLanguageCode;
    closeModal: () => void;
    onlyUpdateBillingInfo?: boolean;
    requireAddress?: boolean;
    isWhiteLabelOwner?: boolean;
    allFieldsRequired?: boolean;
}) {
    const lang = useLang<CommonLang>();
    const hasAdminRight = useHasAccountRights(AccountRights.ADMIN);
    const hasInviteRight = useHasAccountRights(AccountRights.INVITE);
    const [working, setWorking] = useState(false);

    const schema = yup.object({
        ...(onlyUpdateBillingInfo
            ? {
                  billingDetails: yup.object({
                      name: useRequiredString(),
                      contactEmail: yup.string().matches(EMAIL_REGEX, { message: lang.shared.invalidEmail, excludeEmptyString: true }),
                      vatNumber: yup.string().trim().optional(),
                      address: yup.object({
                          line1: yup.string().trim().required(),
                          city: yup.string().trim().required(),
                          postalCode: yup.string().trim().required(),
                          country: yup.string().trim().required()
                      })
                  })
              }
            : {
                  companyName: useRequiredString(),
                  companyIndustry: useRequiredString(),
                  companySize: useRequiredString(),
                  companyRegistrationNumber: useRequiredString(),
                  companyNationality: useRequiredString(),
                  companyAddress: yup.object({
                      line1: useRequiredString(),
                      city: useRequiredString(),
                      postalCode: useRequiredString(),
                      country: useRequiredString()
                  })
              })
    } satisfies Partial<Record<keyof CompanyInformationFields, yup.AnySchema>>);

    const form = useForm<Account>({
        schema,
        defaultValues: {
            ...account,
            billingDetails: {
                ...account.billingDetails,
                name: account.billingDetails?.name ?? account.companyName
            }
        },
        mode: "onChange"
    });

    const isRepresentative = !hasAdminRight && hasInviteRight;
    const disableFields = (!(hasAdminRight || isRepresentative) || working) && !isWhiteLabelOwner;

    const onSubmit = async (values: Account) => {
        setWorking(true);
        try {
            if (onlyUpdateBillingInfo) {
                await updateBillingDetails(account.id, values.billingDetails);
            } else {
                await updateAccountInfo({ ...account, ...values });
            }
            openNotificationWithIcon({ type: "success", description: lang.shared.saveSuccessMessage });
            closeModal();
        } catch (error) {
            const { response } = error;
            openNotificationWithIcon({ type: "error", description: getBillingDetailsErrorMessage(response?.data, lang) });
        } finally {
            setWorking(false);
        }
    };

    return (
        <Modal
            title={onlyUpdateBillingInfo ? lang.payment.billing.billingInformation : lang.accountInformation.title}
            onConfirm={form.handleSubmit(onSubmit)}
            onClose={closeModal}
            isLoading={working}
            cancelText={lang.shared.cancel}
            okText={lang.shared.save}
            isOpen={isOpen}
            lang={lang}
            size="md"
            isDisabled={!form.formState.isValid}
        >
            {!onlyUpdateBillingInfo && (
                <CompanyInformationForm
                    disableFields={disableFields}
                    form={form}
                    onSubmit={onSubmit}
                    langCode={langCode}
                    isAdminOrRepresentative={hasAdminRight || isRepresentative}
                    isWhiteLabelOwner={isWhiteLabelOwner}
                    allFieldsRequired={allFieldsRequired}
                />
            )}
            {onlyUpdateBillingInfo && (
                <CompanyBillingDetailsForm requireAddress={requireAddress} disableFields={disableFields} form={form} onSubmit={onSubmit} langCode={langCode} />
            )}
        </Modal>
    );
}

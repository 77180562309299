import { IconSvgProps } from "../Icons";

function SearchSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.6499 21.1741L15.6937 14.2179C16.7731 12.8223 17.3571 11.1161 17.3571 9.32145C17.3571 7.17324 16.5187 5.15895 15.0026 3.6402C13.4865 2.12145 11.4669 1.28574 9.32136 1.28574C7.17582 1.28574 5.15618 2.12413 3.64011 3.6402C2.12136 5.15627 1.28564 7.17324 1.28564 9.32145C1.28564 11.467 2.12404 13.4866 3.64011 15.0027C5.15618 16.5215 7.17314 17.3572 9.32136 17.3572C11.116 17.3572 12.8196 16.7732 14.2151 15.6965L21.1714 22.65C21.1918 22.6704 21.216 22.6866 21.2426 22.6977C21.2693 22.7087 21.2979 22.7144 21.3267 22.7144C21.3556 22.7144 21.3841 22.7087 21.4108 22.6977C21.4375 22.6866 21.4617 22.6704 21.4821 22.65L22.6499 21.4848C22.6703 21.4644 22.6865 21.4402 22.6976 21.4136C22.7086 21.3869 22.7143 21.3583 22.7143 21.3295C22.7143 21.3006 22.7086 21.2721 22.6976 21.2454C22.6865 21.2187 22.6703 21.1945 22.6499 21.1741ZM13.5642 13.5643C12.4285 14.6973 10.9231 15.3215 9.32136 15.3215C7.71957 15.3215 6.21422 14.6973 5.0785 13.5643C3.94547 12.4286 3.32136 10.9232 3.32136 9.32145C3.32136 7.71966 3.94547 6.21163 5.0785 5.07859C6.21422 3.94556 7.71957 3.32145 9.32136 3.32145C10.9231 3.32145 12.4312 3.94288 13.5642 5.07859C14.6973 6.21431 15.3214 7.71966 15.3214 9.32145C15.3214 10.9232 14.6973 12.4313 13.5642 13.5643Z" />
        </svg>
    );
}

export default SearchSvg;

import { Account } from "@vaultinum/vaultinum-api";
import {
    addQueryParamsToUrl,
    Breadcrumb,
    displayMostRecentDate,
    ExclamationIcon,
    formatAddress,
    LocationIcon,
    MailIcon,
    openNotificationWithIcon,
    RowCard,
    Spin,
    Summary,
    useAuthContext,
    useLang,
    useUrlSearch
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import Flag from "react-flagkit";
import { SubMenuItemKeys } from "../../../helpers";
import { AccountLang } from "../../../lang/AccountLang";
import { getPartner, getPartnerAccount, URL } from "../../../services";

export default function PartnerView(): JSX.Element {
    const { id } = useUrlSearch() as { id: string };
    const lang = useLang<AccountLang>();
    const { selectedAccount } = useAuthContext();
    const [partnerAccount, setPartnerAccount] = useState<Account>();
    const [partner, setPartner] = useState<Account.Partner>();
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const accountId = selectedAccount?.id;
    useEffect(() => {
        void (async function fetchData() {
            try {
                if (id && accountId) {
                    setIsLoading(true);
                    setPartnerAccount(await getPartnerAccount(id, accountId));
                    setPartner(await getPartner(accountId, id));
                }
            } catch (e) {
                openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, id]);

    return (
        <div className="flex flex-col gap-4">
            <Breadcrumb
                items={[
                    {
                        label: lang.accountSettings.partiesView.name,
                        href: addQueryParamsToUrl(URL.settings.index, { tab: SubMenuItemKeys.PARTIES })
                    },
                    {
                        label: partnerAccount?.companyName ?? "..."
                    }
                ]}
            />
            {isLoading && <Spin />}
            {isError && <div className="text-center italic">{lang.accountSettings.partiesView.errorOccured}</div>}
            {!isLoading && !isError && partnerAccount && partner && (
                <RowCard
                    icon={<Flag className="hidden md:flex" country={partnerAccount.companyNationality} />}
                    rightChildren={
                        <Summary
                            rows={[
                                ...(partnerAccount.companyAddress ? [{ label: LocationIcon, children: formatAddress(partnerAccount.companyAddress) }] : []),
                                ...(partner.mainContactEmail ? [{ label: MailIcon, children: partner.mainContactEmail }] : []),
                                ...(partner.note ? [{ label: ExclamationIcon, children: partner.note }] : [])
                            ]}
                        />
                    }
                >
                    <div className="flex flex-col">
                        <div className="flex items-center gap-4">
                            <h2>{partnerAccount.companyName}</h2>
                            <Flag className="flex md:hidden" country={partnerAccount.companyNationality} />
                        </div>
                        <div className="text-grey-primary">
                            <div>
                                {lang.accountInformation.form.companyRegistrationNumber}: {partnerAccount.companyRegistrationNumber}
                            </div>
                            {displayMostRecentDate({ addedDate: partner.creationDate }, lang)}
                        </div>
                    </div>
                </RowCard>
            )}
        </div>
    );
}

import { COLOR_SCHEME } from "../../../referentials";
import { LogoSvgProps } from "../Logos";

/* eslint max-len: 0 */
function VaultSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg viewBox="0 0 48 48" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.5031 43.1372H3.525C2.3625 43.1372 1.41563 42.1909 1.41563 41.0291V3.52294C1.40625 2.35175 2.35312 1.40543 3.525 1.40543H33.225C33.6187 1.40543 33.9281 1.08686 33.9281 0.702713C33.9281 0.318563 33.6094 0 33.225 0H3.525C1.58437 0 0 1.58345 0 3.52294V41.0291C0 42.9217 1.5 44.4677 3.38437 44.5427V45.8919C3.38437 47.0537 4.33125 48 5.49375 48H9.02813C9.825 48 10.575 47.5315 10.9312 46.8194L12.0469 44.552H29.5031C29.8969 44.552 30.2063 44.2335 30.2063 43.8493C30.2063 43.4558 29.8875 43.1372 29.5031 43.1372ZM9.66562 46.2011C9.54375 46.4447 9.3 46.5946 9.02813 46.5946H5.49375C5.10938 46.5946 4.79062 46.276 4.79062 45.8919V44.552H10.4719L9.66562 46.2011Z"
                fill="url(#paint0_linear_2787_37242)"
            />
            <path
                d="M44.4758 0H36.0383C35.6445 0 35.3352 0.318563 35.3352 0.702713C35.3352 1.08686 35.6539 1.40543 36.0383 1.40543H44.4758C45.6383 1.40543 46.5852 2.35175 46.5852 3.51357V41.0197C46.5852 42.1815 45.6383 43.1279 44.4758 43.1279H32.3164C31.9227 43.1279 31.6133 43.4464 31.6133 43.8306C31.6133 44.2147 31.932 44.5333 32.3164 44.5333H35.9539L37.0695 46.8007C37.4258 47.5222 38.1664 47.9813 38.9727 47.9813H42.507C43.6695 47.9813 44.6164 47.0349 44.6164 45.8731V44.5239C46.4914 44.449 48.0008 42.903 48.0008 41.0103V3.52294C48.0008 1.58345 46.4164 0 44.4758 0ZM42.507 46.5946H38.9727C38.7102 46.5946 38.457 46.4353 38.3352 46.2011L37.5289 44.552H43.2102V45.8919C43.2102 46.276 42.8914 46.5946 42.507 46.5946Z"
                fill="url(#paint1_linear_2787_37242)"
            />
            <path
                d="M12.8059 22.871H11.5215C11.1277 22.871 10.8184 23.1895 10.8184 23.5737C10.8184 23.9578 11.1371 24.2764 11.5215 24.2764H12.8059C13.1996 24.2764 13.509 23.9578 13.509 23.5737C13.509 23.1895 13.1996 22.871 12.8059 22.871Z"
                fill="url(#paint2_linear_2787_37242)"
            />
            <path
                d="M16.6223 22.871H15.3379C14.9441 22.871 14.6348 23.1895 14.6348 23.5737C14.6348 23.9578 14.9535 24.2764 15.3379 24.2764H16.6223C17.016 24.2764 17.3254 23.9578 17.3254 23.5737C17.3254 23.1895 17.0066 22.871 16.6223 22.871Z"
                fill="url(#paint3_linear_2787_37242)"
            />
            <path
                d="M20.4289 22.871H19.1445C18.7508 22.871 18.4414 23.1895 18.4414 23.5737C18.4414 23.9578 18.7602 24.2764 19.1445 24.2764H20.4289C20.8227 24.2764 21.132 23.9578 21.132 23.5737C21.132 23.1895 20.8227 22.871 20.4289 22.871Z"
                fill="url(#paint4_linear_2787_37242)"
            />
            <path
                d="M12.8059 25.7568H11.5215C11.1277 25.7568 10.8184 26.0754 10.8184 26.4595C10.8184 26.8437 11.1371 27.1623 11.5215 27.1623H12.8059C13.1996 27.1623 13.509 26.8437 13.509 26.4595C13.509 26.0754 13.1996 25.7568 12.8059 25.7568Z"
                fill="url(#paint5_linear_2787_37242)"
            />
            <path
                d="M16.6223 25.7568H15.3379C14.9441 25.7568 14.6348 26.0754 14.6348 26.4595C14.6348 26.8437 14.9535 27.1623 15.3379 27.1623H16.6223C17.016 27.1623 17.3254 26.8437 17.3254 26.4595C17.3254 26.0754 17.0066 25.7568 16.6223 25.7568Z"
                fill="url(#paint6_linear_2787_37242)"
            />
            <path
                d="M20.4289 25.7568H19.1445C18.7508 25.7568 18.4414 26.0754 18.4414 26.4595C18.4414 26.8437 18.7602 27.1623 19.1445 27.1623H20.4289C20.8227 27.1623 21.132 26.8437 21.132 26.4595C21.132 26.0754 20.8227 25.7568 20.4289 25.7568Z"
                fill="url(#paint7_linear_2787_37242)"
            />
            <path
                d="M12.8059 28.6426H11.5215C11.1277 28.6426 10.8184 28.9611 10.8184 29.3453C10.8184 29.7294 11.1371 30.048 11.5215 30.048H12.8059C13.1996 30.048 13.509 29.7294 13.509 29.3453C13.509 28.9518 13.1996 28.6426 12.8059 28.6426Z"
                fill="url(#paint8_linear_2787_37242)"
            />
            <path
                d="M15.3281 30.048H16.6125C17.0062 30.048 17.3156 29.7294 17.3156 29.3453C17.3156 28.9611 16.9969 28.6426 16.6125 28.6426H15.3281C14.9344 28.6426 14.625 28.9611 14.625 29.3453C14.625 29.7294 14.9437 30.048 15.3281 30.048Z"
                fill="url(#paint9_linear_2787_37242)"
            />
            <path
                d="M20.4289 28.6426H19.1445C18.7508 28.6426 18.4414 28.9611 18.4414 29.3453C18.4414 29.7294 18.7602 30.048 19.1445 30.048H20.4289C20.8227 30.048 21.132 29.7294 21.132 29.3453C21.132 28.9611 20.8227 28.6426 20.4289 28.6426Z"
                fill="url(#paint10_linear_2787_37242)"
            />
            <path
                d="M30.909 17.2493C30.5152 17.2493 30.2059 17.5678 30.2059 17.952V19.2075C28.5184 19.4979 27.1777 20.8378 26.8871 22.5243H25.6309C25.2371 22.5243 24.9277 22.8429 24.9277 23.227C24.9277 23.6112 25.2465 23.9297 25.6309 23.9297H26.8871C27.1777 25.6162 28.5184 26.9561 30.2059 27.2465V28.5021C30.2059 28.8956 30.5246 29.2048 30.909 29.2048C31.2934 29.2048 31.6121 28.8862 31.6121 28.5021V27.2465C33.2996 26.9561 34.6402 25.6162 34.9309 23.9297H36.1871C36.5809 23.9297 36.8902 23.6112 36.8902 23.227C36.8902 22.8429 36.5715 22.5243 36.1871 22.5243H34.9309C34.6402 20.8378 33.2996 19.4979 31.6121 19.2075V17.952C31.6121 17.5678 31.3027 17.2493 30.909 17.2493ZM33.5902 23.2364C33.5902 24.7168 32.3902 25.9161 30.909 25.9161C29.4277 25.9161 28.2277 24.7168 28.2277 23.2364C28.2277 21.756 29.4277 20.5567 30.909 20.5567C32.3902 20.5567 33.5902 21.756 33.5902 23.2364Z"
                fill="url(#paint11_linear_2787_37242)"
            />
            <path
                d="M21.3563 19.4979V18.2143C21.3563 17.2586 20.5781 16.481 19.6219 16.481H12.3281C11.3719 16.481 10.5938 17.2586 10.5938 18.2143V19.4979C10.5938 20.4536 11.3719 21.2313 12.3281 21.2313H19.6219C20.5781 21.2313 21.3563 20.4536 21.3563 19.4979ZM12 19.4979V18.2143C12 18.0363 12.15 17.8864 12.3281 17.8864H19.6219C19.8 17.8864 19.95 18.0363 19.95 18.2143V19.4979C19.95 19.676 19.8 19.8259 19.6219 19.8259H12.3281C12.15 19.8259 12 19.676 12 19.4979Z"
                fill="url(#paint12_linear_2787_37242)"
            />
            <path
                d="M40.8844 4.60046H6.70312C5.54062 4.60046 4.59375 5.54678 4.59375 6.7086V37.8622C4.59375 39.024 5.54062 39.9704 6.70312 39.9704H14.5687C14.9625 39.9704 15.2719 39.6518 15.2719 39.2676C15.2719 38.8835 14.9531 38.5649 14.5687 38.5649H6.70312C6.31875 38.5649 6 38.2464 6 37.8622V6.7086C6 6.32445 6.31875 6.00589 6.70312 6.00589H40.8844C41.2687 6.00589 41.5875 6.32445 41.5875 6.7086V9.57567C40.725 9.62252 40.0312 10.3346 40.0312 11.2153V14.2792C40.0312 15.1599 40.725 15.872 41.5875 15.9188V28.6707C40.725 28.7176 40.0312 29.4297 40.0312 30.3104V33.3742C40.0312 34.255 40.725 34.967 41.5875 35.0139V37.881C41.5875 38.2651 41.2687 38.5837 40.8844 38.5837H17.3812C16.9875 38.5837 16.6781 38.9022 16.6781 39.2864C16.6781 39.6705 16.9969 39.9891 17.3812 39.9891H40.8844C42.0469 39.9891 42.9937 39.0428 42.9937 37.881V35.0139C43.8562 34.967 44.55 34.255 44.55 33.3742V30.3104C44.55 29.4297 43.8562 28.7176 42.9937 28.6707V15.9095C43.8562 15.8626 44.55 15.1505 44.55 14.2698V11.206C44.55 10.3252 43.8562 9.61315 42.9937 9.5663V6.7086C43.0031 5.54678 42.0469 4.60046 40.8844 4.60046ZM43.1437 30.301V33.3649C43.1437 33.496 43.0406 33.5991 42.9094 33.5991H41.6719C41.5406 33.5991 41.4375 33.496 41.4375 33.3649V30.301C41.4375 30.1699 41.5406 30.0668 41.6719 30.0668H42.9094C43.0406 30.0668 43.1437 30.1699 43.1437 30.301ZM43.1437 11.2153V14.2792C43.1437 14.4103 43.0406 14.5134 42.9094 14.5134H41.6719C41.5406 14.5134 41.4375 14.4103 41.4375 14.2792V11.2153C41.4375 11.0842 41.5406 10.9811 41.6719 10.9811H42.9094C43.0406 10.9811 43.1437 11.0842 43.1437 11.2153Z"
                fill="url(#paint13_linear_2787_37242)"
            />
            <defs>
                <linearGradient id="paint0_linear_2787_37242" x1="-0.742178" y1="-1.5" x2="34.0053" y2="-1.44579" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint1_linear_2787_37242" x1="31.2548" y1="-1.49941" x2="48.0368" y2="-1.4872" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint2_linear_2787_37242" x1="10.7595" y1="22.8271" x2="13.5154" y2="22.8393" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint3_linear_2787_37242" x1="14.5759" y1="22.8271" x2="17.3318" y2="22.8393" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint4_linear_2787_37242" x1="18.3825" y1="22.8271" x2="21.1397" y2="22.8417" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint5_linear_2787_37242" x1="10.7595" y1="25.7129" x2="13.5154" y2="25.7252" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint6_linear_2787_37242" x1="14.5759" y1="25.7129" x2="17.3318" y2="25.7252" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint7_linear_2787_37242" x1="18.3825" y1="25.7129" x2="21.1397" y2="25.7276" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint8_linear_2787_37242" x1="10.7595" y1="28.5987" x2="13.5154" y2="28.6109" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint9_linear_2787_37242" x1="14.5661" y1="28.5987" x2="17.322" y2="28.6109" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint10_linear_2787_37242" x1="18.3825" y1="28.5987" x2="21.1397" y2="28.6134" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint11_linear_2787_37242" x1="24.6661" y1="16.8757" x2="36.9159" y2="16.9012" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint12_linear_2787_37242" x1="10.3583" y1="16.3325" x2="21.3804" y2="16.3875" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint13_linear_2787_37242" x1="3.71971" y1="3.49457" x2="44.6407" y2="3.59657" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default VaultSvg;

import { IconSvgProps } from "../Icons";

export default function CodeSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.45078 6.43845C8.05436 5.96626 7.48803 5.96626 7.09161 6.43845L3.29732 10.958C2.90089 11.4302 2.90089 12.1047 3.29732 12.5769L7.09161 17.0965C7.48803 17.5012 8.05437 17.5012 8.39416 17.0965C8.73395 16.6917 8.79058 15.9497 8.39416 15.545L5.27942 11.8349L8.39416 8.12484C8.79058 7.5852 8.79057 6.84319 8.45078 6.43845Z" />
            <path d="M16.5492 16.9615C16.9456 17.4337 17.512 17.4337 17.9084 16.9615L21.7027 12.442C22.0991 11.9698 22.0991 11.2953 21.7027 10.8231L17.9084 6.30355C17.512 5.89882 16.9456 5.89882 16.6058 6.30355C16.266 6.70828 16.2094 7.4503 16.6058 7.85503L19.7206 11.5651L16.6058 15.2752C16.2094 15.8148 16.2094 16.5568 16.5492 16.9615Z" />
            <path d="M13.6612 6.1012C13.1515 5.96628 12.6418 6.30356 12.5285 6.91066L10.6597 15.8823C10.603 16.1521 10.6596 16.4894 10.7729 16.7592C10.8862 17.029 11.1127 17.2314 11.3392 17.2988C11.3959 17.2988 11.5091 17.3663 11.5658 17.2988C12.0188 17.2988 12.3586 16.9616 12.4719 16.4219L14.3407 7.45031C14.5106 6.84321 14.1709 6.23611 13.6612 6.1012Z" />
        </svg>
    );
}

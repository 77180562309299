import { Account } from "@vaultinum/vaultinum-api";
import { displayMostRecentDate, openNotificationWithIcon, RowCard, Skeleton, useLang } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import Flag from "react-flagkit";
import { AccountLang } from "../lang/AccountLang";
import { getPartnerAccount } from "../services";
import { SharedProducts } from "../views/private/settings/components/SharedProducts";

export default function PartnerCard({
    partner,
    accountId,
    goToDetail
}: {
    partner: Account.Partner;
    accountId: string;
    goToDetail?: (partnerAccountId: string) => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    const [partnerAccount, setPartnerAccount] = useState<Account | null>();

    useEffect(() => {
        void (async function fetchData() {
            try {
                const account = await getPartnerAccount(partner.partnerAccountId, accountId);
                setPartnerAccount(account);
            } catch {
                openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner.partnerAccountId, accountId]);

    return (
        <RowCard
            icon={partnerAccount ? <Flag country={partnerAccount.companyNationality} /> : <Skeleton className="h-5 w-6" />}
            onClick={() => goToDetail?.(partner.partnerAccountId)}
        >
            <div className="flex flex-1 flex-col">
                {partner.name}
                <div className="text-grey-primary">
                    {displayMostRecentDate({ addedDate: partner.creationDate }, lang)} • {partner.mainContactEmail}
                </div>
            </div>
            <SharedProducts partner={partner} />
        </RowCard>
    );
}

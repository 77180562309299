import { AccountInvitation, AccountRights, AccountUser } from "@vaultinum/vaultinum-api";
import { Modal, formatDisplayName, openNotificationWithIcon, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import AccountUserRightsSelector from "../../../components/AccountUserRightsSelector";
import { AccountLang } from "../../../lang/AccountLang";
import { isAccountInvitation, updateAccountInvitationRights } from "../../../services/accountInvitationService";
import { updateAccountUserRights } from "../../../services/accountService";

function ChangeRoleDialog({
    accountUser,
    isLoggedUserAdmin,
    onClose
}: {
    accountUser: AccountUser | AccountInvitation;
    isLoggedUserAdmin: boolean;
    onClose: () => void;
}): JSX.Element {
    const [isWorking, setIsWorking] = useState(false);
    const [rights, setRights] = useState<AccountRights[]>(accountUser.rights);
    const lang = useLang<AccountLang>();

    async function updateUserAccountRole() {
        try {
            setIsWorking(true);
            if (isAccountInvitation(accountUser)) {
                await updateAccountInvitationRights(accountUser, rights);
            } else {
                await updateAccountUserRights(accountUser, rights);
            }
            openNotificationWithIcon({ type: "success", description: lang.accountSettings.userAccessView.changeRoleSuccessMessage });
            onClose();
        } catch (err) {
            openNotificationWithIcon({ type: "error", description: lang.accountSettings.userAccessView.changeRoleErrorMessage });
        } finally {
            setIsWorking(false);
        }
    }

    return (
        <Modal
            isOpen
            title={lang.accountSettings.userAccessView.changeRoleTitle(isAccountInvitation(accountUser) ? accountUser.email : formatDisplayName(accountUser))}
            onClose={onClose}
            onConfirm={updateUserAccountRole}
            isLoading={isWorking}
            children={<AccountUserRightsSelector isLoggedUserAdmin={isLoggedUserAdmin} rights={rights} onRightsChange={setRights} isDisabled={isWorking} />}
            lang={lang}
        />
    );
}

export default ChangeRoleDialog;

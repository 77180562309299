import Stripe from "stripe";
import { AddIcon, Button, CreditCardIcon, RowCard, Spin } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { usePaymentContext } from "../../contexts";
import { useModal } from "../../hooks";
import { PaymentMethodAddModal, PaymentMethodDetails } from "./methods";

export default function PaymentForm({
    paymentMethods,
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    listPaymentMethods,
    loading
}: {
    paymentMethods: Stripe.PaymentMethod[] | undefined;
    selectedPaymentMethodId: string | undefined;
    setSelectedPaymentMethodId: (id: string) => void;
    listPaymentMethods: () => Promise<void>;
    loading: boolean;
}): JSX.Element {
    const lang = useLang<CommonLang>();
    const { isOpen, doOpen, doClose } = useModal();
    const { account } = usePaymentContext();

    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
                <CreditCardIcon shade="extraLight" color="slate" size="md" />
                <span className="text-lg font-bold text-primary">{lang.payment.payment.method}</span>
            </div>
            {loading && <Spin />}
            <RowCard
                children={<span className="font-bold">{lang.payment.method.addPaymentMethod}</span>}
                rightChildren={<Button icon={AddIcon} type="primary" data-id="add-payment-method" isLoading={loading} />}
                onClick={doOpen}
            />
            {!loading &&
                paymentMethods?.map(element => {
                    return (
                        <PaymentMethodDetails.Radio
                            key={element.id}
                            paymentMethod={element}
                            radio={{
                                selectedId: selectedPaymentMethodId,
                                onValueChange: setSelectedPaymentMethodId
                            }}
                        />
                    );
                })}
            <PaymentMethodAddModal accountId={account.id} visible={isOpen} doClose={doClose} onSuccessCallback={listPaymentMethods} />
        </div>
    );
}

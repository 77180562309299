import { Buttons, ColCard, FieldValues, Form, openNotificationWithIcon, UseFormReturn, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { AccountLang } from "../../../lang/AccountLang";

export function Step<T extends FieldValues>({
    title,
    children,
    form,
    doCancel,
    doBack,
    onSubmit,
    submitText,
    stepKey,
    nextButtonDisabled,
    validateStep,
    isCard = true
}: {
    title: string;
    children: React.ReactNode;
    form: UseFormReturn<T>;
    doCancel?: () => void;
    doBack?: () => void;
    onSubmit?: (values: T) => Promise<void> | void;
    submitText?: string;
    stepKey?: string;
    nextButtonDisabled?: boolean;
    validateStep?: () => Promise<boolean>;
    isCard?: boolean;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    const [working, setWorking] = useState(false);
    return (
        <div className="mx-auto max-w-2xl" data-id={stepKey}>
            <Form
                onSubmit={form.handleSubmit(async values => {
                    if (validateStep) {
                        if (!(await validateStep())) {
                            return;
                        }
                    }
                    if (onSubmit) {
                        setWorking(true);
                        try {
                            await onSubmit(values);
                        } catch (err) {
                            openNotificationWithIcon({ type: "error", description: lang.onboarding.userProfile.errorOnSave });
                        } finally {
                            setWorking(false);
                        }
                    }
                })}
            >
                <h2 className="text-slate-primary font-bold text-center" children={title} />
                {isCard ? (
                    <ColCard>
                        <div className="w-full space-y-8 p-3" children={children} />
                    </ColCard>
                ) : (
                    children
                )}
                <div className="mt-8 flex gap-2 justify-between">
                    {doCancel && <Buttons.Cancel onClick={doCancel} isLoading={false} />}
                    {!doCancel && doBack && <Buttons.Previous onClick={doBack} isLoading={false} />}
                    <div className="ml-auto">
                        <Buttons.Next isLoading={working} isDisabled={nextButtonDisabled || !form.formState.isValid} children={submitText} />
                    </div>
                </div>
            </Form>
        </div>
    );
}

import { IconSvgProps } from "../../../../../common";

export default function ScopeSoftwareOutlinedSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 8.28932H22V19.0755C22 19.8511 21.6919 20.595 21.1434 21.1434C20.595 21.6919 19.8511 22 19.0755 22H4.92453C4.14889 22 3.40503 21.6919 2.85657 21.1434C2.30812 20.595 2 19.8511 2 19.0755V8.28932ZM3.13208 9.4214V19.0755C3.13208 19.5509 3.32092 20.0068 3.65707 20.3429C3.99322 20.6791 4.44914 20.8679 4.92453 20.8679H19.0755C19.5509 20.8679 20.0068 20.6791 20.3429 20.3429C20.6791 20.0068 20.8679 19.5509 20.8679 19.0755V9.4214H3.13208Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.92453 3.13208C4.44914 3.13208 3.99322 3.32092 3.65707 3.65707C3.32092 3.99322 3.13208 4.44914 3.13208 4.92453V8.28931H20.8679V4.92453C20.8679 4.44914 20.6791 3.99322 20.3429 3.65707C20.0068 3.32092 19.5509 3.13208 19.0755 3.13208H4.92453ZM2.85657 2.85657C3.40503 2.30812 4.14889 2 4.92453 2H19.0755C19.8511 2 20.595 2.30812 21.1434 2.85657C21.6919 3.40503 22 4.14889 22 4.92453V9.42138H2V4.92453C2 4.14889 2.30812 3.40503 2.85657 2.85657Z"
            />
            <path d="M6.49689 6.49684H5.71072C5.50222 6.49684 5.30226 6.41401 5.15482 6.26658C5.00739 6.11915 4.92456 5.91918 4.92456 5.71068C4.92456 5.50217 5.00739 5.30221 5.15482 5.15478C5.30226 5.00734 5.50222 4.92451 5.71072 4.92451H6.49689C6.70539 4.92451 6.90536 5.00734 7.05279 5.15478C7.20022 5.30221 7.28305 5.50217 7.28305 5.71068C7.28305 5.91918 7.20022 6.11915 7.05279 6.26658C6.90536 6.41401 6.70539 6.49684 6.49689 6.49684Z" />
            <path d="M10.4278 6.49684H9.64163C9.43313 6.49684 9.23316 6.41401 9.08573 6.26658C8.9383 6.11915 8.85547 5.91918 8.85547 5.71068C8.85547 5.50217 8.9383 5.30221 9.08573 5.15478C9.23316 5.00734 9.43313 4.92451 9.64163 4.92451H10.4278C10.6363 4.92451 10.8363 5.00734 10.9837 5.15478C11.1311 5.30221 11.214 5.50217 11.214 5.71068C11.214 5.91918 11.1311 6.11915 10.9837 6.26658C10.8363 6.41401 10.6363 6.49684 10.4278 6.49684Z" />
            <path d="M9.84854 12.3429C9.63791 12.092 9.337 12.092 9.12637 12.3429L7.11036 14.7442C6.89974 14.9951 6.89974 15.3535 7.11036 15.6044L9.12637 18.0058C9.337 18.2208 9.63791 18.2208 9.81845 18.0058C9.99899 17.7907 10.0291 17.3965 9.81845 17.1814L8.16351 15.2102L9.81845 13.2389C10.0291 12.9522 10.0291 12.5579 9.84854 12.3429Z" />
            <path d="M14.1514 17.9341C14.362 18.1849 14.6629 18.1849 14.8736 17.9341L16.8896 15.5327C17.1002 15.2818 17.1002 14.9234 16.8896 14.6725L14.8736 12.2712C14.6629 12.0561 14.362 12.0561 14.1815 12.2712C14.0009 12.4862 13.9708 12.8805 14.1815 13.0955L15.8364 15.0668L14.1815 17.038C13.9708 17.3248 13.9709 17.719 14.1514 17.9341Z" />
            <path d="M12.6168 12.1637C12.346 12.092 12.0752 12.2712 12.015 12.5938L11.0221 17.3606C10.992 17.504 11.022 17.6832 11.0822 17.8265C11.1424 17.9699 11.2628 18.0774 11.3831 18.1133C11.4132 18.1133 11.4734 18.1491 11.5035 18.1133C11.7442 18.1133 11.9247 17.9341 11.9849 17.6473L12.9779 12.8805C13.0682 12.5579 12.8876 12.2354 12.6168 12.1637Z" />
        </svg>
    );
}

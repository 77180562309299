import { FullAudit, FullAuditActors, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { NonUndefined } from "react-hook-form";
import { Color, TYPE_TO_COLOR, Tag, TagInterface, useLang } from "../../../common";

export type EnrichedFullAudit = Omit<FullAudit, "auditedAccount" | "beneficiaryAccount"> & {
    auditedAccount: FullAudit["auditedAccount"] & FullAuditActors["auditedAccount"];
    beneficiaryAccount?: FullAudit["beneficiaryAccount"] & FullAuditActors["beneficiaryAccount"];
    reviewerAccounts?: FullAuditActors["reviewerAccounts"];
    domain?: WhiteLabelDomain;
};

function formatFullAuditStatusToType(status?: FullAudit.Status): NonUndefined<TagInterface["type"]> {
    switch (status) {
        case FullAudit.Status.OPEN:
            return "info";
        case FullAudit.Status.CANCELLED:
            return "danger";
        case FullAudit.Status.BLOCKED:
            return "default";
        case FullAudit.Status.DONE:
            return "success";
        case FullAudit.Status.IN_PROGRESS:
        default:
            return "warning";
    }
}

export function formatFullAuditStatusToColor(status: FullAudit.Status): Color {
    return TYPE_TO_COLOR[formatFullAuditStatusToType(status)];
}

export function FullAuditStatusTag({ status }: { status: FullAudit.Status | undefined }): JSX.Element {
    const lang = useLang();
    return <Tag type={formatFullAuditStatusToType(status)} message={status ? lang.fullAudit.status[status] : lang.shared.unknown} />;
}

import { ReactNode } from "react";
import { DropdownItem } from "../navigation/DropdownMenu";
import NavigationMenu, { NaviguationMenuLayout } from "../navigation/NavigationMenu";

type Props = {
    children: ReactNode;
    dropdownItems?: DropdownItem[];
    layout?: NaviguationMenuLayout;
    hideExpandButton?: boolean;
    isMenuDefaultExpanded?: boolean;
};

const AppLayout = (props: Props): JSX.Element => {
    return (
        <div data-id="logged-page" className="h-screen">
            <NavigationMenu {...props} />
        </div>
    );
};

export default AppLayout;

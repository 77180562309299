/* eslint-disable max-len */
import { merge } from "lodash";
import { itCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const it: BaseLang = merge(itCommon, {
    apps: {
        account: {
            name: "Account",
            description: "Gestisci il tuo account e la tua organizzazione."
        },
        deposit: {
            name: "Deposito di PI",
            description: "Dimostra la tua proprietà"
        },
        escrow: {
            name: "Escrow agreement",
            description: "Rassicura i tuoi clienti e assicura la continuità del business"
        },
        "full-audit": {
            name: "Due Diligence",
            description: "Esegui un Software due diligence per mitigare i tuoi rischi di PI, cybersecurezza e manutenibilità."
        },
        timestamping: {
            name: "Timestamping",
            description: "Dimostrate la data esatta e l'integrità dei dati dei vostri documenti importanti." // to review
        }
    },
    register: {
        slogan: "La soluzione online per proteggere e verificare i tuoi asset digitali"
    }
});

import classNames from "classnames";

// TODO needs to be handled by the futur Button component
export default function CTALink({ text, onClick, underlined }: { text: string; onClick: () => void; underlined?: boolean }): JSX.Element {
    return (
        <span
            className={classNames("text-blue-dark hover:cursor-pointer hover:text-blue-light", {
                underline: underlined,
                "hover:underline": !underlined
            })}
            onClick={onClick}
        >
            {text}
        </span>
    );
}

import { LogoSvgProps } from "../Logos";

export default function TimestampingLogo({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M114.469 6.1875H105.188C104.641 6.1875 104.116 6.40481 103.729 6.79159C103.342 7.17839 103.125 7.70298 103.125 8.25V66C103.125 66.547 103.342 67.0717 103.729 67.4584C104.116 67.8451 104.641 68.0625 105.188 68.0625H123.75C124.297 68.0625 124.822 67.8451 125.208 67.4584C125.595 67.0717 125.812 66.547 125.812 66V17.5312C125.807 14.5244 124.61 11.6422 122.484 9.51602C120.358 7.38983 117.476 6.19295 114.469 6.1875Z"
                fill="#DEE1E4"
            />
            <path
                d="M103.438 6H34.3438C31.3368 6.00545 28.4547 7.20233 26.3285 9.32852C24.2024 11.4547 23.0054 14.3369 23 17.3438V123.562C23 124.109 23.2174 124.634 23.6041 125.021C23.9908 125.408 24.5155 125.625 25.0625 125.625H74.0056C74.5526 125.625 75.0773 125.408 75.464 125.021C75.8507 124.634 76.0681 124.109 76.0681 123.562V118.303C76.0681 117.756 75.8507 117.231 75.464 116.845C75.0773 116.458 74.5526 116.241 74.0056 116.241H72.0669V113.25C72.0669 112.216 72.4775 111.225 73.2087 110.494C73.9396 109.763 74.9311 109.352 75.965 109.352H82.9156C85.2314 109.352 87.4525 108.433 89.092 106.798C90.7315 105.162 105.268 110.128 105.273 107.812V105.312C105.251 103.51 105.273 101.812 105.273 99.3125C105.273 97.3125 105.273 97.8125 105.273 93.8125C105.273 91.3125 105.273 88.3125 105.273 85.8125C105.273 83.8125 105.273 83.8125 105.273 81.8125C105.273 78.8681 105.25 81.3125 105.273 80.3125C105.273 78.6619 102.682 79.756 104.654 79.8125C106.157 79.9253 97.158 74.6208 98.4671 75.3662C99.7761 76.1118 100.903 77.1387 101.767 78.3731H101.891C101.984 78.4798 102.087 78.5765 102.2 78.6619L102.509 78.8681C102.619 78.9273 102.737 78.9692 102.86 78.9919C102.997 79.0113 103.136 79.0113 103.272 78.9919H103.438H103.664H104.056C104.196 78.9512 104.328 78.8888 104.448 78.8063H104.654V78.6825C104.766 78.5948 104.863 78.4905 104.943 78.3731C104.943 78.27 105.088 78.1875 105.149 78.0637C105.209 77.954 105.25 77.8356 105.273 77.7131C105.293 77.5764 105.293 77.4374 105.273 77.3006C105.282 77.246 105.282 77.1903 105.273 77.1356V8.0625C105.276 7.55263 105.09 7.05967 104.751 6.67879C104.412 6.29789 103.944 6.05608 103.438 6Z"
                fill="#EEEFEE"
            />
            <path
                d="M77.474 33H89.5885C89.8966 33 90.192 32.7826 90.4099 32.3959C90.6277 32.0092 90.75 31.4845 90.75 30.9375C90.75 30.3905 90.6277 29.8658 90.4099 29.4791C90.192 29.0924 89.8966 28.875 89.5885 28.875H77.474C77.166 28.875 76.8706 29.0924 76.6527 29.4791C76.4349 29.8658 76.3125 30.3905 76.3125 30.9375C76.3125 31.4845 76.4349 32.0092 76.6527 32.3959C76.8706 32.7826 77.166 33 77.474 33Z"
                fill="#4A7398"
            />
            <path
                d="M77.474 43.3125H89.5885C89.8966 43.3125 90.192 43.0951 90.4099 42.7084C90.6277 42.3217 90.75 41.797 90.75 41.25C90.75 40.703 90.6277 40.1783 90.4099 39.7916C90.192 39.4049 89.8966 39.1875 89.5885 39.1875H77.474C77.166 39.1875 76.8706 39.4049 76.6527 39.7916C76.4349 40.1783 76.3125 40.703 76.3125 41.25C76.3125 41.797 76.4349 42.3217 76.6527 42.7084C76.8706 43.0951 77.166 43.3125 77.474 43.3125Z"
                fill="#4A7398"
            />
            <path
                d="M77.474 53.625H89.5885C89.8966 53.625 90.192 53.4076 90.4099 53.0209C90.6277 52.6342 90.75 52.1095 90.75 51.5625C90.75 51.0155 90.6277 50.4908 90.4099 50.1041C90.192 49.7174 89.8966 49.5 89.5885 49.5H77.474C77.166 49.5 76.8706 49.7174 76.6527 50.1041C76.4349 50.4908 76.3125 51.0155 76.3125 51.5625C76.3125 52.1095 76.4349 52.6342 76.6527 53.0209C76.8706 53.4076 77.166 53.625 77.474 53.625Z"
                fill="#4A7398"
            />
            <path
                d="M88.9555 63.0506H38.3005C37.7536 63.0506 37.2291 63.268 36.8421 63.6547C36.4554 64.0415 36.238 64.5662 36.238 65.1131C36.238 65.6601 36.4554 66.1848 36.8421 66.5715C37.2291 66.9584 37.7536 67.1756 38.3005 67.1756H88.9555C89.5027 67.1756 90.0272 66.9584 90.4141 66.5715C90.8009 66.1848 91.018 65.6601 91.018 65.1131C91.018 64.5662 90.8009 64.0415 90.4141 63.6547C90.0272 63.268 89.5027 63.0506 88.9555 63.0506Z"
                fill="#4A7398"
            />
            <path
                d="M74.3943 74.8894H38.3005C37.7536 74.8894 37.2291 75.1068 36.8421 75.4935C36.4554 75.8802 36.238 76.4049 36.238 76.9519C36.238 77.4988 36.4554 78.0235 36.8421 78.4103C37.2291 78.797 37.7536 79.0144 38.3005 79.0144H74.3943C74.9415 79.0144 75.466 78.797 75.8529 78.4103C76.2396 78.0235 76.4568 77.4988 76.4568 76.9519C76.4568 76.4049 76.2396 75.8802 75.8529 75.4935C75.466 75.1068 74.9415 74.8894 74.3943 74.8894Z"
                fill="#4A7398"
            />
            <path
                d="M74.3943 86.7281H38.3005C37.7536 86.7281 37.2291 86.9453 36.8421 87.3322C36.4554 87.7189 36.238 88.2436 36.238 88.7906C36.238 89.3376 36.4554 89.8623 36.8421 90.249C37.2291 90.6357 37.7536 90.8531 38.3005 90.8531H74.3943C74.9415 90.8531 75.466 90.6357 75.8529 90.249C76.2396 89.8623 76.4568 89.3376 76.4568 88.7906C76.4568 88.2436 76.2396 87.7189 75.8529 87.3322C75.466 86.9453 74.9415 86.7281 74.3943 86.7281Z"
                fill="#4A7398"
            />
            <path
                d="M74.3943 98.5669H38.3005C37.7536 98.5669 37.2291 98.7843 36.8421 99.171C36.4554 99.5577 36.238 100.082 36.238 100.629C36.238 101.176 36.4554 101.701 36.8421 102.088C37.2291 102.475 37.7536 102.692 38.3005 102.692H74.3943C74.9415 102.692 75.466 102.475 75.8529 102.088C76.2396 101.701 76.4568 101.176 76.4568 100.629C76.4568 100.082 76.2396 99.5577 75.8529 99.171C75.466 98.7843 74.9415 98.5669 74.3943 98.5669Z"
                fill="#4A7398"
            />
            <g clip-path="url(#clip0_6701_309)">
                <path
                    d="M52.5938 54.5188C61.9534 54.5188 69.5407 46.9314 69.5407 37.5719C69.5407 28.2124 61.9534 20.625 52.5938 20.625C43.2343 20.625 35.647 28.2124 35.647 37.5719C35.647 46.9314 43.2343 54.5188 52.5938 54.5188Z"
                    fill="#DEE1E4"
                />
                <path
                    d="M52.5938 55.6875C42.927 55.6875 35.0625 47.823 35.0625 38.1563C35.0625 33.4853 36.8782 29.0903 40.1752 25.7815C40.6304 25.3228 41.3714 25.3228 41.8278 25.778C42.2854 26.2338 42.2865 26.9737 41.8313 27.4306C38.9737 30.2993 37.4 34.1077 37.4 38.1563C37.4 46.5344 44.2156 53.35 52.5938 53.35C60.9719 53.35 67.7875 46.5344 67.7875 38.1563C67.7875 30.1719 61.596 23.6059 53.7625 23.0069V29.293C53.7625 29.9382 53.2389 30.4618 52.5938 30.4618C51.9486 30.4618 51.425 29.9382 51.425 29.293V21.7938C51.425 21.1486 51.9486 20.625 52.5938 20.625C62.2605 20.625 70.125 28.4895 70.125 38.1563C70.125 47.823 62.2605 55.6875 52.5938 55.6875Z"
                    fill="#4A7398"
                />
                <path
                    d="M46.75 32.3832L54.3141 37.8547C55.0586 38.3958 55.1434 39.4746 54.4924 40.1256C53.8414 40.7766 52.7632 40.6919 52.2215 39.9474L46.75 32.3832Z"
                    fill="#4A7398"
                />
                <path
                    d="M63.1126 55.6764C66.9855 55.6764 70.1251 52.5368 70.1251 48.6639C70.1251 44.791 66.9855 41.6514 63.1126 41.6514C59.2397 41.6514 56.1001 44.791 56.1001 48.6639C56.1001 52.5368 59.2397 55.6764 63.1126 55.6764Z"
                    fill="#72ACE1"
                />
                <path
                    d="M66.9524 45.2628C66.6865 45.0782 66.323 45.1436 66.139 45.4089L62.8688 50.1073L60.5611 48.2128C60.3122 48.0076 59.9434 48.0445 59.7389 48.294C59.5338 48.5435 59.57 48.9117 59.8201 49.1162L62.6169 51.4122C62.7221 51.4981 62.853 51.5449 62.9874 51.5449C63.0137 51.5449 63.0406 51.5431 63.0675 51.5396C63.2288 51.5168 63.3737 51.4286 63.4672 51.2948L67.0985 46.0769C67.2826 45.8116 67.2171 45.4469 66.9524 45.2628Z"
                    fill="white"
                />
            </g>
            <path
                d="M119.741 121.086H71.3621C70.6103 121.086 70 120.476 70 119.724V108.69C70 107.938 70.6103 107.328 71.3621 107.328H119.742C120.494 107.328 121.104 107.938 121.104 108.69V119.724C121.104 120.476 120.494 121.086 119.741 121.086Z"
                fill="#294966"
            />
            <path d="M113.241 126H77.8618C75.1484 126 72.948 123.8 72.948 121.086H118.155C118.155 123.8 115.955 126 113.241 126Z" fill="#163046" />
            <path d="M103.414 101.431H87.6897V107.328H103.414V101.431Z" fill="#4A7398" />
            <path
                d="M106.362 79.8103C106.362 73.4214 100.82 68.3268 94.2809 69.0727C89.4477 69.624 85.494 73.4843 84.8424 78.3047C84.3019 82.3046 85.954 85.9457 88.7617 88.2129C90.6368 89.7254 91.6205 92.086 91.6205 94.4937V101.431H99.4826V94.3139C99.4826 91.9847 100.455 89.7126 102.278 88.263C104.765 86.2847 106.362 83.2362 106.362 79.8103Z"
                fill="#72ACE1"
            />
            <defs>
                <clipPath id="clip0_6701_309">
                    <rect width="35.0625" height="35.0625" fill="white" transform="translate(35.0625 20.625)" />
                </clipPath>
            </defs>
        </svg>
    );
}

import { ElementType, ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { Size } from "../..";
import { CommonLang } from "../../../lang";
import Modal from "./Modal";
export interface ModalProps {
    lang: CommonLang;
    children: ReactNode;
    title?: string;
    icon?: ElementType;
    isLoading?: boolean;
    isDisabled?: boolean;
    isOpen?: boolean;
    onClose?: () => void;
    onConfirm?: () => void | Promise<void>;
    okText?: string;
    cancelText?: string;
    hideFooter?: boolean;
    size?: Extract<Size, "sm" | "md" | "lg">;
    isSubmitKeyPressDisabled?: boolean;
    maxHeight?: string;
}

export type InfoModalProps = Required<Pick<ModalProps, "title" | "lang">> & Pick<ModalProps, "onClose" | "okText" | "icon"> & { children: string };
export type ConfirmModalProps = InfoModalProps & Required<Pick<ModalProps, "onConfirm">>;

function renderModal(props: ModalProps): () => void {
    const modalRoot = document.createElement("div");
    modalRoot.id = "modal-root";
    document.body.appendChild(modalRoot);

    const unmountModal = () => {
        props.onClose?.();
        modalRootNode.unmount();
        document.body.removeChild(modalRoot);
    };

    const modalElement = <Modal {...props} isOpen onClose={unmountModal} />;
    const modalRootNode = createRoot(modalRoot);
    modalRootNode.render(modalElement);

    return unmountModal;
}

export const ModalHelper = {
    Confirm: ({ ...props }: ConfirmModalProps) => renderModal(props),
    Info: ({ ...props }: InfoModalProps) => renderModal(props),
    Modal: ({ ...props }: ModalProps) => renderModal(props)
};

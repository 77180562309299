export const ACCEPTED_IMAGES_FILE_TYPES = { "image/png": [".png"], "image/jpg": [".jpg", ".jpeg"] };
export const IMAGES_FILE_TYPES = Object.values(ACCEPTED_IMAGES_FILE_TYPES).flat();

export async function downloadImage(imageUrl: string, fileName = "image"): Promise<File | null> {
    try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
    } catch {
        return null;
    }
}

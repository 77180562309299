import { passwordRequirements } from "@vaultinum/vaultinum-api";
import { CheckIcon, Controller, Input, UseFormReturn } from "../../../design-system";
import { CommonLang } from "../../../lang";
import { getPasswordErrorMessage } from "../../tools";

export default function PasswordFields({
    form,
    password,
    lang,
    working,
    initialEmailValue
}: {
    form: UseFormReturn<{ password: string; confirm: string }>;
    password: string;
    lang: CommonLang;
    working: boolean;
    initialEmailValue?: string;
}): JSX.Element {
    return (
        <>
            <Controller
                name="password"
                control={form.control}
                data-id="password-control"
                render={({ field }) => (
                    <Input.Password
                        {...field}
                        data-id="password-input"
                        autoComplete="new-password"
                        autoFocus={!!initialEmailValue}
                        label={lang.shared.password}
                        disabled={working}
                        required
                        help={
                            <div className="flex flex-col">
                                {passwordRequirements(password).map(requirement => (
                                    <span key={requirement.id} className="flex items-center">
                                        <span className="w-4">
                                            {requirement.condition ? <CheckIcon color="green" size="xs" /> : <span className="pl-1">•</span>}
                                        </span>
                                        <span>{getPasswordErrorMessage(requirement.id, lang)}</span>
                                    </span>
                                ))}
                            </div>
                        }
                    />
                )}
            />
            <Controller
                name="confirm"
                control={form.control}
                data-id="password-confirmation-control"
                render={({ field }) => (
                    <Input.Password
                        {...field}
                        data-id="password-confirmation-input"
                        label={lang.shared.confirmPassword}
                        disabled={working}
                        errorMessage={form.formState.errors.confirm?.message}
                        onPaste={e => e.preventDefault()}
                        required
                    />
                )}
            />
        </>
    );
}

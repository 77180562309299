import { IconSvgProps } from "../../../../../common";

export default function ScopeCyberSvg({ size, color }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.5904C0 10.1141 10.114 0 22.5904 0H277.41C289.886 0 300 10.114 300 22.5904V277.41C300 289.886 289.886 300 277.41 300H22.5904C10.1141 300 0 289.886 0 277.41V22.5904Z"
                fill={color ?? "#C7E1FF"}
            />
            <path
                d="M227.319 69.9831C179.922 80.8237 159.068 47.1065 158.23 45.7112C154.555 39.4283 145.443 39.4309 141.769 45.7112C141.563 46.0647 120.539 80.929 72.681 69.9831C66.7191 68.6195 61.021 73.1474 61.021 79.2603V156.623C61.021 209.177 88.4499 241.278 147.342 257.651C149.014 258.116 150.782 258.116 152.455 257.651C206.99 242.49 238.979 212.916 238.979 156.623V79.2603C238.979 73.1512 233.284 68.6164 227.319 69.9831Z"
                fill="white"
            />
            <path
                d="M219.004 155.739C219.004 198.986 198.031 223.712 148.991 238.025C100.094 223.749 79.1799 199.022 79.1799 155.739V89.398C107.415 92.6296 131.929 82.9432 149.092 64.6067C166.269 82.9591 190.776 92.6277 219.004 89.398V155.739Z"
                fill="#4A7398"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M149.092 55.8264L153.472 60.5066C169.338 77.4572 191.96 86.4531 218.322 83.437L225.004 82.6724V155.739C225.004 178.375 219.49 196.881 206.958 211.619C194.53 226.236 175.78 236.456 150.672 243.785L148.99 244.276L147.309 243.785C122.269 236.474 103.568 226.261 91.1726 211.64C78.6768 196.9 73.1799 178.388 73.1799 155.739V82.6722L79.8622 83.437C106.23 86.4548 128.86 77.4423 144.711 60.5066L149.092 55.8264ZM149.092 73.0734C132.404 88.5845 110.24 97.0966 85.1799 95.902V155.739C85.1799 176.373 90.1403 191.865 100.326 203.88C110.368 215.725 126.053 224.859 148.991 231.767C172.005 224.84 187.742 215.695 197.816 203.846C208.032 191.831 213.004 176.35 213.004 155.739V95.9019C187.95 97.0967 165.79 88.5962 149.092 73.0734Z"
                fill="#C7E1FF"
            />
            <path
                d="M169.606 144.796C167.445 144.796 165.693 143.043 165.693 140.882V130.475C165.693 121.847 158.673 114.828 150.045 114.828C141.417 114.828 134.397 121.847 134.397 130.475V140.882C134.397 143.043 132.645 144.796 130.484 144.796C128.322 144.796 126.57 143.043 126.57 140.882V130.475C126.57 117.531 137.101 107 150.045 107C162.989 107 173.52 117.531 173.52 130.475V140.882C173.52 143.043 171.768 144.796 169.606 144.796Z"
                fill="white"
            />
            <path
                d="M171.991 192.082H127.551C124.285 192.082 121.639 189.424 121.639 186.145V146.558C121.639 143.279 124.286 140.621 127.551 140.621H171.991C175.256 140.621 177.903 143.279 177.903 146.558V186.145C177.903 189.424 175.256 192.082 171.991 192.082Z"
                fill="white"
            />
            <path
                d="M157.221 176.733L153.275 167.717C155.736 166.382 157.325 163.686 156.984 160.661C156.616 157.396 153.922 154.75 150.598 154.389C146.219 153.914 142.512 157.269 142.512 161.474C142.512 164.163 144.029 166.503 146.267 167.717L142.321 176.733C141.984 177.502 142.559 178.359 143.412 178.359H156.131C156.983 178.359 157.558 177.502 157.221 176.733Z"
                fill="#4A7398"
            />
        </svg>
    );
}

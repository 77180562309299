import { IconSvgProps } from "../Icons";

export default function RecommendationsSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2962_1863)">
                <path d="M21.5369 0H5.85628C4.60842 0 3.59306 1.01536 3.59306 2.26317V3.46509H2.46291C1.21505 3.46509 0.199219 4.48045 0.199219 5.72831V18.0545C0.199219 19.3028 1.21505 20.3181 2.46291 20.3181H3.25327L4.23581 22.991C4.73011 24.3362 6.6352 24.3364 7.12997 22.991L8.11298 20.3181H18.1436C19.3915 20.3181 20.4068 19.3027 20.4068 18.0545V16.853H21.537C22.7849 16.853 23.8007 15.8377 23.8007 14.5893V2.26317C23.8006 1.01536 22.7848 0 21.5369 0V0ZM19.0005 18.0545C19.0005 18.527 18.6161 18.9119 18.1435 18.9119H7.62211C7.32726 18.9119 7.06383 19.0956 6.96211 19.3722L5.80987 22.5059C5.80097 22.5302 5.77753 22.5945 5.68284 22.5945C5.58862 22.5945 5.56472 22.5298 5.55581 22.5059L4.40358 19.3722C4.30186 19.0956 4.03842 18.9119 3.74358 18.9119H2.46291C1.99041 18.9119 1.60552 18.527 1.60552 18.0545V5.72831C1.60552 5.25581 1.99036 4.87139 2.46291 4.87139H18.1435C18.616 4.87139 19.0005 5.25577 19.0005 5.72831V18.0545ZM22.3943 14.5894C22.3943 15.0623 22.0095 15.4468 21.5369 15.4468H20.4067V5.72831C20.4067 4.48045 19.3913 3.46509 18.1435 3.46509H4.99936V2.26317C4.99936 1.79067 5.38373 1.40625 5.85628 1.40625H21.5369C22.0094 1.40625 22.3943 1.79062 22.3943 2.26317V14.5894Z" />
                <path d="M12.1786 7.88085L8.98822 11.0713L8.70931 10.6391C8.07725 9.65994 6.77286 9.37752 5.79312 10.01C4.90812 10.581 4.59805 11.6955 4.99948 12.6182C5.08287 12.8125 4.97201 12.6342 6.85906 15.5541C7.5859 16.6812 9.17131 16.8546 10.1235 15.9024C10.3187 15.7072 14.9315 11.0944 15.1618 10.8641C15.984 10.0414 15.984 8.70308 15.1618 7.88089C14.3375 7.05655 13.0032 7.05632 12.1786 7.88085ZM14.1676 9.86938L9.12931 14.9076C8.81581 15.2211 8.28565 15.1715 8.04083 14.7919L6.34578 12.164C6.13531 11.8371 6.22869 11.4027 6.55531 11.1917C6.88658 10.979 7.31895 11.0783 7.52755 11.4013L8.27848 12.5657C8.52129 12.9418 9.04981 12.9981 9.3665 12.6815L13.1729 8.87507C13.4486 8.60033 13.8921 8.60052 14.1676 8.87507C14.4414 9.14933 14.4414 9.59558 14.1676 9.86938Z" />
            </g>
            <defs>
                <clipPath id="clip0_2962_1863">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
        </svg>
    );
}

import { Account, AccountUser, Claims } from "@vaultinum/vaultinum-api";
import { createContext, useContext, useMemo } from "react";
import { User, UserProfileWithAccounts } from "../services";

export interface AuthProps {
    user: User | null;
    userProfile: UserProfileWithAccounts | null;
    claims: Claims;
    accountUser: AccountUser | null;
    selectedAccount: Account | null;
}

const Context = createContext<AuthProps>({} as AuthProps);

export function AuthContextProvider({ children, value }: { children: React.ReactNode; value: AuthProps }) {
    const exposed = useMemo((): AuthProps => value, [value]);
    return <Context.Provider value={exposed} children={children} />;
}

export function useAuthContext(): AuthProps {
    return useContext(Context);
}

import { IconSvgProps } from "../Icons";

function ToggleSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.5C15.3078 8.5 14.6311 8.70527 14.0555 9.08986C13.4799 9.47444 13.0313 10.0211 12.7664 10.6606C12.5015 11.3001 12.4322 12.0039 12.5673 12.6828C12.7023 13.3617 13.0356 13.9854 13.5251 14.4749C14.0146 14.9644 14.6383 15.2977 15.3172 15.4327C15.9961 15.5678 16.6999 15.4985 17.3394 15.2336C17.9789 14.9687 18.5256 14.5201 18.9101 13.9445C19.2947 13.3689 19.5 12.6922 19.5 12C19.5 11.0717 19.1313 10.1815 18.4749 9.52513C17.8185 8.86875 16.9283 8.5 16 8.5ZM16 5H8C6.14348 5 4.36301 5.7375 3.05025 7.05025C1.7375 8.36301 1 10.1435 1 12C1 13.8565 1.7375 15.637 3.05025 16.9497C4.36301 18.2625 6.14348 19 8 19H16C17.8565 19 19.637 18.2625 20.9497 16.9497C22.2625 15.637 23 13.8565 23 12C23 10.1435 22.2625 8.36301 20.9497 7.05025C19.637 5.7375 17.8565 5 16 5ZM16 17H8C6.67392 17 5.40215 16.4732 4.46447 15.5355C3.52678 14.5979 3 13.3261 3 12C3 10.6739 3.52678 9.40215 4.46447 8.46447C5.40215 7.52678 6.67392 7 8 7H16C17.3261 7 18.5979 7.52678 19.5355 8.46447C20.4732 9.40215 21 10.6739 21 12C21 13.3261 20.4732 14.5979 19.5355 15.5355C18.5979 16.4732 17.3261 17 16 17Z" />
        </svg>
    );
}

export default ToggleSvg;

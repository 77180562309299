import { IconSvgProps } from "../Icons";

function FileSvg({ color, size }: IconSvgProps) {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.013 2.76527L8.06953 0.821519C7.75521 0.509072 7.33073 0.333499 6.89062 0.333499L2.16667 0.333496C1.24609 0.333496 0.5 1.07959 0.5 2.00016L0.500169 12.0002C0.500169 12.9205 1.24626 13.6668 2.16684 13.6668H8.83333C9.75 13.6668 10.5 12.9168 10.5 12.0002V3.94287C10.5 3.50277 10.3255 3.07829 10.013 2.76527ZM9.72886 12.0002C9.72886 12.6786 9.06344 12.8196 8.83333 12.8196H2.16719C1.5 12.8196 1.32474 12.227 1.32474 11.9969L1.32422 2.00024C1.32422 1.77014 1.49948 1.1841 2.16667 1.1841H6.33333V3.66683C6.33333 4.12699 6.70651 4.50017 7.16667 4.50017L9.72886 4.50024V12.0002ZM3 7.62516C3 7.97152 3.28125 8.25016 3.625 8.25016H7.375C7.72135 8.25016 8 7.97152 8 7.62516C8 7.27881 7.72135 7.00016 7.375 7.00016H3.625C3.28125 7.00016 3 7.28141 3 7.62516ZM7.375 9.50016H3.625C3.28125 9.50016 3 9.78141 3 10.1252C3 10.4689 3.27995 10.7502 3.625 10.7502H7.375C7.72005 10.7502 8 10.4702 8 10.1252C8 9.78011 7.72135 9.50016 7.375 9.50016Z" />
        </svg>
    );
}

export default FileSvg;

import { IconSvgProps } from "../../../../../common";

export default function ScopeEsgSvg({ size, color }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.5904C0 10.1141 10.114 0 22.5904 0H277.41C289.886 0 300 10.114 300 22.5904V277.41C300 289.886 289.886 300 277.41 300H22.5904C10.1141 300 0 289.886 0 277.41V22.5904Z"
                fill={color ?? "#C7E1FF"}
            />
            <path
                d="M140.355 98.9146C115.148 134.414 127.468 162.133 145.866 175.182C164.264 188.231 194.496 190.825 219.702 155.244C238.344 129.065 238.668 82.7858 234.453 64.7117C232.994 58.552 227.24 54.4184 220.918 55.0668C202.52 57.1741 158.996 72.7356 140.355 98.9146Z"
                fill="white"
            />
            <path
                d="M201.709 94.2137C187.121 110.505 174.153 128.417 162.887 147.139C153.08 163.43 144.57 180.694 138.572 198.768L136.059 206.467C135.33 209.142 132.493 210.763 129.819 209.871C127.225 209.061 125.766 206.143 126.739 203.55L129.656 195.526C130.467 193.256 131.764 189.852 132.817 187.664C144.732 159.215 161.995 133.28 181.447 109.532C186.796 103.129 192.308 96.7263 198.143 90.7286C200.494 88.6213 203.898 91.8633 201.709 94.2137Z"
                fill="#C7E1FF"
            />
            <path
                d="M158.834 131.74C182.177 168.536 168.398 195.607 149.351 207.602C130.305 219.597 99.9923 220.651 76.65 183.855C59.4675 156.784 61.4938 110.586 66.6809 92.7549C68.464 86.6762 74.4617 82.8669 80.7025 83.8395C98.9387 86.7573 141.652 104.588 158.834 131.74Z"
                fill="#4A7398"
            />
            <path
                d="M101.451 120.717C115.635 137.98 128.035 156.784 138.815 176.398C148.217 193.58 156.321 211.898 161.914 230.62L164.264 238.806C164.994 241.4 163.535 244.074 160.941 244.804C158.186 245.614 155.349 243.831 154.782 240.994L152.836 233.133C145.299 203.955 132.088 176.479 116.445 150.867C110.772 141.709 104.45 132.145 97.8848 123.635C96.1017 121.365 99.4248 118.447 101.451 120.717Z"
                fill="#294966"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M98.8847 122.148C98.8622 122.283 98.875 122.467 99.0644 122.708L99.0726 122.718C105.681 131.285 112.033 140.896 117.72 150.077L117.725 150.085C133.418 175.779 146.703 203.393 154.289 232.757L154.291 232.765L156.246 240.667L156.253 240.7C156.654 242.71 158.643 243.916 160.518 243.365L160.527 243.362L160.535 243.36C162.31 242.86 163.33 241.031 162.822 239.216C162.821 239.215 162.821 239.213 162.82 239.212L160.477 231.049C160.476 231.047 160.475 231.044 160.474 231.042C154.918 212.443 146.86 194.226 137.499 177.118C126.764 157.586 114.422 138.871 100.315 121.697C100.077 121.439 99.8841 121.398 99.7616 121.396C99.6026 121.393 99.3984 121.458 99.209 121.618C99.0188 121.779 98.9127 121.979 98.8847 122.148ZM99.815 118.396C100.827 118.414 101.802 118.858 102.57 119.718L102.59 119.741L102.61 119.765C116.86 137.109 129.311 155.991 140.129 175.675L140.131 175.678C149.572 192.933 157.723 211.348 163.351 230.191L163.354 230.198L165.708 238.4C166.656 241.768 164.762 245.283 161.356 246.245C157.734 247.305 154.067 244.965 153.318 241.324L151.384 233.508C151.384 233.505 151.383 233.503 151.382 233.501C143.893 204.513 130.758 177.18 115.168 151.653C109.51 142.52 103.22 133.007 96.7009 124.556C95.2116 122.654 95.9331 120.462 97.27 119.329C97.9417 118.76 98.8368 118.379 99.815 118.396Z"
                fill="#294966"
            />
        </svg>
    );
}

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useLang } from "../../lang";
import { yup } from "../services";

export default function usePhoneValidation() {
    const lang = useLang();
    return yup
        .object()
        .nullable()
        .shape({
            countryCode: yup.string(),
            phoneNumber: yup.string().test("phone", lang.shared.invalidPhoneNumber, function (phoneNumber) {
                const { countryCode } = this.parent;
                if (!phoneNumber || !countryCode) {
                    return true;
                }
                if (!/^\d+$/.test(phoneNumber)) {
                    return false;
                }
                return parsePhoneNumberFromString(`${countryCode}${phoneNumber}`)?.isValid();
            })
        });
}

import { IconSvgProps } from "../Icons";

export default function ComplexitySvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg viewBox="0 0 24 24" color={color} fill="none" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2958 11.5742C10.2958 12.0261 10.4754 12.4596 10.7949 12.7792C11.1145 13.0987 11.548 13.2783 11.9999 13.2783C12.4519 13.2783 12.8854 13.0987 13.205 12.7792C13.5246 12.4596 13.7041 12.0261 13.7041 11.5742C13.7041 11.1222 13.5246 10.6887 13.205 10.3691C12.8854 10.0495 12.4519 9.86996 11.9999 9.86996C11.548 9.86996 11.1145 10.0495 10.7949 10.3691C10.4754 10.6887 10.2958 11.1222 10.2958 11.5742Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.625 11.5742C2.625 12.0261 2.80455 12.4596 3.12414 12.7792C3.44373 13.0987 3.8772 13.2783 4.32916 13.2783C4.78114 13.2783 5.21461 13.0987 5.5342 12.7792C5.85379 12.4596 6.03333 12.0261 6.03333 11.5742C6.03333 11.1222 5.85379 10.6887 5.5342 10.3691C5.21461 10.0495 4.78114 9.86996 4.32916 9.86996C3.8772 9.86996 3.44373 10.0495 3.12414 10.3691C2.80455 10.6887 2.625 11.1222 2.625 11.5742Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2958 4.32917C10.2958 4.78114 10.4754 5.2146 10.7949 5.53419C11.1145 5.85379 11.548 6.03333 11.9999 6.03333C12.4519 6.03333 12.8854 5.85379 13.205 5.53419C13.5246 5.2146 13.7041 4.78114 13.7041 4.32917C13.7041 3.87719 13.5246 3.44373 13.205 3.12414C12.8854 2.80455 12.4519 2.625 11.9999 2.625C11.548 2.625 11.1145 2.80455 10.7949 3.12414C10.4754 3.44373 10.2958 3.87719 10.2958 4.32917Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2958 19.6708C10.2958 20.1228 10.4754 20.5563 10.7949 20.8758C11.1145 21.1954 11.548 21.375 11.9999 21.375C12.4519 21.375 12.8854 21.1954 13.205 20.8758C13.5246 20.5563 13.7041 20.1228 13.7041 19.6708C13.7041 19.2188 13.5246 18.7854 13.205 18.4658C12.8854 18.1462 12.4519 17.9667 11.9999 17.9667C11.548 17.9667 11.1145 18.1462 10.7949 18.4658C10.4754 18.7854 10.2958 19.2188 10.2958 19.6708Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9667 19.6708C17.9667 20.1228 18.1462 20.5563 18.4658 20.8758C18.7854 21.1954 19.2188 21.375 19.6708 21.375C20.1228 21.375 20.5563 21.1954 20.8758 20.8758C21.1954 20.5563 21.375 20.1228 21.375 19.6708C21.375 19.2188 21.1954 18.7854 20.8758 18.4658C20.5563 18.1462 20.1228 17.9667 19.6708 17.9667C19.2188 17.9667 18.7854 18.1462 18.4658 18.4658C18.1462 18.7854 17.9667 19.2188 17.9667 19.6708Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9667 11.5742C17.9667 12.0261 18.1462 12.4596 18.4658 12.7792C18.7854 13.0987 19.2188 13.2783 19.6708 13.2783C20.1228 13.2783 20.5563 13.0987 20.8758 12.7792C21.1954 12.4596 21.375 12.0261 21.375 11.5742C21.375 11.1222 21.1954 10.6887 20.8758 10.3691C20.5563 10.0495 20.1228 9.86996 19.6708 9.86996C19.2188 9.86996 18.7854 10.0495 18.4658 10.3691C18.1462 10.6887 17.9667 11.1222 17.9667 11.5742Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.625 4.32917C2.625 4.78114 2.80455 5.2146 3.12414 5.53419C3.44373 5.85379 3.8772 6.03333 4.32916 6.03333C4.78114 6.03333 5.21461 5.85379 5.5342 5.53419C5.85379 5.2146 6.03333 4.78114 6.03333 4.32917C6.03333 3.87719 5.85379 3.44373 5.5342 3.12414C5.21461 2.80455 4.78114 2.625 4.32916 2.625C3.8772 2.625 3.44373 2.80455 3.12414 3.12414C2.80455 3.44373 2.625 3.87719 2.625 4.32917Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9667 4.32917C17.9667 4.78114 18.1462 5.2146 18.4658 5.53419C18.7854 5.85379 19.2188 6.03333 19.6708 6.03333C20.1228 6.03333 20.5563 5.85379 20.8758 5.53419C21.1954 5.2146 21.375 4.78114 21.375 4.32917C21.375 3.87719 21.1954 3.44373 20.8758 3.12414C20.5563 2.80455 20.1228 2.625 19.6708 2.625C19.2188 2.625 18.7854 2.80455 18.4658 3.12414C18.1462 3.44373 17.9667 3.87719 17.9667 4.32917Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.625 19.6708C2.625 20.1228 2.80455 20.5563 3.12414 20.8758C3.44373 21.1954 3.8772 21.375 4.32916 21.375C4.78114 21.375 5.21461 21.1954 5.5342 20.8758C5.85379 20.5563 6.03333 20.1228 6.03333 19.6708C6.03333 19.2188 5.85379 18.7854 5.5342 18.4658C5.21461 18.1462 4.78114 17.9667 4.32916 17.9667C3.8772 17.9667 3.44373 18.1462 3.12414 18.4658C2.80455 18.7854 2.625 19.2188 2.625 19.6708Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M5.50171 18.4333L10.8275 12.8117" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.6708 6.03418V9.86918" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.7042 11.5742H17.9659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.2391 10.4033L18.4308 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.56909 5.5L10.7607 10.4033" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

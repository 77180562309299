import { App } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { ElementType, ReactNode, useState } from "react";
import { AdaptiveLink, CheckIcon, ColCard, IconProps } from "../../../../design-system";
import { PriceInfo } from "../../../services/pricingService";
import "./PricingColCard.css";

export default function PricingColCard({
    priceInfo,
    icon: Icon,
    extraFooter,
    showActionButton = true,
    onClick
}: {
    priceInfo: PriceInfo;
    icon?: ElementType<IconProps>;
    extraFooter?: ReactNode;
    showActionButton?: boolean;
    onClick?: (appPricingPlan: App.PricingPlans) => void | Promise<void>;
}): JSX.Element {
    const [isWorking, setIsWorking] = useState(false);
    async function actionButtonClicked() {
        if (onClick && !isWorking) {
            setIsWorking(true);
            await onClick(priceInfo.typeKey);
            setIsWorking(false);
        }
    }
    return (
        <div className="flex w-full sm:max-w-sm">
            <ColCard
                header={
                    <div className="flex flex-col items-center gap-8">
                        {Icon && <Icon size="md" />}
                        <div className="center text-xl font-bold text-slate-light" children={priceInfo.title} />
                        <h2 className="text-4xl font-bold text-slate-primary">{priceInfo.price}</h2>
                        {!!priceInfo.subtitle && <div className="text-center font-light">{priceInfo.subtitle}</div>}
                        {showActionButton && (
                            <AdaptiveLink
                                data-id={`btn-cta-${priceInfo.typeKey}`}
                                onClick={actionButtonClicked}
                                href={!onClick && priceInfo.ctaUrl?.url ? priceInfo.ctaUrl.url : undefined}
                                routed={priceInfo.ctaUrl?.link_type === "routed"}
                                className={classNames("text-sm", {
                                    "btn-cta": !isWorking,
                                    "btn cursor-wait bg-accent-gradiant text-white opacity-50 hover:text-white": isWorking
                                })}
                                children={priceInfo.ctaText}
                            />
                        )}
                    </div>
                }
                children={
                    <div className="hidden flex-col gap-4 px-4 sm:flex">
                        {priceInfo.features?.map(feature => (
                            <div
                                key={feature.text}
                                className={classNames("flex items-center gap-4 text-left", {
                                    "text-gray-300": !feature.included
                                })}
                            >
                                <CheckIcon {...(feature.included ? { color: "blue" } : { color: "grey", shade: "light" })} />
                                <span className="break-words">{feature.text}</span>
                            </div>
                        ))}
                    </div>
                }
                {...(extraFooter && { footer: extraFooter })}
            />
        </div>
    );
}

import { Note } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { ReactNode, useRef, useState } from "react";
import { Buttons, Controller, EditIcon, Input, ModalButton, TrashIcon, UseFormReturn, formatDate, useLang } from "../../../../common";

function AuthorAndDate({ date, userName }: { date: Date; userName: string }) {
    const lang = useLang();
    return (
        <div className="flex items-center justify-end space-x-2">
            <div className="font-semibold text-primary">{userName}</div>
            <span>{formatDate(date, lang.code)}</span>
        </div>
    );
}

export default function NoteText({
    working,
    note,
    header,
    editable,
    visibleBy,
    form,
    onSubmit,
    handleDelete
}: {
    working: boolean;
    form?: UseFormReturn<{
        noteText: string;
    }>;
    note?: Note & { userName: string };
    header?: ReactNode;
    editable?: boolean;
    visibleBy?: string;
    onSubmit?: () => void;
    handleDelete?: () => void;
}): JSX.Element {
    const lang = useLang();
    const forwardedRef = useRef(null);
    const [editMode, setEditMode] = useState(!note);

    const submitOnExit = () => {
        if (onSubmit) {
            onSubmit();
        }
        setEditMode(false);
    };

    return (
        <div className="space-y-4">
            <div
                className={classNames({
                    "border-b pb-2": !!header
                })}
            >
                {header}
            </div>
            {editable && (editMode || !note) && (
                <>
                    <Controller
                        name="noteText"
                        control={form?.control}
                        render={({ field }) => <Input.TextArea {...field} forwardedRef={forwardedRef} autoSize />}
                    />
                    <div className="flex justify-end">
                        <Buttons.Save isLoading={working} onClick={submitOnExit} isDisabled={!form?.formState.isValid} />
                    </div>
                </>
            )}
            {!editable && note && !editMode && (
                <div className="space-y-4 whitespace-pre-line border-b pb-4">
                    {note.text}
                    <div className="pb-2 text-xs italic">
                        <AuthorAndDate userName={note.userName} date={note.lastUpdated} />
                    </div>
                </div>
            )}
            {editable && note && !editMode && (
                <div className="space-y-4 pb-4">
                    <div className="flex justify-between whitespace-pre-line">
                        {note.text}
                        {note && handleDelete && (
                            <ModalButton.Confirm
                                buttonProps={{
                                    type: "default",
                                    size: "sm",
                                    icon: TrashIcon,
                                    isLoading: false
                                }}
                                title={lang.accountInformation.notes.deleteNote}
                                icon={TrashIcon}
                                children={lang.accountInformation.notes.permanentlyDeleteNote}
                                onConfirm={handleDelete}
                                lang={lang}
                            />
                        )}
                    </div>
                    <div className="flex justify-between text-xs italic">
                        <div className="flex items-center space-x-2">
                            {lang.accountInformation.notes.visibleBy} : {visibleBy || lang.accountInformation.notes.reviewers}
                        </div>
                        <div className="flex space-x-2">
                            <a className="flex items-end space-x-1 text-primary" onClick={() => setEditMode(true)}>
                                <EditIcon />
                                <span>{lang.shared.edit}</span>
                            </a>
                            {note && <AuthorAndDate userName={note.userName} date={note.lastUpdated} />}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

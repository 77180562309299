import { COLOR_SCHEME, PolarChart, Size } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";

export const getScoreColor = (score: number, refScore: number) => {
    if (score > refScore * 1.05) {
        return COLOR_SCHEME.green.primary;
    }
    if (score < refScore * 0.75) {
        return COLOR_SCHEME.red.primary;
    }
    return COLOR_SCHEME.orange.primary;
};

export type ScorePolarChartData = {
    label: string;
    value: number;
    refValue: number;
    color?: string;
};

export function ScorePolarChart({ data, className, fontSize }: { data: ScorePolarChartData[]; className?: string; fontSize?: Size }) {
    const lang = useLang<CommonLang>();
    const chartData = data.map(({ label, value, refValue, color }) => ({
        label,
        value,
        refValue,
        color: color ?? getScoreColor(value, refValue)
    }));
    return <PolarChart className={className} data={chartData} valueLabel={lang.report.score} refValueLabel={lang.report.industryAverage} fontSize={fontSize} />;
}

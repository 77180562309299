import { IconSvgProps } from "../../../..";

function GitSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.9335 13.6645L16.8355 0.567032C16.0816 -0.187456 14.8581 -0.187456 14.1032 0.567032L11.3836 3.28711L14.8337 6.73719C15.6355 6.46644 16.5549 6.64816 17.1939 7.28721C17.8366 7.93024 18.0168 8.85763 17.739 9.66233L21.0644 12.9873C21.8691 12.7099 22.7969 12.8892 23.4397 13.5329C24.3375 14.4304 24.3375 15.8854 23.4397 16.7837C22.5412 17.6821 21.0865 17.6821 20.1876 16.7837C19.5124 16.1078 19.3453 15.1155 19.6877 14.2835L16.5862 11.1822V19.3432C16.8049 19.4516 17.0115 19.5959 17.1939 19.7778C18.0917 20.6755 18.0917 22.1303 17.1939 23.0294C16.2961 23.9268 14.8403 23.9268 13.9431 23.0294C13.0453 22.1303 13.0453 20.6755 13.9431 19.7778C14.165 19.5561 14.422 19.3884 14.6962 19.2762V11.0398C14.422 10.9276 14.1657 10.7613 13.9431 10.5381C13.2631 9.85835 13.0995 8.86017 13.4483 8.02498L10.0471 4.62325L1.06576 13.6038C0.311415 14.3589 0.311415 15.5823 1.06576 16.3368L14.1644 29.4342C14.9185 30.1886 16.1415 30.1886 16.8967 29.4342L29.9335 16.3975C30.6882 15.6429 30.6882 14.4188 29.9335 13.6645Z" />
        </svg>
    );
}

export default GitSvg;

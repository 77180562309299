import { LogoSvgProps } from "../Logos";

export default function FullAuditOutlinedSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1915_1550)">
                <path
                    d="M469.3 0H42.7C19.1 0 0 19.1 0 42.7V469.4C0 492.9 19.1 512 42.7 512H469.4C493 512 512 492.9 512.1 469.3V42.7C512 19.1 492.9 0 469.3 0ZM42.7 17.1H469.4C483.5 17.1 495 28.6 495 42.7V102.4H17.1V42.7C17.1 28.5 28.5 17.1 42.7 17.1ZM469.3 494.9H42.7C28.6 494.9 17.1 483.4 17.1 469.3V119.5H495V469.4C494.9 483.5 483.5 494.9 469.3 494.9Z"
                    fill="url(#paint0_linear_1915_1550)"
                />
                <path
                    d="M372.6 85.3001C386.7 85.3001 398.2 73.8001 398.2 59.7001C398.2 45.6001 386.7 34.1001 372.6 34.1001C358.5 34.1001 347 45.6001 347 59.7001C347 73.9001 358.5 85.3001 372.6 85.3001ZM372.6 51.2001C377.3 51.2001 381.1 55.0001 381.1 59.7001C381.1 64.4001 377.3 68.2001 372.6 68.2001C367.9 68.2001 364.1 64.4001 364.1 59.7001C364.1 55.0001 367.9 51.2001 372.6 51.2001Z"
                    fill="url(#paint1_linear_1915_1550)"
                />
                <path
                    d="M440.9 85.3001C455 85.3001 466.5 73.8001 466.5 59.7001C466.5 45.6001 455 34.1001 440.9 34.1001C426.8 34.1001 415.3 45.6001 415.3 59.7001C415.3 73.9001 426.7 85.3001 440.9 85.3001ZM440.9 51.2001C445.6 51.2001 449.4 55.0001 449.4 59.7001C449.4 64.4001 445.6 68.2001 440.9 68.2001C436.2 68.2001 432.4 64.4001 432.4 59.7001C432.3 55.0001 436.2 51.2001 440.9 51.2001Z"
                    fill="url(#paint2_linear_1915_1550)"
                />
                <path
                    d="M441.1 150.7H69.1002C65.0002 150.7 61.7002 154 61.7002 158.1V502.6C61.7002 506.7 65.0002 510 69.1002 510H441.2C445.3 510 448.6 506.7 448.6 502.6V158.2C448.6 154.1 445.2 150.7 441.1 150.7ZM433.7 495.3H76.5002V165.6H433.7V495.3Z"
                    fill="url(#paint3_linear_1915_1550)"
                />
                <path
                    d="M255.1 150.7H69.1002C65.0002 150.7 61.7002 154 61.7002 158.1V502.6C61.7002 506.7 65.0002 510 69.1002 510H255.1C259.2 510 262.5 506.7 262.5 502.6V158.2C262.5 154.1 259.2 150.7 255.1 150.7ZM247.6 495.3H76.5002V165.6H247.6V495.3Z"
                    fill="url(#paint4_linear_1915_1550)"
                />
                <path
                    d="M176.6 301.5C172.9 299.7 168.5 301.2 166.6 304.8L144.3 349.4C142.5 353.1 144 357.5 147.6 359.4C148.7 359.9 149.8 360.2 150.9 360.2C153.6 360.2 156.3 358.7 157.6 356.1L179.9 311.5C181.7 307.8 180.2 303.3 176.6 301.5Z"
                    fill="url(#paint5_linear_1915_1550)"
                />
                <path
                    d="M109.3 330.5L126.4 313.4C129.3 310.5 129.3 305.8 126.4 302.9C123.5 300 118.8 300 115.9 302.9L93.5998 325.2C90.6998 328.1 90.6998 332.8 93.5998 335.7L115.9 358C117.4 359.5 119.3 360.2 121.2 360.2C123.1 360.2 125 359.5 126.5 358C129.4 355.1 129.4 350.4 126.5 347.5L109.3 330.5Z"
                    fill="url(#paint6_linear_1915_1550)"
                />
                <path
                    d="M230.6 325.2L208.3 302.9C205.4 300 200.7 300 197.8 302.9C194.9 305.8 194.9 310.5 197.8 313.4L214.9 330.5L197.8 347.6C194.9 350.5 194.9 355.2 197.8 358.1C199.3 359.6 201.2 360.3 203.1 360.3C205 360.3 206.9 359.6 208.4 358.1L230.7 335.8C231.1 335.4 231.4 335 231.7 334.6C232.1 334 232.4 333.3 232.6 332.6C232.7 332.1 232.8 331.7 232.9 331.2C232.9 329 232.2 326.8 230.6 325.2Z"
                    fill="url(#paint7_linear_1915_1550)"
                />
                <path
                    d="M362.3 233.7H287.9C283.8 233.7 280.5 237 280.5 241.1C280.5 245.2 283.8 248.5 287.9 248.5H362.3C366.4 248.5 369.7 245.2 369.7 241.1C369.7 237 366.4 233.7 362.3 233.7Z"
                    fill="url(#paint8_linear_1915_1550)"
                />
                <path
                    d="M414.3 233.7H392C387.9 233.7 384.6 237 384.6 241.1C384.6 245.2 387.9 248.5 392 248.5H414.3C418.4 248.5 421.7 245.2 421.7 241.1C421.7 237 418.5 233.7 414.3 233.7Z"
                    fill="url(#paint9_linear_1915_1550)"
                />
                <path
                    d="M414.3 263.5H339.9C335.8 263.5 332.5 266.8 332.5 270.9C332.5 275 335.8 278.3 339.9 278.3H414.3C418.4 278.3 421.7 275 421.7 270.9C421.8 266.8 418.5 263.5 414.3 263.5Z"
                    fill="url(#paint10_linear_1915_1550)"
                />
                <path
                    d="M310.2 263.5H287.9C283.8 263.5 280.5 266.8 280.5 270.9C280.5 275 283.8 278.3 287.9 278.3H310.2C314.3 278.3 317.6 275 317.6 270.9C317.6 266.8 314.3 263.5 310.2 263.5Z"
                    fill="url(#paint11_linear_1915_1550)"
                />
                <path
                    d="M362.3 293.2H287.9C283.8 293.2 280.5 296.5 280.5 300.6C280.5 304.7 283.8 308 287.9 308H362.3C366.4 308 369.7 304.7 369.7 300.6C369.7 296.6 366.4 293.2 362.3 293.2Z"
                    fill="url(#paint12_linear_1915_1550)"
                />
                <path
                    d="M414.3 293.2H392C387.9 293.2 384.6 296.5 384.6 300.6C384.6 304.7 387.9 308 392 308H414.3C418.4 308 421.7 304.7 421.7 300.6C421.8 296.6 418.5 293.2 414.3 293.2Z"
                    fill="url(#paint13_linear_1915_1550)"
                />
                <path
                    d="M414.3 323H339.9C335.8 323 332.5 326.3 332.5 330.4C332.5 334.5 335.8 337.8 339.9 337.8H414.3C418.4 337.8 421.7 334.5 421.7 330.4C421.8 326.3 418.5 323 414.3 323Z"
                    fill="url(#paint14_linear_1915_1550)"
                />
                <path
                    d="M310.2 323H287.9C283.8 323 280.5 326.3 280.5 330.4C280.5 334.5 283.8 337.8 287.9 337.8H310.2C314.3 337.8 317.6 334.5 317.6 330.4C317.6 326.3 314.3 323 310.2 323Z"
                    fill="url(#paint15_linear_1915_1550)"
                />
                <path
                    d="M414.3 412.3H339.9C335.8 412.3 332.5 415.6 332.5 419.7C332.5 423.8 335.8 427.1 339.9 427.1H414.3C418.4 427.1 421.7 423.8 421.7 419.7C421.8 415.6 418.5 412.3 414.3 412.3Z"
                    fill="url(#paint16_linear_1915_1550)"
                />
                <path
                    d="M310.2 412.3H287.9C283.8 412.3 280.5 415.6 280.5 419.7C280.5 423.8 283.8 427.1 287.9 427.1H310.2C314.3 427.1 317.6 423.8 317.6 419.7C317.6 415.6 314.3 412.3 310.2 412.3Z"
                    fill="url(#paint17_linear_1915_1550)"
                />
                <path
                    d="M362.3 352.8H287.9C283.8 352.8 280.5 356.1 280.5 360.2C280.5 364.3 283.8 367.6 287.9 367.6H362.3C366.4 367.6 369.7 364.3 369.7 360.2C369.7 356.1 366.4 352.8 362.3 352.8Z"
                    fill="url(#paint18_linear_1915_1550)"
                />
                <path
                    d="M414.3 352.8H392C387.9 352.8 384.6 356.1 384.6 360.2C384.6 364.3 387.9 367.6 392 367.6H414.3C418.4 367.6 421.7 364.3 421.7 360.2C421.8 356.1 418.5 352.8 414.3 352.8Z"
                    fill="url(#paint19_linear_1915_1550)"
                />
                <path
                    d="M414.3 382.5H339.9C335.8 382.5 332.5 385.8 332.5 389.9C332.5 394 335.8 397.3 339.9 397.3H414.3C418.4 397.3 421.7 394 421.7 389.9C421.8 385.9 418.5 382.5 414.3 382.5Z"
                    fill="url(#paint20_linear_1915_1550)"
                />
                <path
                    d="M310.2 382.5H287.9C283.8 382.5 280.5 385.8 280.5 389.9C280.5 394 283.8 397.3 287.9 397.3H310.2C314.3 397.3 317.6 394 317.6 389.9C317.6 385.9 314.3 382.5 310.2 382.5Z"
                    fill="url(#paint21_linear_1915_1550)"
                />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1915_1550" x1="-11.2022" y1="-16" x2="513.264" y2="-14.8416" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint1_linear_1915_1550" x1="345.88" y1="32.5001" x2="398.323" y2="32.6227" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint2_linear_1915_1550" x1="414.18" y1="32.5001" x2="466.623" y2="32.6227" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint3_linear_1915_1550" x1="53.2368" y1="139.472" x2="449.477" y2="140.413" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint4_linear_1915_1550" x1="57.3077" y1="139.472" x2="262.958" y2="139.726" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint5_linear_1915_1550" x1="142.725" y1="298.879" x2="180.745" y2="298.93" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint6_linear_1915_1550" x1="90.61" y1="298.867" x2="128.757" y2="298.918" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint7_linear_1915_1550" x1="194.81" y1="298.863" x2="232.982" y2="298.915" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint8_linear_1915_1550" x1="278.549" y1="233.238" x2="369.889" y2="234.469" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint9_linear_1915_1550" x1="383.789" y1="233.238" x2="421.781" y2="233.443" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint10_linear_1915_1550" x1="330.549" y1="263.038" x2="421.891" y2="264.269" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint11_linear_1915_1550" x1="279.688" y1="263.038" x2="317.681" y2="263.242" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint12_linear_1915_1550" x1="278.549" y1="292.738" x2="369.889" y2="293.969" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint13_linear_1915_1550" x1="383.788" y1="292.738" x2="421.783" y2="292.943" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint14_linear_1915_1550" x1="330.549" y1="322.538" x2="421.891" y2="323.769" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint15_linear_1915_1550" x1="279.688" y1="322.538" x2="317.681" y2="322.742" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint16_linear_1915_1550" x1="330.549" y1="411.838" x2="421.891" y2="413.069" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint17_linear_1915_1550" x1="279.688" y1="411.838" x2="317.681" y2="412.043" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint18_linear_1915_1550" x1="278.549" y1="352.338" x2="369.889" y2="353.569" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint19_linear_1915_1550" x1="383.788" y1="352.338" x2="421.783" y2="352.543" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint20_linear_1915_1550" x1="330.549" y1="382.038" x2="421.891" y2="383.269" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <linearGradient id="paint21_linear_1915_1550" x1="279.688" y1="382.038" x2="317.681" y2="382.242" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#60CFFF" />
                    <stop offset="1" stopColor="#5985F6" />
                </linearGradient>
                <clipPath id="clip0_1915_1550">
                    <rect width="512" height="512" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

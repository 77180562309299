import { CardCommon } from "../../../../../design-system";
import PaymentMethodDetail, { PaymentMethodDetailInterface } from "../PaymentMethodDetail";

export namespace PaymentMethodDetails {
    export function Action({ actions, ...props }: CardCommon.WithActions & PaymentMethodDetailInterface) {
        return <PaymentMethodDetail {...props} actions={actions} />;
    }

    export function Radio({ radio, ...props }: { radio: Omit<CardCommon.WithRadio["radio"], "id"> } & PaymentMethodDetailInterface) {
        return <PaymentMethodDetail {...props} radio={{ ...radio, id: props.paymentMethod.id }} />;
    }
}

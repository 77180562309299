import { IconSvgProps } from "../Icons";

function BackArrowSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75017 4.25014H3.05767L5.53267 1.78264C5.67389 1.64141 5.75323 1.44987 5.75323 1.25014C5.75323 1.05041 5.67389 0.858867 5.53267 0.717639C5.39144 0.576411 5.19989 0.49707 5.00017 0.49707C4.80044 0.49707 4.60889 0.576411 4.46767 0.717639L0.717666 4.46764C0.649386 4.53897 0.595862 4.62308 0.560166 4.71514C0.485153 4.89774 0.485153 5.10254 0.560166 5.28514C0.595862 5.3772 0.649386 5.46131 0.717666 5.53264L4.46767 9.28264C4.53739 9.35293 4.62034 9.40873 4.71173 9.44681C4.80313 9.48489 4.90116 9.50449 5.00017 9.50449C5.09918 9.50449 5.1972 9.48489 5.2886 9.44681C5.37999 9.40873 5.46294 9.35293 5.53267 9.28264C5.60296 9.21292 5.65876 9.12997 5.69684 9.03857C5.73491 8.94718 5.75452 8.84915 5.75452 8.75014C5.75452 8.65113 5.73491 8.5531 5.69684 8.46171C5.65876 8.37031 5.60296 8.28736 5.53267 8.21764L3.05767 5.75014H8.75017C8.94908 5.75014 9.13984 5.67112 9.2805 5.53047C9.42115 5.38982 9.50017 5.19905 9.50017 5.00014C9.50017 4.80123 9.42115 4.61046 9.2805 4.46981C9.13984 4.32916 8.94908 4.25014 8.75017 4.25014Z" />
        </svg>
    );
}

export default BackArrowSvg;

import { Button, Buttons } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";

export default function PaymentButtons({
    canProceed,
    isProceeding,
    proceedToPayment,
    onPrevious
}: {
    proceedToPayment: () => Promise<void>;
    canProceed?: boolean;
    isProceeding?: boolean;
    onPrevious?: () => void;
}): JSX.Element {
    const lang = useLang<CommonLang>();

    return (
        <div className="flex w-full flex-col justify-between gap-2 sm:flex-row">
            {!!onPrevious && (
                <div className="w-full sm:w-auto">
                    <Buttons.Previous type="default" onClick={onPrevious} isDisabled={isProceeding} isLoading={false} />
                </div>
            )}
            <Button
                onClick={proceedToPayment}
                isLoading={!!isProceeding}
                isDisabled={!canProceed}
                data-id="proceed-payment-button"
                children={lang.payment.proceed}
            />
        </div>
    );
}

import { IconSvgProps } from "../Icons";

export default function CrossCircleFilledSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.4183 9.54924L12.9452 11.9896L15.4183 14.43C15.4871 14.4944 15.542 14.5724 15.5795 14.6589C15.617 14.7455 15.6364 14.8388 15.6364 14.9332C15.6364 15.0275 15.617 15.1209 15.5795 15.2075C15.542 15.294 15.4871 15.372 15.4183 15.4364C15.3539 15.5003 15.2776 15.5509 15.1936 15.5852C15.1097 15.6195 15.0198 15.6369 14.9292 15.6364C14.7485 15.6356 14.5753 15.5638 14.447 15.4364L11.9739 12.9616L9.53527 15.4364C9.40695 15.5638 9.23377 15.6356 9.05305 15.6364C8.96239 15.6369 8.87252 15.6195 8.78859 15.5852C8.70466 15.5509 8.62832 15.5003 8.56395 15.4364C8.43564 15.3073 8.36363 15.1326 8.36363 14.9504C8.36363 14.7683 8.43564 14.5936 8.56395 14.4644L11.0026 11.9896L8.56395 9.54924C8.45109 9.41737 8.39212 9.24773 8.39881 9.07423C8.40551 8.90074 8.47738 8.73616 8.60007 8.61339C8.72275 8.49061 8.88722 8.41869 9.06059 8.41199C9.23397 8.40529 9.40348 8.4643 9.53527 8.57724L11.9739 11.0176L14.4125 8.57724C14.4763 8.51115 14.5525 8.45828 14.6367 8.42165C14.7209 8.38502 14.8114 8.36534 14.9032 8.36374C14.995 8.36214 15.0862 8.37865 15.1717 8.41232C15.2571 8.446 15.335 8.49618 15.4011 8.56C15.4671 8.62383 15.5199 8.70004 15.5565 8.7843C15.5931 8.86855 15.6128 8.95919 15.6144 9.05105C15.616 9.14291 15.5995 9.23419 15.5659 9.31967C15.5322 9.40515 15.4821 9.48316 15.4183 9.54924Z"
            />
        </svg>
    );
}

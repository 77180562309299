import { Color } from "./Colors";

export const TYPES = ["primary", "accent", "info", "success", "warning", "danger", "default"] as const;
export type Type = (typeof TYPES)[number];

export const TYPE_TO_COLOR: Record<Type, Color> = {
    primary: "slate",
    accent: "pink",
    info: "blue",
    success: "green",
    warning: "orange",
    danger: "red",
    default: "grey"
};

import fileSize from "filesize";
import { Progress } from "../../../design-system";

export function UploadedFileRow({ file, progress }: Readonly<{ file: File; progress: number }>): JSX.Element {
    return (
        <>
            <td className="p-3 text-sm" data-id={progress !== 100 ? "pending-file" : "uploaded-file"}>
                {file.name}
            </td>
            <td className="p-3 text-sm">{fileSize(file.size)}</td>
            <td className="p-3">
                <Progress size="small" percent={Math.floor(progress)} />
            </td>
        </>
    );
}

import { COLOR_SCHEME } from "../../../referentials";
import { LogoSvgProps } from "../Logos";

function DragAndDropSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.54297 35.3101C8.54297 36.0385 9.13293 36.6284 9.86133 36.6284C10.5893 36.6284 11.1797 36.0385 11.1797 35.3101V33.3325C11.1797 32.6045 10.5893 32.0142 9.86133 32.0142C9.13293 32.0142 8.54297 32.6045 8.54297 33.3325V35.3101Z"
                fill="url(#paint0_linear_3803_18627)"
            />
            <path
                d="M9.86133 22.1265C10.5893 22.1265 11.1797 21.5365 11.1797 20.8081V18.8306C11.1797 18.1026 10.5893 17.5122 9.86133 17.5122C9.13293 17.5122 8.54297 18.1026 8.54297 18.8306V20.8081C8.54297 21.5365 9.13293 22.1265 9.86133 22.1265Z"
                fill="url(#paint1_linear_3803_18627)"
            />
            <path
                d="M8.54297 28.0591C8.54297 28.7875 9.13293 29.3774 9.86133 29.3774C10.5893 29.3774 11.1797 28.7875 11.1797 28.0591V26.0815C11.1797 25.3536 10.5893 24.7632 9.86133 24.7632C9.13293 24.7632 8.54297 25.3536 8.54297 26.0815V28.0591Z"
                fill="url(#paint2_linear_3803_18627)"
            />
            <path
                d="M9.86133 41.9018H11.8389C12.5668 41.9018 13.1572 41.3119 13.1572 40.5835C13.1572 39.8555 12.5668 39.2651 11.8389 39.2651H9.86133C9.13293 39.2651 8.54297 39.8555 8.54297 40.5835C8.54297 41.3119 9.13293 41.9018 9.86133 41.9018Z"
                fill="url(#paint3_linear_3803_18627)"
            />
            <path
                d="M43.6113 21.8628C44.3393 21.8628 44.9297 21.2728 44.9297 20.5444V18.5669C44.9297 17.8389 44.3393 17.2485 43.6113 17.2485C42.8829 17.2485 42.293 17.8389 42.293 18.5669V20.5444C42.293 21.2728 42.8829 21.8628 43.6113 21.8628Z"
                fill="url(#paint4_linear_3803_18627)"
            />
            <path
                d="M38.4697 19.7534C39.1977 19.7534 39.7881 19.1635 39.7881 18.4351C39.7881 17.7071 39.1977 17.1167 38.4697 17.1167H36.4922C35.7638 17.1167 35.1738 17.7071 35.1738 18.4351C35.1738 19.1635 35.7638 19.7534 36.4922 19.7534H38.4697Z"
                fill="url(#paint5_linear_3803_18627)"
            />
            <path
                d="M16.7178 17.1167H14.7402C14.0118 17.1167 13.4219 17.7071 13.4219 18.4351C13.4219 19.1635 14.0118 19.7534 14.7402 19.7534H16.7178C17.4458 19.7534 18.0361 19.1635 18.0361 18.4351C18.0361 17.7071 17.4458 17.1167 16.7178 17.1167Z"
                fill="url(#paint6_linear_3803_18627)"
            />
            <path
                d="M31.2197 19.7534C31.9477 19.7534 32.5381 19.1635 32.5381 18.4351C32.5381 17.7071 31.9477 17.1167 31.2197 17.1167H29.2422C28.5138 17.1167 27.9238 17.7071 27.9238 18.4351C27.9238 19.1635 28.5138 19.7534 29.2422 19.7534H31.2197Z"
                fill="url(#paint7_linear_3803_18627)"
            />
            <path
                d="M42.293 27.7954C42.293 28.5238 42.8829 29.1138 43.6113 29.1138C44.3393 29.1138 44.9297 28.5238 44.9297 27.7954V25.8179C44.9297 25.0899 44.3393 24.4995 43.6113 24.4995C42.8829 24.4995 42.293 25.0899 42.293 25.8179V27.7954Z"
                fill="url(#paint8_linear_3803_18627)"
            />
            <path
                d="M23.9678 19.7534C24.6958 19.7534 25.2861 19.1635 25.2861 18.4351C25.2861 17.7071 24.6958 17.1167 23.9678 17.1167H21.9902C21.2618 17.1167 20.6719 17.7071 20.6719 18.4351C20.6719 19.1635 21.2618 19.7534 21.9902 19.7534H23.9678Z"
                fill="url(#paint9_linear_3803_18627)"
            />
            <path
                d="M15.7949 40.5835C15.7949 41.3119 16.3849 41.9018 17.1133 41.9018H19.0908C19.8188 41.9018 20.4092 41.3119 20.4092 40.5835C20.4092 39.8555 19.8188 39.2651 19.0908 39.2651H17.1133C16.3849 39.2651 15.7949 39.8555 15.7949 40.5835Z"
                fill="url(#paint10_linear_3803_18627)"
            />
            <path
                d="M44.9734 6.53662H26.7363C26.0079 6.53662 25.418 7.127 25.418 7.85498C25.418 8.58337 26.0079 9.17334 26.7363 9.17334H44.9734C45.7018 9.17334 46.2917 8.58337 46.2917 7.85498C46.2917 7.127 45.7018 6.53662 44.9734 6.53662Z"
                fill="url(#paint11_linear_3803_18627)"
            />
            <path
                d="M13.6621 6.5376C13.3156 6.5376 12.9757 6.67685 12.7306 6.92363C12.4846 7.16876 12.3438 7.50783 12.3438 7.85596C12.3438 8.20244 12.4846 8.54274 12.7306 8.78787C12.9757 9.03301 13.3156 9.17432 13.6621 9.17432C14.009 9.17432 14.3489 9.03301 14.594 8.78787C14.8392 8.54274 14.9805 8.20244 14.9805 7.85596C14.9805 7.50783 14.84 7.16876 14.594 6.92363C14.3489 6.67685 14.0102 6.5376 13.6621 6.5376Z"
                fill="url(#paint12_linear_3803_18627)"
            />
            <path
                d="M8.23633 6.5376C7.88902 6.5376 7.54913 6.67685 7.304 6.92363C7.05763 7.16876 6.91797 7.50906 6.91797 7.85596C6.91797 8.20244 7.05763 8.54274 7.304 8.78787C7.54913 9.03301 7.8882 9.17432 8.23633 9.17432C8.58281 9.17432 8.92187 9.03301 9.16783 8.78787C9.41296 8.54274 9.55469 8.20244 9.55469 7.85596C9.55469 7.50906 9.41337 7.16876 9.16783 6.92363C8.92187 6.67685 8.58281 6.5376 8.23633 6.5376Z"
                fill="url(#paint13_linear_3803_18627)"
            />
            <path
                d="M19.0898 6.5376C18.7434 6.5376 18.4027 6.6785 18.1575 6.92363C17.9124 7.16876 17.7715 7.50783 17.7715 7.85596C17.7715 8.20244 17.9124 8.54274 18.1575 8.78787C18.4027 9.03301 18.7434 9.17432 19.0898 9.17432C19.4363 9.17432 19.7766 9.03301 20.0218 8.78787C20.2669 8.54274 20.4082 8.20244 20.4082 7.85596C20.4082 7.50783 20.2669 7.16876 20.0218 6.92363C19.7766 6.6785 19.4363 6.5376 19.0898 6.5376Z"
                fill="url(#paint14_linear_3803_18627)"
            />
            <path
                d="M3.5332 41.9023C3.87969 41.9023 4.21999 41.761 4.46512 41.5159C4.71025 41.2708 4.85156 40.9305 4.85156 40.584C4.85156 40.2371 4.71025 39.8968 4.46512 39.6517C4.21999 39.4065 3.87969 39.2656 3.5332 39.2656C3.18672 39.2656 2.84601 39.4065 2.60088 39.6517C2.35574 39.8968 2.21484 40.2371 2.21484 40.584C2.21484 40.9305 2.35574 41.2708 2.60088 41.5159C2.84601 41.761 3.18672 41.9023 3.5332 41.9023Z"
                fill="url(#paint15_linear_3803_18627)"
            />
            <path
                d="M69.3284 62.4106L62.5327 55.6149L67.2149 53.5908C67.7299 53.3683 68.048 52.8438 68.0068 52.2848C67.9656 51.7249 67.575 51.2527 67.0328 51.1073L62.5957 49.9183V33.9431C62.5957 33.2147 62.0054 32.6247 61.2774 32.6247H51.2578V6.24024C51.2578 4.05959 49.4838 2.28516 47.3028 2.28516H6.16992C3.98886 2.28516 2.21484 4.05959 2.21484 6.24024V35.2442C2.21484 35.9725 2.80481 36.5625 3.5332 36.5625C4.26119 36.5625 4.85156 35.9725 4.85156 35.2442V13.4253H48.6211V32.6247H27.5274C26.799 32.6247 26.209 33.2147 26.209 33.9431V39.2651H24.3633C23.6349 39.2651 23.0449 39.8555 23.0449 40.5835C23.0449 41.3119 23.6349 41.9019 24.3633 41.9019H26.209V48.6255H6.16992C5.44277 48.6255 4.85156 48.0343 4.85156 47.3071V45.791C4.85156 45.063 4.26119 44.4727 3.5332 44.4727C2.80481 44.4727 2.21484 45.063 2.21484 45.791V47.3071C2.21484 49.4882 3.98886 51.2622 6.16992 51.2622H26.209V56.0911C26.209 56.8191 26.799 57.4095 27.5274 57.4095H48.4415L51.0383 67.1015C51.1837 67.6433 51.6558 68.0342 52.2157 68.075C52.7748 68.1166 53.2992 67.7986 53.5221 67.2836L55.5466 62.6014L62.3424 69.3971C62.5896 69.6443 62.9249 69.7831 63.2743 69.7831C63.6241 69.7831 63.9594 69.6443 64.2066 69.3971L69.3284 64.2753C69.8434 63.7603 69.8434 62.9256 69.3284 62.4106V62.4106ZM4.85156 10.7886V6.24024C4.85156 5.51308 5.44277 4.92188 6.16992 4.92188H47.3028C48.0299 4.92188 48.6211 5.51308 48.6211 6.24024V10.7886H4.85156ZM28.8457 54.7728V35.2615H59.959V49.2118L47.3897 45.8438C46.9344 45.7218 46.4495 45.852 46.1162 46.1853C45.7833 46.5178 45.6532 47.0035 45.7751 47.4588L47.7349 54.7728H28.8457ZM63.2743 66.6005L56.0385 59.3644C55.7885 59.1151 55.4527 58.9783 55.1062 58.9783C55.0242 58.9783 54.9414 58.9862 54.859 59.0018C54.4301 59.0838 54.0697 59.3726 53.8962 59.7735L52.605 62.7604L48.9132 48.9819L62.6913 52.6741L59.7052 53.9649C59.3044 54.1379 59.0156 54.4984 58.9336 54.9273C58.8516 55.3566 58.9871 55.7982 59.2961 56.1072L66.5323 63.3433L63.2743 66.6005Z"
                fill="url(#paint16_linear_3803_18627)"
            />
            <defs>
                <linearGradient id="paint0_linear_3803_18627" x1="8.48529" y1="31.87" x2="11.1848" y2="31.8729" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint1_linear_3803_18627" x1="8.48529" y1="17.368" x2="11.1848" y2="17.3709" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint2_linear_3803_18627" x1="8.48529" y1="24.619" x2="11.1848" y2="24.6219" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint3_linear_3803_18627" x1="8.44203" y1="39.1827" x2="13.1675" y2="39.2006" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint4_linear_3803_18627" x1="42.2353" y1="17.1043" x2="44.9348" y2="17.1073" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint5_linear_3803_18627" x1="35.0729" y1="17.0343" x2="39.7983" y2="17.0522" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint6_linear_3803_18627" x1="13.3209" y1="17.0343" x2="18.0463" y2="17.0522" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint7_linear_3803_18627" x1="27.8229" y1="17.0343" x2="32.5483" y2="17.0522" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint8_linear_3803_18627" x1="42.2353" y1="24.3553" x2="44.9348" y2="24.3582" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint9_linear_3803_18627" x1="20.5709" y1="17.0343" x2="25.2964" y2="17.0522" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint10_linear_3803_18627" x1="15.694" y1="39.1827" x2="20.4194" y2="39.2006" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint11_linear_3803_18627" x1="24.9614" y1="6.45422" x2="46.3337" y2="6.83831" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint12_linear_3803_18627" x1="12.2861" y1="6.4552" x2="14.9856" y2="6.46031" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint13_linear_3803_18627" x1="6.86029" y1="6.4552" x2="9.5611" y2="6.46159" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint14_linear_3803_18627" x1="17.7138" y1="6.4552" x2="20.4159" y2="6.46287" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint15_linear_3803_18627" x1="2.15717" y1="39.1832" x2="4.85735" y2="39.189" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint16_linear_3803_18627" x1="0.738285" y1="0.175845" x2="69.8679" y2="0.328356" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default DragAndDropSvg;

import { Account, AccountRights, EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import {
    Alert,
    CompanyBillingDetailsForm,
    ContentLoader,
    getBillingDetailsErrorMessage,
    getLangCode,
    openNotificationWithIcon,
    updateBillingDetails,
    useAuthContext,
    useForm,
    useHasAccountRights,
    useLang,
    useRequiredString,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { AccountLang } from "../lang/AccountLang";
import ViewWrapper from "./ViewWrapper";

export default function CompanyBillingDetails() {
    const lang = useLang<AccountLang>();

    const [loading, setLoading] = useState<boolean>(false);
    const { userProfile, selectedAccount: account } = useAuthContext();

    const schema = yup
        .object()
        .shape({
            companyName: useRequiredString(),
            companyIndustry: useRequiredString(),
            companySize: useRequiredString(),
            billingDetails: yup.object({
                name: useRequiredString(),
                contactEmail: yup.string().matches(EMAIL_REGEX, { message: lang.shared.invalidEmail, excludeEmptyString: true }),
                address: yup.object().when("vatNumber", ([vatNumber], builder) => {
                    return vatNumber
                        ? yup
                              .object({
                                  line1: yup.string().trim().max(255).required(),
                                  line2: yup.string().trim().max(255).optional(),
                                  city: yup.string().trim().max(255).required(),
                                  postalCode: yup.string().trim().max(255).required(),
                                  country: yup.string().trim().max(255).required()
                              })
                              .noUnknown()
                        : builder.optional();
                }),
                vatNumber: yup.string().trim().max(255).optional()
            })
        })
        .strict();

    const form = useForm<Account>({
        schema,
        defaultValues: { ...account, billingDetails: { ...account?.billingDetails, name: account?.billingDetails?.name ?? account?.companyName } }
    });

    const isAdmin = useHasAccountRights(AccountRights.ADMIN);
    const canInvite = useHasAccountRights(AccountRights.INVITE);
    const isRepresentative = !isAdmin && canInvite;
    const disableFields = !(isAdmin || isRepresentative) || loading;

    const onSubmit = async (values: Account) => {
        setLoading(true);
        try {
            if (values.billingDetails && account) {
                await updateBillingDetails(account.id, values.billingDetails);
                openNotificationWithIcon({ type: "success", description: lang.shared.saveSuccessMessage });
            }
        } catch (error) {
            const { response } = error;

            openNotificationWithIcon({ type: "error", description: getBillingDetailsErrorMessage(response.data, lang) });
        } finally {
            setLoading(false);
        }
    };

    if (!userProfile || !account) {
        return <ContentLoader />;
    }
    return (
        <ViewWrapper title={lang.accountSettings.billingView.billingInformationTitle}>
            {!(isAdmin || isRepresentative) && <Alert.Info message={lang.accountInformation.roleAdminIsRequired} />}
            <div className="rounded-md bg-white p-4">
                <CompanyBillingDetailsForm langCode={getLangCode(userProfile)} disableFields={disableFields} form={form} onSubmit={onSubmit} displayButton />
            </div>
        </ViewWrapper>
    );
}

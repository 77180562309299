import { LogoSvgProps } from "../Logos";

export default function FullAuditSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.4586 27.0771L36.6064 33.0851C37.0345 33.4004 37.3519 33.843 37.5133 34.3496C37.6746 34.8562 37.6716 35.4008 37.5047 35.9056C37.3378 36.4103 37.0155 36.8494 36.584 37.16C36.1525 37.4705 35.6338 37.6366 35.1021 37.6345C34.4305 37.6344 33.7861 37.3688 33.3094 36.8956L25.8369 29.4482L28.4586 27.0771Z"
                fill="#163046"
            />
            <path
                d="M16.4295 32.494C25.3015 32.494 32.4937 25.3017 32.4937 16.4297C32.4937 7.55766 25.3015 0.365448 16.4295 0.365448C7.55745 0.365448 0.365234 7.55766 0.365234 16.4297C0.365234 25.3017 7.55745 32.494 16.4295 32.494Z"
                fill="#294966"
            />
            <path
                d="M16.4295 29.9237C23.882 29.9237 29.9235 23.8823 29.9235 16.4297C29.9235 8.97722 23.882 2.93576 16.4295 2.93576C8.97701 2.93576 2.93555 8.97722 2.93555 16.4297C2.93555 23.8823 8.97701 29.9237 16.4295 29.9237Z"
                fill="#72ACE1"
            />
            <path
                d="M24.1404 13.8595V22.2129C24.1404 22.7241 23.9373 23.2145 23.5758 23.576C23.2143 23.9375 22.724 24.1406 22.2127 24.1406H10.6465C10.1352 24.1406 9.64488 23.9375 9.28336 23.576C8.92185 23.2145 8.71875 22.7241 8.71875 22.2129V13.8595H24.1404Z"
                fill="#FCFDFF"
            />
            <path
                d="M24.1404 10.6466V13.8594H8.71875V10.6466C8.71875 10.1353 8.92185 9.645 9.28336 9.28349C9.64488 8.92197 10.1352 8.71887 10.6465 8.71887H22.2127C22.724 8.71887 23.2143 8.92197 23.5758 9.28349C23.9373 9.645 24.1404 10.1353 24.1404 10.6466Z"
                fill="#294966"
            />
            <path
                d="M11.9316 11.9317H11.2891C11.1186 11.9317 10.9552 11.864 10.8347 11.7435C10.7142 11.623 10.6465 11.4596 10.6465 11.2892C10.6465 11.1188 10.7142 10.9553 10.8347 10.8348C10.9552 10.7143 11.1186 10.6466 11.2891 10.6466H11.9316C12.102 10.6466 12.2655 10.7143 12.386 10.8348C12.5065 10.9553 12.5742 11.1188 12.5742 11.2892C12.5742 11.4596 12.5065 11.623 12.386 11.7435C12.2655 11.864 12.102 11.9317 11.9316 11.9317Z"
                fill="white"
            />
            <path
                d="M15.1445 11.9317H14.5019C14.3315 11.9317 14.1681 11.864 14.0476 11.7435C13.9271 11.623 13.8594 11.4596 13.8594 11.2892C13.8594 11.1188 13.9271 10.9553 14.0476 10.8348C14.1681 10.7143 14.3315 10.6466 14.5019 10.6466H15.1445C15.3149 10.6466 15.4784 10.7143 15.5989 10.8348C15.7194 10.9553 15.7871 11.1188 15.7871 11.2892C15.7871 11.4596 15.7194 11.623 15.5989 11.7435C15.4784 11.864 15.3149 11.9317 15.1445 11.9317Z"
                fill="white"
            />
            <path
                d="M13.9972 15.9987C13.7833 15.7439 13.4777 15.7439 13.2637 15.9987L11.2161 18.4377C11.0022 18.6925 11.0022 19.0565 11.2161 19.3113L13.2637 21.7504C13.4777 21.9688 13.7833 21.9688 13.9667 21.7504C14.15 21.5319 14.1806 21.1315 13.9667 20.9131L12.2858 18.9109L13.9667 16.9087C14.1806 16.6175 14.1806 16.2171 13.9972 15.9987Z"
                fill="#294966"
            />
            <path
                d="M18.3678 21.6775C18.5818 21.9324 18.8874 21.9324 19.1013 21.6775L21.1489 19.2385C21.3629 18.9837 21.3629 18.6197 21.1489 18.3649L19.1013 15.9259C18.8874 15.7074 18.5817 15.7074 18.3984 15.9259C18.215 16.1443 18.1844 16.5447 18.3984 16.7631L20.0793 18.7653L18.3984 20.7675C18.1844 21.0587 18.1844 21.4591 18.3678 21.6775Z"
                fill="#294966"
            />
            <path
                d="M16.809 15.8167C16.534 15.7438 16.2589 15.9259 16.1978 16.2535L15.1892 21.0951C15.1587 21.2407 15.1892 21.4227 15.2503 21.5683C15.3115 21.7139 15.4337 21.8232 15.556 21.8596C15.5865 21.8596 15.6477 21.896 15.6782 21.8596C15.9227 21.8596 16.1061 21.6775 16.1672 21.3863L17.1758 16.5447C17.2674 16.2171 17.0841 15.8895 16.809 15.8167Z"
                fill="#294966"
            />
        </svg>
    );
}

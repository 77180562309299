import { WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { Spin } from "../../../common";

const WHITE_LABEL_SUFFIX = ".vaultinum.net";

export function getDomainLogo(
    domainLogo?: string,
    whiteLabelDomain?: WhiteLabelDomain | null,
    fallBack?: JSX.Element,
    classNames?: string
): JSX.Element | null {
    if (window.location.host.endsWith(WHITE_LABEL_SUFFIX) || whiteLabelDomain) {
        if (domainLogo) {
            return <img src={domainLogo} className={classNames} />;
        }
        return <Spin />;
    }
    if (fallBack) {
        return fallBack;
    }
    return null;
}

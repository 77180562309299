/**
 * This service provides the common methods for interacting with UserProfile collection
 * @module services/userProfile
 */
import { Account, USER_PROFILE_COLLECTION, UserProfile, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { IndexedDataEventSource } from "../tools";
import { getAccountsByIdsAndDomainId } from "./accountService";
import { doPost } from "./apiService";
import { DocumentData, DocumentSnapshot, User, collection, doc, getFirestore, onSnapshot, updateDoc } from "./firebaseService";

export function convertDocToUserProfile(docSnapshot: DocumentSnapshot<DocumentData>): UserProfile {
    const data = docSnapshot.data() as Omit<UserProfile, "creationDate" | "birthDate">;
    return {
        ...data,
        id: docSnapshot.id,
        creationDate: docSnapshot.data()?.creationDate?.toDate(),
        birthDate: docSnapshot.data()?.birthDate?.toDate()
    };
}

export type UserProfileWithAccounts = UserProfile & { accounts: Account[] };

const userProfileListener = new IndexedDataEventSource<UserProfile, string>((userProfileId, onUpdate) =>
    onSnapshot(doc(collection(getFirestore(), USER_PROFILE_COLLECTION), userProfileId), querySnapshot => onUpdate(convertDocToUserProfile(querySnapshot)))
);

export function getUserProfile(
    user: User | null,
    onUpdate: (userProfile: UserProfileWithAccounts | null) => void,
    whiteLabelDomain: WhiteLabelDomain | null
): () => void {
    if (!user) {
        onUpdate(null);
        return () => {};
    }

    return userProfileListener.addListener(user.uid, async userProfile => {
        const accounts = await getAccountsByIdsAndDomainId(userProfile.accountIds, whiteLabelDomain?.id);
        return onUpdate({
            ...userProfile,
            accountIds: accounts.map(({ id }) => id),
            accounts
        });
    });
}

export function getUserProfileFromUID(uid: string | null): Promise<UserProfile | null>;
export function getUserProfileFromUID(uid: string | null, onUpdate: (userProfile: UserProfile | null) => void): () => void;
export function getUserProfileFromUID(uid: string | null, onUpdate?: (userProfile: UserProfile | null) => void): Promise<UserProfile | null> | (() => void) {
    if (onUpdate) {
        if (!uid) {
            onUpdate(null);
            return () => {};
        }
        return userProfileListener.addListener(uid, onUpdate);
    }
    if (!uid) {
        return Promise.resolve(null);
    }
    return userProfileListener.getData(uid);
}

export function setUserProfileSetting(userProfile: UserProfile, key: "theme" | "preferredLang", value: string | boolean): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), USER_PROFILE_COLLECTION), userProfile.id), { [`settings.${key}`]: value });
}

export function updateUserProfileInfo(
    userProfile: UserProfile,
    key: keyof Pick<UserProfile, "firstName" | "lastName" | "birthDate" | "nationality" | "cellPhone">,
    value: string | Date | { countryCode: string; phoneNumber: string }
): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), USER_PROFILE_COLLECTION), userProfile.id), { [key]: value });
}

export function setSelectedAccount(accountId: string): Promise<void> {
    return doPost(`account/${accountId}/select`);
}

export function setSelectedAppCode(userProfileId: string, appCode: string): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), USER_PROFILE_COLLECTION), userProfileId), { selectedAppCode: appCode });
}

/**
 * Finds and returns the item with a matching href attribute from a list of items, based on the current location.
 * It prioritizes matches with the longest href when comparing the current pathname and the combination of pathname and search query.
 * i.e /app/kys/full-audit/id/recommendations?scope=IP will match over /app/kys/full-audit/id/recommendations
 *
 * @template T
 * @param {Pick<Location, "pathname" | "search">} location
 * @param {T[]} items
 * @returns {T | undefined}
 */
export function getMatchingItem<T extends { key: string; href?: string }>(location: Pick<Location, "pathname" | "search">, items: T[]): T | undefined {
    return items.reduce((acc: T | undefined, item: T) => {
        if (
            item.href &&
            ([location.pathname, `${location.pathname}${location.search}`].includes(item.href) || location.pathname.startsWith(item.href)) &&
            (!acc || item.href.length > (acc.href?.length || 0))
        ) {
            return item;
        }
        return acc;
    }, undefined);
}

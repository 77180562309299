import classNames from "classnames";
import { ReactNode } from "react";
import { InfoCircleIcon } from "../Icons";
import { Tooltip } from "../Tooltip/Tooltip";

export type KeyFactBaseProps = {
    title: string;
    value?: ReactNode;
    prefix?: ReactNode;
    tooltipText?: string;
    grow?: boolean;
    small?: boolean;
    onClick?: () => void;
};

export default function KeyFact({ title, value, prefix, tooltipText, grow, small, onClick }: KeyFactBaseProps): JSX.Element {
    return (
        <div
            className={classNames("flex items-center rounded-lg bg-white", {
                "flex-1": grow,
                "gap-2 p-2": small,
                "gap-4 p-5": !small,
                "cursor-pointer border-2 border-transparent transition-all ease-in-out hover:border-pink-dark hover:shadow-lg focus:border-pink-dark focus:shadow-lg":
                    !!onClick
            })}
            onClick={onClick}
        >
            {prefix}
            <div className={classNames("flex flex-col", { "gap-1": !small })}>
                <div className={classNames("flex items-start gap-2 font-bold text-slate-primary", { "text-base leading-4": small, "text-3xl": !small })}>
                    <div>{value ?? "--"}</div>
                    {tooltipText && <Tooltip children={<InfoCircleIcon color="slate" />} title={tooltipText} />}
                </div>
                <div className="text-xs font-light text-slate-dark">{title}</div>
            </div>
        </div>
    );
}

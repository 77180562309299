import { ElementType, ReactNode } from "react";
import { BaseCardContainer, Button, ButtonProps, TYPE_TO_COLOR, Type } from "../../design-system";

export default function ResultFeedback({
    title,
    buttonProps,
    icon: Icon,
    type = "info",
    containerDataId,
    content
}: {
    title: string;
    buttonProps: ButtonProps;
    containerDataId?: string;
    icon?: ElementType;
    type?: Extract<Type, "info" | "danger">;
    content?: ReactNode;
}): JSX.Element {
    return (
        <BaseCardContainer
            children={
                <div className="mx-auto p-12 text-center flex flex-col text-dark items-center gap-6 bg-white rounded-lg" data-id={containerDataId}>
                    <div className="flex flex-col items-center gap-2">
                        {Icon && <Icon size="xl" color={TYPE_TO_COLOR[type]} />}
                        <h3 className="font-bold">{title}</h3>
                    </div>
                    {content && <p className="mx-10">{content}</p>}
                    <Button data-id="btn-result-feedback" {...buttonProps} />
                </div>
            }
        />
    );
}

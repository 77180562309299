import { COLOR_SCHEME } from "../../../referentials";
import { LogoSvgProps } from "../Logos";

/* eslint max-len: 0 */
function PaymentSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg viewBox="0 0 48 48" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 30H20C20.5304 30 21.0391 29.7893 21.4142 29.4142C21.7893 29.0391 22 28.5304 22 28C22 27.4696 21.7893 26.9609 21.4142 26.5858C21.0391 26.2107 20.5304 26 20 26H14C13.4696 26 12.9609 26.2107 12.5858 26.5858C12.2107 26.9609 12 27.4696 12 28C12 28.5304 12.2107 29.0391 12.5858 29.4142C12.9609 29.7893 13.4696 30 14 30ZM38 10H10C8.4087 10 6.88258 10.6321 5.75736 11.7574C4.63214 12.8826 4 14.4087 4 16V34C4 35.5913 4.63214 37.1174 5.75736 38.2426C6.88258 39.3679 8.4087 40 10 40H38C39.5913 40 41.1174 39.3679 42.2426 38.2426C43.3679 37.1174 44 35.5913 44 34V16C44 14.4087 43.3679 12.8826 42.2426 11.7574C41.1174 10.6321 39.5913 10 38 10ZM40 34C40 34.5304 39.7893 35.0391 39.4142 35.4142C39.0391 35.7893 38.5304 36 38 36H10C9.46957 36 8.96086 35.7893 8.58579 35.4142C8.21071 35.0391 8 34.5304 8 34V22H40V34ZM40 18H8V16C8 15.4696 8.21071 14.9609 8.58579 14.5858C8.96086 14.2107 9.46957 14 10 14H38C38.5304 14 39.0391 14.2107 39.4142 14.5858C39.7893 14.9609 40 15.4696 40 16V18Z"
                fill="url(#paint0_linear_2787_37427)"
            />
            <defs>
                <linearGradient id="paint0_linear_2787_37427" x1="3.125" y1="9.0625" x2="44.0906" y2="9.18295" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default PaymentSvg;

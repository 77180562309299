import { FC, createContext, useContext, useMemo } from "react";

export interface BrandProps {
    logoPath: string;
    logoWhitePath: string;
    LogoSpinnerSvg: FC<React.SVGProps<SVGSVGElement>>;
}

const Context = createContext<BrandProps>({} as BrandProps);

export function BrandContextProvider({ children, value }: { children: React.ReactNode; value: BrandProps }) {
    const exposed = useMemo((): BrandProps => value, [value]);
    return <Context.Provider value={exposed} children={children} />;
}

export function useBrandContext(): BrandProps {
    return useContext(Context);
}

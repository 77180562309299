import { App } from "@vaultinum/vaultinum-api";
import { useMemo } from "react";
import { CardSelector, CustomCardProps } from "../../../../design-system";
import { CommonLang, useLang } from "../../../../lang";
import { usePaymentMethodFormContext } from "../../../contexts";
import { getPaymentMethodDetails } from "../../../tools/PaymentTools";
import CreditCardForm from "./CreditCardForm";
import SepaForm from "./SepaForm";

const AVAILABLE_METHODS = [App.PaymentMethod.CARD, App.PaymentMethod.SEPA_DEBIT] as const;

export default function PaymentMethodForm(): JSX.Element {
    const lang = useLang<CommonLang>();
    const { paymentMethodType, setPaymentMethodType, isDisabled } = usePaymentMethodFormContext();

    const items = useMemo(
        (): CustomCardProps<(typeof AVAILABLE_METHODS)[number]>[] =>
            AVAILABLE_METHODS.map(method => ({
                id: method,
                isSelected: method === paymentMethodType,
                ...getPaymentMethodDetails(method, lang)
            })),
        [lang, paymentMethodType]
    );

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <CardSelector cards={items} onCardSelected={setPaymentMethodType} disabled={isDisabled} className="max-w-xl text-xl" />
                <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
                    {paymentMethodType === App.PaymentMethod.CARD && <CreditCardForm />}
                    {paymentMethodType === App.PaymentMethod.SEPA_DEBIT && <SepaForm />}
                </div>
            </div>
        </div>
    );
}

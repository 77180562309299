import { IconSvgProps } from "../Icons";

export default function FilterSortBgFilledSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3510_9997)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM19.875 9.25C20.4975 9.25 21 8.7475 21 8.125C21 7.5025 20.4975 7 19.875 7H4.125C3.5025 7 3 7.5025 3 8.125C3 8.7475 3.5025 9.25 4.125 9.25H19.875ZM10.125 15.5H13.875C14.4975 15.5 15 16.0025 15 16.625C15 17.2475 14.4975 17.75 13.875 17.75H10.125C9.5025 17.75 9 17.2475 9 16.625C9 16.0025 9.5025 15.5 10.125 15.5ZM7.125 11.25H16.875C17.4975 11.25 18 11.7525 18 12.375C18 12.9975 17.4975 13.5 16.875 13.5H7.125C6.5025 13.5 6 12.9975 6 12.375C6 11.7525 6.5025 11.25 7.125 11.25Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_3510_9997">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export type DataEventListener<T> = (data: T) => void;

export type Unsubscribe = () => void;
type Subscribe<T> = (onUpdate: DataEventListener<T>) => Unsubscribe;

export default class DataEventSource<T> {
    private listeners: DataEventListener<T>[] = [];

    private data?: T; // Cache of last value received

    private subscribe?: Subscribe<T>;

    private unsubscribe?: Unsubscribe;

    public cacheClearDelay = 1000;

    constructor(subscribe?: Subscribe<T>) {
        this.subscribe = subscribe;
    }

    addListener(onUpdate: DataEventListener<T>): () => void {
        this.listeners = [...this.listeners, onUpdate];
        if (this.listeners.length === 1 && this.subscribe && !this.unsubscribe) {
            this.unsubscribe = this.subscribe(data => this.onUpdate(data));
        } else if (this.data) {
            onUpdate(this.data);
        }
        return () => {
            this.listeners = this.listeners.filter(listener => listener !== onUpdate);
            // Add a short delay before actually calling unsubscribe
            // This allows for a new listener to register before the unsubscribe call is made.
            // In turn, this allows to optimize as the subcribe does not need to be re-executed
            setTimeout(() => {
                if (!this.listeners.length && this.unsubscribe) {
                    this.unsubscribe();
                    this.unsubscribe = undefined;
                    this.data = undefined; // reset cache data
                }
            }, this.cacheClearDelay);
        };
    }

    onUpdate(data: T): void {
        this.data = data;
        this.listeners.forEach(listener => listener(data));
    }

    getData(): Promise<T> {
        if (this.listeners.length && this.data) {
            // Subscription is active so "data" value is already cached
            return Promise.resolve(this.data);
        }
        return new Promise(resolve => {
            const unsubscribe = this.addListener(data => {
                resolve(data);
                unsubscribe();
            });
        });
    }
}

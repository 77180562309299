import { DomainsIcon, MenuItem, OverviewIcon, SettingsIcon } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../lang/AccountLang";
import { URL } from "../services";

export enum MenuItemKeys {
    OVERVIEW = "overview",
    DOMAIN = "domain",
    SETTINGS = "settings"
}

export enum SubMenuItemKeys {
    PROFILE = "profile",
    ACCOUNTS = "accounts",
    PREFERENCES = "preferences",
    ORGANISATION = "organisation",
    USERS = "users",
    BILLING = "billing",
    PARTIES = "parties",
    GIT = "git",
    API_KEYS = "apikeys"
}

export function getMenuItems(lang: AccountLang, isDomainOwner?: boolean): MenuItem[][] {
    const menuItems: MenuItem[] = [
        {
            key: "overview",
            text: lang.account.apps.title,
            icon: OverviewIcon,
            href: "/app"
        }
    ];

    if (isDomainOwner) {
        menuItems.push({
            key: "domain",
            text: lang.organisations.title,
            icon: DomainsIcon,
            href: URL.domainOrganisations.index
        });
    }

    menuItems.push({
        key: "settings",
        text: lang.settingsMenu.settings,
        icon: SettingsIcon,
        href: URL.settings.index
    });

    return [menuItems];
}

import { ClientInputMethod } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { CardSelector, CustomCardProps } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";

export default function UploadModeSelector({
    setUploadMethod,
    availableMethods,
    className
}: {
    setUploadMethod: (mode: ClientInputMethod) => void;
    availableMethods: CustomCardProps[];
    className?: string;
}): JSX.Element {
    const lang = useLang<CommonLang>();

    function onMethodSelected(selectedItem: string) {
        setUploadMethod(selectedItem as ClientInputMethod);
    }

    return (
        <div className="flex flex-col gap-3">
            <p className="text-base font-bold text-primary">{lang.shared.uploadMethod}</p>
            <div className={classNames("space-y-2", className)}>
                <CardSelector cards={availableMethods} onCardSelected={onMethodSelected} />
            </div>
        </div>
    );
}

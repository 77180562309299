import Prismic from "@prismicio/client";
import { DefaultClient } from "@prismicio/client/types/client";
import { Document as PrismicDocument } from "@prismicio/client/types/documents";
import { SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { DEFAULT_LANG } from "../../../common";

export const API_ENDPOINT = "https://vaultinum.cdn.prismic.io/api/v2";

export function Client(): DefaultClient {
    return Prismic.client(API_ENDPOINT);
}

export const langMap = <{ [key in SupportedLanguageCode]: string }>{
    en: "en-us",
    de: "de-de",
    it: "it-it",
    es: "es-es",
    fr: "fr-fr"
};

export async function getByUIDWithLocale(
    pageType: string,
    pageUID: string,
    locale: SupportedLanguageCode,
    ignoreFallback?: boolean
): Promise<PrismicDocument | null> {
    let doc = await Client().getByUID(pageType, pageUID, { lang: langMap[locale] });
    if (!doc && !ignoreFallback) {
        doc = await Client().getByUID(pageType, pageUID, { lang: langMap[DEFAULT_LANG] });
    }
    return doc;
}

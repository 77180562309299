import { Chart } from "react-google-charts";
import { getLegendConfig } from "../config";

export type PieChartParams = {
    dataset: {
        label: string;
        value: number;
        color?: string;
    }[];
    isPercentage?: boolean;
    height?: string | number;
    width?: string | number;
    legendPosition?: "top" | "bottom" | "left" | "right" | "none" | "labeled";
};

export function PieChart({ dataset, width, height, isPercentage, legendPosition = "labeled" }: PieChartParams): JSX.Element {
    return (
        <Chart
            chartType="PieChart"
            width={width ?? "100%"}
            height={height ?? "90%"}
            options={{
                legend: getLegendConfig(legendPosition, "center", { fontSize: 14 }),
                pieSliceText: isPercentage ? "percentage" : "value",
                chartArea: {
                    left: 10,
                    right: 10,
                    top: legendPosition === "top" ? 30 : 10,
                    bottom: legendPosition === "bottom" ? 30 : 10
                },
                slices: dataset.map(slice => ({ color: slice.color, textStyle: { fontSize: 14 } }))
            }}
            data={[["key", "value"], ...dataset.map(slice => [slice.label, slice.value])]}
        />
    );
}

import classNames from "classnames";
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "../Icons";

function PageButton({ page, selected, onClick }: { page: number; selected?: boolean; onClick: (page: number) => void }): JSX.Element {
    return (
        <div
            className={classNames("rounded px-2 py-1 hover:cursor-pointer", {
                "bg-slate-primary font-bold text-white": selected,
                "text-slate-primary": !selected
            })}
            onClick={() => onClick(page)}
        >
            {page}
        </div>
    );
}

function PrevNextButton({ disable, type, onClick }: { disable: boolean; type: "prev" | "next"; onClick: () => void }): JSX.Element {
    const BtnIcon = type === "prev" ? ChevronLeftIcon : ChevronRightIcon;
    return (
        <div
            className={classNames("p-2 text-slate-primary", {
                "text-grey-light": disable,
                "hover:cursor-pointer": !disable
            })}
            onClick={() => !disable && onClick()}
        >
            <BtnIcon color={disable ? "grey" : "slate"} />
        </div>
    );
}

export type PaginationProps = {
    currentPage: number;
    totalPages: number;
    maxPages?: number;
    onPageChange: (page: number) => void;
};

export default function Pagination({ currentPage, totalPages, maxPages = 9, onPageChange }: PaginationProps): JSX.Element {
    let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    if (startPage + maxPages > totalPages) {
        startPage = Math.max(1, totalPages - maxPages + 1);
    }
    const pages = Array.from({ length: Math.min(totalPages, maxPages) }, (_, i) => startPage + i);

    return (
        <nav className="flex justify-center">
            {!!totalPages && <PrevNextButton type="prev" disable={currentPage <= 1} onClick={() => onPageChange(currentPage - 1)} />}
            {pages.map((page, i) => {
                if (startPage > 1 && i === 0) {
                    return (
                        <React.Fragment key={i}>
                            <PageButton page={1} selected={currentPage === 1} onClick={onPageChange} />
                            <div className="py-1">…</div>
                        </React.Fragment>
                    );
                }
                if (startPage > 1 && i === 1) {
                    return null;
                }
                if (currentPage < totalPages - 3) {
                    if (totalPages > maxPages && i === maxPages - 2) {
                        return (
                            <React.Fragment key={i}>
                                <div className="py-1">…</div>
                                <PageButton page={totalPages} selected={currentPage === totalPages} onClick={onPageChange} />
                            </React.Fragment>
                        );
                    }
                    if (totalPages > maxPages && i === maxPages - 1) {
                        return null;
                    }
                }
                return <PageButton key={i} page={page} selected={currentPage === page} onClick={onPageChange} />;
            })}
            {!!totalPages && <PrevNextButton type="next" disable={currentPage >= totalPages} onClick={() => onPageChange(currentPage + 1)} />}
        </nav>
    );
}

import { useState } from "react";

export default function useModal(reset?: () => void) {
    const [isOpen, setIsOpen] = useState(false);

    const doOpen = () => setIsOpen(true);
    const doClose = () => {
        setIsOpen(false);
        reset?.();
    };

    return { isOpen, doOpen, doClose };
}

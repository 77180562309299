import * as RadixToolTip from "@radix-ui/react-tooltip";
import classNames from "classnames";
import { ReactNode } from "react";
import { Color, Placement } from "../../referentials";

// Delay in milliseconds
const DELAY = {
    short: 100,
    long: 500
};

export function Tooltip({
    children,
    title,
    placement = "top",
    color = "slate",
    overlayClassName,
    delayDuration = "short",
    asChild = false
}: {
    children: ReactNode;
    title?: ReactNode;
    placement?: Placement;
    color?: Extract<Color, "slate" | "white">;
    overlayClassName?: string;
    delayDuration?: "short" | "long";
    asChild?: boolean;
}): JSX.Element {
    return (
        <RadixToolTip.Provider delayDuration={DELAY[delayDuration]}>
            <RadixToolTip.Root>
                <RadixToolTip.Trigger children={children} asChild={asChild} />
                {title && (
                    <RadixToolTip.Portal>
                        <RadixToolTip.Content
                            side={placement}
                            className={classNames("rounded px-2 py-3 mx-1 drop-shadow-md z-50 break-words text-justify", overlayClassName, {
                                "bg-slate-light text-white": color === "slate",
                                "bg-white text-dark": color === "white",
                                "max-w-md": typeof title === "string"
                            })}
                        >
                            {title}
                            <RadixToolTip.Arrow
                                className={classNames({
                                    "fill-white": color === "white",
                                    "fill-slate-light": color === "slate"
                                })}
                            />
                        </RadixToolTip.Content>
                    </RadixToolTip.Portal>
                )}
            </RadixToolTip.Root>
        </RadixToolTip.Provider>
    );
}

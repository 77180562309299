import { useBrandContext } from "../../contexts";

export default function BrandLogo({ width = 300, redirectTo, className }: { width?: number; redirectTo?: string; className?: string }): JSX.Element {
    const { logoPath } = useBrandContext();
    const img = <img src={logoPath} alt="Brand logo" width={width} className={className} />;
    return redirectTo ? (
        <a href={redirectTo} target="_blank" rel="noopener noreferrer">
            {img}
        </a>
    ) : (
        img
    );
}

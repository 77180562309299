import { LANG_DE, LANG_EN, LANG_ES, LANG_FR, LANG_IT, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { AccountLang } from "./AccountLang";
import deLang from "./locales/de";
import enLang from "./locales/en";
import esLang from "./locales/es";
import frLang from "./locales/fr";
import itLang from "./locales/it";

const languages = new Map<SupportedLanguageCode, AccountLang>([
    [LANG_EN, enLang],
    [LANG_FR, frLang],
    [LANG_DE, deLang],
    [LANG_ES, esLang],
    [LANG_IT, itLang]
]);
export default languages;

/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
    interface Window {
        _linkedin_data_partner_ids: (string | undefined)[];
        lintrk: ((event: string, value: { conversion_id: string | undefined }) => void) & { q?: [string, { conversion_id: string | undefined }][] };
    }
}

const LINKEDIN_REGISTER_CONVERSION_ID = process.env.REACT_APP_LINKEDIN_REGISTRATION_CONVERSION_ID;
const LINKEDIN_PARTNER_ID = process.env.REACT_APP_LINKEDIN_PARTNER_ID;

function triggerConversionId(conversionId?: string) {
    const linkedinTracker = window.lintrk;
    if (linkedinTracker) {
        linkedinTracker("track", { conversion_id: conversionId });
    }
}

export function triggerRegistrationConversion(): void {
    triggerConversionId(LINKEDIN_REGISTER_CONVERSION_ID);
}

export function activateLinkedinAds(): void {
    // Script provided by LinkedIn
    // eslint-disable-next-line no-underscore-dangle
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    // eslint-disable-next-line no-underscore-dangle
    window._linkedin_data_partner_ids.push(LINKEDIN_PARTNER_ID);
    const addLinkedInScript = (l: unknown) => {
        if (!l) {
            window.lintrk = (a: string, b: { conversion_id: string | undefined }) => {
                window.lintrk.q = [...(window.lintrk.q || []), [a, b]];
            };
            window.lintrk.q = [];
        }
        const s = document.getElementsByTagName("script")[0];
        const b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s?.parentNode?.insertBefore(b, s);
    };

    addLinkedInScript(window.lintrk);
}

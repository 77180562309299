import { IconSvgProps } from "../Icons";

export default function FunnelSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.9479 4.3017C20.8579 4.11749 20.6656 4 20.4545 4H3.54557C3.33441 4 3.14224 4.11749 3.05211 4.3017C2.96206 4.4859 2.99028 4.70364 3.12463 4.86076L9.62465 12.4635V19.4739C9.62465 19.6559 9.7222 19.825 9.88251 19.9209C9.97038 19.9735 10.0701 20 10.1702 20C10.2529 20 10.3359 19.9818 10.4124 19.9453L14.0645 18.1997C14.25 18.111 14.3674 17.9287 14.3677 17.729L14.3749 12.4637L20.8752 4.86069C21.0097 4.70364 21.038 4.48583 20.9479 4.3017ZM13.4087 11.9398C13.3284 12.0338 13.2843 12.1519 13.2841 12.2738L13.2772 17.4017L10.7158 18.626V12.2745C10.7158 12.1524 10.6718 12.0341 10.5912 11.9398L4.70241 5.05212H19.2975L13.4087 11.9398Z"
                fill={color}
            />
        </svg>
    );
}

import { BaseTrackingEvents, getAppNameForTrackingService, trackingService, useAvailableAppItems, useLang } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { AccountLang } from "../../../lang/AccountLang";

function HomePage(): JSX.Element {
    const lang = useLang<AccountLang>();
    const apps = useAvailableAppItems();

    return (
        <div data-id="home-page" className="flex flex-col justify-center gap-6 pt-6 m-6">
            <h1 className="text-center text-2xl font-bold text-primary">{lang.homePage.title}</h1>
            <ul className={classNames("grid grid-cols-1 gap-4 lg:grid-cols-2", { "mx-auto lg:grid-cols-1": apps.length === 1 })}>
                {apps.map(({ name, description, href, logo: Logo }) => (
                    <li className="sm:h-36" key={name}>
                        <a
                            href={href}
                            onClick={() =>
                                trackingService.sendEvent(BaseTrackingEvents.AppLogoClicked, {
                                    App: getAppNameForTrackingService(href),
                                    Source: "Home Page"
                                })
                            }
                            className="flex h-full flex-col items-center justify-center space-y-4 rounded-md border-2 border-transparent bg-white py-4 px-8 shadow-medium transition-all ease-in-out hover:scale-105 hover:border-pink-dark hover:shadow-lg focus:scale-105 focus:border-pink-dark focus:shadow-lg sm:flex-row sm:space-x-8 sm:space-y-0"
                        >
                            <Logo className="w-14 sm:w-24" />
                            <div className="text-center text-primary sm:w-4/5 sm:text-left">
                                <h2 className="text-base font-bold text-primary">{name}</h2>
                                <p className="whitespace-normal text-sm">{description}</p>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default HomePage;

import { isDefined, SupportedLanguageCode, Update, USER_PROFILE_COLLECTION, UserProfile } from "@vaultinum/vaultinum-api";
import { collection, doc, getCountriesData, getFirestore, updateDoc } from "@vaultinum/vaultinum-sdk";
import { pickBy } from "lodash";

export type UserProfileFields = Pick<UserProfile, "firstName" | "lastName">;

export function updateUserProfileInfo(userProfileId: string, userProfile: Update<UserProfile>): Promise<void> {
    const { firstName, lastName, birthDate, cellPhone, nationality } = userProfile;
    // Update only editable keys
    return updateDoc(
        doc(collection(getFirestore(), USER_PROFILE_COLLECTION), userProfileId),
        pickBy(
            {
                firstName,
                lastName,
                birthDate,
                cellPhone,
                nationality
            },
            isDefined
        )
    );
}

export function updateUserProfileSettings(userProfile: Pick<UserProfile, "id"> & { settings: { preferredLang: string } }): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), USER_PROFILE_COLLECTION), userProfile.id), { settings: userProfile.settings });
}

export function getPhoneData(langCode: SupportedLanguageCode): { name: string; countryCode: string }[] {
    return getCountriesData(langCode).map(country => ({ name: country.name, countryCode: country.callingCode }));
}

import { ACCOUNT_COLLECTION, ACCOUNT_PARTNERS_COLLECTION, ACCOUNT_PARTNER_INVITATIONS_COLLECTION, Account, InvitationStatus } from "@vaultinum/vaultinum-api";
import {
    DocumentSnapshot,
    collection,
    collectionGroup,
    doGet,
    doPost,
    doc,
    getDoc,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where
} from "@vaultinum/vaultinum-sdk";

const docToPartner = (snapshot: DocumentSnapshot): Account.Partner => ({
    ...(snapshot.data() as Omit<Account.Partner, "creationDate" | "partnerAccountId">),
    partnerAccountId: snapshot.id,
    creationDate: snapshot.data()?.creationDate?.toDate()
});

export function getAccountPartners(accountId: string, onUpdate: (partners: Account.Partner[]) => void): () => void {
    return onSnapshot(
        query(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_PARTNERS_COLLECTION), where("accountId", "==", accountId)),
        querySnapshot => onUpdate(querySnapshot.docs.map(docToPartner)),
        () => onUpdate([])
    );
}

export async function getPartner(accountId: string, partnerAccountId: string): Promise<Account.Partner> {
    const partnerDoc = await getDoc(doc(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_PARTNERS_COLLECTION, partnerAccountId));
    return docToPartner(partnerDoc);
}

const docToInvitation = (snapshot: DocumentSnapshot): Account.PartnerInvitation => ({
    ...(snapshot.data() as Omit<Account.PartnerInvitation, "sentDate" | "sentToEmail">),
    sentToEmail: snapshot.id,
    sentDate: snapshot.data()?.sentDate?.toDate()
});

export function getPendingPartnerInvitationsByEmailAndDomainId(
    email: string,
    whiteLabelDomainId: string | undefined,
    onUpdate: (invitations: Account.PartnerInvitation[]) => void
): () => void {
    return onSnapshot(
        query(
            collectionGroup(getFirestore(), ACCOUNT_PARTNER_INVITATIONS_COLLECTION),
            where("whiteLabelDomainId", "==", whiteLabelDomainId ?? null),
            where("sentToEmail", "==", email),
            where("status", "==", InvitationStatus.PENDING),
            orderBy("sentDate", "desc")
        ),
        querySnapshot => onUpdate(querySnapshot.docs.map(docToInvitation))
    );
}

export function getPendingInvitationsByAccountId(accountId: string, onUpdate: (invitations: Account.PartnerInvitation[]) => void): () => void {
    return onSnapshot(
        query(
            collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_PARTNER_INVITATIONS_COLLECTION),
            where("status", "==", InvitationStatus.PENDING),
            orderBy("sentDate", "desc")
        ),
        querySnapshot => onUpdate(querySnapshot.docs.map(docToInvitation))
    );
}

export function cancelInvitation(accountId: string, sentToEmail: string): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_PARTNER_INVITATIONS_COLLECTION), sentToEmail), {
        status: InvitationStatus.CANCELED
    });
}

export async function acceptPartnershipInvitation(partnerAccountId: string, userAccountId: string): Promise<void> {
    return doPost("partner/invite/accept", {
        partnerAccountId,
        userAccountId
    });
}

export async function rejectPartnershipInvitation(accountId: string, partnerAccountId: string): Promise<void> {
    return doPost("partner/invite/reject", {
        accountId,
        partnerAccountId
    });
}

export async function getPartnerAccount(partnerAccountId: string, accountId: string): Promise<Account> {
    return doGet(`account/${accountId}/partner/${partnerAccountId}`);
}

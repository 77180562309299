import { LogoSvgProps } from "../Logos";

export default function EscrowSvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 206 232" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M137.608 201.648V38.9303C131.077 37.4705 126.197 31.6404 126.197 24.6699V2.92032C126.197 2.24939 125.822 1.62318 125.213 1.34171C123.794 0.686137 122.237 0.337341 120.644 0.337341H15.3778C7.43408 0.337341 0.994019 6.7774 0.994019 14.7215V200.005C0.994019 207.95 7.43408 214.39 15.3782 214.39H130.154C134.603 211.884 137.608 207.117 137.608 201.648Z"
                fill="#F2F3F4"
            />
            <path
                d="M139.542 40.1785C138.459 40.1785 137.405 40.0547 136.39 39.8279V200.005C136.39 207.949 129.95 214.39 122.006 214.39L143.98 204.102C151.924 204.102 153.468 188.552 153.468 180.608L164.999 122.349V44.6921C164.999 43.1194 164.657 41.5828 164.018 40.1785H139.542Z"
                fill="#F2F3F4"
            />
            <path
                d="M161.807 36.987L128.35 3.52888C127.418 2.59725 126.334 1.85448 125.158 1.31909V25.7944C125.158 33.7385 131.598 40.1786 139.542 40.1786H164.017C163.482 39.0021 162.739 37.9186 161.807 36.987Z"
                fill="#72ACE1"
            />
            <path
                d="M123.166 149.401C123.166 149.401 124.436 147.258 125.794 144.964L115.4 138.815C108.543 134.88 105.337 134.152 99.1507 136.544L92.4088 139.15C88.3059 140.736 83.7025 140.353 79.9181 138.111L61.873 127.472L36.2433 170.733L59.3496 184.372C60.9147 185.299 62.2895 186.515 63.4001 187.956L71.1861 198.054C75.4868 203.084 84.3626 209.608 90.0562 212.981L98.144 217.773C99.8257 216.982 101.299 215.706 102.318 213.987C103.584 211.85 103.922 209.426 103.461 207.171C103.31 206.436 103.613 205.683 104.225 205.25C104.838 204.817 105.647 204.785 106.292 205.167L109.541 207.092C114.087 209.785 120.064 208.498 122.838 204.002C124.992 200.511 124.635 196.212 122.313 193.147C122.201 192.999 122.205 192.794 122.331 192.657C122.454 192.523 122.654 192.494 122.81 192.587L126.767 194.949C131.335 197.655 137.232 196.146 139.938 191.578C141.927 188.22 141.634 184.147 139.527 181.149C139.404 180.974 139.416 180.74 139.562 180.585C139.705 180.434 139.934 180.401 140.113 180.508L145.149 183.491C149.044 185.799 154.085 185.268 157.226 182.008C158.383 180.806 159.31 178.981 159.738 177.506L125.108 156.999C122.475 155.437 121.605 152.036 123.166 149.401Z"
                fill="#72ACE1"
            />
            <path
                d="M176.486 187.423L158.78 176.938C158.391 178.452 157.639 179.919 156.413 181.193C153.539 184.177 149.072 184.874 145.349 183.202L138.339 179.049C136.679 178.065 134.538 178.614 133.555 180.273C132.572 181.932 133.12 184.074 134.779 185.057L140.168 188.249C139.947 189.112 139.602 189.958 139.124 190.763C136.639 194.958 131.463 196.573 127.096 194.712L121.376 191.323C119.716 190.34 117.575 190.888 116.591 192.547C115.608 194.207 116.156 196.349 117.815 197.332L123.164 200.501C122.93 201.425 122.553 202.33 122.024 203.187C119.25 207.682 113.272 208.97 108.727 206.277L105.478 204.352C104.833 203.97 104.023 204.002 103.411 204.435C102.799 204.868 102.496 205.621 102.647 206.356C103.108 208.61 102.77 211.035 101.504 213.172C100.422 214.999 98.825 216.326 97.011 217.101L119.589 230.477C123.57 232.835 128.881 231.58 131.164 227.556C133.379 223.653 132.068 218.679 128.191 216.382L137.289 221.772C141.27 224.13 146.581 222.875 148.865 218.851C151.079 214.948 149.768 209.974 145.891 207.677L151.894 211.234C155.875 213.592 161.186 212.337 163.47 208.313C165.685 204.41 164.374 199.436 160.496 197.139L167.885 201.516C171.866 203.875 177.177 202.62 179.46 198.596C181.674 194.693 180.363 189.72 176.486 187.423Z"
                fill="#72ACE1"
            />
            <path
                d="M183.888 150.061L172.036 130.056L158.177 138.111C154.392 140.353 149.788 140.736 145.686 139.15L138.083 136.163C135.705 135.229 133.093 135.064 130.616 135.691L118.967 138.639C113.544 140.011 109.745 144.891 109.745 150.485V166.036C109.745 168.235 111.527 170.023 113.726 170.024C120.356 170.029 125.732 164.655 125.732 158.026V157.367L148.455 170.824L183.888 150.061Z"
                fill="#294966"
            />
            <path
                d="M183.088 148.71L146.91 169.909L175.597 186.896C176.52 185.912 177.58 185.061 178.744 184.371L197.665 173.317L183.088 148.71Z"
                fill="#294966"
            />
            <path
                d="M69.477 193.533L64.8022 200.045C62.1442 203.748 56.9877 204.594 53.2851 201.936C49.5826 199.278 48.7359 194.122 51.3939 190.419L56.0687 183.907C58.7267 180.205 63.8832 179.358 67.5857 182.016C71.2883 184.674 72.135 189.831 69.477 193.533Z"
                fill="#294966"
            />
            <path
                d="M88.0444 195.972L78.2104 209.671C75.5524 213.373 70.3959 214.22 66.6933 211.562C62.9908 208.904 62.1441 203.748 64.8021 200.045L74.6361 186.347C77.2941 182.644 82.4506 181.797 86.1531 184.455C89.8561 187.113 90.7028 192.27 88.0444 195.972Z"
                fill="#294966"
            />
            <path
                d="M101.453 205.599L91.6192 219.297C88.9612 223 83.8047 223.846 80.1022 221.188C76.3996 218.53 75.5529 213.374 78.2109 209.671L88.0449 195.973C90.7029 192.27 95.8594 191.424 99.5619 194.082C103.265 196.74 104.111 201.896 101.453 205.599Z"
                fill="#294966"
            />
            <path
                d="M110.492 221.311L107.221 225.866C104.563 229.569 99.4069 230.416 95.7043 227.758C92.0018 225.1 91.1551 219.943 93.8131 216.241L97.0832 211.685C99.7412 207.983 104.898 207.136 108.6 209.794C112.303 212.452 113.15 217.609 110.492 221.311Z"
                fill="#294966"
            />
            <path
                d="M71.5325 122.974L64.304 118.692C62.3156 117.514 59.7485 118.171 58.5706 120.159L28.8525 170.322C27.6747 172.311 28.3316 174.878 30.32 176.056L37.5485 180.338C42.3968 183.21 48.6562 181.608 51.5283 176.76L75.1108 136.954C77.983 132.106 76.3813 125.847 71.5325 122.974Z"
                fill="#294966"
            />
            <path
                d="M169.605 118.692L162.376 122.974C157.528 125.847 155.926 132.106 158.798 136.954L182.381 176.76C185.253 181.608 191.512 183.21 196.361 180.338L203.589 176.056C205.577 174.878 206.234 172.311 205.057 170.322L175.338 120.159C174.16 118.171 171.593 117.514 169.605 118.692Z"
                fill="#163046"
            />
            <path
                d="M140.085 67.9995H25.9082C23.9794 67.9995 22.4161 66.4362 22.4161 64.5075C22.4161 62.5787 23.9794 61.0154 25.9082 61.0154H140.085C142.014 61.0154 143.577 62.5787 143.577 64.5075C143.577 66.4362 142.014 67.9995 140.085 67.9995Z"
                fill="#C7E1FF"
            />
            <path
                d="M82.9967 43.6706H25.9082C23.9794 43.6706 22.4161 42.1074 22.4161 40.1786C22.4161 38.2498 23.9794 36.6866 25.9082 36.6866H82.9967C84.9255 36.6866 86.4887 38.2498 86.4887 40.1786C86.4887 42.1074 84.9255 43.6706 82.9967 43.6706Z"
                fill="#C7E1FF"
            />
            <path
                d="M140.085 89.5565H25.9082C23.9794 89.5565 22.4161 87.9933 22.4161 86.0645C22.4161 84.1358 23.9794 82.5725 25.9082 82.5725H140.085C142.014 82.5725 143.577 84.1358 143.577 86.0645C143.577 87.9933 142.014 89.5565 140.085 89.5565Z"
                fill="#C7E1FF"
            />
        </svg>
    );
}

import { Tabs } from "antd";
import { isEqual, uniq } from "lodash";
import { ElementType, ReactNode, useEffect, useRef, useState } from "react";
import { Location, NavigationType, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { Overwrite } from "../../../../helpers";
import { TabLabel } from "../../Tabs";
import { Drawer, DrawerProps } from "../Drawer";

type TabProps = {
    key: string;
    icon: ElementType;
    title: string;
    children: ReactNode;
}[];

type DrawerWithTabProps = Overwrite<DrawerProps, { tabs: TabProps; children?: JSX.Element }>;
type DrawerWithNavigation = Overwrite<DrawerWithTabProps, { header: Omit<DrawerProps["header"], "navigation"> }>;

export namespace Drawers {
    export function WithTabs({ tabs, children, onClose, ...props }: DrawerWithTabProps): JSX.Element {
        const currentTabs = useRef(tabs);
        const [activeTab, setActiveTab] = useState<string | undefined>(tabs?.[0].key);

        useEffect(() => {
            // Reset active tab if tabs change
            if (
                !activeTab ||
                !isEqual(
                    tabs.map(tab => tab.key),
                    currentTabs.current.map(tab => tab.key)
                )
            ) {
                setActiveTab(tabs?.[0].key);
            }
            currentTabs.current = tabs;
        }, [tabs]);

        function onTabChange(key: string) {
            setActiveTab(key);
        }

        return (
            <Drawer
                children={
                    <div className="flex w-full flex-col gap-4">
                        {children}
                        <Tabs defaultActiveKey={tabs[0].key} activeKey={activeTab} onChange={onTabChange} destroyInactiveTabPane>
                            {tabs.map(tab => (
                                <Tabs.TabPane
                                    tab={<TabLabel Icon={tab.icon} label={tab.title} isActive={activeTab === tab.key} />}
                                    key={tab.key}
                                    children={<div className="h-full overflow-y-scroll">{tab.children}</div>}
                                />
                            ))}
                        </Tabs>
                    </div>
                }
                onClose={() => {
                    setActiveTab(tabs?.[0].key);
                    onClose?.();
                }}
                {...props}
            />
        );
    }

    export function WithNavigations({ tabs, header, ...props }: DrawerWithNavigation): JSX.Element {
        const [canGoBack, setCanGoBack] = useState(false);
        const [canGoForward, setCanGoForward] = useState(false);
        const location = useLocation();
        const navigate = useNavigate();
        const navigationType = useNavigationType();
        const historyStack = useRef<{ entries: Location[]; currentIndex: number }>({ entries: [], currentIndex: -1 });

        useEffect(() => {
            const { entries, currentIndex } = historyStack.current;
            if (navigationType === NavigationType.Push) {
                // Navigate to a new route
                const newEntries = uniq([...entries.slice(0, currentIndex + 1), location]);
                historyStack.current = {
                    entries: newEntries,
                    currentIndex: newEntries.length - 1
                };
            } else if (navigationType === NavigationType.Pop) {
                // Navigate back
                const index = entries.findIndex(entry => entry.key === location.key);
                if (index !== -1) {
                    historyStack.current.currentIndex = index;
                }
            }

            setCanGoBack(historyStack.current.currentIndex > 0);
            setCanGoForward(historyStack.current.currentIndex < historyStack.current.entries.length - 1);
        }, [location, navigationType]);

        function doForward() {
            navigate(1);
        }

        function doBack() {
            navigate(-1);
        }

        return (
            <WithTabs
                tabs={tabs}
                header={{
                    ...header,
                    navigation: {
                        onForward: canGoForward ? doForward : undefined,
                        onPrevious: canGoBack ? doBack : undefined
                    }
                }}
                {...props}
            />
        );
    }
}

import { Account } from "@vaultinum/vaultinum-api";
import { AddIcon, Empty, Input } from "../../../design-system";
import { CommonLang, plural, useLang } from "../../../lang";
import RepositoryRow, { SelectedGitRepositories } from "./RepositoryRow";

export default function GitRepositories({
    selectedRepositories,
    gitRepositories,
    addRepository,
    onRepositorySelected,
    onBranchSelected,
    removeRepository,
    addAllRepositories,
    onAddAllRepositoriesChange
}: {
    selectedRepositories: SelectedGitRepositories[];
    gitRepositories: Account.GitRepository[];
    addRepository: (gitRepository: Account.GitRepository) => void;
    onRepositorySelected: (oldRepository: Account.GitRepository, newRepository: Account.GitRepository, defaultBranch?: string) => void;
    onBranchSelected: (repositoryId: string, branch: string) => void;
    removeRepository: (repository: Account.GitRepository) => void;
    addAllRepositories: boolean;
    onAddAllRepositoriesChange: (checked: boolean) => Promise<void>;
}) {
    const lang = useLang<CommonLang>();
    const allRepositoriesSelected = addAllRepositories || gitRepositories.length === selectedRepositories.length;

    return (
        <div className="space-y-4">
            <div className="flex justify-between">
                <div className="font-semibold">{!!selectedRepositories.length && plural(lang.shared.selectedRepositories, selectedRepositories.length)}</div>
                <div className="flex gap-2">
                    <Input.Checkbox
                        label={lang.git.addAllRepositories}
                        onChange={e => onAddAllRepositoriesChange(e.target.checked)}
                        checked={allRepositoriesSelected}
                        id="addAllRepositories"
                    />
                </div>
            </div>
            <div className="space-y-3 overflow-auto">
                {selectedRepositories.map(({ repository, selectedBranch }) => (
                    <RepositoryRow
                        key={repository.id}
                        repositories={gitRepositories}
                        selectedRepository={repository}
                        selectedBranch={selectedBranch}
                        removeRepository={removeRepository}
                        onRepositorySelected={onRepositorySelected}
                        onBranchSelected={onBranchSelected}
                        selectedRepositories={selectedRepositories}
                    />
                ))}
                {!selectedRepositories.length && <Empty className="text-gray-400" description={lang.git.codeRepositoryEmpty} />}
            </div>
            {!allRepositoriesSelected && (
                <div
                    onClick={() => {
                        const filteredRepositories = gitRepositories.filter(
                            repository => !selectedRepositories.map(element => element.repository.id).includes(repository.id)
                        );
                        if (filteredRepositories.length) {
                            addRepository(filteredRepositories[0]);
                        }
                    }}
                    className="flex w-full items-center justify-center rounded-md border border-dashed border-gray-300 p-2 pt-2 text-gray-400 hover:cursor-pointer hover:border-primary hover:text-primary"
                >
                    <div className="flex items-center gap-1">
                        <AddIcon color="grey" shade="light" />
                        {lang.git.addRepository}
                    </div>
                </div>
            )}
        </div>
    );
}

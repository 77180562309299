import { IconSvgProps } from "../Icons";

function SourceCodeSvg({ color, size }: IconSvgProps) {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.72135 7.04704C4.51792 6.8436 4.18828 6.8436 3.9849 7.04704L2.7349 8.29704C2.53146 8.50047 2.53146 8.83089 2.7349 9.03428L3.9849 10.2843C4.08854 10.3882 4.22135 10.4377 4.35417 10.4377C4.48698 10.4377 4.62057 10.3872 4.7224 10.2855C4.92583 10.082 4.92583 9.75162 4.7224 9.54824L3.84115 8.66683L4.72292 7.78558C4.90365 7.58089 4.90365 7.25277 4.72135 7.04704ZM10.013 2.76527L8.06953 0.821517C7.75521 0.509069 7.33073 0.333496 6.89062 0.333496H2.16667C1.24609 0.333496 0.5 1.07959 0.5 2.00016L0.500169 12.0002C0.500169 12.9205 1.24626 13.6668 2.16684 13.6668H8.83333C9.75 13.6668 10.5 12.9168 10.5 12.0002V3.94287C10.5 3.50277 10.3255 3.07829 10.013 2.76527ZM9.80693 12.0014C9.80693 12.2315 9.5 13.0001 8.83333 13.0001H2.16719C1.5 13.0001 1.21074 12.2302 1.21074 12.0001L1.21022 2.00351C1.21022 1.20452 1.93708 1.00012 2.16719 1.00012H6.33385L6.33333 3.66683C6.33333 4.12699 6.70651 4.50016 7.16667 4.50016H9.80693V8.29704V12.0014ZM6.27865 7.04704C6.07521 7.25047 6.07521 7.58089 6.27865 7.78428L7.15885 8.66683L6.27708 9.54808C6.07365 9.75152 6.07365 10.0819 6.27708 10.2853C6.38021 10.3882 6.51302 10.4377 6.64583 10.4377C6.77865 10.4377 6.91224 10.3872 7.01406 10.2855L8.26406 9.03547C8.4675 8.83204 8.4675 8.50162 8.26406 8.29824L7.01406 7.04824C6.8099 6.84391 6.48177 6.84391 6.27865 7.04704Z" />
        </svg>
    );
}

export default SourceCodeSvg;

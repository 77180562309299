import { Account, AccountRights, AccountRole, AccountUser } from "@vaultinum/vaultinum-api";
import { Modal, Select, formatDisplayName, getAccountUsers, openNotificationWithIcon, useAuthContext, useLang } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { AccountLang } from "../../../lang/AccountLang";
import { deleteAccountUser, getAccountRoleRights, getAccountUserRole, updateAccountUserRights } from "../../../services/accountService";

const LeaveAccountDialog = ({
    account,
    onDeleteAccountClicked,
    onClose
}: {
    account: Account;
    onDeleteAccountClicked: (account: Account) => void;
    onClose: () => void;
}): JSX.Element => {
    const { userProfile } = useAuthContext();
    const [working, setWorking] = useState(false);
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>([]);
    const [accountUserIdToAssignAsAdmin, setAccountUserIdToAssignAsAdmin] = useState<string>();
    const lang = useLang<AccountLang>();

    useEffect(() => {
        void (async () => {
            const users = await getAccountUsers(account);
            setAccountUsers(users);
        })();
    }, [account]);

    const loggedAccountUser = accountUsers.find(accountUser => accountUser.id === userProfile?.id);
    const isLastAccountUserAdmin =
        loggedAccountUser?.rights.includes(AccountRights.ADMIN) &&
        accountUsers.filter(accountUser => accountUser.rights.includes(AccountRights.ADMIN)).length === 1;
    const isLastAccountUser = accountUsers.length === 1;
    const isRepresentativeAndLastAccountUser = isLastAccountUser && getAccountUserRole(accountUsers[0].rights) === AccountRole.REPRESENTATIVE;

    async function leaveAccount() {
        const adminUser = accountUsers.find(accountUser => accountUser.id === accountUserIdToAssignAsAdmin);
        setWorking(true);
        try {
            if (adminUser) {
                await updateAccountUserRights(adminUser, getAccountRoleRights(AccountRole.ADMIN));
            }
            if (userProfile?.id) {
                await deleteAccountUser(account, userProfile.id);
            }
            onClose();
            openNotificationWithIcon({ type: "success", description: lang.shared.successMessage });
        } catch (err) {
            openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
        } finally {
            setWorking(false);
        }
    }

    const onOk = async () => {
        if ((isLastAccountUser && !isRepresentativeAndLastAccountUser) || accountUserIdToAssignAsAdmin === "") {
            onClose();
            onDeleteAccountClicked(account);
        } else {
            await leaveAccount();
        }
    };

    return (
        <Modal
            data-id="leave-account-dialog"
            title={lang.profileSettings.companyAccounts.leaveAccount(account.companyName)}
            isOpen
            onConfirm={onOk}
            onClose={onClose}
            isLoading={working}
            okText={
                (isLastAccountUser && !isRepresentativeAndLastAccountUser) || accountUserIdToAssignAsAdmin === ""
                    ? lang.profileSettings.companyAccounts.deleteAccount
                    : lang.profileSettings.companyAccounts.leave
            }
            lang={lang}
        >
            <p>{lang.profileSettings.companyAccounts.leaveAccountDescription(account.companyName)}</p>
            <div className="mt-2">
                {isLastAccountUserAdmin && !isLastAccountUser && lang.profileSettings.companyAccounts.leaveAccountAdminDescription}
                {!isLastAccountUserAdmin && lang.profileSettings.companyAccounts.leaveAccountNonAdminDescription}
            </div>
            {isLastAccountUserAdmin && !isLastAccountUser && (
                <Select.Search
                    placeholder={lang.profileSettings.companyAccounts.newAdminToAssign}
                    className="mt-4 w-full"
                    options={[
                        ...accountUsers
                            .filter(accountUser => accountUser.id !== loggedAccountUser?.id)
                            .map(accountUser => ({
                                label: formatDisplayName(accountUser),
                                value: accountUser.id
                            })),
                        { label: lang.profileSettings.companyAccounts.doNotAssignNewAdmin, value: "" }
                    ]}
                    onChange={value => setAccountUserIdToAssignAsAdmin(value?.toString())}
                />
            )}
        </Modal>
    );
};
export default LeaveAccountDialog;

/* eslint-disable import/no-unused-modules */
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames";
import { ReactNode } from "react";

export namespace Segment {
    export function Root({ value, onValueChange, children }: { value: string; onValueChange: (value: string) => void; children: ReactNode }) {
        return (
            <ToggleGroup.Root
                className="inline-flex rounded border border-grey-light bg-grey-light"
                type="single"
                value={value}
                onValueChange={onValueChange}
                children={children}
            />
        );
    }

    export function Item({
        currentValue,
        selectedValue,
        ...props
    }: {
        currentValue: {
            label: string;
            value: string;
        };
        selectedValue: string;
    }) {
        return (
            <ToggleGroup.Item
                {...props}
                key={currentValue.value}
                value={currentValue.value}
                className={classNames("flex items-center justify-center px-4 py-2 text-sm first:ml-0 first:rounded-l last:rounded-r", {
                    "bg-slate-primary text-white transition-colors hover:bg-slate-light": selectedValue === currentValue.value,
                    "bg-white text-grey-dark hover:text-slate-primary": selectedValue !== currentValue.value
                })}
                style={{ marginLeft: 1 }}
            >
                {currentValue.label}
            </ToggleGroup.Item>
        );
    }
}

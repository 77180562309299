import { BrandLogo, getDomainLogo, useAuthContext, useLang, useNavigationMenuContext, useWhiteLabelContext } from "@vaultinum/vaultinum-sdk";
import { useEffect } from "react";
import { AccountLang } from "../../../lang/AccountLang";

function Header({ title, subtitle, description }: { title: string; subtitle: string; description: string }): JSX.Element {
    const { whiteLabelDomain, domainLogo } = useWhiteLabelContext();
    const logoClassName = "h-28 m-auto w-auto";
    return (
        <div className="flex flex-col items-center gap-4 text-center">
            {getDomainLogo(domainLogo, whiteLabelDomain, <BrandLogo className={logoClassName} />, logoClassName)}
            <p className="text-4xl font-bold" children={title} />
            <div className="flex flex-col gap-10">
                <p className="text-xl font-semibold" children={subtitle} />
                <p className="text-base" children={description} />
            </div>
        </div>
    );
}

export default function VerifyEmailPage(): JSX.Element {
    const lang = useLang<AccountLang>();
    const { user } = useAuthContext();
    const { setMenuItems } = useNavigationMenuContext();

    useEffect(() => {
        setMenuItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Header title={lang.onboarding.title} subtitle={lang.onboarding.subtitle} description={lang.onboarding.verifyEmail.sent(user?.email || "")} />
            <p data-id="onboarding-confirm-email" className="text-center text-base" children={lang.onboarding.verifyEmail.confirm} />
        </>
    );
}

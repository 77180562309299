import { IconSvgProps } from "../../../../../../common";

export default function InsightsOverviewSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.5" cy="16.5" r="3.5" />
            <rect x="4" y="4" width="7" height="7" />
            <rect x="13" y="13" width="7" height="7" />
            <circle cx="16.5" cy="7.5" r="3.5" />
        </svg>
    );
}

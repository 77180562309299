import { IconSvgProps } from "../Icons";

function InfoCircleSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z" />
            <path d="M12.5357 10.5714H11.4642C11.366 10.5714 11.2857 10.6518 11.2857 10.75V16.8214C11.2857 16.9196 11.366 17 11.4642 17H12.5357C12.6339 17 12.7143 16.9196 12.7143 16.8214V10.75C12.7143 10.6518 12.6339 10.5714 12.5357 10.5714ZM13.0714 8.07143C13.0714 7.78727 12.9585 7.51475 12.7576 7.31381C12.5567 7.11288 12.2841 7 12 7C11.7158 7 11.4433 7.11288 11.2423 7.31381C11.0414 7.51475 10.9285 7.78727 10.9285 8.07143C10.9285 8.35559 11.0414 8.62811 11.2423 8.82904C11.4433 9.02997 11.7158 9.14286 12 9.14286C12.2841 9.14286 12.5567 9.02997 12.7576 8.82904C12.9585 8.62811 13.0714 8.35559 13.0714 8.07143Z" />
        </svg>
    );
}

export default InfoCircleSvg;

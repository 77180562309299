import { IconSvgProps } from "../../../../../common";

export default function ScopeIPSvg({ size, color }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.5904C0 10.1141 10.114 0 22.5904 0H277.41C289.886 0 300 10.114 300 22.5904V277.41C300 289.886 289.886 300 277.41 300H22.5904C10.1141 300 0 289.886 0 277.41V22.5904Z"
                fill={color ?? "#C7E1FF"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M155.021 44.5341C155.021 47.3086 152.773 49.5582 149.999 49.5582C147.226 49.5582 144.978 47.3086 144.978 44.5341V30.024C144.978 27.2495 147.226 25 149.999 25C152.773 25 155.021 27.2495 155.021 30.024V44.5341ZM232.929 95.3341C230.535 96.7107 227.479 95.8842 226.103 93.489C224.727 91.0938 225.553 88.036 227.947 86.6595L240.508 79.4041C242.902 78.0275 245.958 78.8533 247.334 81.2492C248.71 83.6444 247.885 86.7022 245.49 88.0787L232.929 95.3341ZM200.776 59.4762C199.4 61.8715 196.343 62.6973 193.949 61.3213C191.555 59.9447 190.73 56.887 192.105 54.4918L199.357 41.9254C200.733 39.5302 203.789 38.7043 206.183 40.0803C208.578 41.4569 209.403 44.5146 208.028 46.9099L200.776 59.4762ZM72.0524 86.6595C74.4466 88.036 75.272 91.0938 73.8967 93.489C72.5207 95.8842 69.4643 96.7101 67.0702 95.3341L54.5096 88.0787C52.1154 86.7022 51.29 83.6444 52.6653 81.2492C54.0413 78.854 57.0977 78.0281 59.4918 79.4041L72.0524 86.6595ZM107.894 54.4717C109.281 56.8669 108.464 59.9334 106.07 61.3207C103.676 62.708 100.611 61.8909 99.2239 59.4957L91.9719 46.9293C90.5852 44.5341 91.4019 41.4676 93.796 40.0803C96.1901 38.693 99.2553 39.5101 100.642 41.9053L107.894 54.4717Z"
                fill="#4A7398"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M122.313 223.818H178.137V245.652C178.137 251.394 173.442 256.089 167.702 256.089H132.751C127.01 256.089 122.314 251.394 122.314 245.652V223.818H122.313Z"
                fill="#4A7398"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M111.877 203.917H188.574V224.779C188.574 230.519 183.879 235.215 178.139 235.215H122.313C116.573 235.215 111.877 230.519 111.877 224.779V203.917Z"
                fill="#294966"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M150.226 64C185.696 64 214.451 92.755 214.451 128.226C214.451 163.895 191.665 161.763 188.575 214.341H111.876C108.786 161.764 86 163.896 86 128.226C86 92.755 114.755 64 150.226 64Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M137.187 213.011V165.392H131.002C126.631 165.392 122.66 163.6 119.785 160.713C116.909 157.826 115.124 153.839 115.124 149.449C115.124 145.06 116.909 141.073 119.785 138.186C122.66 135.298 126.631 133.506 131.002 133.506C135.373 133.506 139.344 135.298 142.219 138.186C145.095 141.073 146.879 145.06 146.879 149.449V155.66H153.582V149.449C153.582 145.06 155.366 141.073 158.242 138.186C161.117 135.298 165.088 133.506 169.459 133.506C173.831 133.506 177.801 135.298 180.677 138.186C183.552 141.073 185.337 145.06 185.337 149.449C185.337 153.839 183.552 157.826 180.677 160.713C177.801 163.6 173.831 165.392 169.459 165.392H163.274V213.011H153.582V165.392H146.879V213.011H137.187ZM137.187 155.659V149.449C137.187 147.746 136.49 146.195 135.366 145.067C134.243 143.939 132.698 143.239 131.002 143.239C129.307 143.239 127.762 143.939 126.638 145.067C125.515 146.195 124.818 147.747 124.818 149.449C124.818 151.151 125.515 152.703 126.638 153.831C127.762 154.959 129.307 155.659 131.002 155.659L137.187 155.659ZM163.274 155.659H169.459C171.155 155.659 172.7 154.959 173.823 153.831C174.946 152.703 175.644 151.152 175.644 149.449C175.644 147.747 174.946 146.195 173.823 145.067C172.7 143.939 171.154 143.239 169.459 143.239C167.764 143.239 166.218 143.939 165.095 145.067C163.972 146.195 163.274 147.747 163.274 149.449V155.659Z"
                fill="#4A7398"
            />
        </svg>
    );
}

import { customIconComponent } from "../../../../common";
import {
    AccessClauseSvg,
    ActivityAnalysisSvg,
    ArchitectureSvg,
    BipartiteSvg,
    BitbucketSvg,
    BranchsTagsSvg,
    CodeExplorerSvg,
    ContainersSvg,
    CopyrightsSvg,
    DependenciesSvg,
    DevelopersSvg,
    DueDiligenceSvg,
    EvaluationSvg,
    GitlabSvg,
    GraphSvg,
    InsightsOverviewSvg,
    LicensesSvg,
    NetworkScanSvg,
    RepositoriesSvg,
    ScopeCyberOutlinedSvg,
    ScopeEsgOutlinedSvg,
    ScopeGDPROutlinedSvg,
    ScopeIPOutlinedSvg,
    ScopeSoftwareOutlinedSvg,
    TechnologiesSvg,
    TrademarksSvg,
    TripartiteSvg,
    UrlsEmailsSvg,
    VulnerabilitiesSvg
} from "./business";

/*
 * Business Icons
 */
export const AccessClauseIcon = customIconComponent(AccessClauseSvg);
export const BipartiteIcon = customIconComponent(BipartiteSvg);
export const BitbucketIcon = customIconComponent(BitbucketSvg);
export const DueDiligenceIcon = customIconComponent(DueDiligenceSvg);
export const GitlabIcon = customIconComponent(GitlabSvg);
export const ScopeCyberIcon = customIconComponent(ScopeCyberOutlinedSvg);
export const ScopeEsgIcon = customIconComponent(ScopeEsgOutlinedSvg);
export const ScopeGDPRIcon = customIconComponent(ScopeGDPROutlinedSvg);
export const ScopeIPIcon = customIconComponent(ScopeIPOutlinedSvg);
export const ScopeSoftwareIcon = customIconComponent(ScopeSoftwareOutlinedSvg);
export const TripartiteIcon = customIconComponent(TripartiteSvg);

/*
 * Insights Icons
 */
export const RepositoriesIcon = customIconComponent(RepositoriesSvg);
export const CodeExplorerIcon = customIconComponent(CodeExplorerSvg);
export const EvaluationIcon = customIconComponent(EvaluationSvg);
export const BranchsTagsIcon = customIconComponent(BranchsTagsSvg);
export const ActivityAnalysisIcon = customIconComponent(ActivityAnalysisSvg);
export const TechnologiesIcon = customIconComponent(TechnologiesSvg);
export const DevelopersIcon = customIconComponent(DevelopersSvg);
export const DependenciesIcon = customIconComponent(DependenciesSvg);
export const VulnerabilitiesIcon = customIconComponent(VulnerabilitiesSvg);
export const NetworkScanIcon = customIconComponent(NetworkScanSvg);
export const LicensesIcon = customIconComponent(LicensesSvg);
export const CopyrightsIcon = customIconComponent(CopyrightsSvg);
export const TrademarksIcon = customIconComponent(TrademarksSvg);
export const UrlsEmailsIcon = customIconComponent(UrlsEmailsSvg);
export const ContainersIcon = customIconComponent(ContainersSvg);
export const ArchitectureIcon = customIconComponent(ArchitectureSvg);
export const GraphIcon = customIconComponent(GraphSvg);
export const InsightsOverviewIcon = customIconComponent(InsightsOverviewSvg);

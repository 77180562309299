import { IconSvgProps } from "../Icons";

function ExpandSvg({ color, size }: IconSvgProps) {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.714844 3.51562V1.09375C0.714844 0.833984 0.923828 0.625 1.18359 0.625H3.60547C3.73438 0.625 3.83984 0.730469 3.83984 0.859375V1.64062C3.83984 1.76953 3.73438 1.875 3.60547 1.875H1.96484V3.51562C1.96484 3.64453 1.85938 3.75 1.73047 3.75H0.949219C0.820312 3.75 0.714844 3.64453 0.714844 3.51562ZM6.33984 0.859375V1.64062C6.33984 1.76953 6.44531 1.875 6.57422 1.875H8.21484V3.51562C8.21484 3.64453 8.32031 3.75 8.44922 3.75H9.23047C9.35938 3.75 9.46484 3.64453 9.46484 3.51562V1.09375C9.46484 0.833984 9.25586 0.625 8.99609 0.625H6.57422C6.44531 0.625 6.33984 0.730469 6.33984 0.859375ZM9.23047 6.25H8.44922C8.32031 6.25 8.21484 6.35547 8.21484 6.48438V8.125H6.57422C6.44531 8.125 6.33984 8.23047 6.33984 8.35938V9.14062C6.33984 9.26953 6.44531 9.375 6.57422 9.375H8.99609C9.25586 9.375 9.46484 9.16602 9.46484 8.90625V6.48438C9.46484 6.35547 9.35938 6.25 9.23047 6.25ZM3.83984 9.14062V8.35938C3.83984 8.23047 3.73438 8.125 3.60547 8.125H1.96484V6.48438C1.96484 6.35547 1.85938 6.25 1.73047 6.25H0.949219C0.820312 6.25 0.714844 6.35547 0.714844 6.48438V8.90625C0.714844 9.16602 0.923828 9.375 1.18359 9.375H3.60547C3.73438 9.375 3.83984 9.26953 3.83984 9.14062Z" />
        </svg>
    );
}

export default ExpandSvg;

import { COLOR_SCHEME } from "../../../referentials";
import { LogoSvgProps } from "../Logos";

function SummarySvg({ size }: LogoSvgProps): JSX.Element {
    return (
        <svg viewBox="0 0 48 48" width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.224 7.67667C21.8211 7.67667 21.4944 8.0033 21.4944 8.40623C21.4944 8.80926 21.8211 9.1358 22.224 9.1358H25.5098C26.2153 9.1358 26.7892 8.56186 26.7892 7.85648V5.32823C26.7892 4.62277 26.2153 4.04883 25.5098 4.04883H10.42C9.71456 4.04883 9.14062 4.62277 9.14062 5.32823V7.85658C9.14062 8.56195 9.71456 9.13589 10.42 9.13589H18.7948C19.1977 9.13589 19.5244 8.80926 19.5244 8.40633C19.5244 8.0033 19.1977 7.67677 18.7948 7.67677H10.5998V5.50805H25.33V7.67658H22.224V7.67667Z"
                fill="url(#paint0_linear_3307_11722)"
            />
            <path
                d="M5.88281 12.8812C5.88281 13.2842 6.20944 13.6107 6.61237 13.6107H29.4782C29.8812 13.6107 30.2077 13.2841 30.2077 12.8812C30.2077 12.4781 29.8811 12.1516 29.4782 12.1516H6.61237C6.20944 12.1515 5.88281 12.4781 5.88281 12.8812Z"
                fill="url(#paint1_linear_3307_11722)"
            />
            <path
                d="M6.61237 18.2016H29.4782C29.8812 18.2016 30.2077 17.8749 30.2077 17.472C30.2077 17.069 29.8811 16.7424 29.4782 16.7424H6.61237C6.20944 16.7424 5.88281 17.0691 5.88281 17.472C5.88281 17.875 6.20944 18.2016 6.61237 18.2016Z"
                fill="url(#paint2_linear_3307_11722)"
            />
            <path
                d="M24.968 22.0628C24.968 21.6598 24.6413 21.3333 24.2384 21.3333H6.61237C6.20944 21.3333 5.88281 21.6599 5.88281 22.0628C5.88281 22.4658 6.20944 22.7924 6.61237 22.7924H24.2383C24.6412 22.7924 24.968 22.4658 24.968 22.0628Z"
                fill="url(#paint3_linear_3307_11722)"
            />
            <path
                d="M15.7255 27.3832C16.1285 27.3832 16.4551 27.0566 16.4551 26.6536C16.4551 26.2506 16.1285 25.9241 15.7255 25.9241H6.61237C6.20944 25.9241 5.88281 26.2507 5.88281 26.6536C5.88281 27.0567 6.20944 27.3832 6.61237 27.3832H15.7255Z"
                fill="url(#paint4_linear_3307_11722)"
            />
            <path
                d="M5.73305 41.5692C6.1208 41.6802 6.5242 41.455 6.63464 41.0674C6.63933 41.0512 7.11792 39.4463 8.98111 39.4463C9.90079 39.4463 10.3373 39.8076 10.9415 40.3077C11.6357 40.8824 12.4998 41.5976 14.1693 41.5976C16.251 41.5976 17.37 39.3966 17.4167 39.3029C17.5958 38.9432 17.4495 38.5076 17.0904 38.3273C16.7313 38.1469 16.2933 38.2921 16.1115 38.6504C16.104 38.6653 15.3435 40.1383 14.1693 40.1383C13.0254 40.1383 12.5163 39.717 11.8719 39.1836C11.1943 38.6227 10.4264 37.9871 8.98101 37.9871C6.61889 37.9871 5.4953 39.7405 5.2312 40.6676C5.12086 41.0551 5.34558 41.4588 5.73305 41.5692Z"
                fill="url(#paint5_linear_3307_11722)"
            />
            <path
                d="M47.2847 7.49137C47.2125 7.31128 47.0716 7.16747 46.8931 7.09144L45.1978 6.37012C45.34 6.01069 45.1612 5.58403 44.8093 5.43197L44.4713 5.286L45.0198 4.01587C45.6393 2.57841 44.9541 0.855281 43.5181 0.234938C42.0831 -0.384937 40.3584 0.300469 39.7384 1.73494L39.1732 3.04322C38.5267 2.93053 37.8728 3.28575 37.6133 3.88669L36.0936 7.40597V3.67097C36.0936 1.64672 34.4467 0 32.4226 0H3.66994C1.64634 0 0 1.64634 0 3.66994V44.3301C0 46.3537 1.64634 48 3.66994 48H32.4236C34.4472 48 36.0935 46.3537 36.0935 44.3301V34.1152C36.0935 33.7122 35.7668 33.3857 35.3639 33.3857C34.961 33.3857 34.6343 33.7123 34.6343 34.1152V44.3301C34.6343 45.5491 33.6427 46.5408 32.4237 46.5408H3.66994C2.45091 46.5408 1.45922 45.5491 1.45922 44.3301V3.66994C1.45922 2.45091 2.451 1.45922 3.66994 1.45922H32.4226C33.6421 1.45922 34.6343 2.45138 34.6343 3.67097V10.7849L23.4513 36.6854C23.4124 36.7756 23.3907 36.8768 23.3909 36.9751L23.3994 43.121C23.4006 44.4923 25.0964 45.2308 26.1002 44.287L30.5784 40.0789C30.6403 40.0214 30.6922 39.9498 30.7299 39.8745C30.7339 39.8667 30.7378 39.859 30.7415 39.8511C30.7438 39.8461 30.7465 39.8413 30.7487 39.8363L44.6203 7.71019L45.6478 8.14734L40.2212 20.7663C40.062 21.1364 40.233 21.5656 40.6032 21.7248C40.6971 21.7653 40.7949 21.7843 40.891 21.7843C41.1738 21.7843 41.4428 21.6189 41.5616 21.3428L47.2776 8.05106C47.3542 7.87275 47.3568 7.67137 47.2847 7.49137ZM42.4616 1.46175C43.4293 1.51144 44.0601 2.55637 43.6799 3.43772L43.1317 4.70728L40.5292 3.58378L41.0779 2.31375C41.3128 1.77019 41.8678 1.42894 42.4616 1.46175ZM38.943 4.48837L43.5611 6.48234L37.6933 20.0719L33.0744 18.0774L38.943 4.48837ZM29.6983 38.5882L25.0803 36.5939L32.4961 19.4172L37.115 21.4117L29.6983 38.5882ZM25.1007 43.2237C25.0108 43.3081 24.8586 43.2434 24.8586 43.1196L24.8517 38.0847L28.7693 39.7763L25.1007 43.2237Z"
                fill="url(#paint6_linear_3307_11722)"
            />
            <defs>
                <linearGradient id="paint0_linear_3307_11722" x1="8.75456" y1="3.88986" x2="26.8281" y2="4.02718" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint1_linear_3307_11722" x1="5.3507" y1="12.106" x2="30.2295" y2="13.0196" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint2_linear_3307_11722" x1="5.3507" y1="16.6968" x2="30.2294" y2="17.6112" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint3_linear_3307_11722" x1="5.46532" y1="21.2877" x2="24.995" y2="21.8468" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint4_linear_3307_11722" x1="5.65154" y1="25.8785" x2="16.4767" y2="26.0543" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint5_linear_3307_11722" x1="4.93428" y1="37.8742" x2="17.5201" y2="37.9677" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
                <linearGradient id="paint6_linear_3307_11722" x1="-1.0355" y1="-1.5" x2="47.4446" y2="-1.39438" gradientUnits="userSpaceOnUse">
                    <stop stopColor={COLOR_SCHEME.blue.light} />
                    <stop offset="1" stopColor={COLOR_SCHEME.blue.primary} />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default SummarySvg;

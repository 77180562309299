import { Account, AccountRights } from "@vaultinum/vaultinum-api";
import { IconTag, RowCards, displayMostRecentDate, invitePartner, openNotificationWithIcon, useHasAccountRights, useLang } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../lang/AccountLang";
import { cancelInvitation } from "../services";

export default function SentInvitationCard({ invitation, accountId }: { invitation: Account.PartnerInvitation; accountId: string }): JSX.Element {
    const lang = useLang<AccountLang>();
    const canUserInvite = useHasAccountRights(AccountRights.INVITE);

    async function onClickResend() {
        try {
            await invitePartner(invitation.sentToEmail, accountId, true);
            openNotificationWithIcon({ type: "success", description: lang.shared.inviteSuccessMessage });
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: lang.shared.inviteErrorMessage });
        }
    }

    async function onClickCancel() {
        try {
            await cancelInvitation(accountId, invitation.sentToEmail);
            openNotificationWithIcon({ type: "success", description: lang.accountSettings.partiesView.invitationCancelled });
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
        }
    }

    const actions = canUserInvite
        ? [
              { "data-id": "re-send", onClick: onClickResend, label: lang.accountSettings.userAccessView.resendInvite },
              { "data-id": "cancel", onClick: onClickCancel, label: lang.accountSettings.userAccessView.cancelInvitation, danger: true }
          ]
        : [];

    return (
        <RowCards.WithActions actions={actions} color="orange">
            <div className="flex-1">
                <div>{invitation.sentToEmail}</div>
                <div className="text-grey-primary">{displayMostRecentDate({ requestedDate: invitation.sentDate }, lang, true)}</div>
            </div>
            <IconTag.Pending message={lang.accountSettings.userAccessView.invitePending} />
        </RowCards.WithActions>
    );
}

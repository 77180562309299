import { deepRemoveUndefined, mapDeepObject, New } from "@vaultinum/vaultinum-api";
import {
    CollectionReference,
    DocumentData,
    DocumentReference,
    FieldValue,
    FirestoreDataConverter,
    getDoc,
    getDocs,
    onSnapshot,
    PartialWithFieldValue,
    Query,
    QueryDocumentSnapshot,
    Timestamp,
    Unsubscribe
} from "firebase/firestore";

export type DateAsTimestamp = Record<`${string}Date`, FieldValue>;
export type NewWithoutDate<T> = Omit<New<T>, `${string}Date`>;
export const REQUEST_LIMIT = 50;

function isFieldValue(value: unknown): value is FieldValue {
    return value instanceof FieldValue;
}

function isFirestoreTimestamp(value: unknown): value is Timestamp {
    return value instanceof Timestamp || (typeof value === "object" && value !== null && "_seconds" in value && "_nanoseconds" in value);
}

function convertTimestampToDate(value: Timestamp): Date {
    return value.toDate();
}

export function converter<T extends object>({ idKey, map }: { idKey?: keyof T; map?: (data: T) => T } = {}): FirestoreDataConverter<T> {
    return {
        // Only triggered by add & set function (not update)
        toFirestore: (data: PartialWithFieldValue<T>): DocumentData => deepRemoveUndefined(data, isFieldValue),
        fromFirestore: (snapshot: QueryDocumentSnapshot<T>): T => {
            const data = mapDeepObject<T, Timestamp>(snapshot.data(), isFirestoreTimestamp, convertTimestampToDate);
            const result = {
                ...data,
                [idKey ?? "id"]: snapshot.id
            };
            return map?.(result) ?? result;
        }
    };
}

// Firestore data fetcher
export function getItem<T extends object>(docReference: DocumentReference<T>): Promise<T | null>;
export function getItem<T extends object>(docReference: DocumentReference<T>, onUpdate: (item: T | null) => void): Unsubscribe;
export function getItem<T extends object>(docReference: DocumentReference<T>, onUpdate?: (item: T | null) => void): Promise<T | null> | Unsubscribe {
    if (onUpdate) {
        return onSnapshot(docReference, querySnapshot => onUpdate(querySnapshot.data() ?? null));
    }
    return getDoc(docReference).then(doc => (doc.exists() ? doc.data() : null));
}

export function getItems<T>(colReference: CollectionReference<T> | Query<T>): Promise<T[]>;
export function getItems<T>(colReference: CollectionReference<T> | Query<T>, onUpdate: (items: T[]) => void): Unsubscribe;
export function getItems<T extends object>(colReference: CollectionReference<T> | Query<T>, onUpdate?: (items: T[]) => void): Promise<T[]> | Unsubscribe {
    if (onUpdate) {
        return onSnapshot(colReference, querySnapshot => onUpdate(querySnapshot.docs.map(doc => doc.data())));
    }
    return getDocs(colReference).then(querySnapshot => querySnapshot.docs.map(doc => doc.data()));
}

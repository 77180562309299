import classNames from "classnames";
import { Size } from "../../referentials";

export function Label({
    size = "xs",
    label,
    disabled,
    required,
    htmlFor
}: {
    size?: Extract<Size, "xs" | "sm" | "md">;
    label?: React.ReactNode;
    disabled?: boolean;
    required?: boolean;
    htmlFor?: string;
}) {
    return (
        <label
            className={classNames(`text-${size} font-semibold truncate`, {
                "text-grey-primary": disabled,
                "text-slate-dark": !disabled,
                "after:ml-1 after:text-danger after:content-['*']": required
            })}
            children={label}
            htmlFor={htmlFor}
        />
    );
}

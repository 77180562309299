import { ReactNode } from "react";

export default function IconedButton({ label, icon, onClick }: { label: string; icon: ReactNode; onClick?: () => void }): JSX.Element {
    return (
        <div className="flex flex-col items-center space-y-6">
            {icon}
            <button className="btn-cta w-72" onClick={onClick}>
                {label}
            </button>
        </div>
    );
}

import { CardElement, useElements } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Fieldset, FieldsetInput, Input } from "../../../../design-system";
import { CommonLang, useLang } from "../../../../lang";
import { usePaymentMethodFormContext } from "../../../contexts";

function isNameValid(name: string | undefined): boolean {
    return !!name?.trim();
}

export default function CreditCardForm(): JSX.Element {
    const lang = useLang<CommonLang>();
    const elements = useElements();
    const { setIsFormValid, isDisabled, cardName, setCardName, setCardElement } = usePaymentMethodFormContext();
    const [fieldsetType, setFieldsetType] = useState<"danger" | "success" | undefined>();
    const [hasAccepted, setHasAccepted] = useState<boolean>(false);

    useEffect(() => {
        setIsFormValid(fieldsetType === "success" && hasAccepted);
    }, [fieldsetType, hasAccepted]);

    function onCardElementChange({ error, empty, complete }: StripeCardElementChangeEvent) {
        const cardElement = elements?.getElement(CardElement);
        if (cardElement) {
            setCardElement(cardElement);
        }
        if (error) {
            setFieldsetType("danger");
        } else if (complete) {
            setFieldsetType("success");
        } else if (empty || !complete) {
            setFieldsetType(undefined);
        }
    }

    return (
        <>
            <FieldsetInput
                onChange={e => setCardName(e.target.value)}
                label={lang.payment.payment.creditCard.cardName}
                placeholder={lang.shared.name}
                isDisabled={isDisabled}
                type={isNameValid(cardName) ? "success" : undefined}
                className="w-full"
            />
            <Fieldset label={lang.payment.payment.creditCard.name} type={fieldsetType} isRequired>
                <CardElement
                    onChange={onCardElementChange}
                    options={{
                        hidePostalCode: true,
                        disabled: isDisabled
                    }}
                    className="max-w-2xl bg-white py-1"
                />
            </Fieldset>
            <Input.Checkbox
                checked={hasAccepted}
                onChange={e => setHasAccepted(e.target.checked)}
                label={lang.payment.payment.creditCard.saveAcceptance}
                id="accept-terms"
                required
                data-id="accept-terms"
            />
        </>
    );
}

import { Context, createContext } from "react";

// eslint-disable-next-line
let langContext: Context<any> | null = null;
function getLangContext<T>(): Context<T> {
    if (!langContext) {
        langContext = createContext<T>({} as T);
    }
    return langContext;
}

export default getLangContext;

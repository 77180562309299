import { UserProfile } from "@vaultinum/vaultinum-api";
import { ElementType } from "react";
import { AdaptiveLink, LogoutIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import AccountDropDownMenu from "./AccountDropDownMenu";
import AppsDropdownMenu, { AppMenuItem } from "./AppsDropdownMenu";
import SettingsDropdownMenu from "./SettingsDropdownMenu";

function LinkWithIcon({
    title,
    AppIcon,
    isAccountApp,
    onClick,
    href
}: {
    title: string;
    AppIcon: ElementType;
    isAccountApp?: boolean;
    onClick?: () => void;
    href?: string;
}) {
    return (
        <div className="flex w-full justify-end text-slate-primary">
            <AdaptiveLink
                {...(href && { href })}
                {...(onClick && { onClick })}
                routed={isAccountApp}
                children={
                    <div className="flex items-center justify-center gap-2 rounded bg-blue-extra-light p-2">
                        <AppIcon />
                        <span>{title}</span>
                    </div>
                }
            />
        </div>
    );
}

export default function TopNavBar({
    onLogout,
    appsMenuItems = [],
    userProfile,
    onAppItemClick,
    layout = "full"
}: {
    onLogout: () => Promise<void>;
    appsMenuItems?: AppMenuItem[];
    userProfile?: UserProfile | null;
    onAppItemClick?: (item: AppMenuItem) => void;
    layout?: "logout-only" | "minimal" | "full";
}): JSX.Element {
    const lang = useLang<CommonLang>();
    return (
        <header className="relative top-0 z-40 h-[4.5rem] w-full bg-white p-5 drop-shadow-sm">
            <nav className="flex h-full w-full items-center justify-between gap-6">
                {layout === "logout-only" && <LinkWithIcon title={lang.settingsMenu.signOut} AppIcon={LogoutIcon} onClick={onLogout} />}
                {layout === "minimal" && userProfile && (
                    <div className="flex w-full justify-end">
                        <SettingsDropdownMenu userProfile={userProfile} onLogout={onLogout} layout={layout} />
                    </div>
                )}
                {layout === "full" && userProfile && (
                    <>
                        <AppsDropdownMenu appsMenuItems={appsMenuItems} onItemClick={onAppItemClick} />
                        <div className="flex flex-row items-center gap-6">
                            <AccountDropDownMenu />
                            <SettingsDropdownMenu userProfile={userProfile} onLogout={onLogout} layout={layout} />
                        </div>
                    </>
                )}
            </nav>
        </header>
    );
}

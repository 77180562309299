/* eslint-disable import/no-unused-modules */
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ElementType } from "react";

export function Dropdown(props: Omit<DropdownMenu.DropdownMenuProps, "modal">) {
    return <DropdownMenu.Root {...props} modal={false} />;
}

export interface DropdownActionProps extends Omit<DropdownMenu.DropdownMenuItemProps, "className" | "children" | "disabled"> {
    label: string;
    "data-id"?: string;
    href?: string;
    icon?: ElementType;
    isDisabled?: boolean;
}

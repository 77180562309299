import { Address, isDefined } from "@vaultinum/vaultinum-api";
import { formatDurationFromYears } from "../../helpers";
import { CommonLang } from "../../lang";
import { SHARED_URL, addQueryParamsToUrl } from "../services";

function trimAndJoin(array: (string | undefined)[], separator = " "): string {
    return array
        .filter(isDefined)
        .map(str => str.trim())
        .filter(str => str.length)
        .join(separator);
}

export function formatAddress(address: Address): string {
    return trimAndJoin([
        trimAndJoin(
            [trimAndJoin([trimAndJoin([address.line1, address.line2], ", "), trimAndJoin([address.postalCode, address.city])], " - "), address.country],
            " - "
        )
    ]);
}

export function separateWithBullet<T>(data: T, i: number): (string | T)[] {
    return [...(i > 0 ? [" • "] : []), data];
}

// Since app names are translated in each language, we need to use a common name to avoid duplicates in our tracking service
export function getAppNameForTrackingService(url: string): string {
    switch (url) {
        case SHARED_URL.app.deposit:
            return "Deposit";
        case SHARED_URL.app.escrow:
            return "Escrow";
        case SHARED_URL.app.kys.fullAudit:
            return "Full Audit";
        case SHARED_URL.app.timestamping:
            return "Timestamping";
        default:
            return "Unknown app";
    }
}

export function formatName<T extends { firstName: string; lastName: string }>({ firstName, lastName }: T): string {
    return trimAndJoin([firstName, lastName]);
}

export function formatDisplayName<T extends Parameters<typeof formatName>[0] & { email: string }>(user: T): string {
    return formatName(user) || user.email;
}

export function formatEmailHref(to: string, subject?: string) {
    return addQueryParamsToUrl(`mailto:${to}`, { subject });
}

export function formatYearAbbreviation(lang: CommonLang, value: string | number): string | number {
    return typeof value === "number" ? `${value}${lang.chart.yearAbbreviation}` : value;
}

export function formatYearMonthAbbreviation(lang: CommonLang, value: string | number): string | number {
    return typeof value === "number" ? formatDurationFromYears(lang, value) : value;
}

import { CodeAnalysis, Question, ScoreImpactEvaluation, SurveyReport } from "@vaultinum/vaultinum-api";
import { COLOR_SCHEME } from "../../design-system/referentials";

const VERY_NEGATIVE_COEFF = 2 / 3;
const ReportColors = {
    COLOR_SLATE: COLOR_SCHEME.slate.primary,
    COLOR_GREEN: COLOR_SCHEME.green.primary,
    COLOR_GREEN_BRIGHTEN_10: COLOR_SCHEME.green.light,
    COLOR_GREEN_BRIGHTEN_20: COLOR_SCHEME.green.extraLight,
    COLOR_ORANGE: COLOR_SCHEME.orange.primary,
    COLOR_RED: COLOR_SCHEME.red.primary,
    COLOR_RED_BRIGHTEN_10: COLOR_SCHEME.red.light,
    COLOR_RED_BRIGHTEN_20: COLOR_SCHEME.red.extraLight,
    COLOR_UNKNOWN: "#7BC27B",
    COLOR_YELLOW: "#E0BC00"
};

export function formatRiskColor(risk?: CodeAnalysis.Risk, variant?: number): string {
    switch (risk) {
        case CodeAnalysis.Risk.HIGH:
            return (
                (variant && [ReportColors.COLOR_RED, ReportColors.COLOR_RED_BRIGHTEN_10, ReportColors.COLOR_RED_BRIGHTEN_20][variant]) || ReportColors.COLOR_RED
            );
        case CodeAnalysis.Risk.MEDIUM:
            return ReportColors.COLOR_ORANGE;
        case CodeAnalysis.Risk.LOW:
            return ReportColors.COLOR_GREEN_BRIGHTEN_10;
        case CodeAnalysis.Risk.NONE:
            return (
                (variant && [ReportColors.COLOR_GREEN, ReportColors.COLOR_GREEN_BRIGHTEN_10, ReportColors.COLOR_GREEN_BRIGHTEN_20][variant]) ||
                ReportColors.COLOR_GREEN
            );
        case CodeAnalysis.Risk.UNKNOWN:
        default:
            return ReportColors.COLOR_UNKNOWN;
    }
}

export function formatRiskText(risk?: CodeAnalysis.Risk): string {
    return `${risk === CodeAnalysis.Risk.NONE ? "NO" : risk} RISK`;
}

export function formatScoreColor(score: number): string {
    if (score > 75) {
        return formatRiskColor(CodeAnalysis.Risk.NONE);
    }
    if (score > 50) {
        return formatRiskColor(CodeAnalysis.Risk.LOW);
    }
    if (score > 25) {
        return formatRiskColor(CodeAnalysis.Risk.MEDIUM);
    }
    return formatRiskColor(CodeAnalysis.Risk.HIGH);
}

export function formatScoreText(score: number) {
    return score !== null && Number.isFinite(score) ? score : "-";
}

export function formatScoreToRiskColor(score: number) {
    if (score <= 4) {
        return formatRiskColor(CodeAnalysis.Risk.LOW);
    }
    if (score <= 7) {
        return formatRiskColor(CodeAnalysis.Risk.MEDIUM);
    }
    return formatRiskColor(CodeAnalysis.Risk.HIGH);
}

export function formatPriorityColor(priority: CodeAnalysis.Priority): string {
    switch (priority) {
        case CodeAnalysis.Priority.VERY_HIGH:
            return ReportColors.COLOR_RED;
        case CodeAnalysis.Priority.HIGH:
            return ReportColors.COLOR_RED_BRIGHTEN_10;
        case CodeAnalysis.Priority.NORMAL:
            return ReportColors.COLOR_ORANGE;
        case CodeAnalysis.Priority.LOW:
            return ReportColors.COLOR_GREEN_BRIGHTEN_10;
        case CodeAnalysis.Priority.VERY_LOW:
            return ReportColors.COLOR_GREEN_BRIGHTEN_20;
        default:
            return ReportColors.COLOR_UNKNOWN;
    }
}

export function formatEvaluationColor(evaluation?: Question.Evaluation): string {
    if (evaluation === Question.Evaluation.Critical) {
        return ReportColors.COLOR_RED;
    }
    if (evaluation === Question.Evaluation.High) {
        return ReportColors.COLOR_ORANGE;
    }
    if (evaluation === Question.Evaluation.Warning) {
        return ReportColors.COLOR_YELLOW;
    }
    if (evaluation === Question.Evaluation.Positive) {
        return ReportColors.COLOR_GREEN;
    }
    return ReportColors.COLOR_SLATE;
}

export function formatScoreImpactEvaluationColor(evaluation?: ScoreImpactEvaluation): string {
    if (evaluation === ScoreImpactEvaluation.CRITICAL) {
        return ReportColors.COLOR_RED;
    }
    if (evaluation === ScoreImpactEvaluation.HIGH) {
        return ReportColors.COLOR_ORANGE;
    }
    if (evaluation === ScoreImpactEvaluation.LOW) {
        return ReportColors.COLOR_YELLOW;
    }
    return ReportColors.COLOR_SLATE;
}

export function formatBenchmarkColor(evaluation: SurveyReport.Evaluation): string {
    switch (evaluation) {
        case SurveyReport.Evaluation.POSITIVE:
            return ReportColors.COLOR_GREEN;
        case SurveyReport.Evaluation.IN_RANGE:
            return ReportColors.COLOR_GREEN_BRIGHTEN_10;
        case SurveyReport.Evaluation.NEGATIVE:
            return ReportColors.COLOR_ORANGE;
        case SurveyReport.Evaluation.VERY_NEGATIVE:
            return ReportColors.COLOR_RED;
        default:
            return ReportColors.COLOR_SLATE;
    }
}

export function formatScoreToBenchmarkEvaluation(score: number, benchmarkScore?: number, tolerance = 5): SurveyReport.Evaluation {
    if (!benchmarkScore) {
        return SurveyReport.Evaluation.NONE;
    }
    if (score >= benchmarkScore) {
        return SurveyReport.Evaluation.POSITIVE;
    }
    if (score >= benchmarkScore - tolerance) {
        return SurveyReport.Evaluation.IN_RANGE;
    }
    if (score <= benchmarkScore * VERY_NEGATIVE_COEFF) {
        return SurveyReport.Evaluation.VERY_NEGATIVE;
    }
    if (score < benchmarkScore - tolerance) {
        return SurveyReport.Evaluation.NEGATIVE;
    }
    return SurveyReport.Evaluation.NONE;
}

export function formatScoreToBenchmarkColor(score: number, benchmarkScore?: number, tolerance?: number): string {
    const evaluation = formatScoreToBenchmarkEvaluation(score, benchmarkScore, tolerance);
    return formatBenchmarkColor(evaluation);
}

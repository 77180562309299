import { ArcElement, Chart as ChartJS, Legend, LinearScale, RadialLinearScale, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Color from "color";
import { PolarArea } from "react-chartjs-2";
import { COLOR_SCHEME, Size } from "../../../referentials";
ChartJS.register(LinearScale, RadialLinearScale, ArcElement, Tooltip, Legend);

const TRANSPARENT_COLOR = Color("white").alpha(0).hexa();

export type PolarChartData = {
    label: string;
    value: number;
    refValue?: number;
    color: string;
};

const LABEL_SIZE = {
    xxs: 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20
};

export function PolarChart({
    data,
    valueLabel,
    refValueLabel,
    className,
    fontSize = "lg"
}: {
    data: PolarChartData[];
    valueLabel?: string;
    refValueLabel?: string;
    className?: string;
    fontSize?: Size;
}) {
    const hasRefValue = data.every(({ refValue }) => refValue !== undefined);
    const options = {
        scales: {
            r: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 20
                }
            }
        },
        interaction: {
            mode: "point"
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                mode: "index",
                filter: (ctx: { datasetIndex: number }) => [1, 3].includes(ctx.datasetIndex),
                callbacks: {
                    labelColor: function (ctx: { datasetIndex: number; dataIndex: number; dataset: { borderColor: string; backgroundColor: string[] } }) {
                        if (ctx.datasetIndex === 3) {
                            return {
                                borderColor: ctx.dataset.borderColor,
                                borderWidth: 4,
                                borderRadius: 2
                            };
                        }
                        return {
                            backgroundColor: ctx.dataset.backgroundColor[ctx.dataIndex],
                            borderWidth: 2,
                            borderRadius: 2
                        };
                    },
                    label: function (ctx: { datasetIndex: number; formattedValue: number }) {
                        if ([1, 3].includes(ctx.datasetIndex)) {
                            return [ctx.datasetIndex === 1 ? valueLabel : refValueLabel, `${ctx.formattedValue}%`].filter(Boolean).join(" - ");
                        }
                        return null;
                    }
                }
            },
            datalabels: {
                anchor: "end",
                backgroundColor: COLOR_SCHEME.grey.extraLight,
                borderRadius: 4,
                padding: 5,
                font: {
                    size: LABEL_SIZE[fontSize]
                },
                formatter: function (_: number, context: { datasetIndex: number; dataIndex: number }) {
                    // Use the first dataset that has fixed values (80) to ensure labels are allways at the same place.
                    return context.datasetIndex === 0 ? data[context.dataIndex].label : null;
                }
            }
        }
    };
    const datasets = [];
    // This data set is only used to have a more precise positioning of the labels
    datasets.push({
        data: data.map(() => 80),
        backgroundColor: TRANSPARENT_COLOR,
        borderColor: TRANSPARENT_COLOR,
        hoverBorderColor: TRANSPARENT_COLOR
    });
    // Value dataset
    datasets.push({
        data: data.map(({ value }) => value),
        backgroundColor: data.map(({ color }) => Color(color).alpha(0.65).hexa()),
        hoverBackgroundColor: data.map(({ color }) => Color(color).alpha(0.8).hexa()),
        borderWidth: "1"
    });
    // RefValue dataset
    if (hasRefValue) {
        // Extra dataset used to add a "white" border around the refValue one.
        // Since the ref value already uses a border for the display, another is required to add a padding simulation around it
        datasets.push({
            data: data.map(({ refValue }) => refValue),
            backgroundColor: TRANSPARENT_COLOR,
            borderWidth: "1",
            borderAlign: "inner"
        });
        datasets.push({
            data: data.map(({ refValue }) => refValue),
            backgroundColor: TRANSPARENT_COLOR,
            hoverBackgroundColor: Color(COLOR_SCHEME.slate.light).alpha(0.5).hexa(),
            borderColor: Color(COLOR_SCHEME.slate.primary).alpha(0.5).hexa(),
            borderWidth: "6",
            borderAlign: "inner"
        });
    }

    const chartData = {
        labels: data.map(({ label }) => label),
        datasets
    };

    return (
        <PolarArea
            className={className}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data={chartData as any} // "any" required as the "borderWidth" and "borderAlign" properties are not recognized
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            options={options as any} // "any" required as the "datalabels" property is part of the DataLabels plugin and not recognized by default
            plugins={[ChartDataLabels]}
        />
    );
}

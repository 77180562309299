import { InvitationFeedback } from "@vaultinum/vaultinum-api";
import { addQueryParamsToUrl, AuthenticationLogo, CardWithFeedback, useLang, useUrlSearch } from "@vaultinum/vaultinum-sdk";
import { FeedbackButton } from "../../components/FeedbackButton";
import { AccountLang } from "../../lang/AccountLang";
import { ALLOWED_DOMAIN, ALLOWED_SUBDOMAIN_SUFFIX, URL as RoutingUrl } from "../../services/routingService";
import ResetPasswordPage from "./ResetPasswordPage";
import VerifyEmailPage from "./VerifyEmailPage";

export default function EmailActionHandlers(): JSX.Element | null {
    const {
        mode,
        oobCode,
        message,
        status,
        continueUrl
    }: {
        mode?: "resetPassword" | "recoverEmail" | "verifyEmail";
        oobCode?: string;
        message?: InvitationFeedback;
        status?: "success" | "error";
        continueUrl?: string;
    } = useUrlSearch();
    let feedBackStatus = status || "error";
    const lang = useLang<AccountLang>();

    if (continueUrl) {
        const parsedUrl = new URL(continueUrl);
        if (parsedUrl.hostname === ALLOWED_DOMAIN || parsedUrl.hostname.endsWith(ALLOWED_SUBDOMAIN_SUFFIX)) {
            window.location.assign(addQueryParamsToUrl(`${continueUrl}${RoutingUrl.authAction}`, { mode, message, status, oobCode }));
            return <></>;
        }
    }

    if (mode === "resetPassword") {
        return <ResetPasswordPage />;
    }
    if (mode === "verifyEmail") {
        return <VerifyEmailPage />;
    }

    function getHumanReadableMessage(messageKey?: InvitationFeedback): string {
        switch (messageKey) {
            case InvitationFeedback.INVITATION_NOT_FOUND:
                return lang.invitation.invitationNotFound;
            case InvitationFeedback.INVITATION_ALREADY_ACCEPTED:
                return lang.invitation.invitationAlreadyAccepted;
            case InvitationFeedback.INVITATION_CANCELED:
                return lang.invitation.invitationCanceled;
            case InvitationFeedback.INVITATION_REJECTED:
                return lang.invitation.invitationRejected;
            case InvitationFeedback.ACCOUNT_NO_LONGER_EXISTS:
                return lang.invitation.accountNoLongerExists;
            case InvitationFeedback.WRONG_MATCHING_EMAIL:
                return lang.invitation.wrongMatchingEmail;
            case InvitationFeedback.MISSING_DATA:
                return lang.invitation.missingData;
            default:
                // Force error status on unknown code
                feedBackStatus = "error";
                return lang.invitation.unknownCode;
        }
    }

    const humanReadableMessage = getHumanReadableMessage(message);

    return (
        <CardWithFeedback
            extra={<AuthenticationLogo />}
            status={feedBackStatus}
            successTitle={humanReadableMessage}
            successExtra={<FeedbackButton label={lang.shared.back} dest={RoutingUrl.login} />}
            errorTitle={humanReadableMessage}
            errorExtra={<FeedbackButton label={lang.shared.back} dest={RoutingUrl.login} />}
        />
    );
}

import { UserProfile } from "@vaultinum/vaultinum-api";
import { User } from "@vaultinum/vaultinum-sdk";
import { trim } from "lodash";
import { URL } from "../services/routingService";

export function getPrioritaryUrl(user: User | null, userProfile: UserProfile | null): string | null {
    if (!!user && !user.emailVerified) {
        return URL.verify;
    }

    if (!trim(userProfile?.firstName) || !trim(userProfile?.lastName) || !userProfile?.accountIds?.length) {
        return URL.welcome;
    }
    return null;
}

import { WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { createContext, useContext, useMemo } from "react";

export interface WhiteLabelProps {
    whiteLabelDomain: WhiteLabelDomain | null;
    domainLogo?: string;
}
const Context = createContext<WhiteLabelProps>({} as WhiteLabelProps);

export function WhiteLabelContextProvider({ children, value }: { children: React.ReactNode; value: WhiteLabelProps }) {
    const exposed = useMemo((): WhiteLabelProps => value, [value]);
    return <Context.Provider value={exposed} children={children} />;
}

export function useWhiteLabelContext(): WhiteLabelProps {
    return useContext(Context);
}

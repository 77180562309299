import classNames from "classnames";
import { motion } from "framer-motion";
import { ElementType, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import useMeasure from "react-use-measure";
import { ChevronDownIcon, IconProps, ROTATE_OPEN_VARIANT, Separator } from "../../../design-system";
import { useToggle } from "../../hooks";

export type SubItemProps = {
    key: string;
    text: string;
    href: string;
    icon?: ElementType<IconProps>;
    count?: number;
};

export type SubItemSection = string;

export type SubMenuItemProps = {
    text: string;
    items: (SubItemProps | SubItemSection)[];
    sectionTitle?: string;
};

export default function SubMenuItem({ subMenuItem, activeSubMenuItemKey }: { subMenuItem: SubMenuItemProps; activeSubMenuItemKey?: string }) {
    const [isExpanded, toggleExpanded] = useToggle();
    const [ref, bounds] = useMeasure();
    const navigate = useNavigate();

    const sectionBodyVariants = {
        open: { height: bounds.height },
        closed: { height: 0 }
    };

    return (
        <div className="flex flex-col">
            <div className="flex h-12 cursor-pointer select-none items-center justify-between px-4 font-bold text-slate-dark" onClick={toggleExpanded}>
                {subMenuItem.text}
                <motion.div initial="open" animate={isExpanded ? "open" : "closed"} variants={ROTATE_OPEN_VARIANT}>
                    <ChevronDownIcon color="slate" size="md" />
                </motion.div>
            </div>
            <motion.div className="overflow-hidden" initial="open" animate={isExpanded ? "open" : "closed"} variants={sectionBodyVariants}>
                <ol ref={ref}>
                    {subMenuItem.items.map((subItem: SubItemProps | SubItemSection) => {
                        if (typeof subItem === "string") {
                            return (
                                <Fragment key={subItem}>
                                    <Separator />
                                    <div className="flex h-12 select-none items-center px-4 text-gray-300">{subItem}</div>
                                </Fragment>
                            );
                        }
                        const isActive = activeSubMenuItemKey === subItem.key;
                        const IconElement = subItem.icon;
                        return (
                            <li
                                onClick={() => navigate(subItem.href)}
                                className={classNames("group flex h-12 cursor-pointer items-center px-4 text-slate-dark", {
                                    "hover:bg-grey-extra-light": !isActive,
                                    "bg-blue-extra-light": isActive
                                })}
                                key={subItem.key}
                            >
                                <div className="flex flex-1 items-center gap-2 truncate font-semilight leading-5">
                                    {IconElement ? <IconElement color="slate" shade="light" /> : <span className="w-4" />}
                                    <span className="truncate flex-1" title={subItem.text}>
                                        {subItem.text}
                                    </span>
                                </div>
                                {subItem.count && (
                                    <div
                                        className={classNames("h-6 min-w-[1.5rem] rounded p-1 text-center text-xs font-bold text-slate-light", {
                                            "bg-white": isActive,
                                            "bg-blue-extra-light": !isActive
                                        })}
                                        children={subItem.count}
                                    />
                                )}
                            </li>
                        );
                    })}
                </ol>
            </motion.div>
        </div>
    );
}

import { IconSvgProps } from "../Icons";

export default function DataRoomSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.3334 5.83337H18.5301L16.4907 1.7547C16.2084 1.19001 15.6312 0.833374 15 0.833374H1.66664C0.746172 0.833374 0 1.57955 0 2.50001V37.5C0 38.4205 0.746172 39.1667 1.66664 39.1667H38.3333C39.2537 39.1667 39.9999 38.4205 39.9999 37.5V12.5V7.50001C40 6.57955 39.2538 5.83337 38.3334 5.83337ZM36.6666 9.16666V10.8333H21.0301L20.1967 9.16666H36.6666ZM3.33336 35.8334V4.16666H13.97L16.0094 8.24533L18.5094 13.2453C18.7917 13.8099 19.3688 14.1667 20.0001 14.1667H36.6667V35.8333H3.33336V35.8334Z" />
            <path d="M26.6668 22.5V22.4997C26.6668 18.8176 23.6823 15.8331 20.0002 15.8331C18.0795 15.8331 16.2858 16.6522 15.031 18.0555C14.4175 18.7417 14.4763 19.7953 15.1626 20.4089C15.8487 21.0224 16.9024 20.9635 17.5159 20.2773C18.1445 19.5744 19.0379 19.1664 20.0002 19.1664C21.8413 19.1664 23.3335 20.6585 23.3335 22.4997V22.5H13.3334C12.4129 22.5 11.6667 23.2462 11.6667 24.1667V32.5C11.6667 33.4205 12.4129 34.1667 13.3334 34.1667H26.6667C27.5872 34.1667 28.3334 33.4205 28.3334 32.5V24.1667C28.3334 23.2463 27.5873 22.5001 26.6668 22.5ZM25 30.8334H15V25.8334H25V30.8334Z" />
        </svg>
    );
}

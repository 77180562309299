import { Survey, SURVEY_VERSION_COLLECTION } from "@vaultinum/vaultinum-api";
import { collection, CollectionReference, converter, doc, DocumentReference, getItem, getItems, query, Unsubscribe, where } from "../../../common";
import { surveyDoc } from "./surveyService";

export function surveyVersionCollection(surveyKey: string): CollectionReference<Survey.Version> {
    return collection(surveyDoc(surveyKey), SURVEY_VERSION_COLLECTION).withConverter(
        converter<Survey.Version>({
            idKey: "version",
            map: data => ({
                ...data,
                version: Number(data.version),
                sections: data?.sections && typeof data.sections === "string" ? JSON.parse(data.sections) : []
            })
        })
    );
}

export function surveyVersionDoc(surveyKey: string, version: number): DocumentReference<Survey.Version> {
    return doc(surveyVersionCollection(surveyKey), String(version));
}

export function getSurveyVersion(surveyKey: string | undefined, version: number): Promise<Survey.Version | null> | undefined;
export function getSurveyVersion(surveyKey: string | undefined, version: number, onUpdate: (survey: Survey.Version | null) => void): Unsubscribe;
export function getSurveyVersion(
    surveyKey: string | undefined,
    version: number,
    onUpdate?: (survey: Survey.Version | null) => void
): Promise<Survey.Version | null> | Unsubscribe | undefined {
    if (!surveyKey) {
        return undefined;
    }
    if (onUpdate) {
        return getItem(surveyVersionDoc(surveyKey, version), onUpdate);
    }
    return getItem(surveyVersionDoc(surveyKey, version));
}

function getLatestSurveyVersions(surveyKey: string): Promise<Survey.Version[]> {
    return getItems(query(surveyVersionCollection(surveyKey), where("latest", "==", true), where("publishedLangs", "!=", [])));
}

export async function getLatestSurveyVersion(surveyKey: string): Promise<Survey.Version | null> {
    const surveysVersions = await getLatestSurveyVersions(surveyKey);
    if (surveysVersions.length === 1) {
        return surveysVersions[0];
    }
    return null;
}
